import {Component, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {ReferenceLoginRequest} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {ReferenceService} from '@app/services/reference.service';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';

@Component({
  selector: 'app-reference-login',
  templateUrl: './reference-login.component.html',
  styleUrls: ['./reference-login.component.scss']
})
export class ReferenceLoginComponent extends AbstractForm implements OnInit {

  model: ReferenceLoginRequest = new ReferenceLoginRequest();

  fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          key: 'pin',
          type: 'input',
          className: 'reference-pin',
          templateOptions: {
            pattern: '[0-9]+',
            type: 'password',
            label: this.tr(_t('REFERENCE.PIN')),
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              pattern: (error, field: FormlyFieldConfig) =>
                this.tr(_t('REFERENCE.ONLY_DIGITS'), {value: field.templateOptions.label}),
            },
          },
        },
      ]

    }
  ];
  private hash: string;

  constructor(private service: ReferenceService,
              private route: ActivatedRoute,
              private router: Router,
              private viewerAuthService: ViewerAuthService,
              private localizeService: LocalizeRouterService,
              protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit(): void {
    this.hash = this.route.snapshot.paramMap.get('hash');
  }

  submit() {
     this.service.pinLogin(this.hash, this.model).subscribe(response => {
       if (response) {
         this.viewerAuthService.storeReferenceLogin(this.hash, JSON.stringify(response), String(this.model.pin));

         this.router.navigate(
         [
           this.localizeService.translateRoute(`/reference/${this.hash}`)
         ],
         )
         .finally();
       }
     });
  }
}
