
<ng-container>
  <mat-tab-group (tabChange)="tabChange($event)" *ngIf="webCV?.profile?.skills?.categorized?.length > 0 || webCV?.profile?.skills?.uncategorized?.length > 0">
      <mat-tab label="{{ 'WEB_CV.TAB.CATEGORIES' | translate }}">
          <div class="categories-wrapper">
              <app-section-skills
                  [gravityTheme]="gravityTheme"
                  [webCV]="webCV"
                  [locale]="locale">
              </app-section-skills>
          </div>
      </mat-tab>

  </mat-tab-group>
  <div *ngIf="!webCV?.profile?.skills?.categorized[0]?.skills?.length && !webCV?.profile?.skills?.uncategorized?.length" class="text-center">
    <h3>{{'SKILLS.EMPTY_SKILLS' | translate}}</h3>
  </div>
</ng-container>
