import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractForm } from '@helpers/abstract.form';
import { Skill } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { _t } from '@helpers/string-helpers';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form-skills-new',
  templateUrl: './form-skills-new.component.html',
  styleUrls: ['./form-skills-new.component.scss']
})
export class FormSkillsNewComponent extends AbstractForm {
  @Input() locale: string;
  @Input() allSkills: Skill[];
  @Output() skillAdded: EventEmitter<Skill> = new EventEmitter<Skill>();
  public isButtonDisabled = true;
  model: Skill;
  fields: FormlyFieldConfig[];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit() {
    this.fields = [
      {
        fieldGroupClassName: 'd-flex',
        wrappers: [''],
        fieldGroup: [
          {
            key: 'newSkillsInput',
            type: 'input-autocomplete',
            className: 'col ps-0',
            templateOptions: {
              placeholder: this.tr(_t('SKILLS.ADD_SKILLS_COMPONENT.SKILL_PLACEHOLDER')),
              locale: () => this.locale,
              keyup: (field: FormlyFieldConfig, event?: any) => {
                this.checkSkill(this.model);
                if (event.keyCode === 13 && !this.isButtonDisabled) {
                  this.submit();
                }
              },
              allSkills: () => this.allSkills,
              setSkill: (skill) => {
                this.checkSkill(skill);
                this.model = skill;
              }
            }
          },
          {
            key: 'newSkillsButton',
            type: 'button-field',
            className: 'pe-0',
            expressionProperties: {
              'templateOptions.disabled': (model: any) => {
                return this.isButtonDisabled;
              },
            },
            templateOptions: {
              disabled: this.isButtonDisabled,
              buttonText: this.tr(_t('FORM.BUTTON_FILTER')),
              buttonClick: () => {
                this.submit();
              },
            },
          },
        ],
      }
    ];
  }

  submit() {
    if(!this.model) return;
    this.skillAdded.emit(this.model);
    this.form.controls.newSkillsInput.setValue(undefined);
    this.model = undefined;
  }

  private checkSkill(skill: Skill): void {
    if(!skill){
      this.isButtonDisabled = true;
      return;
    }
    this.isButtonDisabled = ! this.allSkills.some( x => x.nameLocalizations[this.locale] === skill.nameLocalizations[this.locale]);
  }
}
