import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Project, ProjectSkill, Skill, Tag} from '@app/models';
import {UntypedFormControl} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {debounceTime} from 'rxjs/operators';

enum SkillInputValidationState {
  INVALID,
  FROM_AUTOCOMPLETE,
  NEW_ITEM
}

interface SkillInputValidation {
  state: SkillInputValidationState;
  item: Skill;
}


@Component({
  selector: 'app-input-drag-autocomplete',
  templateUrl: './input-drag-autocomplete.component.html',
  styleUrls: ['./input-drag-autocomplete.component.scss'],
  animations: [
    trigger('showDropdown', [
      state('visible', style({
        maxHeight: '{{dropDownMaxHeight}}',
        opacity: 1,
        visibility: 'visible'
      }), {params: {dropDownMaxHeight: '9rem'}}),
      state('hidden', style({maxHeight: 0, opacity: 0, visibility: 'hidden'})),
      transition('hidden => visible', [animate('300ms ease-in')]),
    ]),
  ]
})
export class InputDragAutocompleteComponent extends FieldType implements OnInit, OnChanges {

  @ViewChild('textInput') public textInput;
  @ViewChildren('dropdownItem') public dropdownItem: QueryList<ElementRef>;

  // Input section
  textInputControl: UntypedFormControl;
  existedSkill: string;

  // Draggable section
  projectModel: Project;
  showDropdown = false;
  filteredItems: Skill[] = [];

  // Input section
  get disabled() {
    return this.to.disabled || false;
  }

  // Dropdown section
  get identifyBy() {
    return this.to.identifyBy || 'id';
  }

  // Dropdown section
  get maxHeight(): string {
    return this.to.maxHeight || '12rem';
  }

  // Draggable section
  get orderByField(): string {
    return this.to.orderBy || 'order';
  }

  ngOnInit() {
    this.textInputControl = new UntypedFormControl({value: '', disabled: this.disabled});

    this.textInputControl.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe((value: string) => {
        this.getAutocompleteItems(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && !changes.disabled.firstChange) {
      if (changes.disabled.currentValue) {
        this.textInputControl.disable();
      } else {
        this.textInputControl.enable();
      }
    }
  }

  // Input section
  onFocus() {
    this.getAutocompleteItems();
    this.showDropdown = true;
  }

  // Input section
  onKeyDown($event) {
    this.showDropdown = true;
    const inputText = this.textInputControl.value;
    if ($event.key === 'Enter') {
      this.submit($event, inputText);
    }
  }

  onItemKeydown($event: KeyboardEvent, item: Skill) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      this.onSkillSelect(item);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent($event: KeyboardEvent) {
    if (!this.dropdownItem) {
      return;
    }

    let focused = this.dropdownItem.first;
    let index = -1;

    const getIndex = (idx, direction) => {
      let elementIdx;
      if (idx === 0) {
        elementIdx = idx + direction;
        if (elementIdx === -1) {
          elementIdx = 0;
        }
      } else {
        elementIdx = idx + direction;
        if (elementIdx >= this.dropdownItem.length) {
          elementIdx = (this.dropdownItem.length === 1) ? 0 : this.dropdownItem.length - 1;
        }
        if (elementIdx <= 0) {
          elementIdx = 0;
        }
      }

      return (item, i: number) => i === elementIdx;
    };
    if (this.showDropdown && this.filteredItems.length > 0) {
      this.dropdownItem.map((el, i) => {
        if (el.nativeElement.classList.contains('is-focused')) {
          focused = el;
          index = i;
        }
        return el;
      });

      switch ($event.key) {
        case 'ArrowDown':
          focused.nativeElement.classList.remove('is-focused');
          focused.nativeElement.blur();
          const next = this.dropdownItem.find(getIndex(index, 1));
          next.nativeElement.classList.add('is-focused');
          next.nativeElement.focus();
          $event.preventDefault();
          break;
        case 'ArrowUp':
          focused.nativeElement.classList.remove('is-focused');
          focused.nativeElement.blur();
          const prev = this.dropdownItem.find(getIndex(index, -1));
          prev.nativeElement.classList.add('is-focused');
          prev.nativeElement.focus();
          $event.preventDefault();
          break;
      }

    }
  }

  // Input section
  onClickOutside() {
    this.showDropdown = false;
  }

  // Input section
  submit($event: any, inputText): void {
    $event.preventDefault();

    if (!!inputText === false) {
      return;
    }

    const skill = new Skill();
    skill.nameLocalizations[this.to.locale()] = inputText;
    this.addSkill(skill);
  }

  // Dropdown component
  onSkillSelect(value: Skill) {
    this.addSkill(value);
    if(this.field && this.field.templateOptions && this.field.templateOptions.onSelect){
      this.to.onSelect()
    }
  }


  // Dropdown component
  getAutocompleteItems(value?: string): Skill[] {
    const autocompleteItems = this.to.allSkills();
    if (!!autocompleteItems === false) {
      return;
    }
    const locale = this.to.locale();
    const items = autocompleteItems.filter(item => {
      const alreadyAdded = this.projectModel['skills'].findIndex(el => el.nameLocalizations[locale].toLowerCase() === item.nameLocalizations[locale].toLowerCase());
      if (alreadyAdded === -1) {
        return item;
      }
    });

    if (value && value.length > 0) {
      this.filteredItems = items.filter(item => item.nameLocalizations[locale].toLowerCase().includes(value.toLowerCase()));
    } else {
      this.filteredItems = items;
    }

    return this.filteredItems;
  }

  // Draggable section
  select(badge: Skill | Tag) {
    this.projectModel['skills'].map(item => {
      if (!!badge[this.identifyBy]) {
        if (item[this.identifyBy] === badge[this.identifyBy]) {
          item[this.to.isSelected] = !item[this.to.isSelected];
        }
      } else {
        if ((badge.constructor == Skill && item.nameLocalizations[this.to.locale()] === (<Skill>badge).nameLocalizations[this.to.locale()])
            || item.nameLocalizations[this.to.locale()] === (<Tag>badge).name) {
          item[this.to.isSelected] = !item[this.to.isSelected];
        }
      }
    });
  }

  // Draggable section
  delete(badge: Skill | Tag) {
    this.projectModel['skills'] = (this.projectModel['skills'] as Skill[]).filter((value) => value !== badge);
    if(this.field && this.field.templateOptions && this.field.templateOptions.onSelect){
      this.to.onSelect()
    }
  }

  // Input section
  private validateInputField(value: Skill): SkillInputValidation {
    const lastItem = value;
    const currentSkills = this.projectModel["skills"];

    const existsInAutocomplete = this.filteredItems.find(
      (item) => {
        return item.nameLocalizations[this.to.locale()].toLowerCase() === lastItem.nameLocalizations[this.to.locale()].toLowerCase();
      }
    );

    if (existsInAutocomplete) {
      return {
        state: SkillInputValidationState.FROM_AUTOCOMPLETE,
        item: existsInAutocomplete
      };
    }

    const existsInModel = currentSkills.filter(
      (item) => {
        return item.nameLocalizations[this.to.locale()].toLowerCase() === lastItem.nameLocalizations[this.to.locale()].toLowerCase();
      }
    );

    if (existsInModel.length >= 1) {
      this.existedSkill = lastItem.nameLocalizations[this.to.locale()];
      this.textInputControl.setErrors({skillAlreadyAdded: true});
      this.field.formControl.setErrors({skillAlreadyAdded: true});
      this.textInputControl.markAsTouched();
      return {
        state: SkillInputValidationState.INVALID,
        item: value
      };
    } else {
      this.existedSkill = null;
      this.textInputControl.setErrors(null);
      this.field.formControl.setErrors(null);
      this.textInputControl.markAsTouched();
      return {
        state: SkillInputValidationState.NEW_ITEM,
        item: value
      };
    }
  }

  private addSkill(skill: Skill) {
    switch (this.validateInputField(skill).state) {
      case SkillInputValidationState.INVALID:
        return;
      case SkillInputValidationState.FROM_AUTOCOMPLETE:
        const existedSkill = this.validateInputField(skill).item;
        this.projectModel["skills"].push(existedSkill as Skill & ProjectSkill);
        this.showDropdown = false;
        this.textInputControl.setValue(null);
        this.getAutocompleteItems(existedSkill.nameLocalizations[this.to.locale()]);
        setTimeout(() => {this.textInput.nativeElement.focus(); }, 250);
        break;
      case SkillInputValidationState.NEW_ITEM:
        this.projectModel["skills"].push(skill as Skill & ProjectSkill);
        this.showDropdown = false;
        this.textInputControl.setValue(null);
        this.getAutocompleteItems(skill.nameLocalizations[this.to.locale()]);
        break;
    }
  }
}
