import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {SharedCv} from '@app/models';
import {CvTemplateStructure, Section} from '@app/models/shared-cv/cv-template-structure';

@Injectable({
  providedIn: 'root'
})
export class CvGeneratorService {
  private generatorHost: string = environment.cvGeneratorHost;
  private apiHost: string = environment.apiHost;
  defaultCVTemplate: any = {
    background_image: null,
    bgPdfName: null,
    contentAlignment: null,
    contentFont: null,
    contentSize: null,
    contentWeight: null,
    cvSections: [
      {
        "marginX": 2.7777775893976786,
        "marginY": 25,
        "name": "image",
        "x": 25,
        "y": 25,
        "orderX": 1,
        "orderY": 1,
        "height": 175,
        "width": 27.55555368682497
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 2,
        "name": "teamIcon",
        "x": 25,
        "y": 225,
        "orderX": 1,
        "orderY": 3,
        "height": 175,
        "width": 27.55555368682497
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 2,
        "name": "blocker_0",
        "x": 25,
        "y": 200,
        "orderX": 1,
        "orderY": 2,
        "width": 94.22221583236926,
        "height": 23
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 25,
        "name": "skills",
        "x": 25,
        "y": 1025,
        "orderX": 1,
        "orderY": 6,
        "width": 94.22221583236926
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 25,
        "name": "projects",
        "x": 25,
        "y": 1225,
        "orderX": 1,
        "orderY": 7,
        "width": 94.22221583236926,
        "subsections": [
          "title",
          "company",
          "role",
          "skills",
          "description",
          "teamSize"
        ]
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 25,
        "name": "skillUps",
        "x": 25,
        "y": 1675,
        "orderX": 1,
        "orderY": 8,
        "width": 94.22221583236926,
        "subsections": [
          "title",
          "skillupType",
          "durationType",
          "link",
          "skills",
          "description"
        ]
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 27,
        "name": "contactInfo",
        "x": 25,
        "y": 425,
        "orderX": 1,
        "orderY": 4,
        "width": 94.22221583236926,
        "subsections": [
          "availability",
          "mobilePhone",
          "phone",
          "publicEmail",
          "address",
          "website",
          "dateOfBirth",
          "socialMedia"
        ]
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 25,
        "name": "rates",
        "x": 25,
        "y": 850,
        "orderX": 1,
        "orderY": 5,
        "width": 94.22221583236926
      },
      {
        "marginX": 2.7777775893976786,
        "marginY": 25,
        "name": "languages",
        "x": 25,
        "y": 1975,
        "orderX": 1,
        "orderY": 9,
        "width": 94.22221583236926
      },
      {
        "marginX": 2.999999796549493,
        "marginY": 26,
        "name": "bio",
        "x": 300,
        "y": 26,
        "orderX": 2,
        "orderY": 1,
        "width": 63.6666623489948
      },
      {
        "marginX": 2.999999796549493,
        "marginY": 3,
        "name": "teamBio",
        "x": 300,
        "y": 226,
        "orderX": 2,
        "orderY": 3,
        "width": 63.6666623489948
      }
    ],
    headerAlignment: null,
    headerBoxColor: null,
    accentColor: null,
    headerFont: null,
    headerSize: null,
    headerWeight: null,
    id: null,
    marginBottomP1: null,
    marginBottomP2: null,
    marginLeft: null,
    marginRight: null,
    marginTopP1: null,
    marginTopP2: null,
    subHeaderFont: null,
    subHeaderSize: null,
    subHeaderWeight: null,
    teamId: null
  };


  constructor(
    protected httpClient: HttpClient,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
  }

  public downloadCV(usernameOrHash:string, model: SharedCv, type: 'pdf' | 'doc'): Observable<any> {
    return this.httpClient.get(`${this.apiHost}/cv-template/hash/${usernameOrHash}`,  {headers: this.getHeaders()})
    .pipe(map(res => this.generateCV(model, res as CvTemplateStructure, type, (res as CvTemplateStructure)?.bgPdfName ? environment.s3PublicUrl +"/users/"+(res as CvTemplateStructure).bgPdfName : null)));
  }

  public downloadTeamCV(teamDomain:string, model: SharedCv, type: 'pdf' | 'doc'): Observable<any> {
    return this.httpClient.get(`${this.apiHost}/team/${teamDomain}/cv-template/`,  { headers: this.getHeaders()})
    .pipe(map(res => this.generateCV(model, res as CvTemplateStructure, type, (res as CvTemplateStructure)?.bgPdfName ? environment.s3PublicUrl +"/teams/"+(res as CvTemplateStructure).bgPdfName : null)));
  }

  checkAnonymizationPersonalInfo(data, structure: CvTemplateStructure): CvTemplateStructure {
    if (data.profile && !data.profile?.personal && !data.profile?.images?.avatar &&
      (structure.cvSections.some(section => section.name === 'image') && structure.cvSections.some(section => section.name === 'bio'))) {

      const imageSection = structure.cvSections.find(item => item.name === 'image');
      const bioSection = structure.cvSections.find(item => item.name === 'bio');

      if (imageSection.orderY === 1 && (bioSection.orderY === 1 && bioSection.orderX === 2)) {
        structure.cvSections = this.changeBioSectionPosition(structure.cvSections);
        structure.cvSections = this.removeImageSection(structure.cvSections);
      }
    }

    return structure;
  }

  changeBioSectionPosition(sections: Section[]): Section[] {
    sections.forEach(section => {
      if (section.name === 'bio') {
        section.width = 94.22221583236926;
        section.x = 25;
        section.orderX = 1;
      }
    });
    return sections;
  }

  removeImageSection(sections: Section[]): Section[] {
    return sections.filter(section => section.name !== 'image');
  }

  private generateCV(data, structure: CvTemplateStructure, type, background_image): Observable<any> {
    if (!structure) {
      structure = this.defaultCVTemplate;
    }

    this.removeInactive(data, structure);
    structure = this.checkAnonymizationPersonalInfo(data, structure);

    return this.httpClient.post(
      `${this.generatorHost}/cv-generate/`+(type=='doc'?'word':type),
      {data:data, structure:{...structure,
        marginTop:[structure.marginTopP1, structure.marginTopP2],
        marginBottom:[structure.marginBottomP1, structure.marginBottomP2],
        background_image:background_image}},
      {
        headers: this.getHeaders(),
        responseType: 'blob'
      },
    )
    .pipe(
      map((response: any) => response),
      map(data => data),
      tap(() => console.log(`download CV`)),
      catchError(this.handleError<any>(`download CV`))
    );
  }

  private removeInactive(data, structure: CvTemplateStructure){
    data.order.inactive.forEach(el =>{
      if (el.value == "qualification"){
        let idx = structure.cvSections.findIndex(section => section.name == "skillUps");
        if (idx > -1) structure.cvSections.splice(idx,1);
      }else if (el.value == "project"){
        let idx = structure.cvSections.findIndex(section => section.name == "projects");
        if (idx > -1) structure.cvSections.splice(idx,1);
      }else if (el.value == "profile"){
        let idx = structure.cvSections.findIndex(section => section.name == "languages");
        if (idx > -1) structure.cvSections.splice(idx,1);
        idx = structure.cvSections.findIndex(section => section.name == "bio");
        if (idx > -1) structure.cvSections.splice(idx,1);
        idx = structure.cvSections.findIndex(section => section.name == "languages");
        if (idx > -1) structure.cvSections.splice(idx,1);
        idx = structure.cvSections.findIndex(section => section.name == "contactInfo");
        if (idx > -1) structure.cvSections.splice(idx,1);
      }else{
        let idx = structure.cvSections.findIndex(section => section.name == el.value);
        if (idx > -1) structure.cvSections.splice(idx,1);
      }
    });
  }

  public trackCvDownloadForSingleUser(usernameOrHash: string, type: 'pdf' | 'doc'): Observable<any> {
    const trackType = this.getTrackType(type);
    const url = `${this.apiHost}/cv/track/download/${usernameOrHash}?type=${trackType}`;

    return this.trackDownload(url);
  }

  public trackCvSendoutDownloadForSingleUser(usernameOrHash: string, type: 'pdf' | 'doc'): Observable<any> {
    const trackType = this.getTrackType(type);
    const url = `${this.apiHost}/cv/sendout/track/download/${usernameOrHash}?type=${trackType}`;

    return this.trackDownload(url);
  }

  public trackCvDownloadForTeamMember(teamWebCvUniqueHash: string,
                                      teamIdOrDomain: string,
                                      groupIdOrUrl: string,
                                      sendOutUniqueHash: string,
                                      type: 'pdf' | 'doc'): Observable<any> {
    const trackType = this.getTrackType(type);
    let url = `${this.apiHost}/pub/team/${teamIdOrDomain}/download/track/download/${teamWebCvUniqueHash}?type=${trackType}`;

    if (groupIdOrUrl) {
      url = url + `&groupIdOrUrl=${groupIdOrUrl}`;
    } else if (sendOutUniqueHash) {
      url = url + `&sendOutUniqueHash=${sendOutUniqueHash}`;
    }

    return this.trackDownload(url);
  }

  private trackDownload(url: string): Observable<any> {
    return this.httpClient
      .post(
        url,
        '',
        {
          headers: this.getHeaders(),
          responseType: 'blob'
        },
      )
      .pipe(
        map((response: any) => response),
        map(data => data),
        tap(() => console.log(`track cv download`)),
        catchError(this.handleError<any>(`track download CV`))
      );
  }

  private getTrackType(type: 'pdf' | 'doc') {
    let trackType;
    switch (type) {
      case 'pdf':
        trackType = 'PDF';
        break;
      case 'doc':
        trackType = 'WORD';
        break;
    }

    return trackType;
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }
}
