<!-- <div class="language-switcher" *ngIf="langSwitcher">
  <app-lang-switcher
    (langChanged)="langSwitch($event)"
    [selectedLanguage]="lang">
  </app-lang-switcher>
</div>

<nb-layout>

  <nb-layout-header *ngIf="(hasHeader | async)" class="gravity-header" fixed>
 
    <app-gravity-logo class="mx-auto"></app-gravity-logo>

  </nb-layout-header>

  <nb-layout-column *ngIf="webCV">

    <div class="cv-container cv-section bg-white cv-section-main">
      <app-section-main [gravityTheme]="gravityTheme" [webCV]="webCV"></app-section-main>
    </div>

    <div class="cv-container mt-7">
      <app-cv-tabs
        [gravityTheme]="gravityTheme"
        [hasSidebar]="hasSidebar"
        [usernameOrHash]="usernameOrHash"
        [webCV]="webCV"
        [isSendout]="isSendout"
        [locale]="lang">
      </app-cv-tabs>
    </div>

    <app-web-cv-footer class="footer web-cv-footer pt-8"></app-web-cv-footer>

  </nb-layout-column>

</nb-layout> -->
<nb-layout>
  <nb-layout-column class="p-0">
    <div class="defaultCV">
      <div class="defaultCV-wrapper">
          <div class="row" *ngIf="webCV">
              <div class="col-xl-3">
                  <div class="cv-container cv-section bg-white cv-section-main">
                      <app-section-main [gravityTheme]="gravityTheme" [webCV]="webCV"></app-section-main>
                  </div>
              </div>
              <div class="col-xl-9">
                  <div class="tabbing-wrapper">
                      <app-cv-tabs
                        [gravityTheme]="gravityTheme"
                        [hasSidebar]="hasSidebar"
                        [usernameOrHash]="usernameOrHash"
                        [webCV]="webCV"
                        [isSendout]="isSendout"
                        [locale]="lang"
                        [teamWebCvUniqueHash]="webCV?.teamWebCvUniqueHash"
                        [teamDomain]="teamDomain"
                        [sendoutHash]="hash"
                        [groupUrl]="groupUrl">
                      </app-cv-tabs>
                  </div>
              </div>
          </div>
    
        </div>
      </div>
    </nb-layout-column>
    <nb-layout-footer>
    <div class="defaul-footer">
        <app-web-cv-footer *ngIf="!isTeamMember" class="footer web-cv-footer"></app-web-cv-footer>
    </div>
  </nb-layout-footer>
</nb-layout>
