import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'app-badge-wrapper',
  template: `
    <div class="{{sectionClass}}">
      <div class="badge-wrap-title" *ngIf="title">{{ title }}</div>
      <div class="badge-wrap-subtitle" *ngIf="subTitle">{{ subTitle }}</div>
      <div class="badge-wrap-semi-bold-title" *ngIf="semiBoldTitle">{{ semiBoldTitle }}</div>
      <div class="badge-wrap-semi-bold-subtitle" *ngIf="semiBoldSubTitle">{{ semiBoldSubTitle }}</div>
      <div class="{{fieldWrapperClass}}">
        <ng-template #fieldComponent></ng-template>
      </div>
    </div>
  `,
  styleUrls: ['./badge-wrapper.component.scss']
})
export class BadgeWrapperComponent extends FieldWrapper {

  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  get title(): string {
    return this.to.title;
  }

  get subTitle(): string {
    return this.to.subtitle;
  }

  get semiBoldTitle(): string {
    return this.to.semiBoldTitle;
  }

  get semiBoldSubTitle(): string {
    return this.to.semiBoldSubTitle;
  }

  get sectionClass(): string {
    return this.to.sectionClass || 'py-5';
  }

  get fieldWrapperClass(): string {
    return this.to.fieldWrapperClass || 'mt-5';
  }
}
