import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamService} from '@app/services/team.service';
import {GravityTheme, PinLoginRequest, ScenarioType, SendoutType, SharedCv} from '@app/models';
import {ThemeService} from '@app/services/theme.service';
import {UiService} from '@app/services/ui.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-view-cv',
  templateUrl: './view-cv.component.html',
  styleUrls: ['./view-cv.component.scss']
})
export class ViewCvComponent implements OnInit {

  gravityTheme: GravityTheme;

  webCV: SharedCv;

  langSwitcher: boolean;
  hasSidebar = false;

  companyName: string;
  groupName: string;

  teamDomain: string;
  groupUrl: string;
  usernameOrHash: string;
  previewToken: string;

  // sendout
  hash: string;
  isSendout = false;
  memberIdOrCvId: string;

  isPreview = false;

  lang: string;

  private scenario: ScenarioType;

  constructor(protected ts: TranslateService,
              private service: TeamService,
              private viewerService: ViewerAuthService,
              private themeService: ThemeService,
              private ui: UiService,
              private location: Location,
              private router: Router,
              private localizeService: LocalizeRouterService,
              private route: ActivatedRoute) {
    this.scenario = sessionStorage.getItem('scenario') as ScenarioType;
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }

  ngOnInit() {
    this.ui.isProcessing = true;
    // sendout
    if (this.router.url.includes('/s/')) {
      this.isSendout = true;
      this.hash = this.route.snapshot.paramMap.get('hash');
      this.memberIdOrCvId = this.route.snapshot.paramMap.get('memberIdOrCvId');
    }

    this.teamDomain = this.route.snapshot.paramMap.get('teamDomain');
    this.groupUrl = this.route.snapshot.paramMap.get('groupUrl');
    this.usernameOrHash = this.route.snapshot.paramMap.get('username');


    switch (this.scenario) {
      case 'teamCustomDomain':
        this.teamDomain = sessionStorage.getItem('teamDomain');
        break;
      case 'teamSubdomain':
        const url = new URL(window.location.href);
        const regexPattern = /^([a-zA-Z0-9\-]+)\.(.+)\.(.+)/g;
        const match = regexPattern.exec(url.host);
        this.teamDomain = match[1];
        break;
    }
    this.previewToken = this.route.snapshot.queryParamMap.get('token');

    // this preview route requires the previewToken above
    if (this.router.url.includes('/preview/')) {
      this.isPreview = true;
    }

    try {
      this.webCV = JSON.parse(this.route.snapshot.paramMap.get('webCV'));

      this.companyName = window.history.state && window.history.state.companyName ? window.history.state.companyName : null;
      this.groupName = window.history.state && window.history.state.groupName ? window.history.state.groupName : null;
    } catch (e) {
      console.log(e);
    }

    if (this.isSendout) {
      this.getSendoutTheme();
    } else if (!!this.groupUrl) {
      this.getDefaultWebCvForTeamGroupsPage();
    } else if (this.isPreview) {
      this.getThemeForWebCvPreview();
    } else {
      this.getThemeForTeamMainPage();

    }
  }

  goToOverview() {
    let url;

    if (this.isSendout) {
      url = this.localizeService.translateRoute(`/s/${this.hash}`);
    } else if (!!this.groupUrl) {
      url = this.localizeService.translateRoute(`/group/${this.groupUrl}`);
    } else {
      url = this.localizeService.translateRoute(`${localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE')}/`);
    }

    this.router.navigate(
      [url],
      {queryParamsHandling: 'preserve'}
    )
      .finally();
  }

  setWebCvFromResponse(response: SharedCv) {
    this.webCV = response;

    if (!!this.webCV.profile.social || !!this.webCV.profile.languages || !!this.webCV.controls) {
      this.hasSidebar = true;
    }
    this.ui.isProcessing = false;
  }

  /*** WEBCV AS PREVIEW WITH TOKEN **/

  getThemeForWebCvPreview() {
    this.service.checkWebCvPreviewPage(this.teamDomain, this.usernameOrHash, this.previewToken).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);

        this.resolveWebCvAsPreview();
        this.themeClass();
      } else {
        this.routeNotFoundPage();
      }
    });
  }

  resolveWebCvAsPreview() {
    this.service.getWebCvPreviewPage(this.teamDomain, this.usernameOrHash, this.previewToken).subscribe(response => {
      if (response) {
        if (this.languageSwitchCheck(response)) {
          this.setWebCvFromResponse(response);
        }
      }
    });
  }

  /*** WEBCV AS SUBPAGE OF TEAM MAIN PAGE **/

  getThemeForTeamMainPage() {
    this.service.checkPublicTeamPage(this.teamDomain, this.previewToken).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.themeClass();

        this.resolveDefaultWebCvOfMemberForTeamMainPage();
      } else {
        this.ui.isProcessing = false
      }
    });
  }

  resolveDefaultWebCvOfMemberForTeamMainPage() {
    this.service.getPublicTeamPageMember(this.teamDomain, this.usernameOrHash).subscribe(response => {
      if (response) {
        if (this.languageSwitchCheck(response)) {
          this.setWebCvFromResponse(response);
        }
      }
    });
  }

  /*** WEBCV AS SUBPPAGE OF TEAM GROUPS PAGES **/

  getDefaultWebCvForTeamGroupsPage() {
    this.service.checkPublicGroupPage(this.teamDomain, this.groupUrl, this.previewToken).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.themeClass();

        // e.g. if component is loaded after successful login
        if (!!this.webCV) {
          return;
        }

        if (response.pinProtected) {
          this.resolvePinProtectedDefaultWebCvOfMemberCvForTeamGroupsPage();
        } else {
          this.resolveDefaultWebCvOfMemberCvForTeamGroupsPage();
        }
      } else {
        this.routeNotFoundPage();
      }
    });
  }

  resolveDefaultWebCvOfMemberCvForTeamGroupsPage() {
    this.service.getPublicTeamGroupMember(this.teamDomain, this.groupUrl, this.usernameOrHash, this.previewToken).subscribe(response => {
      if (response) {
        if (this.languageSwitchCheck(response)) {
          this.setWebCvFromResponse(response);
        }
      }
    });
  }

  resolvePinProtectedDefaultWebCvOfMemberCvForTeamGroupsPage() {
    if (this.groupUrl !== this.viewerService.getTeamGroupAccessGroupUrl()) {
      const url = this.localizeService.translateRoute(`/group/${this.groupUrl}`);
      this.router.navigate(
        [url], // will automatically route to login page
        {queryParamsHandling: 'preserve'}
      )
        .finally();
    } else {
      const pinLoginRequest = new PinLoginRequest();

      pinLoginRequest.pin = this.viewerService.getTeamGroupAccessPin();
      pinLoginRequest.email = this.viewerService.getTeamGroupAccessEmail();

      this.service.loginPublicTeamGroupMember(this.teamDomain, this.groupUrl, this.usernameOrHash, pinLoginRequest).subscribe(response => {
        if (response) {
          if (this.languageSwitchCheck(response)) {
            this.setWebCvFromResponse(response);
          }
        }
      });
    }
  }

  /*** WEBCV AS SUBPAGE FOR SENDOUT PAGE **/

  getSendoutTheme() {
    this.service.checkSendoutPage(this.teamDomain, this.hash).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.themeClass();

        // e.g. if component is loaded after successful login
        if (!!this.webCV) {
          return;
        }

        this.resolveSendoutWebCv(response.sendoutType, response.pinProtected);
      } else {
        this.routeNotFoundPage();
      }
    });
  }

  resolveSendoutWebCv(sendoutType: SendoutType, pinProtected: boolean) {
    if (pinProtected) {
      this.resolvePinProtectedSendoutWebCV(sendoutType);
    } else {
      this.resolveNotProtectedSendoutWebCV(sendoutType);
    }
  }

  resolvePinProtectedSendoutWebCV(sendoutType: SendoutType) {
    if (this.hash !== this.viewerService.getSendoutAccessUniqueHash()) {
      this.router.navigate(
        [this.localizeService.translateRoute(`/s/${this.hash}`)], // will automatically route to login page
        {queryParamsHandling: 'preserve'}
      )
        .finally();
    } else {
      const pinLoginRequest = new PinLoginRequest();

      pinLoginRequest.pin = this.viewerService.getSendoutAccessPin();
      pinLoginRequest.email = this.viewerService.getSendoutAccessEmail();

      if (sendoutType === SendoutType.GROUP) {
        this.service.loginSendoutMember(this.teamDomain, this.hash, this.memberIdOrCvId, pinLoginRequest).subscribe(response => {
          if (response) {
            if (this.languageSwitchCheck(response)) {
              this.setWebCvFromResponse(response);
            }
          }
        });
      } else {
        this.service.loginSendoutCv(this.teamDomain, this.hash, this.memberIdOrCvId, pinLoginRequest).subscribe(response => {
          if (response) {
            if (this.languageSwitchCheck(response)) {
              this.setWebCvFromResponse(response);
            }
          }
        });
      }
    }
  }

  resolveNotProtectedSendoutWebCV(sendoutType: SendoutType) {
    if (sendoutType === SendoutType.GROUP) {
      this.service.getSendoutMember(this.teamDomain, this.hash, this.memberIdOrCvId).subscribe(response => {
        if (response) {
          if (this.languageSwitchCheck(response)) {
            this.setWebCvFromResponse(response);
          }
        }
      });
    } else {
      this.service.getSendoutCv(this.teamDomain, this.hash, this.memberIdOrCvId).subscribe(response => {
        if (response) {
          if (this.languageSwitchCheck(response)) {
            this.setWebCvFromResponse(response);
          }
        }
      });
    }
  }

  /*** GENERAL **/

  routeNotFoundPage() {
    this.router
      .navigate(
        [this.localizeService.translateRoute('/404')],
        {skipLocationChange: true}
      )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  languageSwitchCheck(webCv: SharedCv) {
    if (webCv.translations.length > 1) {
      this.langSwitcher = true;
      return true;
    } else {
      if (!webCv.translations.includes(this.localizeService.parser.currentLang)) {
        this.langSwitch(webCv.translations[0]);
        return false;
      }
      this.langSwitcher = false;
      return true;
    }
  }

  langSwitch(lang: string) {
    const url = new URL(window.location.href);
    window.location.href = '/' + lang + url.pathname.substr(3) + url.search;
  }

  themeClass(){
    if(this.gravityTheme.theme == 'DEFAULT'){
      let element: any = document.getElementsByClassName('columns')[0]
      element.classList.remove("columns");
    }
  }
}
