<ng-template [ngIf]="project?.dateFrom && !project?.dateTo && !project?.untilNow">
  {{ 'PROJECT.DATE_START' | translate }}
</ng-template>

<ng-template [ngIf]="project?.dateFrom">
  {{project?.dateFrom | date:'dd.MM.yyyy'}}
</ng-template>

<ng-template [ngIf]="!project?.dateFrom && project?.dateTo && !project?.untilNow">
  {{ 'PROJECT.DATE_END' | translate }}
</ng-template>

<ng-template [ngIf]="!project?.dateTo && project?.untilNow">
  <ng-template [ngIf]="!project?.dateFrom">
    {{ 'PROJECT.DATE_END' | translate }}
  </ng-template>
  <ng-template [ngIf]="project?.dateFrom"> -</ng-template>
  {{ 'PROJECT.TODAY' | translate }}
</ng-template>

<ng-template [ngIf]="project?.dateTo">
  <ng-template [ngIf]="project?.dateFrom"> -</ng-template>
  <ng-container>
    {{project?.dateTo |  date:'dd.MM.yyyy'}}
  </ng-container>
</ng-template>
