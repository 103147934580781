import {EventEmitter, Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {_t} from '@helpers/string-helpers';
import {NbSidebarService} from '@nebular/theme';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalConfig} from '@components/modal-dialog/modal-config';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  isSidebarVisible: boolean;
  toggleSidebar: EventEmitter<boolean> = new EventEmitter();

  isProcessing: boolean;
  togglePreloader: EventEmitter<boolean> = new EventEmitter();

  modalCropperRef: NgbModalRef;
  modalSubmitted: EventEmitter<ModalConfig | any> = new EventEmitter();

  constructor(private toastr: ToastrService,
              private sidebarService: NbSidebarService,
              private modalService: NgbModal) {
  }

  public showSidebar() {
    this.isSidebarVisible = true;
    this.sidebarService.toggle(false);
    this.toggleSidebar.emit(this.isSidebarVisible);
  }

  public hideSidebar() {
    this.isSidebarVisible = false;
    this.sidebarService.toggle(false);
    this.toggleSidebar.emit(this.isSidebarVisible);
  }

  openCropperModal(content) {
    this.modalCropperRef = this.modalService.open(content, {backdrop: 'static'});
  }

  setCropperImage(image, meta?) {
    this.modalCropperRef.componentInstance.avatar = image;
    this.modalCropperRef.componentInstance.meta = meta;
  }

  closeCropperModal() {
    this.modalCropperRef.close();
  }

  showToast(type: 'info' | 'success' | 'warning' | 'error', title?: string, message?: string, icon?: string) {

    if (!title) {
      switch (type) {
        case 'info':
          title = _t('TOAST.DEFAULT.INFO');
          break;
        case 'success':
          title = _t('TOAST.DEFAULT.SUCCESS');
          break;
        case 'warning':
          title = _t('TOAST.DEFAULT.WARNING');
          break;
        case 'error':
          title = _t('TOAST.DEFAULT.DANGER');
          break;
      }
    }

    this.toastr.show(
      message,
      title,
      {
        toastClass: (icon) ? `ngx-toastr toast-icon-${icon}` : 'ngx-toastr'
      },
      `toast-${type}`);
  }
}
