import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'app-field-wrapper',
  template: `

      <div class="form-group" [class.is-invalid]="showError">

          <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id">
              {{ to.label }}
              <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
          </label>

          <ng-template #fieldComponent></ng-template>

          <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
              <formly-validation-message [field]="field"></formly-validation-message>
          </div>

          <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
      </div>
  `,
  styleUrls: ['./field-wrapper.component.scss']
})
export class FieldWrapperComponent extends FieldWrapper {

  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

}
