import {ModelAdapter} from '@api/interfaces/resourceAdapter';

class AnonymousControls {
  downloadPdf = true;
  downloadWord = true;
  contactform = true;
}

class AnonymousControlsAdapter implements ModelAdapter {

  fromJson(json: any): AnonymousControls {

    const obj = new AnonymousControls();

    if (json) {
      obj.downloadPdf = json.downloadPdf;
      obj.downloadWord = json.downloadWord;
      obj.contactform = json.contactform;
    }

    return obj;
  }

  toJson(data: AnonymousControls): any {
    return data;
  }
}

class AnonymousReference {
  showCompany = true;
  projectReferences = true;
  skillReferences = true;
}

class AnonymousReferenceAdapter implements ModelAdapter {

  fromJson(json: any): AnonymousReference {

    const obj = new AnonymousReference();

    if (json) {
      obj.showCompany = json.showCompany;
      obj.projectReferences = json.projectReferences;
      obj.skillReferences = json.skillReferences;
    }
    return obj;
  }

  toJson(data: AnonymousReference): any {
    return data;
  }
}

class AnonymousProject {
  skills = true;
  company = true;
  references = new AnonymousReference();
}

class AnonymousProjectAdapter implements ModelAdapter {

  fromJson(json: any): AnonymousProject {

    const obj = new AnonymousProject();
    if (json) {

      obj.skills = json.skills;
      obj.company = json.company;
      obj.references = new AnonymousReferenceAdapter().fromJson(json.references);
    }
    return obj;
  }

  toJson(data: AnonymousProject): any {
    return data;
  }
}

class AnonymousProfile {
  personal = true;
  contactInfo = true;
  social = true;
  location = true;
  availability = true;
  rate = true;
  languages = true;
}

class AnonymousProfileAdapter implements ModelAdapter {

  fromJson(json: any): AnonymousProfile {

    const obj = new AnonymousProfile();

    if (json) {
      obj.personal = json.personal;
      obj.contactInfo = json.contactInfo;
      obj.social = json.social;
      obj.location = json.location;
      obj.availability = json.availability;
      obj.rate = json.rate;
      obj.languages = json.languages;
    }

    return obj;
  }

  toJson(data: AnonymousProfile): any {
    return data;
  }
}

export class Anonymous {
  profile = new AnonymousProfile();
  project = new AnonymousProject();
  controls = new AnonymousControls();
  skillups = true;

  static getEmptyAnonymousFields(): Anonymous {
    return new Anonymous();
  }

  static getAnonymousFieldsForExistingItems(): Anonymous {

    const anonymous = new Anonymous();

    anonymous.controls.contactform = false;
    anonymous.controls.downloadWord = false;

    return anonymous;
  }
}

export class AnonymousAdapter implements ModelAdapter {

  fromJson(json: any): Anonymous {

    const obj = new Anonymous();

    if (json.profile) {
      obj.profile = new AnonymousProfileAdapter().fromJson(json.profile);
    }

    if (json.project) {
      obj.project = new AnonymousProjectAdapter().fromJson(json.project);
    }

    if (json.controls) {
      obj.controls = new AnonymousControlsAdapter().fromJson(json.controls);
    }

    if (json.skillups) {
      obj.skillups = json.skillups;
    }

    return obj;
  }

  toJson(data: Anonymous): any {
    return data;
  }
}
