import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CvImages {
  avatar?: string;
  teamIcon?: string;
}

export class CvImagesAdapter implements ModelAdapter {
  fromJson(json: any): CvImages {

    const obj = new CvImages();

    if (!!json.avatar) {
      obj.avatar = json.avatar;
    }
    if (!!json.logo) {
      obj.teamIcon = json.logo;
    }

    return obj;
  }

  toJson(resource: CvImages): any {
    return resource;
  }
}
