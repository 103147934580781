
import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Team} from '@app/models';

@Component({
  selector: 'app-company-area',
  templateUrl: './company-area.component.html',
  styleUrls: ['./company-area.component.scss']
})
export class CompanyAreaComponent implements OnInit {

  @Input() model: Team;
  @Input() gravityTheme

  constructor(protected ts: TranslateService) {
  }

  ngOnInit() {
  }

}
