import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TeamMember} from '@app/models';
import {SelectCVEvent} from '@helpers/events/SelectCVEvent';

@Component({
  selector: 'app-candidate-area',
  templateUrl: './candidate-area.component.html',
  styleUrls: ['./candidate-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidateAreaComponent implements OnInit {

  @Input() members: TeamMember[];

  @Output() public selectCv = new EventEmitter<SelectCVEvent>();

  constructor(
    protected ts: TranslateService
  ) {
  }

  ngOnInit() {
  }

  viewCv(username: string, memberId: number, cvId?: number) {
    this.selectCv.emit({ username, memberId, cvId });
  }

}
