import {UrlSegment, UrlSegmentGroup, Route} from '@angular/router';

export function teamMatcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route) {
  const currentScenario = sessionStorage.getItem('scenario');
  const isTeamScenario = (currentScenario === 'teamCustomDomain') || (currentScenario === 'teamSubdomain');
  if (isTeamScenario) {
    console.log('isTeamScenario: ' + currentScenario);
    return {consumed: []}; // we will return empty as this is for default path and we do not want to affect sub segments
  } else {
    return null;
  }
}
