import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TeamService} from '@app/services/team.service';
import {ThemeService} from '@app/services/theme.service';
import {GravityTheme, ScenarioType, Team} from '@app/models';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {UiService} from '@app/services/ui.service';
import {SelectCVEvent} from '@helpers/events/SelectCVEvent';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit {

  @Input() team: Team;
  teamDomain: string;
  previewToken: string = null;
  gravityTheme: GravityTheme;
  private scenario: ScenarioType;

  constructor(protected ts: TranslateService,
              private ui: UiService,
              private themeService: ThemeService,
              private teamService: TeamService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService) {
    this.scenario = sessionStorage.getItem('scenario') as ScenarioType;
  }

  ngOnInit() {

    this.teamDomain = this.route.snapshot.paramMap.get('teamDomain');
    this.previewToken = this.route.snapshot.queryParamMap.get('token');

    switch (this.scenario) {
      case 'teamCustomDomain':
        this.teamDomain = sessionStorage.getItem('teamDomain');
        break;
      case 'teamSubdomain':
        const url = new URL(window.location.href);
        const regexPattern = /^([a-zA-Z0-9\-]+)\.(.+)\.(.+)/g;
        const match = regexPattern.exec(url.host);
        this.teamDomain = match[1];
        break;
    }

    try {
      this.team = JSON.parse(this.route.snapshot.paramMap.get('team'));
    } catch (e) {
      console.log(e);
    }

    if (!!this.teamDomain) {
      this.checkRouting();
    } else {
      this.routeNotFoundPage();
    }

  }

  viewCv($event: SelectCVEvent) {
    const url = this.localizeService.translateRoute(`/${$event.username}`);

    this.router.navigate([url], {
      state: {companyName: this.team.companyName},
      skipLocationChange: false,
      replaceUrl: true,
    });
  }

  checkRouting() {
    this.teamService.checkPublicTeamPage(this.teamDomain, this.previewToken).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.ui.isProcessing = false;

        // e.g. if component is loaded after successful login
        if (!!this.team) {
          return;
        }

        // For MVP we won't provide Main Team Page with Pin Protection
        /*if (response.pinProtected) {
          this.routePinProtectionPage();
        } else {
          this.resolveTeamPage();
        }*/
        this.resolveTeamPage();
      }
    });
  }

  resolveTeamPage() {
    this.teamService.getPublicTeamPage(this.teamDomain, this.previewToken).subscribe(responseTeam => {
      if (responseTeam) {
        this.team = responseTeam;
      }
    });
  }

  // Not used for MVP
  routePinProtectionPage() {
    this.router.navigate(
      [this.localizeService.translateRoute(`/login`)],
      {skipLocationChange: true, queryParamsHandling: 'preserve'}
    )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  routeNotFoundPage() {
    this.router
      .navigate(
        [this.localizeService.translateRoute('/404')],
        {skipLocationChange: true}
      )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

}
