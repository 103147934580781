import {Component, Input, OnInit} from '@angular/core';
import {Project} from '@app/models';

@Component({
  selector: 'app-project-dates',
  templateUrl: './project-dates.component.html',
  styleUrls: ['./project-dates.component.scss']
})
export class ProjectDatesComponent implements OnInit {
  @Input() project: Project;

  constructor() {
  }

  ngOnInit() {
    if (!this.project) {
      console.log('"project" is required property for ProjectDatesComponent');
      //throw new TypeError('"project" is required property for ProjectDatesComponent');
    }
    if (!(this.project instanceof Project)) {
      console.log('property "project" should be instanceof Project');
      //throw new TypeError('property "project" should be instanceof Project');
    }
  }

}
