import {Component, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {SharedWebCvService} from '@app/services/shared-web-cv.service';
import {PinLoginRequest} from '@models/web-cv/pin-login';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Validators} from '@angular/forms';
import {HelperService} from '@helpers/helper.service';
import {GravityTheme} from '@app/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractForm implements OnInit {

  model: PinLoginRequest = new PinLoginRequest();
  isSendout = false;

  fields: FormlyFieldConfig[];

  private usernameOrHash: string;
  private gravityTheme: GravityTheme;

  constructor(private service: SharedWebCvService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService,
              protected ts: TranslateService,
              private helper: HelperService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit(): void {
    if (this.router.url.includes('/s/')) {
      this.usernameOrHash = this.route.snapshot.paramMap.get('hash');
      this.isSendout = true;
    } else {
      this.usernameOrHash = this.route.snapshot.paramMap.get('usernameOrHash');
    }

    this.gravityTheme = JSON.parse(this.route.snapshot.paramMap.get('gravityTheme'));

    this.fields = [
      {
        fieldGroup: [
          {
            key: 'email',
            type: 'input',
            defaultValue: this.route.snapshot.queryParamMap.get('email'),
            templateOptions: {
              label: this.tr(_t('PIN_PROTECTED.EMAIL')),
              required: true,
              disabled: !!this.route.snapshot.queryParamMap.get('email'),
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            }
          },
          {
            key: 'pin',
            type: 'input',
            templateOptions: {
              pattern: '[0-9]+',
              type: 'password',
              label: this.tr(_t('PIN_PROTECTED.PIN')),
              required: true
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
                pattern: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('PIN_PROTECTED.ONLY_DIGITS'), {value: field.templateOptions.label}),
              },
            },
          },
          /*{
            key: 'subscribe',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.tr(_t('PIN_PROTECTED.SUBSCRIBE'))
            },
          },*/
          {
            key: 'terms',
            type: 'custom-checkbox',
            templateOptions: {
              customLabel: this.tr(_t('PIN_PROTECTED.TERMS'), {
                value: this.gravityTheme.userFullName,
                terms: this.helper.getTermsLinks().tos,
                privacyPolicy: this.helper.getTermsLinks().privacyPolicy,
              }),
              required: true
            },
            validators: {
              validation: [Validators.requiredTrue],
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              },
            },
          },
        ]

      }
    ];
  }

  submit() {
    if (this.isSendout) {
      this.loginForSendOut();
    } else {
      this.loginForCv();
    }
  }

  loginForSendOut() {
    this.service.sendOutPinLogin(this.usernameOrHash, this.model).subscribe(response => {
      if (response) {
        this.router.navigate(
          [
            this.localizeService.translateRoute('/s/' + this.usernameOrHash),
            {
              webCV: JSON.stringify(response)
            }
          ],
          {skipLocationChange: true})
          .finally();
      }
    });
  }

  loginForCv() {
    this.service.pinLogin(this.usernameOrHash, this.model).subscribe(response => {
      if (response) {
        this.router.navigate(
          [
            this.localizeService.translateRoute('/'),
            {
              usernameOrHash: this.usernameOrHash,
              webCV: JSON.stringify(response)
            }
          ],
          {skipLocationChange: true})
          .finally();
      }
    });
  }
}
