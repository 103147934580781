import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CvLocation {
  europe: boolean;
  germany: boolean;
  location: string;
  radius: number;
  remote: boolean;
}

export class CvLocationAdapter implements ModelAdapter {
  fromJson(json: any): CvLocation {
    const obj = new CvLocation();

    obj.europe = json.europe;
    obj.germany = json.germany;
    obj.location = json.location;
    obj.radius = json.radius;
    obj.remote = json.remote;

    return obj;
  }

  toJson(resource: CvLocation): any {
    return resource;
  }
}
