import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Skill, Tag } from '@app/models';

export interface DeleteBadgeEvent {
  id: number;
  badge: Skill | Tag;
}

export interface SelectBadgeEvent {
  badge: Skill | Tag;
}

export interface EditBadgeEvent {
  badge: Skill | Tag;
}

@Component({
  selector: 'app-gravity-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {

  @HostBinding('class.gravity-badge') public badgeClass = true;

  @Input() public badge: Skill | Tag;

  @Input() public badgeStyle = 'dark-grey';

  @Input() public badgeStyleSelected = 'primary';

  @Input() public isFeatured = false;

  @Input() public customClass = false;

  @Input() public isDeletable = false;

  @Input() public isSelectable = false;

  @Input() public isStarsEditable = false;

  @Input() public isSelected = false;

  public isMenuMode = false;

  @Input() public isPresentMode = false; // Used for Member Box

  @Input() public locale: string;

  @Input() public showSkills: boolean = false;

  @Output() public deleteItem = new EventEmitter<DeleteBadgeEvent>();

  @Output() public selectItem = new EventEmitter<SelectBadgeEvent>();

  get displayName() {
    if(this.showSkills){
      this.badge.constructor = Skill;
    }
    switch (this.badge.constructor) {
      case Skill:
        if (!this.locale) return null
        let name = (<Skill>this.badge).nameLocalizations[this.locale];
        if (name) return name;
        let otherLang = this.locale == "de" ? "en" : "de";
        let otherName = (<Skill>this.badge).nameLocalizations[otherLang];
        // initialize new language with value from other language
        (<Skill>this.badge).nameLocalizations[this.locale] = otherName;
        return (<Skill>this.badge).nameLocalizations[this.locale];
      default:
        return (<Tag>this.badge).name;
    }
  }

  get isSkill() {
    return this.badge.constructor == Skill;
  }

  get stars() {
    return (<Skill>this.badge).stars;
  }

  get starsArray() {
    let stars = [];
    if (this.stars) {
      for (let index = 0; index < this.stars; index++) {
        stars.push('*');
      }
    }
    return stars
  }

  set displayName(name: string) {
    switch (this.badge.constructor) {
      case Skill:
        (<Skill>this.badge).nameLocalizations[this.locale] = name;
      default:
        (<Tag>this.badge).name = name;
    }
  }

  onDeleteItem(): void {
    if (this.isDeletable) {
      this.deleteItem.emit({ id: this.badge.id, badge: this.badge });
    }
  }

  onSelectItem(): void {
    if (this.isSelectable) {
      this.isMenuMode = false
      this.badge.isSelected = !this.badge.isSelected;
      this.selectItem.emit({ badge: this.badge });
    }
  }

  onMenu(): void {
    this.isMenuMode = !this.isMenuMode
  }

  addStar(): void {
    if (this.stars < 5) {
      (<Skill>this.badge).stars += 1;
    }
  }

  removeStar(): void {
    if (this.stars > 0) {
      (<Skill>this.badge).stars -= 1;
    }
  }
}
