<nb-layout>
  <nb-layout-column>
    <div class="container text-center">
      <img alt="image invalid link" class="error-page-image" src="../../../../assets/images/illu-too-late.svg"/>
      <h3 class="error-page-title">{{'ERROR_PAGE.EXPIRED.TITLE' | translate}}</h3>
      <p [innerHTML]="'ERROR_PAGE.EXPIRED.MESSAGE' | translate" class="error-page-message"></p>
      <!-- todo AFTER MVP add button to request new link -->
    </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-web-cv-footer></app-web-cv-footer>
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
