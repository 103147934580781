
<div class="row">
    <div class="col-xxl-4 col-md-6 mb-30" *ngIf="viewContact()">
        <div class="bio-card bg-white">
            <h4>{{'WEB_CV.LABEL.CONTACT' | translate}}</h4>
            <ul>
                <li *ngIf="webCV.profile?.contactInfo?.publicEmail"><p>{{'WEB_CV.LABEL.EMAIL' | translate}} <a class="cv-any-link" href="mailto:{{webCV.profile?.contactInfo?.publicEmail}}">{{webCV.profile?.contactInfo?.publicEmail}}</a></p></li>
                <li *ngIf="webCV.profile?.contactInfo?.phone && webCV.profile?.contactInfo?.phone !== null"><p>{{'WEB_CV.LABEL.PHONE' | translate}} <span>{{webCV.profile?.contactInfo?.phone}}</span></p></li>
            </ul>
        </div>
    </div>
    <div class="col-xxl-4 col-md-6 mb-30" *ngIf="webCV.controls?.downloadPdf || webCV.controls?.downloadWord">
        <div class="bio-card bg-white position-relative">
            <h4>{{'WEB_CV.LABEL.DOWNLOADS' | translate}}</h4>
            <div (click)="downloadCV('pdf')"
                *ngIf="webCV.controls?.downloadPdf"
                class="cv-download-link d-flex  mb-2">
                {{getCleanFileName()}}.pdf
                <!-- <i class="gvcv-icon icon-add-category ms-2"></i> -->
                <img src="assets/images/file-pdf.svg" class="download-btn ms-2" alt="">
                <!-- <div class="download-btn ms-2 icon-pdf"></div> -->
            </div>
            <div (click)="downloadCV('doc')"
                *ngIf="webCV.controls?.downloadWord"
                class="cv-download-link d-flex">
                {{getCleanFileName()}}.doc
                <img src="assets/images/file-word.svg" class="download-btn ms-2" alt="">
                <!-- <div class="download-btn ms-2 icon-word"></div> -->

            </div>
            <div *ngIf="downloadSpinner" class="cv-download-spinner">
                <div class="spinner-grow text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xxl-4 col-md-6 mb-30" *ngIf="webCV?.profile?.languages && webCV?.profile?.languages.length > 0">
        <div class="bio-card bg-white position-relative">
            <h4>{{'WEB_CV.LABEL.LANGUAGES' | translate}}</h4>
            <dl class="row mb-0">
              <!-- TODO: Check after finalizing languages -->
                <ng-container *ngFor="let lang of webCV.profile?.languages">
                    <dt class="col-xxl-4 col-xl-6 col-md-4 col-6">{{lang?.label}}</dt>
                    <ng-container [ngSwitch]="lang?.value">
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.A1">{{'WEB_CV.LANGUAGES.VALUES.A1' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.A2">{{'WEB_CV.LANGUAGES.VALUES.A2' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.B1">{{'WEB_CV.LANGUAGES.VALUES.B1' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.B2">{{'WEB_CV.LANGUAGES.VALUES.B2' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.C1">{{'WEB_CV.LANGUAGES.VALUES.C1' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.C2">{{'WEB_CV.LANGUAGES.VALUES.C2' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.BASIC_KNOWLEDGE">{{'WEB_CV.LANGUAGES.VALUES.BASIC_KNOWLEDGE' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.GOOD_KNOWLEDGE">{{'WEB_CV.LANGUAGES.VALUES.GOOD_KNOWLEDGE' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.FLUENT">{{'WEB_CV.LANGUAGES.VALUES.FLUENT' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchCase="cvLanguageValueEnum.MOTHER_TONGUE">{{'WEB_CV.LANGUAGES.VALUES.MOTHER_TONGUE' | translate}}</dd>
                        <dd class="col-xxl-6 col-xl-6 col-md-8 col-6" *ngSwitchDefault></dd>
                    </ng-container>
                </ng-container>
            </dl>
        </div>
    </div>

    <div class="col-xxl-4 col-lg-12 mb-30" *ngIf="webCV?.profile?.social && social && social.length > 0">
        <div class="bio-card bg-white">
            <h4>{{'WEB_CV.LABEL.SOCIAL_MEDIA' | translate}}</h4>
            <div class="social-links-wrapper">
                <a *ngFor="let link of social" [href]="link.value" class="cv-social-link" target="_blank">
                  <span class="gvcv-icon icon-{{link.label == 'linkedin' ? 'linked-in' : link.label }} me-3"></span>
                </a>
            </div>
        </div>
    </div>
</div>
