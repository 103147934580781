<div [style.display]="state.value === 'inactive' ? 'none' : ''">
  <button (click)="remove()" *ngIf="options.closeButton" aria-label="Close" class="toast-close-button">
    <i aria-hidden="true" class="gvcv-icon icon-close"></i>
  </button>
  <div class="d-flex align-items-center">
    <div>
      <div class="toast-icon-bg">
        <div class="toast-icon"></div>
      </div>
    </div>
    <div class="col">
      <div *ngIf="title" [attr.aria-label]="title" [class]="options.titleClass">
        {{ title | translate}}
        <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
      </div>
      <div *ngIf="message && options.enableHtml" [class]="options.messageClass" [innerHTML]="message"
           aria-live="polite" role="alert">
      </div>
      <div *ngIf="message && !options.enableHtml" [attr.aria-label]="message" [class]="options.messageClass"
           aria-live="polite" role="alert">
        {{ message.toString() | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div [style.width]="width + '%'" class="toast-progress"></div>
  </div>
</div>
