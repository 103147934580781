import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CvOrderItem} from '@app/models';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-skill-tabs",
  templateUrl: "./skill-tabs.component.html",
  styleUrls: ["./skill-tabs.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition("void <=> *", animate(300)),
    ]),
  ],
})
export class SkillTabsComponent implements OnInit {
  @Input() webCV: any;
  @Input() gravityTheme: any;
  @Input() usernameOrHash: any;
  @Input() hasSidebar: boolean;
  @Input() isSendout = false;
  @Input() locale: string;

  // only used for teams
  @Input() teamWebCvUniqueHash;
  @Input() teamDomain;
  @Input() sendoutHash;
  @Input() groupUrl;

  glowShow = true;

  constructor(protected ts: TranslateService) {}

  ngOnInit() {
    console.log("webCV", this.webCV);
    console.log("gravityTheme", this.gravityTheme);
    console.log("locale", this.locale);
  }

  trackByOrder(index: number, item: CvOrderItem): string {
    return item.value;
  }

  public tabChange($event: NgbNavChangeEvent) {
    console.log($event);
    if ($event.nextId === "projects") {
      this.glowShow = false;
    }
  }
}
