import {UrlSegment, UrlSegmentGroup, Route} from '@angular/router';

export function defaultMatcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route) {
  const rootPath = segments.length === 0 && !route.path;
  const currentScenario = sessionStorage.getItem('scenario');
  const isDefaultScenario = (currentScenario === 'default');
  if (rootPath && isDefaultScenario) {
    console.log('isDefaultScenario: ' + currentScenario);
    return {consumed: []};
  } else {
    return null;
  }
}
