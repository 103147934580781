<nb-layout>
  <nb-layout-column>
    <div class="container text-center">
      <img alt="image invalid link" class="error-page-image" src="../../../../assets/images/illu-not-existing.svg"/>
      <h3 class="error-page-title">{{'ERROR_PAGE.NOT_EXISTS.TITLE' | translate}}</h3>
      <p class="error-page-message">{{'ERROR_PAGE.NOT_EXISTS.MESSAGE' | translate}}</p>
    </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-web-cv-footer></app-web-cv-footer>
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
