import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {environment} from '@env/environment';

export class PinLoginRequest {
  email: string;
  pin: number | string;
  subscribe: boolean;
  terms = false;
  agbVersion: string;
  dataProtectionVersion: string;
}

export class PinLoginAdapter implements ModelAdapter {
  fromJson(json: any, userId?: number): PinLoginRequest {
    return json;
  }

  toJson(resource: PinLoginRequest): any {
    if (resource.pin) {
      resource.pin = Number(resource.pin);
    }

    resource.agbVersion = environment.agbVersion;
    resource.dataProtectionVersion = environment.dataProtectionVersion;

    return resource;
  }
}

// TODO: Check all fields and create model with other name
export class PinLoginResponse {
  [args: string]: any;
}

export class PinLoginResponseAdapter implements ModelAdapter {
  fromJson(json: any, userId?: number): PinLoginResponse {
    return json;
  }

  toJson(resource: PinLoginResponse): any {
    return resource;
  }
}
