<section>
  <nb-layout *ngIf="team">
    <nb-layout-column>
      <!-- TODO: Add company header-->
      <div class="cv-container cv-section bg-white">
        <app-company-area [model]="team" [gravityTheme]="gravityTheme"></app-company-area>
      </div>

      <div class="cv-container p-4">
        <div class="mt-4 mb-4 member-box-title">
          {{'TEAM.MAIN.CANDIDATES'| translate}}
        </div>
        <app-candidate-area
          [members]="team?.members"
          (selectCv)="viewCv($event)">
        </app-candidate-area>
      </div>
    </nb-layout-column>

    <nb-layout-footer>
      <app-web-cv-footer class="footer"></app-web-cv-footer>
    </nb-layout-footer>

  </nb-layout>
</section>
