import {Component, Input, OnInit} from '@angular/core';
import {HelperService} from '@helpers/helper.service';

declare const GVCV_BUILD_NUMBER: string;

@Component({
  selector: 'app-web-cv-footer',
  templateUrl: './web-cv-footer.component.html',
  styleUrls: ['./web-cv-footer.component.scss']
})
export class WebCvFooterComponent implements OnInit {

  @Input() linkClass: string;

  imprint = this.helper.getTermsLinks().imprint;
  dataProtection = this.helper.getTermsLinks().privacyPolicy;
  tos = this.helper.getTermsLinks().tos;

  gvcvBuildNumber = '--';

  constructor(private helper: HelperService) {
  }

  ngOnInit(): void {
    if (typeof GVCV_BUILD_NUMBER !== 'undefined') {
      this.gvcvBuildNumber = GVCV_BUILD_NUMBER;
    }
  }
}
