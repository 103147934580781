import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CvControls {
  contactform: boolean; // TODO: Remove later
  downloadPdf: boolean;
  downloadWord: boolean;
  skillStats: boolean;
  linkLogo: boolean;
}

export class CvControlsAdapter implements ModelAdapter {
  fromJson(json: any): CvControls {
    const obj = new CvControls();

    obj.contactform = json.contactform;
    obj.downloadPdf = json.downloadPdf;
    obj.downloadWord = json.downloadWord;
    obj.skillStats = json.skillStats;
    obj.linkLogo = json.linkLogo;

    return obj;
  }

  toJson(resource: CvControls): any {
    return resource;
  }
}
