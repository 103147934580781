import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@models/profile/salutation';
import {GravityDate} from '@app/models';
import {appVariables} from '@app/app.config';
import {environment} from '@env/environment';

export class UserProfile {
  firstName: string;
  lastName: string;
  fullName: string;
  profession: string;
  dateOfBirth: string | Date | GravityDate;
  cityOfBirth: string;
  salutation: SalutationEnum;
  academicTitle: string;

  bio: string;

  linkedin: string;
  twitter: string;
  facebook: string;
  github: string;
  behance: string;
  xing: string;
  stackoverflow: string;
  instagram: string;
  dribble: string;

  street: string;
  city: string;
  country: string;
  zip: string;
  publicEmail: string;
  mobilePhone: string;
  phone: string;
  website: string;

  availability: string;
  avatar: string;
  avatarOrigin: string;
  avatarOriginUrl: string;
  fax: string;
  gravatarEmail: string;
  hideWelcomeMessage: boolean;
  informOnChange: boolean;
  nationality: string;
  timezone: string;
  userId: number;
}

export class UserProfileAdapter implements ModelAdapter {

  fromJson(json: any): UserProfile {
    const obj = new UserProfile();

    Object.assign(obj, json);

    if (typeof obj.avatar === 'string' && !!obj.avatar) {
      obj.avatar = json.avatar;
      obj.avatarOrigin = json.avatar.replace('__', '__origin__');
      obj.avatarOriginUrl = obj.avatarOrigin;
    }

    obj.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();

    return obj;
  }

  toJson(data: UserProfile): any {
    return data;
  }
}

