import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

export const appVariables = {
  accessTokenLocalStorage: 'accessToken',
  accessTokenServer: 'X-Auth-Token',
  defaultContentTypeHeader: 'application/json',
  notFoundPageUrl: '/404',
  expiredPageUrl: '/expired',
  dashboardPageUrl: '/',
  defaultThemeColor: '#0878ff',
  defaultTheme: 'DEFAULT',
  defaultPrintTheme: 'DEFAULT',

  /** VIEWER VARIABLES */

  // REFERENCES
  viewerReferenceAccessUniqueHash: 'viewerReferenceAccessUniqueHash',
  viewerReferenceAccessData: 'viewerReferenceAccessData',
  viewerReferenceAccessPin: 'viewerReferenceAccessPin',

  // TEAM DOMAIN
  activeTeamIdOrDomain: 'activeTeamIdOrDomain',

  // SENDOUTS
  viewerSendoutAccessUniqueHash: 'viewerSendoutAccessUniqueHash',
  viewerSendoutAccessEmail: 'viewerSendoutAccessEmail',
  viewerSendoutAccessPin: 'viewerSendoutAccessPin',

  // TEAM GROUPS
  viewerTeamGroupAccessGroupUrl: 'viewerTeamGroupAccessGroupUrl',
  viewerTeamGroupAccessEmail: 'viewerTeamGroupAccessEmail',
  viewerTeamGroupAccessPin: 'viewerTeamGroupAccessPin',
};

export class PruningTranslationLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private prefix: string = '/assets/i18n/',
    private suffix: string = '.json') {
  }

  public getTranslation(lang: string): any {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
      .pipe(map(
        (result: object) => this.process(result)
      ));
  }

  private process(object: object) {
    return Object.keys(object)
      .filter(key => object.hasOwnProperty(key) && object[key] !== '')
      .reduce((result, key) => (result[key] = typeof object[key] === 'object' ? this.process(object[key]) : object[key], result), {});
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
}
