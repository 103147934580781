<section class="pin-protected-page">
  <nb-layout>
    <nb-layout-column>

      <div class="blue-circle"></div>
      <div class="grey-circle"></div>
      <div class="red-circle"></div>

      <div class="pin-protected d-flex">
        <div class="start-section d-none d-sm-flex">

          <img alt="Planet illustration" class="pin-planet" src="../../../../assets/images/pin_planet.svg">

          <h1 class="page-title"> {{'PIN_PROTECTED.TEAM.FIND_CANDIDATE'| translate}} </h1>

          <app-picture
            [ext]="'png'"
            [src]="{
               lg: '../../../../assets/images/pin_protected_desktop',
               md: '../../../../assets/images/pin_protected_tablet'
              }"
            class="d-none d-sm-flex pin-picture"></app-picture>

          <img alt="Sparks illustration" class="pin-sparks" src="../../../../assets/images/pin_sparks.svg">

        </div>


        <div class="form-section">
          <h3 class="h1-title"> {{'PIN_PROTECTED.TEAM.TITLE'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'PIN_PROTECTED.TEAM.SUBTITLE'| translate}} </h5>
          <p class="copy-small-info"> {{'PIN_PROTECTED.TEAM.HELPER_TEXT'| translate}} </p>

          <form (submit)="submit()" [formGroup]="form">
            <formly-form
              [fields]="fields"
              [form]="form"
              [model]="model"
              [options]="options">
            </formly-form>

            <div class="text-end my-2">
              <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                {{'PIN_PROTECTED.TEAM.LOGIN_BTN'| translate}}
              </button>
            </div>
          </form>
        </div>

      </div>

      <app-web-cv-footer class="footer" linkClass="text-white"></app-web-cv-footer>

    </nb-layout-column>
  </nb-layout>
</section>
