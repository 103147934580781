import {Skill, SkillAdapter} from '../skillfeed/skill';
import {SkillUpType} from '../skillfeed/skill-up';
import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {_t} from '@helpers/string-helpers';
import {HelperService} from '@helpers/helper.service';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';
import { Localizations } from '../profile/app-language';

export class CvSkillUp {
  title: Localizations;
  description: Localizations;
  skillUpLinkButtonText: Localizations;
  link: string;
  skillupType: string;
  fileName: string;
  badgeName: string;
  skills: Skill[];
  dateFrom: string | Date;
  dateTo: string | Date;
  untilNow = false;
  durationType?: any;
  duration: number;
}

export class CvSkillUpAdapter implements ModelAdapter {
  fromJson(json: any): CvSkillUp {
    const obj = new CvSkillUp();

    obj.title = json.title;
    obj.link = json.link;
    obj.description = json.description;
    obj.untilNow = json.untilNow;
    obj.durationType = json.durationType;
    obj.duration = json.duration;
    obj.skillUpLinkButtonText = json.skillUpLinkButtonText;

    if (!!json.fileName) {
      obj.fileName = json.fileName;
    }
    if (!!json.badgeName) {
      obj.badgeName = json.badgeName;
    }
    if (json.skills) {
      obj.skills = new SkillAdapter().fromJsonArray(json.skills);
    }
    if (!!json.dateFrom && typeof json.dateFrom === 'string') {
      obj.dateFrom = HelperService.string2Date(json.dateFrom);
    }
    if (!!json.dateTo && typeof json.dateTo === 'string') {
      obj.dateTo = HelperService.string2Date(json.dateTo);
    }

    switch (SkillUpType[json.skillupType]) {
      case SkillUpType.BOOK:
        obj.skillupType = _t('SKILL_UP.BOOK');
        break;
      case SkillUpType.CERTIFICATE:
        obj.skillupType = _t('SKILL_UP.CERTIFICATE');
        break;
      case SkillUpType.ONLINE_COURSE:
        obj.skillupType = _t('SKILL_UP.ONLINE_COURSE');
        break;
      case SkillUpType.PODCAST:
        obj.skillupType = _t('SKILL_UP.PODCAST');
        break;
      case SkillUpType.STUDIES:
        obj.skillupType = _t('SKILL_UP.STUDIES');
        break;
      case SkillUpType.TRAINEE:
        obj.skillupType = _t('SKILL_UP.TRAINEE');
        break;
      case SkillUpType.WORKSHOP:
        obj.skillupType = _t('SKILL_UP.WORKSHOP');
        break;
      case SkillUpType.SCHOOL:
        obj.skillupType = _t('SKILL_UP.SCHOOL');
        break;
      case SkillUpType.UNIVERSITY:
        obj.skillupType = _t('SKILL_UP.UNIVERSITY');
        break;
      case SkillUpType.INTERNSHIP:
        obj.skillupType = _t('SKILL_UP.INTERNSHIP');
        break;
      case SkillUpType.NONE:
        obj.skillupType = _t('SKILL_UP.NONE');
        break;
    }

    return obj;
  }

  toJson(resource: CvSkillUp): any {
    return resource;
  }

  fromJsonArray(data: any): CvSkillUp[] | any {
    if (!data) {
      return [new CvSkillUp()];
    }
    return data.map(item => this.fromJson(item));
  }
}
