import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {appVariables} from '@app/app.config';
import {ThemeService} from '@app/services/theme.service';
import {HelperService} from '@helpers/helper.service';

export class GravityTheme {
  pinProtected: boolean;
  themeColor: string;
  theme: string;
  printTheme: string;
  lang: string;
  userFullName: string;
  accent: string;
  accentLighter: string;
  accentDarker: string;
  accentLightBackground: string;
  contrast: 'light' | 'dark';
}

export enum SendoutType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  GROUP = 'GROUP'
}

export class GravitySendoutTheme extends GravityTheme {
  validUntil: Date;
  dataProtection: any; // todo read data protection and let not confirm this again, if recipient already did
  sendoutType: SendoutType;
}

export class GravityThemeAdapter implements ModelAdapter {
  fromJson(json: any): GravityTheme {

    const obj = new GravityTheme();

    obj.lang = json.lang || 'de';
    obj.pinProtected = json.pinProtected;
    obj.userFullName = json.userFullName;
    obj.theme = json.theme || appVariables.defaultTheme;
    obj.themeColor = json.themeColor || appVariables.defaultThemeColor;
    obj.printTheme = json.printTheme || appVariables.defaultPrintTheme;
    obj.accent = json.themeColor || appVariables.defaultThemeColor;

    // Calculable values
    obj.accentLighter = new ThemeService().getLighterAccent(obj.themeColor);
    obj.accentDarker = new ThemeService().gerDarkerAccent(obj.themeColor);
    obj.contrast = new ThemeService().getContrastColor(obj.themeColor);
    obj.accentLightBackground = new ThemeService().getLighterAccentForBackground(obj.themeColor);

    return obj;
  }

  toJson(resource: any): any {
    return resource;
  }
}

export class GravityThemeSendoutAdapter extends GravityThemeAdapter implements ModelAdapter {
  fromJson(json: any): GravitySendoutTheme {
    const obj = super.fromJson(json) as GravitySendoutTheme;

    if (typeof json.validUntil === 'string') {
      obj.validUntil = HelperService.string2Date(json.validUntil);
    }

    obj.sendoutType = json.sendoutType;

    return obj;
  }

  toJson(resource: any): any {
    return resource;
  }
}
