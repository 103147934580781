import {Anonymous, AnonymousAdapter} from './anonymous';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {HelperService} from '@helpers/helper.service';
import {Tag, TagAdapter} from './tag';
import {Rate} from '@app/models';

export class WebCv {
  static LIST_NAME = 'content';

  id: number;
  title: string;
  mainCv: boolean;
  pinProtected: boolean;
  pin: string;
  order: any;
  anonymous: Anonymous;
  tags: Tag[];
  created: string | Date;
  updated: string | Date;
  salaries?: Rate [];
  toggleAdditional?: boolean;
}

export class WebCvAdapter implements ResourceAdapter {
  fromJson(json: any): WebCv {

    const obj = new WebCv();

    obj.id = json.id;
    obj.title = json.title;
    obj.mainCv = json.mainCv;
    obj.pinProtected = json.pinProtected;
    obj.pin = json.pin;
    obj.order = json.order;
    obj.tags = new TagAdapter().fromJsonArray(json.tags);

    if (json.anonymous) {
      obj.anonymous = new AnonymousAdapter().fromJson(json.anonymous);
    }

    obj.toggleAdditional = true;

    if (HelperService.isJsonString(json.order)) {
      obj.order = JSON.parse(json.order);
    }
    if (!!json.updated && typeof json.updated === 'string') {
      obj.updated = HelperService.string2Date(json.updated);
    }
    if (!!json.created && typeof json.created === 'string') {
      obj.created = HelperService.string2Date(json.created);
    }

    return obj;
  }

  toJson(resource: WebCv): any {
    if (typeof resource.order === 'object') {
      resource.order = JSON.stringify(resource.order);
    }
    return resource;
  }
}
