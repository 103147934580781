import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GravityTheme } from '@models/web-cv/gravity-theme';
import { Skill } from '@app/models';
import { SharedCv } from '@models/shared-cv/shared-cv';
import { BreakpointObserver } from '@angular/cdk/layout';
import { slideInOutAnimation } from '@helpers/animations';
import { takeWhile } from 'rxjs/operators';
import {FileModalComponent} from "@components/file-modal/file-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-section-main',
  templateUrl: './section-main.component.html',
  styleUrls: ['./section-main.component.scss'],
  animations: [
    slideInOutAnimation
  ],
})
export class SectionMainComponent implements OnInit, OnDestroy {

  alive = true;

  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() isTeamMember = false;
  lang: string;

  mainSkills: Skill[];
  educationText = '';
  educationTextFull = '';
  isEducationExpanded = false;
  isCollapsed = true;
  isSmallScreen;

  constructor(private breakpointObserver: BreakpointObserver, private modalService: NgbModal) {
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    const layoutChanges = breakpointObserver.observe([
      '(max-width: 767px)'
    ]);

    layoutChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });
  }

  toggleEducation() {
    this.isEducationExpanded = !this.isEducationExpanded;
    this.prepareEducation();
  }

  ngOnInit() {
    if (!!this.webCV) {
      const { categorized, uncategorized } = this.webCV.profile.skills;

      const categorizedSkills = [].concat(...[...categorized.map(({ skills }) => skills)]);

      const allSkills = [...categorizedSkills, ...uncategorized];

      this.mainSkills = allSkills.filter(item => item.mainSkill);
      // console.log(' main skills : ' + JSON.stringify(this.mainSkills));

      this.prepareEducation();
    }
  }

  openLink(): void {
    window.open(this.webCV.profile.contactInfo.bookingLink, 'blank')
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  checkFile(file) {
    if(file && (file.includes('.jpg') || file.includes('.png'))) {
      return true
    }
    else{
      return false
    }
  }

  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = fileUrl;
  }

  checkCertificates() {
    let files: string[] = []
    this.webCV.skillUps.forEach(s => {
      if(s.fileName){
        files.push(s.fileName)
      }
    })
    return files.length > 0 ? true : false;
  }

  private prepareEducation() {
    const graduations = [];
    if (!this.webCV.profile.graduations) {
      return;
    }

    if (this.webCV.profile.graduations.length === 1) {
      this.educationText = this.webCV.profile.graduations[0].instituteName;
    } else if (this.webCV.profile.graduations.length > 0) {
      this.webCV.profile.graduations.forEach(item => {
        if (!!item.instituteName) {
          graduations.push(item.instituteName);
        }
      });

      this.educationText = (this.isEducationExpanded)
        ? graduations.join('<br/>')
        : this.webCV.profile.graduations[0].instituteName + '...';
      this.educationTextFull = graduations.join('<br/>');
    }
  }

  private showAddressSection() {
    if (!!this.webCV.profile.contactInfo) {
      const contactInfo = this.webCV.profile.contactInfo;

      return !!contactInfo.street || !!contactInfo.zip || !!contactInfo.city;
    }
    return false;
  }

  private showLocationSection() {
    if (!!this.webCV.profile.location) {
      const locationInfo = this.webCV.profile.location;

      return !!locationInfo.location || !!locationInfo.radius || !!locationInfo.europe || !!locationInfo.germany || !!locationInfo.remote;
    }
    return false;
  }
}
