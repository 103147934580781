import {Component, Input, OnInit} from '@angular/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import {GravityTheme} from '@models/web-cv/gravity-theme';
import {SharedCv} from '@models/shared-cv/shared-cv';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
  animations: [
    trigger('fadeInOut',
      [
        state('void',
          style({opacity: 0})
        ),
        transition('void <=> *', animate(300)),
      ]
    ),
  ]
})
export class DefaultTemplateComponent implements OnInit {

  @Input() usernameOrHash: string;
  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() isSendout = false;
  @Input() langSwitcher: boolean;

  // only used for teams
  @Input() teamWebCvUniqueHash;
  @Input() teamDomain;
  @Input() sendoutHash;
  @Input() groupUrl;
  @Input() isTeamMember = false;

  lang: string;
  hasHeader: Observable<boolean>;
  hasSidebar = false;
  private subRouter: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router) {
    this.subRouter = this.getRouteData();
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }

  ngOnInit(): void {
    if (!!this.webCV.profile.social || !!this.webCV.profile.languages || !!this.webCV.controls) {
      this.hasSidebar = true;
    }
  }

  private getRouteData() {
    return this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(map(() => {
        let route = this.route.firstChild;
        let child = route;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }

        return route;
      }))
      .pipe(mergeMap(route => route && route.data ? route.data : of({})))
      .subscribe(e => {
          const hasHeader : any = e;
          this.hasHeader = (hasHeader !== undefined) ? of(hasHeader) : of(false);
          return of(e);
        }
      );
  }
  
  langSwitch(lang: string) {
    const url = new URL(window.location.href);
    window.location.href = '/' + lang + url.pathname.substr(3) + url.search;
  }
}
