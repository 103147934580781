<ng-container *ngIf="config">

  <div *ngIf="config.type =='custom'" class="modal-header">
    <h5 class="modal-title">{{config.title}}</h5>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="config.type =='delete'">
      <h5 class="modal-dialog-title">{{config.title}}</h5>
    </div>
    <div [innerHTML]="config.message" *ngIf="config.message"></div>
    <div *ngIf="config.phoneNumber || config.email || config.link" class="mt-1">
      <p *ngIf="config.phoneNumber">{{'TEAMGROUP.PHONE'| translate}}<span>{{config.phoneNumber}}</span></p>
      <p *ngIf="config.email">{{'TEAMGROUP.EMAIL'| translate}}<span [ngbTooltip]="'TEAMGROUP.COPY_TOOLTIP' | translate" (click)="copyLink(config.email)" >{{config.email}} </span>
        <i class="fa fa-envelope-o" aria-hidden="true" (click)="redirectToContact(config.email)"></i></p>
      <!-- <p *ngIf="config.email">{{'TEAMGROUP.EMAIL'| translate}}<span [cdkCopyToClipboard]="config.email">{{config.email}} </span>
        <i class="fa fa-envelope-o" aria-hidden="true" (click)="redirectToContact(config.email)"></i></p> -->
      <p *ngIf="config.link">{{'TEAMGROUP.WEBSITE'| translate}}<span>{{config.link}}</span></p>
    </div>
  </div>

  <div class="modal-footer flex-wrap justify-content-center">
    <button (click)="activeModal.dismiss('Cancel button click')"
            class="btn btn-link btn-link-dark"
            ngbAutofocus
            type="button">
      {{ 'MODAL.CLOSE' | translate }}
    </button>
    <button *ngIf="config.buttonText" (click)="onSubmit()"
            class="btn btn-{{config.buttonClass}}"
            type="button">
      {{config.buttonText}}
    </button>
  </div>

</ng-container>
