import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SingleUserRoutingModule} from './single-user-routing.module';
import {SingleUserComponent} from './single-user.component';
import {ReferenceLoginComponent} from '@layouts/single-user/reference/reference-login/reference-login.component';
import {ReferenceFormComponent} from '@layouts/single-user/reference/reference-form/reference-form.component';
import {ReferenceThanksComponent} from '@layouts/single-user/reference/reference-thanks/reference-thanks.component';
import {NbLayoutModule} from '@nebular/theme';
import {ComponentsModule} from '@components/components.module';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {MainComponent} from '@layouts/single-user/main/main.component';
import {LoginComponent} from '@layouts/single-user/login/login.component';
import {DefaultModule} from '@layouts/single-user/default/default.module';
import {ErrorPageModule} from '@modules/error-page/error-page.module';
import { ThemeLandingComponent } from './custom-templates/theme-landing/theme-landing.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { PipesModule } from '@app/helpers/pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ThemeLanding2Component } from './custom-templates/theme-landing2/theme-landing2.component';
import { AvatarModule } from 'ngx-avatars';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SingleUserComponent,
    MainComponent,
    LoginComponent,
    ReferenceLoginComponent,
    ReferenceFormComponent,
    ReferenceThanksComponent,
    ThemeLandingComponent,
    ThemeLanding2Component,
  ],
  imports: [
    CommonModule,
    SingleUserRoutingModule,
    NbLayoutModule,
    ComponentsModule,
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    DefaultModule,
    PipesModule,
    ErrorPageModule,
    BarRatingModule,
    MatIconModule,
    MatExpansionModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    MatTooltipModule,
    NgbTooltipModule,
    AvatarModule,
    MatTabsModule
  ],
  exports: [
    SingleUserComponent,
    ThemeLandingComponent,
    ThemeLanding2Component

  ]
})
export class SingleUserModule {
}
