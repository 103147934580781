import {Component, Input} from '@angular/core';
import {ModalConfig} from '@components/modal-dialog/modal-config';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import { ClipboardService  } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';
import { _t } from '@helpers/string-helpers';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {

  @Input() config: ModalConfig;

  constructor(protected ui: UiService,
              public activeModal: NgbActiveModal,
              public clipboardService: ClipboardService,
              protected ts: TranslateService,) {
  }

  onSubmit() {
    this.ui.modalSubmitted.emit(this.config);
    this.activeModal.close();
  }
  redirectToContact(email:any){
    var emailstring = "mailto:"+email;
    window.location.href = emailstring;
  }

  copyLink(url) {
    this.clipboardService.copy(url)
    this.ui.showToast(
             'success',
             this.ts.instant(_t('TEAMGROUP.COPY')),);
  }
  
}
