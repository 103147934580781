import {Injectable} from '@angular/core';
import tinycolor from '../../../node_modules/tinycolor2/dist/tinycolor-min.js';
import {GravityTheme} from '@models/web-cv/gravity-theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() {
  }

  /**
   * From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
   * Color brightness is determined by the following formula:
   * ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
   */
  getContrastColor(hex) {
    const threshold = 160; /* about half of 256. Lower threshold equals more dark text on dark background  */

    const cutHex = h => (h.charAt(0) === '#') ? h.substring(1, 7) : h;

    const hRed = parseInt((cutHex(hex)).substring(0, 2), 16);
    const hGreen = parseInt((cutHex(hex)).substring(2, 4), 16);
    const hBlue = parseInt((cutHex(hex)).substring(4, 6), 16);

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;

    if (cBrightness > threshold) {
      return 'dark';
    } else {
      return 'light';
    }
  }

  getLighterAccent(hex) {
    return tinycolor(hex).lighten(44).toString();
  }

  getLighterAccentForBackground(hex) {
    return tinycolor(hex).lighten(50).toString();
  }

  gerDarkerAccent(hex) {
    return tinycolor(hex).darken(14).toString();
  }

  setCssVariables(gravityTheme: GravityTheme) {
    const accent = '--accent-color';
    const accentLight = '--accent-light-color';
    const accentDark = '--accent-dark-color';
    const accentLightBackground = '--accent-light-background-color';

    if (gravityTheme) {
      document.documentElement.style.setProperty(accent, gravityTheme.accent);
      document.documentElement.style.setProperty(accentLight, gravityTheme.accentLighter);
      document.documentElement.style.setProperty(accentDark, gravityTheme.accentDarker);
      document.documentElement.style.setProperty(accentLightBackground, gravityTheme.accentLightBackground);
    }
  }
}
