import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-glow-dot',
  templateUrl: './glow-dot.component.html',
  styleUrls: ['./glow-dot.component.scss']
})
export class GlowDotComponent implements OnInit {

  @Input() size: string; // available options - sm, 2x, 3x
  sizeClass = ''; // default size

  constructor() { }

  ngOnInit() {
    this.sizeClass = this.size ? `la-${this.size}` : '';
  }

}
