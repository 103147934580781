import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ErrorPageComponent} from '@modules/error-page/default/error-page.component';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {ComponentsModule} from '@components/components.module';
import {NbLayoutModule} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';
import {ExpiredPageComponent} from '@modules/error-page/expired/expired-page.component';

@NgModule({
  declarations: [
    ErrorPageComponent,
    ExpiredPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LocalizeRouterModule,
    ComponentsModule,
    NbLayoutModule,
    TranslateModule
  ]
})
export class ErrorPageModule {
}
