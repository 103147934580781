import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CvOrderItem, CvProject} from '@app/models';
import {NgbModal, NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-cv-tabs",
  templateUrl: "./cv-tabs.component.html",
  styleUrls: ["./cv-tabs.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition("void <=> *", animate(300)),
    ]),
  ],
})
export class CvTabsComponent implements OnInit {
  @Input() webCV: any;
  @Input() gravityTheme: any;
  @Input() usernameOrHash: any;
  @Input() hasSidebar: boolean;
  @Input() isSendout = false;
  @Input() locale: string;

  // only used for teams
  @Input() teamWebCvUniqueHash;
  @Input() teamDomain;
  @Input() sendoutHash;
  @Input() groupUrl;

  glowShow = true;
  selectedProject: CvProject;
  lang = localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE");

  constructor(protected ts: TranslateService, private modalService: NgbModal) {}

  ngOnInit() {}

  trackByOrder(index: number, item: CvOrderItem): string {
    return item.value;
  }

  public tabChange($event: NgbNavChangeEvent) {
    if ($event.nextId === "projects") {
      this.glowShow = false;
    }
  }
  public isTabActive(tabName: string): boolean {
    if (this.webCV?.order?.active?.length > 0) {
      return (
        this.webCV?.order?.active?.find((a) => a.value == tabName) != undefined
      );
    }
    return false;
  }

  openModal(content, project): void {
    this.selectedProject = project;
    this.modalService.open(content);
  }
}
