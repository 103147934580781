import {SkillCategory} from '@models/skillfeed/skill-category';
import {ModelAdapter, ResourceAdapter} from '@api/interfaces/resourceAdapter';
import { Localizations } from '../profile/app-language';

export class Skill {

  static LIST_NAME = '';

  id: number;
  userId: number;
  nameLocalizations: Localizations;
  order: string;
  mainSkill: boolean;
  mainSkillOrder: number;
  categoryId: number;
  category: SkillCategory;
  isUsed?: boolean;
  isSelected?: boolean;
  created: string;
  updated: string;
  stars: number = 0;
}

// TODO: Check type
export class SkillTiny {
  id?: number;
  name?: string;
}

export class ProjectSkill {
  skill: Skill;
  isMainProjectSkill: boolean;
  skillOrder: number;
  nameLocalizations?: Localizations;
}

export class ProjectSkillAdapter  {

  fromJson(json: any): ProjectSkill {
    if (!json) {
      return;
    }

    const obj = new ProjectSkill();

    obj.isMainProjectSkill = json.isMainProjectSkill;
    obj.skillOrder = json.skillOrder;

    if (json.skill) {
      obj.skill = new SkillAdapter().fromJson(json.skill);
    }

    return obj;
  }

  toJson(resource: ProjectSkill): any {
    const json: any = {};

    json.skillOrder = resource.skillOrder;
    json.isMainProjectSkill = resource.isMainProjectSkill;

    if (resource.skill) {
      json.skill = new SkillAdapter().toJson(resource.skill);
    }

    return json;
  }


  fromJsonArray(data: any): ProjectSkill[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    data.forEach((item, index) => {
      item.skillOrder = index;
      return item;
    });

    return data.map(item => this.toJson(item));
  }
}

export class SkillAdapter implements ModelAdapter {

  fromJson(json: any): Skill {
    const obj = new Skill();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.nameLocalizations = json.nameLocalizations;
    obj.order = json.order;
    obj.mainSkill = json.mainSkill;
    obj.mainSkillOrder = json.mainSkillOrder;
    obj.categoryId = json.categoryId;
    obj.category = json.category;
    obj.created = json.created;
    obj.updated = json.updated;
    obj.stars = json.stars;

    return obj;
  }

  toJson(resource: Skill): any {
    return resource;
  }

  fromJsonArray(data: any): Skill[] | any {
    if (!data) {
      return [new Skill()];
    }
    return data.map(item => this.fromJson(item));
  }
}
