<section class="reference-form-page">
  <nb-layout>
    <nb-layout-column>

      <nb-layout-header class="gravity-header" fixed>
        <app-gravity-logo class="mx-auto"></app-gravity-logo>
      </nb-layout-header>

      <section class="reference-container">
        <h3 class="h1-title">
          {{'REFERENCE.THANKS_TITLE'| translate}}
        </h3>
        <h5 class="copy-big-subtitle">
          {{'REFERENCE.THANKS_SUBTITLE'| translate}}
        </h5>
      </section>

    </nb-layout-column>

    <nb-layout-footer>
      <app-web-cv-footer class="footer"></app-web-cv-footer>
    </nb-layout-footer>

  </nb-layout>
</section>

