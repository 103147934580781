import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {TeamMember, TeamMemberAdapter} from './team-member';

export class Team {

  logo: string;
  resizedLogo: string;
  domain: string;
  companyName: string;
  shortDescription: string;
  email: string;
  bio: string;
  website: string;
  phone: string;
  members: TeamMember[];

}

export class TeamAdapter implements ModelAdapter {

  fromJson(json: any): Team {
    const obj = new Team();

    obj.domain = json.domain;
    obj.companyName = json.companyName;
    obj.shortDescription = json.shortDescription;
    obj.email = json.email;
    obj.bio = json.bio;
    obj.website = json.website;
    obj.phone = json.phone;

    if (json.members) {
      obj.members = new TeamMemberAdapter().fromJsonArray(json.members);
    }

    if (json.logo) {
      obj.logo = json.logo;
      obj.resizedLogo = json.resizedLogo;
    }

    return obj;
  }

  toJson(data: Team): any {

    return data;
  }
}
