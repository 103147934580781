import { BreakpointObserver } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {CvSkillCategory, GravityTheme, SharedCv, Skill} from '@app/models';
import {_t} from '@helpers/string-helpers';

import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexLegend,
  ApexResponsive,
  ChartComponent,
  ApexDataLabels,
  ApexStroke,
  ApexFill,
  ApexTitleSubtitle,
  ApexAxisChartSeries
} from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
export interface ChartOptions {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries | any[];
  chart: ApexChart;
  labels: string[];
  colors: string[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive | ApexResponsive[];
  title: ApexTitleSubtitle;
}

@Component({
  selector: 'app-section-skills',
  templateUrl: './section-skills.component.html',
  styleUrls: ['./section-skills.component.scss']
})
export class SectionSkillsComponent implements OnChanges {

  skillStatsEnable = false;
  @ViewChild('top-hours') chartHours: ChartComponent;
  @ViewChild('top-score') chartScore: ChartComponent;
  public chartHoursOptions: Partial<ChartOptions>;
  public chartScoreOptions: Partial<ChartOptions>;
  topDays = false;
  topScores = false;

  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() locale: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private ts: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.webCV?.currentValue) {
      this.skillStatsEnable = this.webCV.controls.skillStats;
      if (this.skillStatsEnable) {
          this.setChartVariable([this.webCV.topDays, this.webCV.topScores]);
      }
      this.cdRef.detectChanges();
    }
  }

  setChartVariable(res: any) {
    // TopDays
    if (!!res[0] && res[0].skills.length) {
      this.topDays = true;
      const skillDays = res[0].skills;
      const seriesDays: number[] = [];
      const labelsDays: string[] = [];
      skillDays.forEach(element => {
        seriesDays.push(element.daysSpent);
        labelsDays.push(element.skillName);
      });
      this.chartHoursOptions = {
        series: seriesDays,
        chart: {
          height: 370,
          width: 300,
          type: 'polarArea'
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        labels: labelsDays,
        legend: {
          width: 300,
          position: 'bottom'
        },
        title: {
          text: this.ts.instant(_t('SKILLS.SKILL_STATS_DAYS'))
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
                height: 600,
              }
            }
          }
        ]
      };
    }
    // TopScores
    if (!!res[1] && res[1].skills.length) {
      this.topScores = true;
      const skillScores = res[1].skills;
      const seriesData: any[] = [];
      skillScores.forEach(element => {
        seriesData.push({
            x: element.skillName,
            y: element.summedScore
        });
      });
      const series = [{data: seriesData}];

      this.chartScoreOptions = {
        series,
        chart: {
          width: 480,
          height: 350,
          type: 'treemap'
        },
        title: {
          text: this.ts.instant(_t('SKILLS.SKILL_STATS_SCORES'))
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
                height: 400,
                type: 'treemap'
              }
            }
          }
        ]
      };
    }
  }

  gotoSkillStatsDescription() {
    const url = 'https://help.gravitycv.com/article/47/skillstatistiken';
    window.open(url, '_blank');
  }
}
