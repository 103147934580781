import {Component} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {SharedWebCvService} from '@app/services/shared-web-cv.service';
import {ScenarioType} from '@app/models';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Gravity CV';
  scenario: ScenarioType;

  constructor(private router: Router,
              private localizeService: LocalizeRouterService,
              private activatedRoute: ActivatedRoute,
              private service: SharedWebCvService
  ) {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe((event: NavigationStart) => {
      const language = event.url.split('/').slice(1).shift();
      const browserLanguage = navigator.language.slice(0, 2);
      if (event.url.length === 1) { event.url = ''; }
      let newUrl: string;
      if (language !== 'en' && language !== 'de') {
        switch (browserLanguage) {
          case 'en':
            newUrl = '/en' + event.url;
            break;
          case 'de':
            newUrl = '/de' + event.url;
            break;
          default:
            newUrl = '/de' + event.url;
            break;
        }
        this.router.navigateByUrl(newUrl);
      }
    });

    this.scenario = sessionStorage.getItem('scenario') as ScenarioType || 'default';
  }
}
