import {Component, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {PinLoginRequest} from '@models/web-cv/pin-login';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Validators} from '@angular/forms';
import {TeamService} from '@app/services/team.service';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';
import {HelperService} from '@helpers/helper.service';
import {appVariables} from '@app/app.config';
import {ScenarioType} from '@app/models';

@Component({
  selector: 'app-login',
  templateUrl: './team-login.component.html',
  styleUrls: ['./team-login.component.scss']
})
export class TeamLoginComponent extends AbstractForm implements OnInit {

  model: PinLoginRequest = new PinLoginRequest();

  fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          key: 'email',
          type: 'input',
          defaultValue: this.route.snapshot.queryParamMap.get('email'),
          templateOptions: {
            label: this.tr(_t('PIN_PROTECTED.EMAIL')),
            required: true,
            disabled: !!this.route.snapshot.queryParamMap.get('email'),
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            }
          }
        },
        {
          key: 'pin',
          type: 'input',
          templateOptions: {
            pattern: '[0-9]+',
            type: 'password',
            label: this.tr(_t('PIN_PROTECTED.PIN')),
            required: true
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              pattern: (error, field: FormlyFieldConfig) =>
                this.tr(_t('PIN_PROTECTED.ONLY_DIGITS'), {value: field.templateOptions.label}),
            },
          },
        },
        /*{
          key: 'subscribe',
          type: 'checkbox',
          templateOptions: {
            label: this.tr(_t('PIN_PROTECTED.SUBSCRIBE'))
          },
        },*/
        {
          key: 'terms',
          type: 'custom-checkbox',
          templateOptions: {
            customLabel: this.tr(_t('PIN_PROTECTED.TEAM.TERMS'), {
              terms: this.helper.getTermsLinks().tos,
              privacyPolicy: this.helper.getTermsLinks().privacyPolicy,
            }),
            required: true,
          },
          validators: {
            validation: [Validators.requiredTrue],
          },
          validation: {
            messages: {
              required: (error, field: FormlyFieldConfig) =>
                this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
            },
          },
        },
      ]

    }
  ];
  private teamDomain: string;
  private groupUrl: string;
  private isSendout = false;
  private hash: string;
  private scenario: ScenarioType;

  constructor(private service: TeamService,
              private viewerService: ViewerAuthService,
              private helper: HelperService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService,
              protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
    this.scenario = sessionStorage.getItem('scenario') as ScenarioType;
  }

  ngOnInit(): void {
    if (this.router.url.includes('/s/')) {
      this.isSendout = true;
      this.hash = this.route.snapshot.paramMap.get('hash');
    }

    this.teamDomain = this.route.snapshot.paramMap.get('teamDomain');
    this.groupUrl = this.route.snapshot.paramMap.get('groupUrl');

    switch (this.scenario) {
      case 'teamCustomDomain':
        this.teamDomain = sessionStorage.getItem('teamDomain');
        break;
      case 'teamSubdomain':
        const url = new URL(window.location.href);
        const regexPattern = /^([a-zA-Z0-9\-]+)\.(.+)\.(.+)/g;
        const match = regexPattern.exec(url.host);
        this.teamDomain = match[1];
        break;
    }
  }

  submit() {
    if (this.isSendout) {
      this.loginSendoutPage();
    } else if (!!this.groupUrl) {
      this.loginGroupPage();
    } /*else {
      this.loginTeamPage();
    }*/
  }

  loginSendoutPage() {
    this.service.loginSendoutPage(this.teamDomain, this.hash, this.model).subscribe(response => {
      if (response) {
        this.viewerService.storeLoginDataAsViewerForSendout(this.hash, this.model.email, String(this.model.pin));
        this.router.navigate(
          [
            this.localizeService.translateRoute(`/s/${this.hash}`),
            {
              team: JSON.stringify(response),
            }
          ],
          {skipLocationChange: true})
          .finally();
      }
    });
  }

  loginGroupPage() {
    console.warn(this.teamDomain);
    this.service.loginPublicGroupPage(this.teamDomain, this.groupUrl, this.model).subscribe(response => {
      if (response) {
        this.viewerService.storeLoginDataAsViewerForTeamGroups(this.groupUrl, this.model.email, String(this.model.pin));
        this.router.navigate(
          [
            this.localizeService.translateRoute(`/group/${this.groupUrl}`),
            {
              teamGroup: JSON.stringify(response)
            }
          ],
          {skipLocationChange: true})
          .finally();
      }
    });
  }

  // For MVP we will only provide Team Page without PIN protection
  /*loginTeamPage() {
    this.service.loginPublicTeamPage(this.teamDomain, this.model).subscribe(response => {
      if (response) {
        this.router.navigate(
          [
            this.localizeService.translateRoute(``),
            {
              team: JSON.stringify(response)
            }
          ],
          {skipLocationChange: true})
          .finally();
      }
    });
  }*/
}
