import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TeamDetailsComponent} from './team-details/team-details.component';
import {TeamGroupDetailsComponent} from './team-group-details/team-group-details.component';
import {ViewCvComponent} from './view-cv/view-cv.component';
import {TeamLoginComponent} from '@layouts/team/login/team-login.component';
import {TeamSendoutComponent} from '@layouts/team/team-sendout/team-sendout.component';
import {ErrorPageComponent} from '@modules/error-page/default/error-page.component';

const routes: Routes = [
  {
    path: '',
    component: TeamDetailsComponent,
  },
  {
    path: 'login',
    component: TeamLoginComponent,
  },
  {
    path: 's/:hash',
    component: TeamSendoutComponent,
  },
  {
    path: 's/login/:hash',
    component: TeamLoginComponent,
  },
  {
    path: 's/:hash/cv/:memberIdOrCvId',
    component: ViewCvComponent,
  },
  {
    path: 'group/:groupUrl',
    component: TeamGroupDetailsComponent,
  },
  {
    path: 'group/:groupUrl/login',
    component: TeamLoginComponent,
  },
  {
    path: 'group/:groupUrl/:username',
    component: ViewCvComponent,
  },
  {
    path: ':username',
    component: ViewCvComponent,
  },
  {
    path: 'preview/:username',
    component: ViewCvComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamRoutingModule {
}


