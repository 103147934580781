import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';
import {HelperService} from '@helpers/helper.service';
import { Skill } from '../skillfeed/skill';

export class TeamMember {

  memberId: number;
  webCvId: number;
  username: string;
  firstName: string;
  lastName: string;
  profession: string;
  pdfDownload: boolean;
  wordDownload: boolean;
  avatar: string;
  availabilityDate: string | Date;
  availabilityColor?: 'bg-success' | 'bg-warning' | 'bg-danger';
  availabilityIcon?: 'icon-check' | 'icon-info' | 'icon-attention';
  skills: Skill[];

  fullName: string;
  shortName: string;
}

export class TeamMemberAdapter implements ModelAdapter {

  fromJson(json: any): TeamMember {
    const obj = new TeamMember();

    Object.assign(obj, json);

    obj.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();
    obj.shortName = `${json.firstName ? json.firstName.trim().substring(0, 1) : ''} ${json.lastName ? json.lastName.trim().substring(0, 1) : ''}`;
    obj.availabilityDate = json.availabilityDate;

    if (typeof json.availabilityDate === 'string') {
      obj.availabilityDate = HelperService.string2Date(json.availabilityDate);
      const today = Math.round(Date.now() / 1000);
      if (obj.availabilityDate) {
        const unixAvailabilityDate = Math.round(obj.availabilityDate.getTime() / 1000);
        if (unixAvailabilityDate <= today + 1209600) { // more then 14 days
          obj.availabilityColor = 'bg-success';
          obj.availabilityIcon = 'icon-check';
        } else if (unixAvailabilityDate > today + 1209600 && unixAvailabilityDate <= today + 4838400) { // between 14 and 56 days
          obj.availabilityColor = 'bg-warning';
          obj.availabilityIcon = 'icon-info';
        } else {
          obj.availabilityColor = 'bg-danger';
          obj.availabilityIcon = 'icon-attention';
        }
      }
    }

    if (!!json.avatar) {
      obj.avatar = json.avatar;
    }

    if (!!json.skills) {
      obj.skills = json.skills;
    }

    return obj;
  }

  fromJsonArray(data: any): TeamMember[] | any {
    if (!data) {
      return [new TeamMember()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJson(data: TeamMember): any {

    return data;
  }

}
