import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'app-addon-wrapper',
  template: `
    <div class="input-group" [ngClass]="{disabled: to.disabled, 'is-invalid': showError}">
      <div (click)="addonLeftClick($event)"
           *ngIf="to.addonLeft"
           [ngStyle]="{cursor: to.addonLeft.onClick && !to.addonLeft.hidden  ? 'pointer' : 'inherit'}"
           class="input-group-prepend">
        <i *ngIf="to.addonLeft.class"
           [ngClass]="to.addonLeft.class"
           [ngStyle]="{color: to.addonLeft.hidden ? 'transparent' : null}"
           class="input-group-text"></i>
        <span *ngIf="to.addonLeft.text" class="input-group-text">{{ to.addonLeft.text }}</span>
      </div>
      <div class="input-addons">
        <ng-container #fieldComponent></ng-container>
      </div>
      <div (click)="addonRightClick($event)"
           *ngIf="to.addonRight"
           [ngStyle]="{cursor: to.addonRight.onClick && !to.addonRight.hidden ? 'pointer' : 'inherit'}"
           class="input-group-prepend">
        <i *ngIf="to.addonRight.class"
           [ngClass]="to.addonRight.class"
           [ngStyle]="{color: to.addonRight.hidden ? 'transparent' : null}"
           class="input-group-text"></i>
        <span *ngIf="to.addonRight.text" class="input-group-text">{{ to.addonRight.text }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./addon-wrapper.component.scss']
})

export class AddonWrapperComponent extends FieldWrapper {

  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  addonRightClick($event) {
    if (this.to.addonRight.onClick && !this.to.addonRight.hidden) {
      this.to.addonRight.onClick(this.to, this, $event);
    }
  }

  addonLeftClick($event) {
    if (this.to.addonLeft.onClick && !this.to.addonLeft.hidden) {
      this.to.addonLeft.onClick(this.to, this, $event);
    }
  }
}
