import {Component, OnInit} from '@angular/core';
import {AbstractForm} from '@helpers/abstract.form';
import {ReferenceFeedback, ReferenceLoginResponse, ReferenceLoginResponseAdapter} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {ReferenceService} from '@app/services/reference.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {QuillEditorStyles} from '@components/formly/editor/editor.component';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {HelperService} from '@helpers/helper.service';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';

@Component({
  selector: 'app-reference-form',
  templateUrl: './reference-form.component.html',
  styleUrls: ['./reference-form.component.scss']
})
export class ReferenceFormComponent extends AbstractForm implements OnInit {

  lang: string;
  referenceRequest: ReferenceLoginResponse;
  model: ReferenceFeedback;
  fields: FormlyFieldConfig[];

  private hash: string;

  constructor(private service: ReferenceService,
              private route: ActivatedRoute,
              private router: Router,
              private viewerAuthService: ViewerAuthService,
              private localizeService: LocalizeRouterService,
              protected ts: TranslateService,
              protected ui: UiService,
              private helper: HelperService
  ) {
    super(ts, ui);
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }

  ngOnInit(): void {
    this.hash = this.route.snapshot.paramMap.get('hash');

    if (this.viewerAuthService.isViewerLoggedInForReference(this.hash)) {
      try {
        const json = JSON.parse(this.viewerAuthService.getReferenceAccessData());
        this.referenceRequest = new ReferenceLoginResponseAdapter().fromJson(json);
        this.initFields();
        this.model = new ReferenceFeedback();
        this.model.pin = this.viewerAuthService.getReferenceAccessPin() ? Number(this.viewerAuthService.getReferenceAccessPin()) : 0;
      } catch (e) {
        console.log(e);
      }
    } else {
      this.router.navigate(
        [this.localizeService.translateRoute('/reference/login'), this.hash],
        {skipLocationChange: true, queryParamsHandling: 'preserve'}
      );
    }


    if (!this.hash) {
      this.ui.showToast(
        'error',
        this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
        this.ts.instant(_t('REFERENCE.INVALID_LINK'))
      );
    }
  }

  initFields() {
    this.fields = [
      {
        fieldGroup: [
          {
            key: 'position',
            type: 'input',
            templateOptions: {
              label: this.tr(_t('REFERENCE.POSITION')),
            },
          },
          {
            key: 'feedback',
            type: 'editor',
            templateOptions: {
              label: this.tr(_t('REFERENCE.FEEDBACK')),
              required: true,
              placeholder: '',
              editorStyles: (() => {
                QuillEditorStyles.getInstance().height = '15rem';
                return QuillEditorStyles.getInstance().toPlainObj();
              })(),
            },
            validation: {
              messages: {
                required: (error, field: FormlyFieldConfig) =>
                  this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
              }
            },
          },
          {
            key: 'contactAllowed',
            type: 'custom-radio',
            defaultValue: true,
            templateOptions: {
              inputLabelStyle: 'fw-normal',
              required: true,
              options: [
                {
                  value: true,
                  label: this.tr(
                    _t('REFERENCE.CONTACT_ALLOWED'),
                    {value: this.referenceRequest ? this.referenceRequest.userFullName : null}
                  )
                },
                {
                  value: false,
                  label: this.tr(
                    _t('REFERENCE.CONTACT_NOT_ALLOWED'),
                    {value: this.referenceRequest ? this.referenceRequest.userFullName : null}
                  )
                },
              ],
              validation: {
                messages: {
                  required: (error, field: FormlyFieldConfig) =>
                    this.tr(_t('FORM.REQUIRED'), {value: field.templateOptions.label}),
                }
              },
            },
          },
          {
            key: 'terms',
            template: this.tr(_t('REFERENCE.TERMS_NOTE'), {
              terms: this.helper.getTermsLinks().tos,
              privacyPolicy: this.helper.getTermsLinks().privacyPolicy,
            }),
          }
        ]
      }
    ];
  }

  submit() {
    console.log(this.model);
    this.service.sendReference(this.hash, this.model).subscribe(response => {
      if (response) {
        this.router.navigate(
          [this.localizeService.translateRoute('/reference/confirmed'), this.hash],
          {skipLocationChange: true, queryParamsHandling: 'preserve'}
        )
          .finally(() => {
            this.ui.showToast(
              'success',
              this.ts.instant(_t('TOAST.SUCCESS.GENERAL.TITLE')),
              this.ts.instant(_t('REFERENCE.SUCCESSFULLY_SENT'))
            );
          });
      }
    });
  }
}
