import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export enum CvLanguageValue {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
  BASIC_KNOWLEDGE = 'BASIC_KNOWLEDGE',
  GOOD_KNOWLEDGE = 'GOOD_KNOWLEDGE',
  FLUENT = 'FLUENT',
  MOTHER_TONGUE = 'MOTHER_TONGUE'
}

// TODO: Check properties after CMS finished
export class CvLanguage {
  label: string;
  value: CvLanguageValue;
}

export class CvLanguageAdapter implements ModelAdapter {
  fromJson(json: any): CvLanguage {
    const obj = new CvLanguage();

    obj.label = json.label;
    obj.value = json.value;

    return obj;
  }

  toJson(resource: CvLanguage): any {
    return resource;
  }

  fromJsonArray(data: any): CvLanguage[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }
}
