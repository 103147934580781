import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {Project, ProjectAdapter} from '../skillfeed/project';
import {Company, CompanyAdapter} from '../profile/company';
import {Reference, ReferenceAdapter} from '../skillfeed/reference';
import {ProjectSkill, ProjectSkillAdapter} from '../skillfeed/skill';

export class CvProject {
  project: Project;
  company: Company;
  skills: ProjectSkill[];
  references: Reference[];
}

export class CVProjectAdapter implements ModelAdapter {
  fromJson(json: any): CvProject {
    const obj = new CvProject();

    if (json.project) {
      obj.project = new ProjectAdapter().fromJson(json.project);
    }
    if (json.company) {
      obj.company = new CompanyAdapter().fromJson(json.company);
    }
    if (json.skills) {
      obj.skills = new ProjectSkillAdapter().fromJsonArray(json.skills);
    }
    if (json.references) {
      obj.references = new ReferenceAdapter().fromJsonArray(json.references);
    }

    return obj;
  }

  toJson(resource: CvProject): any {
    return resource;
  }

  fromJsonArray(data: any): CvProject[] | any {
    if (!data) {
      return [new CvProject()];
    }
    return data.map(item => this.fromJson(item));
  }
}
