import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {AvailabilityType} from '@models/profile/availability';

export class CvAvailability {
  date: string;
  value: string;
  type: AvailabilityType;
}

export class CvAvailabilityAdapter implements ModelAdapter {
  fromJson(json: any): CvAvailability {
    const obj = new CvAvailability();

    obj.date = json.date;
    obj.value = json.value;
    obj.type = json.type;

    return obj;
  }

  toJson(resource: CvAvailability): any {
    return resource;
  }
}
