export type AppLanguageEnum = 'GERMAN' | 'ENGLISH';

export const appLanguageEnum = {
  GERMAN: 'GERMAN' as AppLanguageEnum,
  ENGLISH: 'ENGLISH' as AppLanguageEnum
};

export class Localizations {
  en: string;
  de: string;
};

export class LocalizationsWithData {
  en: any;
  de: any;
};
