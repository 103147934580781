import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {UserProfile} from '@app/models';

export class UserBio {
  bio: string;
}

export class UserBioAdapter implements ModelAdapter {

  fromJson(json: any): UserBio {
    const obj = new UserBio();

    obj.bio = json.bio;

    return obj;
  }

  toJson(data: UserProfile): any {
    return data;
  }
}
