import {animate, animation, keyframes, state, style, transition, trigger} from '@angular/animations';
import {appVariables} from '@app/app.config';

export const pulse = animation(
  animate(
    '{{ timing }}s {{ delay }}s',
    keyframes([
      style({transform: 'scale3d(1, 1, 1)', background: appVariables.defaultThemeColor}),
      style({transform: 'scale3d({{ scale }}, {{ scale }}, {{ scale }})'}),
      style({transform: 'scale3d(1, 1, 1)'}),
    ])
  ),
  {params: {timing: 0.5, delay: 0, scale: 1.25}}
);

const hiddenState = {height: '0px', opacity: 0, visibility: 'hidden'};
const visibleState = {height: '*', opacity: 1, visibility: 'visible'};

export const slideInOutAnimation = [
  trigger('showDropdown', [
    state('*', style(hiddenState)),
    state('hidden', style(hiddenState)),
    state('visible', style(visibleState)),
    transition('* => *', [animate('300ms ease-in-out')]),
  ]),
];
