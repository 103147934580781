<ng-template [ngIf]="webCV && !isSmallScreen">
  <section *ngIf="webCV" class="cv-main-section clearfix">
    <div class="profile-content-wrapper">
      <div class="cv-avatar-item">
        <app-avatar
          [avatar]="webCV.profile?.images?.avatar"
          [bgColor]="gravityTheme?.accent"
          [fgColor]="gravityTheme?.contrast"
          [name]="webCV.profile?.personal?.shortName || 'N. N.'"
          [size]="100"
        >
        </app-avatar>
      </div>

      <div
        class="cv-text-item profile-text my-3"
        [class.full-width]="
          webCV.controls?.contactform &&
          webCV.profile?.contactInfo?.publicEmail &&
          !webCV.profile?.personal
        "
      >
        <div class="float-start">
          <h1 *ngIf="webCV.profile?.personal" class="cv-user-name">
            {{ webCV.profile?.personal?.title[lang] || "" }}
            {{ webCV.profile?.personal?.firstName }}
            {{ webCV.profile?.personal?.lastName }}
          </h1>
          <div class="accent-h3">
            {{ webCV.profile?.general?.profession[lang] }}
          </div>
        </div>
      </div>
    </div>

    <section class="profile-cards">
      <div class="row">
        <div *ngIf="mainSkills && mainSkills.length > 0" class="col-lg-12 col-md-8 mb-30">
          <div class="profile-bio-card main-skills-wrapper">
            <h4>{{ "WEB_CV.LABEL.MAIN_SKILLS" | translate }}</h4>
            <div class="skills-list">
              <div class="cv-value cv-main-skills">
                <app-gravity-badge
                  *ngFor="let badge of mainSkills | orderBy : ['mainSkillOrder']"
                  [badgeStyle]="'accent light-variant ' + gravityTheme?.contrast"
                  [className]="'mr-0'"
                  [badge]="badge"
                  [isPresentMode]="true"
                  [locale]="lang"
                  [showSkills]="true">
                </app-gravity-badge>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-12 col-lg-3 col-md-4 mb-30"
             *ngIf="webCV.profile?.availability?.date || webCV.profile?.availability?.value">
          <div class="profile-bio-card">
            <h4>{{ "WEB_CV.LABEL.AVAILABILITY" | translate }}</h4>
            <div class="profile-bio-content">
              <p>
                {{ "WEB_CV.SORT_BY_DATE" | translate }}:
                <b>{{
                  webCV.profile?.availability?.date | date : "dd.MM.yyyy"
                  }}</b>
              </p>
              <p *ngIf="webCV.profile?.availability?.value">
                {{ "WEB_CV.AVAILABLE" | translate }}:
                <b *ngIf="webCV.profile?.availability?.type === 'TEXT'">{{ webCV.profile?.availability?.value }}</b>
                <b *ngIf="webCV.profile?.availability?.type === 'PERCENT'">{{ webCV.profile?.availability?.value }}%</b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-12 col-md-4 col-lg-3 mb-30"
             [ngClass]="!webCV.profile?.availability?.date ? 'col-xxl-12 col-xl-12 col-md-4 col-lg-3' :''"
             *ngIf=" webCV.profile?.general?.employmentModeText">
          <div class="profile-bio-card">
            <h4>{{ "WEB_CV.LABEL.EMPLOYMENT" | translate }}</h4>
            <div class="profile-bio-content">
              <p>
                <b>{{
                  webCV.profile?.general?.employmentModeText | translate
                  }}</b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-3 col-md-4 mb-30" *ngIf=" webCV.profile?.contactInfo?.street">
          <div class="profile-bio-card">
            <h4>{{ "WEB_CV.LABEL.ADDRESS" | translate }}</h4>
            <div class="profile-bio-content">
              <p>
                <b
                >{{ webCV.profile?.contactInfo?.street }}
                  <br
                    *ngIf="
                      webCV.profile?.contactInfo?.street &&
                      (webCV.profile?.contactInfo?.zip ||
                        webCV.profile?.contactInfo?.city[lang])
                    "
                  />
                  {{ webCV.profile?.contactInfo?.zip }}
                  {{ webCV.profile?.contactInfo?.city[lang] }}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-3 col-md-4 mb-30" *ngIf="webCV.profile?.location?.location">
          <div class="profile-bio-card">
            <h4>{{ "WEB_CV.LABEL.LOCATION" | translate }}</h4>
            <div class="profile-bio-content">
              <p>
                <b>
                  <ng-template [ngIf]="webCV.profile?.location?.location">
                    {{ webCV.profile?.location?.location }}
                    <br/>
                  </ng-template>
                  <ng-template [ngIf]="webCV.profile?.location?.radius">
                    {{
                    "WEB_CV.LOCATION.RADIUS"
                      | translate
                      : {radius: webCV.profile?.location?.radius}
                    }}
                    <br/>
                  </ng-template>
                  <ng-template [ngIf]="webCV.profile?.location?.europe">
                    {{ "WEB_CV.LOCATION.EUROPE" | translate }}
                    <br/>
                  </ng-template>
                  <ng-template [ngIf]="webCV.profile?.location?.germany">
                    {{ "WEB_CV.LOCATION.GERMANY" | translate }}
                    <br/>
                  </ng-template>
                  <ng-template [ngIf]="webCV.profile?.location?.remote">
                    {{ "WEB_CV.LOCATION.REMOTE" | translate }}
                  </ng-template>
                </b>
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="webCV.profile?.contactInfo?.bookingLink" class="col-xl-12 col-lg-5 col-md-4 mb-30">
          <div class="profile-bio-card">
            <h4 *ngIf="webCV.profile?.contactInfo?.bookingLink">{{ "WEB_CV.LABEL.BOOKING_LIST" | translate }}</h4>
            <div class="profile-bio-content" *ngIf="webCV.profile.contactInfo?.bookingLinkButtonText; else noTextBookingLinkButtonText">
              <button *ngIf="webCV.profile.contactInfo?.bookingLinkButtonText[lang]; else noTextBookingLinkButtonText" (click)="openLink()"
                      class="btn-sm btn-primary booking-link-button-text">{{webCV.profile.contactInfo.bookingLinkButtonText[lang]}}</button>
            </div>
            <ng-template #noTextBookingLinkButtonText>
              <div class="profile-bio-content">
                <button (click)="openLink()" class="btn-sm btn-primary booking-link-button-text">{{'GENERAL.OPEN_LINK' | translate}}</button>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-xl-12 col-lg-3 col-md-4 mb-30" *ngIf="checkCertificates()">
          <div class="profile-bio-card">
            <div class="profile-bio-content">
              <ul class="certificate-list certificate-list-box">
                <ng-container *ngFor="let certificate of webCV.skillUps">

                  <ng-container
                    *ngIf="certificate.link && (checkFile(certificate.fileName) || certificate.badgeName); else imgWithoutLink">
                    <li class="certificate-badge-image"><a target="_blank" [href]="certificate.link"><img
                      class="certificate-badge-image" [src]="certificate.fileName || certificate.badgeName"
                      alt=""></a></li>
                  </ng-container>
                  <ng-template #imgWithoutLink>
                    <li class="certificate-badge-image"
                        *ngIf="(!checkFile(certificate.fileName)) && certificate.badgeName"
                        (click)="openFileModal($event, certificate?.fileName)"><img
                      class="certificate-badge-image" [src]="certificate.badgeName" alt=""></li>
                    <li class="certificate-badge-image" *ngIf="checkFile(certificate.fileName)"
                        (click)="openFileModal($event, certificate?.fileName)"><img
                      class="certificate-badge-image" [src]="certificate.fileName" alt=""></li>
                  </ng-template>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</ng-template>

<ng-template [ngIf]="webCV && isSmallScreen">
  <section
    *ngIf="webCV"
    class="cv-main-section-mobile cv-main-section d-flex flex-column"
  >
    <div class="profile-content-wrapper">
      <div class="cv-avatar-item">
        <app-avatar
          [avatar]="webCV.profile?.images?.avatar"
          [bgColor]="gravityTheme?.accent"
          [fgColor]="gravityTheme?.contrast"
          [name]="webCV.profile?.personal?.shortName || 'N. N.'"
          [size]="160"
        >
        </app-avatar>
      </div>
      <div
        class="cv-text-item profile-text"
        [class.full-width]="
          webCV.controls?.contactform &&
          webCV.profile?.contactInfo?.publicEmail &&
          !webCV.profile?.personal
        "
      >
        <div class="text-center">
          <h1 *ngIf="webCV.profile?.personal" class="cv-user-name">
            {{ webCV.profile?.personal?.title[lang] || "" }}
            {{ webCV.profile?.personal?.firstName }}
            {{ webCV.profile?.personal?.lastName }}
          </h1>
          <div class="accent-h3">
            {{ webCV.profile?.general?.profession[lang] }}
          </div>
        </div>
      </div>
    </div>
    <div
      [@showDropdown]="{ value: isCollapsed ? 'hidden' : 'visible' }"
      [ngbCollapse]="isCollapsed"
      class="cv-education-collapse"
      id="toggleSection"
      style="overflow: hidden"
    >
      <section class="profile-cards">
        <div class="row">
          <div class="col-lg-12 col-md-8 mb-30">
            <div class="profile-bio-card main-skills-wrapper">
              <h4>{{ "WEB_CV.LABEL.MAIN_SKILLS" | translate }}</h4>
              <div class="skills-list">
                <div
                  *ngIf="mainSkills && mainSkills.length > 0; else noData"
                  class="cv-value cv-main-skills"
                >
                  <app-gravity-badge
                    *ngFor="
                      let badge of mainSkills | orderBy : ['mainSkillOrder']
                    "
                    [badgeStyle]="
                      'accent light-variant ' + gravityTheme?.contrast
                    "
                    [className]="'me-0'"
                    [badge]="badge"
                    [isPresentMode]="true"
                    [locale]="lang"
                  >
                  </app-gravity-badge>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="webCV.profile?.availability?.date || webCV.profile?.availability?.value"
               class="col-xxl-6 col-xl-12 col-lg-3 col-md-4 mb-30">
            <div class="profile-bio-card">
              <h4>{{ "WEB_CV.LABEL.AVAILABILITY" | translate }}</h4>
              <div class="profile-bio-content">
                <p>
                  {{ "WEB_CV.SORT_BY_DATE" | translate }}:
                  <b>{{
                    webCV.profile?.availability?.date | date : "dd.MM.yyyy"
                    }}</b>
                </p>
                <p *ngIf="webCV.profile?.availability?.value">
                  {{ "WEB_CV.AVAILABLE" | translate }}:
                  <b *ngIf="webCV.profile?.availability?.type === 'TEXT'">{{ webCV.profile?.availability?.value }}</b>
                  <b *ngIf="webCV.profile?.availability?.type === 'PERCENT'">{{ webCV.profile?.availability?.value }}%</b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-12 col-md-4 col-lg-3 mb-30" *ngIf="webCV.profile?.general?.employmentModeText">
            <div class="profile-bio-card">
              <h4>{{ "WEB_CV.LABEL.EMPLOYMENT" | translate }}</h4>
              <div class="profile-bio-content">
                <p>
                  <b>{{
                    webCV.profile?.general?.employmentModeText | translate
                    }}</b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-3 col-md-4 mb-30" *ngIf="webCV.profile?.contactInfo?.street">
            <div class="profile-bio-card">
              <h4>{{ "WEB_CV.LABEL.ADDRESS" | translate }}</h4>
              <div class="profile-bio-content">
                <p>
                  <b
                  >{{ webCV.profile?.contactInfo?.street }}
                    <br
                      *ngIf="
                        webCV.profile?.contactInfo?.street &&
                        (webCV.profile?.contactInfo?.zip ||
                          webCV.profile?.contactInfo?.city[lang])
                      "
                    />
                    {{ webCV.profile?.contactInfo?.zip }}
                    {{ webCV.profile?.contactInfo?.city[lang] }}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-3 col-md-4 mb-30" *ngIf="webCV.profile?.location?.location">
            <div class="profile-bio-card">
              <h4>{{ "WEB_CV.LABEL.LOCATION" | translate }}</h4>
              <div class="profile-bio-content">
                <p>
                  <b>
                    <ng-template [ngIf]="webCV.profile?.location?.location">
                      {{ webCV.profile?.location?.location }}
                      <br/>
                    </ng-template>
                    <ng-template [ngIf]="webCV.profile?.location?.radius">
                      {{
                      "WEB_CV.LOCATION.RADIUS"
                        | translate
                        : {radius: webCV.profile?.location?.radius}
                      }}
                      <br/>
                    </ng-template>
                    <ng-template [ngIf]="webCV.profile?.location?.europe">
                      {{ "WEB_CV.LOCATION.EUROPE" | translate }}
                      <br/>
                    </ng-template>
                    <ng-template [ngIf]="webCV.profile?.location?.germany">
                      {{ "WEB_CV.LOCATION.GERMANY" | translate }}
                      <br/>
                    </ng-template>
                    <ng-template [ngIf]="webCV.profile?.location?.remote">
                      {{ "WEB_CV.LOCATION.REMOTE" | translate }}
                    </ng-template>
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="webCV.profile?.contactInfo?.bookingLink" class="col-xl-12 col-lg-3 col-md-4 mb-30">
            <div class="profile-bio-card">
              <h4 *ngIf="webCV.profile.contactInfo.bookingLink">{{ "WEB_CV.LABEL.BOOKING_LIST" | translate }}</h4>
              <div class="profile-bio-content">
                <button *ngIf="webCV.profile.contactInfo?.bookingLinkButtonText[lang]" (click)="openLink()"
                        class="btn-sm btn-primary">{{webCV.profile.contactInfo.bookingLinkButtonText[lang]}}</button>
                <button *ngIf="!webCV.profile.contactInfo?.bookingLinkButtonText[lang]" (click)="openLink()"
                        class="btn-sm btn-primary">{{'GENERAL.OPEN_LINK' | translate}}</button>
              </div>
            </div>
          </div>

          <div class="col-xl-12 col-lg-3 col-md-4 mb-30" *ngIf="checkCertificates()">
            <div class="profile-bio-card">
              <h4>{{ "WEB_CV.LABEL.BADGES" | translate }}</h4>
              <ul class="certificate-list certificate-list-box">
                <ng-container *ngFor="let certificate of webCV.skillUps">

                  <ng-container
                    *ngIf="certificate.link && (checkFile(certificate.fileName) || certificate.badgeName); else imgWithoutLink">
                    <li class="certificate-badge-image">
                      <a target="_blank" [href]="certificate.link">
                        <img class="certificate-badge-image" [src]="certificate.fileName || certificate.badgeName" alt="">
                      </a>
                    </li>
                  </ng-container>
                  <ng-template #imgWithoutLink>
                    <li class="certificate-badge-image" *ngIf="(!checkFile(certificate.fileName)) && certificate.badgeName"
                        (click)="openFileModal($event, certificate?.fileName)">
                      <img class="certificate-badge-image" [src]="certificate.badgeName" alt="">
                    </li>
                    <li class="certificate-badge-image" *ngIf="checkFile(certificate.fileName)"
                        (click)="openFileModal($event, certificate?.fileName)">
                      <img class="certificate-badge-image" [src]="certificate.fileName" alt="">
                    </li>
                  </ng-template>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-controls]="'toggleSection'"
      [attr.aria-expanded]="!isCollapsed"
      [class.opened]="!isCollapsed"
      class="collapse-control"
    >
      <ng-template [ngIf]="isCollapsed">
        {{ "WEB_CV.SHOW_MORE_MOBILE" | translate }}
      </ng-template>
      <ng-template [ngIf]="!isCollapsed">
        {{ "WEB_CV.SHOW_LESS_MOBILE" | translate }}
      </ng-template>
      <br/>
      <ng-template [ngIf]="isCollapsed">
        <i class="gvcv-icon icon-chevron-down"></i>
      </ng-template>
      <ng-template [ngIf]="!isCollapsed">
        <i class="gvcv-icon icon-chevron-up"></i>
      </ng-template>
    </div>
  </section>
</ng-template>

<ng-template #noData>
  <div class="cv-value">-</div>
</ng-template>
