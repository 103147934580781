import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GravityTheme} from '@models/web-cv/gravity-theme';
import {SharedCv} from '@models/shared-cv/shared-cv';

@Component({
  selector: 'app-section-bio',
  templateUrl: './section-bio.component.html',
  styleUrls: ['./section-bio.component.scss']
})
export class SectionBioComponent implements OnInit {
@ViewChild('cvbio') cvBio: ElementRef;
  lang: string;

  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;

  readMore = false;

  constructor() {
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }
  ngOnInit() {
    // if(this.webCV?.profile?.bio[this.lang]){

    //   this.webCV!.profile!.bio[this.lang] = this.webCV?.profile?.bio[this.lang] + '<a href="javascript:;" (click)="readMore= !readMore">' + '{{readMore ?' + 'Read Less' + ' : ...Read More' + '}}</a>'
    //   debugger
    // }
    // this.cvBio.nativeElement.insertAdjacentHTML('beforeend', '<a href="javascript:;" (click)="readMore= !readMore">{{readMore ? "Read Less" : "...Read More"}}</a>')
  }

}
