import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CvProject, GravityTheme, ProjectSkill, SharedCv} from '@app/models';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {UntypedFormGroup} from '@angular/forms';
import {AbstractForm} from '@helpers/abstract.form';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileModalComponent } from '@app/components/file-modal/file-modal.component';

@Component({
  selector: 'app-section-project',
  templateUrl: './section-project.component.html',
  styleUrls: ['./section-project.component.scss']
})
export class SectionProjectComponent extends AbstractForm implements OnInit, OnDestroy {

  lang: string;
  theme: string;
  @Input() importantProject: CvProject[];
  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() searchVisible: boolean;

  @Output() projects = new EventEmitter<any>();

  projectList: CvProject[];
  filteredProjectList: CvProject[];
  filteredProjectNum: number;
  onDestroy$ = new Subject<void>();
  model: any = {};
  // showAll = true
  startLength = 0
  endLength = 2
  sortModel: any; // TODO: Sort projects in future
  formSort = new UntypedFormGroup({});
  fieldsSort: FormlyFieldConfig[] = [
    {
      key: 'sort',
      id: 'sortProjects',
      type: 'nebular-select',
      defaultValue: 'date',
      templateOptions: {
        options: [
          {value: 'date', label: this.tr(_t('PROJECT.SORT_BY_DATE'))},
          {value: 'company', label: this.tr(_t('PROJECT.SORT_BY_COMPANY'))},
        ],
      },
    }
  ];
  fieldsSearch: FormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      className: 'projects-search-field',
      templateOptions: {
        placeholder: this.tr(_t('PROJECT.SEARCH')),
        addonLeft: {
          class: 'gvcv-icon icon-search text-icon-size',
        },
        addonRight: {
          class: 'gvcv-icon icon-close text-icon-size',
          hidden: true,
          onClick: (to, field) => {
            field.formControl.setValue('');
          }
        }
      },
      hooks: {
        onInit: (field) => {
          const form = field.parent.formControl;
          form.get('search').valueChanges.pipe(
            takeUntil(this.onDestroy$),
            tap(() => {
              field.templateOptions.addonRight.hidden = !this.model.search;
            }),
          ).subscribe();
        }
      },
      modelOptions: { debounce: { default: 500 }}
    }
  ];

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private modalService: NgbModal) {
    super(ts, ui);
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }

  ngOnInit(): void {
    this.theme = this.gravityTheme.theme;
     this.projectList = this.importantProject?.length ? this.importantProject : this.webCV.projects;
     this.filteredProjectList = this.importantProject?.length ? this.importantProject : this.webCV.projects;
    if(this.theme == 'THEME1' || this.theme == 'THEME2'){
      if(this.searchVisible){
        this.endLength = this.filteredProjectList.length + 1
      }
      else{
        this.endLength = 2
      }
    }
    else{
      this.endLength = this.filteredProjectList.length + 1
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  submit() {
    const valueSearch = this.model.search.trim().toLowerCase();

    if (valueSearch) {
      this.filteredProjectList = this.projectList.filter(project => {
        const includesTitleEn = project.project && project.project.title.en ? project.project.title.en.toLowerCase().includes(valueSearch) : false;
        const includesTitleDe = project.project && project.project.title.de ? project.project.title.de.toLowerCase().includes(valueSearch) : false;
        const includesDescEn = project.project && project.project.description.en ? project.project.description.en.toLowerCase().includes(valueSearch) : false;
        const includesDescDe = project.project && project.project.description.de ? project.project.description.de.toLowerCase().includes(valueSearch) : false;
        const includesCompanyName = project.company && project.company.name ? project.company.name.toLowerCase().includes(valueSearch) : false;
        const includesSkills = (project.skills)
          ? project.skills.some((val: ProjectSkill) => val.skill.nameLocalizations[this.lang].trim().toLowerCase().includes(valueSearch))
          : false;

        if (includesTitleEn || includesTitleDe || includesDescEn || includesDescDe || includesCompanyName || includesSkills) {
          return project;
        }
      });
      this.filteredProjectNum = this.filteredProjectList.length;
    } else {
      this.filteredProjectNum = null;
      this.filteredProjectList = this.projectList;
    }
  }

  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    console.log(fileUrl);
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = fileUrl;
  }

  isImage(fileUrl: string): boolean {
    return typeof fileUrl === 'string' && fileUrl.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  closeProject(){
    this.searchVisible = false;
    this.projects.emit(this.searchVisible)
  }
}
