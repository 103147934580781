import {Skill, SkillAdapter} from '@models/skillfeed/skill';
import {ResourceAdapter} from '@app/api-provider/interfaces/resourceAdapter';
import {GravityDate} from '@app/models';
import {HelperService} from '@helpers/helper.service';

export enum SkillUpStatus {

}

export enum SkillUpType {
  NONE = 'NONE',
  CERTIFICATE = 'CERTIFICATE',
  STUDIES = 'STUDIES',
  TRAINEE = 'TRAINEE',
  ONLINE_COURSE = 'ONLINE_COURSE',
  BOOK = 'BOOK',
  WORKSHOP = 'WORKSHOP',
  PODCAST = 'PODCAST',
  SCHOOL = 'SCHOOL',
  UNIVERSITY = 'UNIVERSITY',
  INTERNSHIP = 'INTERNSHIP'
}

export class SkillUp {
  static LIST_NAME = 'content';

  id: number;
  userId: number;
  title: string;
  link: string;
  description: string;
  skillupType: SkillUpType;
  skills: Skill[];
  duration: number;
  durationType: string;
  updated: number;
  fileName: string;
  badgeName: string;

  dateFrom: string | Date | GravityDate;
  dateTo: string | Date | GravityDate;
  untilNow = false;

}

export class SkillUpAdapter implements ResourceAdapter {
  fromJson(json: any): SkillUp {
    const skillUp = new SkillUp();

    skillUp.id = json.id;
    skillUp.userId = json.userId;
    skillUp.title = json.title;
    skillUp.link = json.link;
    skillUp.description = json.description;
    skillUp.skillupType = json.skillupType;
    skillUp.duration = json.duration;
    skillUp.durationType = json.durationType;
    skillUp.updated = json.updated;
    skillUp.fileName = json.fileName;
    skillUp.badgeName = json.badgeName;

    if (json.skills) {
      skillUp.skills = new SkillAdapter().fromJsonArray(json.skills);
    }

    skillUp.dateFrom = json.dateFrom;
    skillUp.dateTo = json.dateTo;
    skillUp.untilNow = json.untilNow;

    if (!!json.dateFrom && typeof json.dateFrom === 'string') {
      skillUp.dateFrom = HelperService.string2Date(json.dateFrom);
    }
    if (!!json.dateTo && typeof json.dateTo === 'string') {
      skillUp.dateTo = HelperService.string2Date(json.dateTo);
    }

    return skillUp;
  }

  toJson(resource: SkillUp): any {
    return resource;
  }

  fromJsonArray(data: any): SkillUp[] | any {
    if (!data) {
      return [new SkillUp()];
    }
    return data.map(item => this.fromJson(item));
  }
}
