import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(items: string[], searchText: string): string[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    return items.filter(item => {
      return item.toLowerCase().includes(searchText.toLowerCase())
    });
  }

}
