import {ChangeDetectionStrategy, Component, Input, Output} from '@angular/core';
import {_t} from '@helpers/string-helpers';
import {TeamMember, Skill} from '@app/models';
import { SelectCVEvent } from '@app/helpers/events/SelectCVEvent';
import { EventEmitter } from '@angular/core';
import { List } from 'immutable';

class MatrixElement {
  skill: Skill;
  locale: string;
  constructor(skill: Skill, locale: string){
    this.skill = skill;
    this.locale = locale;
  }

  getStars(member: TeamMember): number[] {
    let memberSkill = member.skills.find(skill => this.skillEquals(skill, this.skill));
    if(memberSkill){
      return Array(memberSkill.stars);
    }else{
      return [];
    }
  }

  hasStars(member: TeamMember): boolean {
    let memberSkill = member.skills.find(skill => this.skillEquals(skill, this.skill));
    if(memberSkill && memberSkill.stars > 0){
      return true;
    }else{
      return false;
    }
  }

  hasSkill(member: TeamMember): boolean {
    let memberSkill = member.skills.find(skill => this.skillEquals(skill, this.skill));
    if(memberSkill){
      return true;
    }else{
      return false;
    }
  }

  private skillEquals(skill1: Skill, skill2: Skill): boolean{
    return skill1.nameLocalizations[this.locale] == skill2.nameLocalizations[this.locale];
  }
}

@Component({
  selector: 'app-skill-matrix-area',
  templateUrl: './skill-matrix-area.component.html',
  styleUrls: ['./skill-matrix-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillMatrixAreaComponent{
  @Input() filteredMembers: TeamMember[] = [];
  @Input() filteredSkills: List<Skill>;
  @Input() locale: string;
  @Output() viewCV: EventEmitter<SelectCVEvent> = new EventEmitter<SelectCVEvent>();
  @Output() deleteMember: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns: string[];
  skillMatrix: MatrixElement[];
  ngOnChanges() {
    this.displayedColumns = Array.from(this.filteredMembers.map(member => member.memberId.toString()));
    this.displayedColumns.push("spacer");
    this.displayedColumns.unshift("matrix");
    this.skillMatrix = [];
    this.filteredSkills.forEach(skill => {
      this.skillMatrix.push(new MatrixElement(skill, this.locale));
    });
  }

  onViewCV(username: string, memberId: number, cvId?: number) {
    this.viewCV.emit({ username, memberId, cvId });
  }
  delete(memberId){
    this.deleteMember.emit({ memberId });
  }
}
