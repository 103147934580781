import {NgModule} from '@angular/core';
import {Location} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ErrorPageComponent} from '@modules/error-page/default/error-page.component';
import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {LocalizeRouterHttpLoader} from '@gilsdav/ngx-translate-router-http-loader';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {ExpiredPageComponent} from '@modules/error-page/expired/expired-page.component';
import {teamMatcher} from '@app/matcher/team-matcher';
import {singleUserMatcher} from '@app/matcher/single-user-matcher';
import {defaultMatcher} from '@app/matcher/default-matcher';

const appRoutes: Routes = [
  {
    path: 'not-found',
    component: ErrorPageComponent
  },
  {
    path: '404',
    pathMatch: 'full',
    redirectTo: 'not-found'
  },
  {
    path: 'expired',
    component: ExpiredPageComponent
  },
  {
    matcher: singleUserMatcher,
    loadChildren: () => import('./layouts/single-user/single-user.module').then(mod => mod.SingleUserModule),
  },
  {
    matcher: teamMatcher,
    loadChildren: () => import('./layouts/team/team.module').then(mod => mod.TeamModule),
  },
  {
    matcher: defaultMatcher,
    pathMatch: 'full',
    redirectTo: 'not-found',
  },
  {
    path: '**',
    component: ErrorPageComponent
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
    ),
    LocalizeRouterModule.forRoot(appRoutes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings, http) =>
          new LocalizeRouterHttpLoader(translate, location, settings, http),
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
    }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
