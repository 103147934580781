import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CvSocial {
  linkedin: string;
  twitter: string;
  facebook: string;
  github: string;
  behance: string;
  xing: string;
  stackoverflow: string;
  instagram: string;
  dribble: string;
}

export class CvSocialAdapter implements ModelAdapter {
  fromJson(json: any): CvSocial {
    const obj = new CvSocial();

    obj.linkedin = json.linkedin;
    obj.twitter = json.twitter;
    obj.facebook = json.facebook;
    obj.github = json.github;
    obj.behance = json.behance;
    obj.xing = json.xing;
    obj.stackoverflow = json.stackoverflow;
    obj.instagram = json.instagram;
    obj.dribble = json.dribble;

    return obj;
  }

  toJson(resource: CvSocial): any {
    return resource;
  }
}
