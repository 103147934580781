import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UiService} from '@app/services/ui.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.scss']
})
export class FileModalComponent implements OnInit {

  @Input() fileUrl: string;
  @Input() title: string;
  @ViewChild('embedFile') embedFileElement;
  trustedFileUrl: SafeResourceUrl;
  isImage: boolean;

  constructor(protected ui: UiService,
              public activeModal: NgbActiveModal,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.trustedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
    this.isImage = this.fileUrl.match(/\.(jpeg|jpg|gif|png)$/) != null;
    // this is needed, because chrome doesn't draw <object>, so we trigger the CSS Engine to draw it again
    if (this.embedFileElement) {
      setTimeout(() => {
        this.embedFileElement.nativeElement.style.zIndex = 10;
      }, 1);
    }
  }

}
