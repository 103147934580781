import {AfterViewChecked, AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {GravityTheme} from '@models/web-cv/gravity-theme';
import {SharedCv} from '@models/shared-cv/shared-cv';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-section-rate',
  templateUrl: './section-rate.component.html',
  styleUrls: ['./section-rate.component.scss']
})
export class SectionRateComponent implements OnInit, AfterViewInit {

  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;

  constructor(private ts: TranslateService) {
  }

  ngOnInit() {
    this.webCV.profile.rates = this.webCV.profile.rates.map(rate => {
      rate.currencyText = rate.type
        ? this.ts.instant(rate.currency) + '&nbsp;/&nbsp;' + this.ts.instant(rate.type)
        : this.ts.instant(rate.currency);
      return rate;
    });
  }

  ngAfterViewInit(): void {
    const boxes = document.querySelectorAll<HTMLElement>('.rate-card-amount');
    boxes.forEach(box => {
      const amountElement = box.childNodes[0] as HTMLElement;
      if (amountElement.textContent.length > 8) {
        const size = this.getFontSize(amountElement.textContent.length);
        box.style.fontSize = `${size}rem`;
        const infoElement = box.childNodes[1] as HTMLElement;
        infoElement.style.top = '0.5rem';
        infoElement.style.left = '9.2rem';
      }
    });
  }

  getFontSize(textLength: number) {
    const baseSize = 9;
    const baseRem = 2.8;
    const factor = 0.33;
    let fontSize = 0;
    if (textLength >= baseSize) {
      let diff = textLength - baseSize;
      diff = diff * factor;
      fontSize = baseRem - diff;
      if (fontSize < 1.4) {
        return 1.3;
      }
      return fontSize;
    }
    return 4;
  }
}
