import {Component, OnDestroy, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {of} from 'rxjs/internal/observable/of';
import {Observable, Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {_t} from '@helpers/string-helpers';
import {SelectOption} from '@models/common/select-option';

@Component({
  selector: 'app-dropdown-select',
  template: `
    <div (click)="(!to.disabled) ? onDropDownClick() : false"
         class="dropdown-link d-flex align-items-center justify-content-between {{dropdownClass}}"
         [ngClass]="[showDropdown ? 'opened' : '', !to.disabled ? 'enabled' : '', showError ? 'is-invalid': '']">
      <a class="d-flex">
        <i class="gvcv-icon icon-{{ icon || ''}}"></i>
        <span class="ms-2 me-2">
          {{ to.placeholder | translate}}
        </span>
      </a>
      <i [ngClass]="!to.disabled ? 'icon-chevron-down' : ''" class="gvcv-icon"></i>
    </div>

    <div [@showDropdown]="{value: showDropdown ? 'visible' : 'hidden', params: {dropDownMaxHeight: maxHeight}}"
         class="dropdown-select hide">
      <div aria-labelledby="profileDropdown"
           [class.is-invalid]="showError"
           [formlyAttributes]="field"
      >
        <ng-container *ngFor="let item of selectOptions | async">
          <a class="dropdown-item d-flex" *ngIf="!item.group && !item.disabled" (click)="onClick(item)">
            <i class="gvcv-icon icon-{{ item.icon }}"></i>
            <span class="ms-3 me-2">
              {{ item[labelProp] | translate}}
            </span>
          </a>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./dropdown-select.component.scss'],
  animations: [
    trigger('showDropdown', [
      state('visible', style({
        maxHeight: '{{dropDownMaxHeight}}',
        opacity: 1,
        visibility: 'visible'
      }), {params: {dropDownMaxHeight: '9rem'}}),
      state('hidden', style({height: 0, opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')]),
    ]),
  ]
})
export class DropdownSelectComponent extends FieldType implements OnInit, OnDestroy {
  showDropdown: boolean;
  icon: any;

  private sub: Subscription;

  get labelProp(): string {
    return this.to.labelProp || 'label';
  }

  get valueProp(): string {
    return this.to.valueProp || 'value';
  }

  get groupProp(): string {
    return this.to.groupProp || 'group';
  }

  get useLabel(): string {
    return this.to.useLabel || true;
  }

  get dropdownClass(): string {
    return this.to.dropdownClass || '';
  }

  get maxHeight(): string {
    return this.to.maxHeight || '9rem';
  }

  get selectOptions(): Observable<any[]> {
    if (!(this.to.options instanceof Observable)) {
      const options: SelectOption[] = [];
      const groups: { [key: string]: SelectOption[] } = {};

      this.to.options.map((option: SelectOption) => {
        if (!option[this.groupProp]) {
          options.push(option);
        } else {
          if (groups[option[this.groupProp]]) {
            groups[option[this.groupProp]].push(option);
          } else {
            groups[option[this.groupProp]] = [option];
            options.push({
              label: option[this.groupProp],
              group: groups[option[this.groupProp]],
            });
          }
        }
      });

      return of(options);
    } else {
      // return observable directly
      return this.to.options;
    }
  }

  setSelected(val) {
    if (val === 'linkedin') {
      this.icon = 'linked-in';
    } else {
      this.icon = val;
    }

    if (!!val) {
      if (!!this.useLabel) {

        let currentLabel;

        this.to.options.forEach((item: SelectOption | any) => {
          if (item[this.valueProp] === val) {
            currentLabel = item[this.labelProp];
          }
          this.to.placeholder = (currentLabel) ? currentLabel : this.to.placeholder || _t('FORM.SELECT');
        });
      } else {

        const capitalized = (val) ? val.charAt(0).toUpperCase() + val.slice(1) : '';
        this.to.placeholder = capitalized || this.to.placeholder || _t('FORM.SELECT');
      }
    } else {
      if (!this.to.placeholder) {
        this.to.placeholder = _t('FORM.SELECT');
      }
    }
  }

  onDropDownClick() {
    if (!this.to.disabled) {
      this.showDropdown = !this.showDropdown;
    }

    return false;
  }

  onClick(value) {
    this.formControl.setValue(value[this.valueProp]);
    this.setSelected(this.formControl.value);
    this.showDropdown = !this.showDropdown;
  }

  ngOnInit(): void {
    this.setSelected(this.formControl.value);

    this.sub = this.formControl.valueChanges.subscribe(value => {
      this.setSelected(value);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
