import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'app-field-horizontal',
  template: `
    <div class="form-group d-flex flex-wrap">
      <label [attr.for]="id" class="col-form-label me-2" *ngIf="to.label">
        {{ to.label }}
        <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>
      </label>
      <div class="">
        <ng-template #fieldComponent></ng-template>
      </div>
    </div>
  `,
  styleUrls: ['./field-horizontal.component.scss']
})
export class FieldHorizontalComponent extends FieldWrapper {

  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

}
