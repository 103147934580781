<!-- <div class="d-inline-flex footer-logo-wrap">
  <span class="footer-text me-2">{{"GENERAL.CREATED_BY" | translate}}</span>
  <app-gravity-logo class="mx-auto"></app-gravity-logo>
</div>

<div class="gvcv-build-number">{{ gvcvBuildNumber  }}</div>

<div class="d-inline-flex align-items-center footer-links-wrap">
  <a [href]="imprint" class="footer-link {{linkClass}}" target="_blank">{{"GENERAL.IMPRINT" | translate}}</a>
  <span class="footer-link-separator {{linkClass}}"></span>
  <a [href]="dataProtection" class="footer-link {{linkClass}}"
     target="_blank">{{"GENERAL.DATA_PROTECTION" | translate}}</a>
  <span class="footer-link-separator {{linkClass}}"></span>
  <a [href]="tos" class="footer-link {{linkClass}}"
     target="_blank">{{"GENERAL.TERMS_OF_SERVICE" | translate}}</a>

</div> -->


<div class="new-footer">
    <div class="new-footer-wrapper">
      <app-gravity-logo class="mx-auto"></app-gravity-logo>
      <p>{{"FOOTER.CONTENT" | translate}}</p>
    </div>
</div>
