import {Company, CompanyAdapter} from '@models/profile/company';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@app/models';

export class Contact {

  static LIST_NAME = 'content';

  id: number;
  userId: number;
  salutation: SalutationEnum;
  firstName: string;
  lastName: string;
  email: string;
  companyId: number;
  company?: Company;
  informOnChange?: boolean;
  fullName?: string;
  position: string;
  notes: string;
  phone: string;
  created: string | Date;
  updated: string | Date;
  companyList?: Company | Company[] | any;
}

export class ContactAdapter implements ResourceAdapter {
  fromJson(json: any): Contact {
    const contact = new Contact();

    contact.id = json.id;
    contact.email = json.email;
    contact.salutation = json.salutation;
    contact.firstName = json.firstName;
    contact.lastName = json.lastName;
    contact.companyId = json.companyId;
    contact.position = json.position;
    contact.phone = json.phone;
    contact.notes = json.notes;
    contact.created = json.created;
    contact.updated = json.updated;
    contact.companyList = [];

    if (json.company) {
      contact.company = new CompanyAdapter().fromJson(json.company);
      contact.companyList = [contact.company];
    }

    contact.informOnChange = json.informOnChange;

    contact.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();

    return contact;
  }

  toJson(resource: Contact): any {
    if (resource.companyList) {
      resource.companyId = resource.companyList.id;
      resource.companyList = null;
      resource.company = null;
    }
    return resource;
  }

  fromJsonArray(data: any): Contact[] | any {
    if (!data) {
      return [new Contact()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    data.forEach((item, index) => {
      item.skillOrder = index;
      return item;
    });

    return data.map(item => this.toJson(item));
  }
}
