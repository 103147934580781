import {Injectable} from '@angular/core';
import {appVariables} from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class ViewerAuthService {


  constructor() {
  }

  /** REFERENCES */

  storeReferenceLogin(uniqueHash: string, data: string, pin: string) {
    sessionStorage.setItem(appVariables.viewerReferenceAccessUniqueHash, uniqueHash);
    sessionStorage.setItem(appVariables.viewerReferenceAccessData, data);
    sessionStorage.setItem(appVariables.viewerReferenceAccessPin, pin);
  }

  isViewerLoggedInForReference(uniqueHash: string) {
    const storedHash = sessionStorage.getItem(appVariables.viewerReferenceAccessUniqueHash);

    if (!!(storedHash && storedHash.length > 0)) {
      return storedHash === uniqueHash;
    }

    return false;
  }

  getReferenceAccessUniqueHash = (): string => sessionStorage.getItem(appVariables.viewerReferenceAccessUniqueHash);

  getReferenceAccessData = (): string => sessionStorage.getItem(appVariables.viewerReferenceAccessData);

  getReferenceAccessPin = (): string => sessionStorage.getItem(appVariables.viewerReferenceAccessPin);

  /** SENDOUT */

  storeLoginDataAsViewerForSendout(uniqueHash: string, email: string, pin: string) {
    sessionStorage.setItem(appVariables.viewerSendoutAccessUniqueHash, uniqueHash);
    sessionStorage.setItem(appVariables.viewerSendoutAccessEmail, email);
    sessionStorage.setItem(appVariables.viewerSendoutAccessPin, pin);
  }

  isViewerLoggedInForSendout(uniqueHash: string) {
    const storedHash = sessionStorage.getItem(appVariables.viewerSendoutAccessUniqueHash);

    if (!!(storedHash && storedHash.length > 0)) {
      return storedHash === uniqueHash;
    }

    return false;
  }

  getSendoutAccessUniqueHash = (): string => sessionStorage.getItem(appVariables.viewerSendoutAccessUniqueHash);

  getSendoutAccessEmail = (): string => sessionStorage.getItem(appVariables.viewerSendoutAccessEmail);

  getSendoutAccessPin = (): string => sessionStorage.getItem(appVariables.viewerSendoutAccessPin);

  /** TEAM GROUPS */

  storeLoginDataAsViewerForTeamGroups(groupUrl: string, email: string, pin: string) {
    sessionStorage.setItem(appVariables.viewerTeamGroupAccessGroupUrl, groupUrl);
    sessionStorage.setItem(appVariables.viewerTeamGroupAccessEmail, email);
    sessionStorage.setItem(appVariables.viewerTeamGroupAccessPin, pin);
  }

  isViewerLoggedInForTeamGroups(groupUrl: string) {
    const storedGroupUrl = sessionStorage.getItem(appVariables.viewerTeamGroupAccessGroupUrl);

    if (!!(storedGroupUrl && storedGroupUrl.length > 0)) {
      return storedGroupUrl === groupUrl;
    }

    return false;
  }

  getTeamGroupAccessGroupUrl = (): string => sessionStorage.getItem(appVariables.viewerTeamGroupAccessGroupUrl);

  getTeamGroupAccessEmail = (): string => sessionStorage.getItem(appVariables.viewerTeamGroupAccessEmail);

  getTeamGroupAccessPin = (): string => sessionStorage.getItem(appVariables.viewerTeamGroupAccessPin);


}
