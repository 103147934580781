import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {GravityDate} from '@models/common/gravity-date';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  env: string;

  constructor(protected ts: TranslateService) {
    this.env = environment.environment;
  }

  static formatDate(date, separator?) {
    separator = separator || '-';
    if (date !== undefined) {

      const mm = date.month;
      const dd = date.day;

      return [date.year,
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
      ].join(separator);
    }
    return undefined;
  }

  static timestamp2Date(value): Date {
    if (value) {
      return new Date(value * 1000);
    }
    return null;
  }

  static timestamp2GravityDate(value): GravityDate {
    if (value) {
      const date = new Date(value * 1000);

      return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
      };
    }
  }

  static string2Date(value): Date {
    if (value) {
      return new Date(value);
    }
    return null;
  }

  static toUTCDate(date, separator?) {
    separator = separator || '-';

    return date.getFullYear() + separator +
      ('0' + (date.getMonth() + 1)).slice(-2) + separator +
      ('0' + date.getDate()).slice(-2);
  }

  static date2String(date) {
    return ('0' + date.getDate()).slice(-2) + '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
      date.getFullYear();
  }

  static gravityDate2String(date) {
    return this.date2String(this.string2Date(date));
  }

  static string2GravityDate(value): GravityDate {
    if (value) {
      const date = new Date(value);

      return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
      };
    }
  }

  static isNullOrUndefined(value) {
    return value === undefined || value === null;
  }

  static isObject = (x: any) => x != null && typeof x === 'object';

  static getObjectKeys(object: {}): string[] {
    if (!object || typeof object !== 'object') {
      throw new Error('Only objects can be passed to retrieve its own enumerable properties(keys).');
    }
    return Object.keys(object);
  }

  static deleteAllCookies(): void {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  static isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getTermsLinks(): { tos: string, imprint: string, privacyPolicy: string } {
    let tos = '';
    let privacyPolicy = '';
    let imprint = '';

    switch (this.ts.currentLang) {
      case 'en':
        imprint = environment.imprintUrl_en;
        privacyPolicy = environment.dataProtectionUrl_en;
        tos = environment.tosUrl_en;
        break;
      case 'de':
        imprint = environment.imprintUrl_de;
        privacyPolicy = environment.dataProtectionUrl_de;
        tos = environment.tosUrl_de;
        break;
      default:
        imprint = environment.imprintUrl_de;
        privacyPolicy = environment.dataProtectionUrl_de;
        tos = environment.tosUrl_de;
        break;
    }

    return {
      tos,
      privacyPolicy,
      imprint
    };
  }

  isProdEnv(): boolean {
    return (this.env.toLocaleLowerCase() === 'prod' || this.env.toLocaleLowerCase() === 'production');
  }

  isStageEnv(): boolean {
    return (this.env.toLocaleLowerCase() === 'prod' || this.env.toLocaleLowerCase() === 'production');
  }

  isDevEnv(): boolean {
    return (this.env.toLocaleLowerCase() === 'dev' || this.env.toLocaleLowerCase() === 'development');
  }

  secondsTicksCounter(): object {
    let seconds = 0;

    return {
      start: () => {
        return setInterval(() => {
          seconds++;
        }, 1000);
      },
      stop: (intervalInstance: any) => {
        clearInterval(intervalInstance);
        return seconds;
      },
      intervalInstance: null,
    };
  }

  fileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {

      return reader.result;

    }, false);
  }
}
