import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {_t} from '@helpers/string-helpers';
import { Localizations } from '../profile/app-language';

export class CvPersonal {
  firstName: string;
  lastName: string;
  fullName: string;
  shortName: string;
  dateOfBirth: string;
  salutation: string;
  // academicTitle: string;
  title: Localizations;
  nationality: Localizations;
}

export class CvPersonalAdapter implements ModelAdapter {
  fromJson(json: any): CvPersonal {
    const obj = new CvPersonal();

    obj.firstName = json.firstName;
    obj.lastName = json.lastName;
    obj.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();
    obj.shortName = `${json.firstName ? json.firstName.trim().substring(0, 1) : ''} ${json.lastName ? json.lastName.trim().substring(0, 1) : ''}`;
    obj.dateOfBirth = json.dateOfBirth;
    obj.salutation = json.salutation;
    // obj.academicTitle = json.academicTitle;
    obj.title = json.title;
    obj.nationality = json.nationality;

    return obj;
  }

  toJson(resource: CvPersonal): any {
    return resource;
  }
}
