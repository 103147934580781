import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {RateCurrency, RateVariant} from '../profile/rate';
import {_t} from '@helpers/string-helpers';

export class CvRate {
  where: string;
  amount: string;
  currency: string;
  type: string;
  currencyText: string;
}

export class CvRateAdapter implements ModelAdapter {
  fromJson(json: any): CvRate {
    const obj = new CvRate();

    obj.amount = json.rate || json.amount;
    obj.where = json.info || json.where;

    switch (RateCurrency[json.currency]) {
      case RateCurrency.DOLLAR:
        obj.currency = _t('RATE_CARDS.DOLLAR');
        break;
      case RateCurrency.EURO:
        obj.currency = _t('RATE_CARDS.EURO');
        break;
      default:
        if (json.currency) {
          obj.currency = json.currency;
        }
    }

    switch (RateVariant[json.type]) {
      case RateVariant.DAILY:
        obj.type = _t('RATE_CARDS.DAILY');
        break;
      case RateVariant.HOURLY:
        obj.type = _t('RATE_CARDS.HOURLY');
        break;
      case RateVariant.MONTHLY:
        obj.type = _t('RATE_CARDS.MONTHLY');
        break;
      case RateVariant.YEARLY:
        obj.type = _t('RATE_CARDS.YEARLY');
        break;
      case RateVariant.NONE:
        obj.type = undefined;
        break;
      default:
        if (json.type) {
          obj.type = json.type;
        }
    }

    return obj;
  }

  toJson(resource: CvRate): any {
    return resource;
  }

  fromJsonArray(data: any): CvRate[] | any {
    if (!data) {
      return [new CvRate()];
    }
    return data.map(item => this.fromJson(item));
  }
}
