import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

export class QuillEditorStyles {
  private static instance: QuillEditorStyles;

  height: string;
  background: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: number;
  color: string;
  borderRadius: string;

  private constructor(...params) {
    this.height = '30rem';
    this.background = 'white';
    this.fontFamily = 'SourceSansPro, sans-serif';
    this.fontSize = '1rem';
    this.fontWeight = 300;
    this.lineHeight = 1.5;
    this.color = '#282828'; // $grey-1
    this.borderRadius = '0 0 4px 4px';

    if (params) {
      Object.assign(this, params);
    }
  }

  static getInstance(): QuillEditorStyles {
    if (!QuillEditorStyles.instance) {
      QuillEditorStyles.instance = new QuillEditorStyles();
    }

    return QuillEditorStyles.instance;
  }

  toPlainObj(): any {
    return Object.assign({}, this);
  }
}


@Component({
  selector: 'app-editor',
  template: `
      <quill-editor
              [formControl]="formControl"
              [formlyAttributes]="field"
              [styles]="editorStyles"
              [placeholder]="to.placeholder"
              class="custom-editor"
              [ngClass]="{'is-invalid': formControl.invalid && formControl.touched || showError}">
          <div quill-editor-toolbar [ngClass]="{'is-invalid': formControl.invalid || showError}">
              <span class="ql-formats">
                  <button class="ql-bold" [title]="'Bold'"></button>
                  <button class="ql-italic" [title]="'Italic'"></button>
                  <button class="ql-underline" [title]="'Underline'"></button>
              </span>
              <span class="ql-divider"></span>
              <span class="ql-formats">
                  <button class="ql-list" [value]="'bullet'" [title]="'Bullet list'"></button>
                  <button class="ql-list" [value]="'ordered'" [title]="'Ordered List'"></button>
              </span>
              <span class="ql-divider"></span>
              <span class="ql-formats">
                  <button class="ql-clean" [title]="'Clean'"></button>
              </span>
          </div>
      </quill-editor>
  `,
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent extends FieldType {

  defaultEditorStyles: QuillEditorStyles = QuillEditorStyles.getInstance().toPlainObj();

  get placehilder(): string {
    return this.to.editorStyles ? this.to.editorStyles : this.defaultEditorStyles;
  }
  get editorStyles(): string {
    return this.to.editorStyles ? this.to.editorStyles : this.defaultEditorStyles;
  }
}
