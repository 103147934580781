import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamRoutingModule} from './team-routing.module';
import {TeamDetailsComponent} from './team-details/team-details.component';
import {CompanyAreaComponent} from './company-area/company-area.component';
import {CandidateAreaComponent} from './candidate-area/candidate-area.component';
import {SkillSearchAreaComponent} from './skill-search-area/skill-search-area.component';
import {SkillMatrixAreaComponent} from './skill-matrix-area/skill-matrix-area.component';
import {ComponentsModule} from '@app/components/components.module';
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {NbButtonModule, NbCardModule, NbDatepickerModule, NbLayoutModule} from '@nebular/theme';
import {TeamGroupDetailsComponent} from './team-group-details/team-group-details.component';
import {ViewCvComponent} from './view-cv/view-cv.component';
import {CvTabsComponent} from '../single-user/default/cv-tabs/cv-tabs.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormlyModule} from '@ngx-formly/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@app/helpers/pipes/pipes.module';
import {DefaultModule} from '../single-user/default/default.module';
import {TeamLoginComponent} from '@layouts/team/login/team-login.component';
import {TeamSendoutComponent} from '@layouts/team/team-sendout/team-sendout.component';
import {TeamComponent} from '@layouts/team/team.component';
import {ErrorPageModule} from '@modules/error-page/error-page.module';
import { SingleUserModule } from '../single-user/single-user.module'
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [
        TeamComponent,
        TeamDetailsComponent,
        CompanyAreaComponent,
        CandidateAreaComponent,
        SkillSearchAreaComponent,
        SkillMatrixAreaComponent,
        TeamGroupDetailsComponent,
        ViewCvComponent,
        TeamLoginComponent,
        TeamSendoutComponent
    ],
    imports: [
        TeamRoutingModule,
        CommonModule,
        ComponentsModule,
        NbLayoutModule,
        NbCardModule,
        NbButtonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        NgbNavModule,
        PipesModule,
        NgbModule,
        DefaultModule,
        ErrorPageModule,
        SingleUserModule,
        MatMenuModule,
        MatIconModule,
        MatTableModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatSelectModule,
        NgSelectModule ,
        MatNativeDateModule,
        NbDateFnsDateModule,
        NbDatepickerModule
    ],
    exports: [
        TeamComponent,
        TeamDetailsComponent,
        CompanyAreaComponent,
        CandidateAreaComponent,
        SkillSearchAreaComponent,
        TeamGroupDetailsComponent,
        ViewCvComponent,
        CvTabsComponent,
        TeamSendoutComponent
    ],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class TeamModule {
}
