import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import {environment} from '@env/environment';
import {UiService} from '@app/services/ui.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {
  GravitySendoutTheme,
  GravityTheme,
  GravityThemeAdapter, GravityThemeSendoutAdapter,
  PinLoginAdapter,
  PinLoginRequest, SharedCv, SharedCvAdapter, TeamSendout, TeamSendoutAdapter,
} from '@app/models';
import {catchError, map, tap} from 'rxjs/operators';
import {_t} from '@helpers/string-helpers';
import {Team, TeamAdapter} from '@models/team/team';
import {TeamGroup, TeamGroupAdapter} from '@models/team/team-group';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private url: string = environment.apiHost;

  constructor(
    protected authService: AuthService,
    protected httpClient: HttpClient,
    protected ui: UiService,
    protected ts: TranslateService,
  ) {
  }

  // WEBCV PREVIEW PAGE

  public checkWebCvPreviewPage(teamDomain: string, usernameOrHash: string, previewToken: string): Observable<GravityTheme> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get(
        `${this.url}/pub/team/${teamDomain}/preview/member/${usernameOrHash}/check${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new GravityThemeAdapter().fromJson(data)),
        tap((data: GravityTheme) => console.log(`check preview webcv page`, data)),
        catchError(this.handleError<GravityTheme>(`check preview webcv page ${typeof GravityTheme}`))
      );
  }

  public getWebCvPreviewPage(teamDomain: string, usernameOrHash: string, previewToken?: string): Observable<SharedCv> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/preview/member/${usernameOrHash}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`get webcv preview page`, data)),
        catchError(this.handleError<SharedCv>(`get webcv preview page`))
      );
  }

  // MAIN TEAM PAGE

  public checkPublicTeamPage(teamDomain: string, previewToken: string): Observable<GravityTheme> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get(
        `${this.url}/pub/team/${teamDomain}/main/check${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new GravityThemeAdapter().fromJson(data)),
        tap((data: GravityTheme) => console.log(`check public team page`, data)),
        catchError(this.handleError<GravityTheme>(`check public team page ${typeof GravityTheme}`))
      );
  }

  public getPublicTeamPage(teamDomain: string, previewToken?: string): Observable<Team> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/main${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamAdapter().fromJson(data)),
        tap((data: Team) => console.log(`get public team page`, data)),
        catchError(this.handleError<Team>(`get team page`))
      );
  }

  // For MVP we will only provide Main Team Page without PIN protection
  /*public loginPublicTeamPage(teamDomain: string, model: PinLoginRequest): Observable<Team> {
    return this.httpClient
      .post<PinLoginRequest>(
        `${this.url}/team/main/${teamDomain}`,
        new PinLoginAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamAdapter().fromJson(data)),
        tap((data: Team) => console.log(`login by pin ${data}`)),
        catchError(this.handleError<Team>(`login by pin`))
      );
  }*/

  public getPublicTeamPageMember(teamDomain: string, username: string): Observable<SharedCv> {
    return this.httpClient
      .get<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/main/cv/${username}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`get members cv for team main page by username`, data)),
        catchError(this.handleError<SharedCv>(`get members cv for team main page by username`))
      );
  }

  // GROUP PAGE

  public checkPublicGroupPage(teamDomain: string, groupName: string, previewToken: string): Observable<GravityTheme> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get(
        `${this.url}/pub/team/${teamDomain}/group/${groupName}/check${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new GravityThemeAdapter().fromJson(data)),
        tap((data: GravityTheme) => console.log(`check public group page`, data)),
        catchError(this.handleError<GravityTheme>(`check public group page ${typeof GravityTheme}`))
      );
  }

  public getPublicGroupPage(teamDomain: string, groupName: string, previewToken?: string): Observable<TeamGroup> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/group/${groupName}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamGroupAdapter().fromJson(data)),
        tap((data: TeamGroup) => console.log(`get public group page`, data)),
        catchError(this.handleError<TeamGroup>(`get group page`))
      );
  }

  public loginPublicGroupPage(teamDomain: string, groupName: string, model: PinLoginRequest): Observable<TeamGroup> {
    return this.httpClient
      .post<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/group/${groupName}/login`,
        new PinLoginAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamGroupAdapter().fromJson(data)),
        tap((data: TeamGroup) => console.log(`login group page by pin`, data)),
        catchError(this.handleError<TeamGroup>(`login group page by pin`))
      );
  }

  public getPublicTeamGroupMember(teamDomain: string, groupUrl: string, username: string, previewToken?: string): Observable<SharedCv> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/group/${groupUrl}/default-cv/${username}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`get members cv for team groups page by username`, data)),
        catchError(this.handleError<SharedCv>(`get members cv for team groups page by username`))
      );
  }

  public loginPublicTeamGroupMember(teamDomain: string, groupUrl: string, username: string, pinLoginRequest: PinLoginRequest): Observable<SharedCv> {
    return this.httpClient
      .post<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/group/${groupUrl}/default-cv/login/${username}`,
        new PinLoginAdapter().toJson(pinLoginRequest),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`login member for team groups page by username`, data)),
        catchError(this.handleError<SharedCv>(`login member for team groups page by username`))
      );
  }

  // SENDOUT

  public checkSendoutPage(teamDomain: string, hash: string): Observable<GravitySendoutTheme> {
    return this.httpClient
      .get(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}/check`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new GravityThemeSendoutAdapter().fromJson(data)),
        tap((data: GravitySendoutTheme) => console.log(`check sendout page`, data)),
        catchError(this.handleError<GravitySendoutTheme>(`check sendout page ${typeof GravitySendoutTheme}`))
      );
  }

  public getSendoutPage(teamDomain: string, hash: string): Observable<TeamSendout> {
    return this.httpClient
      .get<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamSendoutAdapter().fromJson(data)),
        tap((data: TeamSendout) => console.log(`get sendout page`, data)),
        catchError(this.handleError<TeamSendout>(`get sendout page`))
      );
  }

  public loginSendoutPage(teamDomain: string, hash: string, model: PinLoginRequest): Observable<TeamSendout> {
    return this.httpClient
      .post<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}`,
        new PinLoginAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new TeamSendoutAdapter().fromJson(data)),
        tap((data: TeamSendout) => console.log(`login sendout page by pin`, data)),
        catchError(this.handleError<TeamSendout>(`login sendout page by pin`))
      );
  }

  // SENDOUT MEMBERS (SENDOUT TYPE: GROUP)

  public getSendoutMember(teamDomain: string, hash: string, memberId: string): Observable<SharedCv> {
    return this.httpClient
      .get(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}/member/${memberId}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`get sendout for group member`, data)),
        catchError(this.handleError<SharedCv>(`get for group member`))
      );
  }

  public loginSendoutMember(teamDomain: string, hash: string, memberId: string, pinLoginRequest: PinLoginRequest): Observable<SharedCv> {
    return this.httpClient
      .post<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}/member/${memberId}`,
        new PinLoginAdapter().toJson(pinLoginRequest),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`login sendout for group member by pin`, data)),
        catchError(this.handleError<SharedCv>(`login sendout for group member by pin`))
      );
  }

  // SENDOUT CVS (SENDOUT TYPE: SINGLE AND MULTIPLE)

  public getSendoutCv(teamDomain: string, hash: string, cvId: string): Observable<SharedCv> {
    return this.httpClient
      .get(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}/cv/${cvId}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`get sendout for single/multiple cv`, data)),
        catchError(this.handleError<SharedCv>(`get for single/multiple cv`))
      );
  }

  public loginSendoutCv(teamDomain: string, hash: string, cvId: string, pinLoginRequest: PinLoginRequest): Observable<SharedCv> {
    return this.httpClient
      .post<PinLoginRequest>(
        `${this.url}/pub/team/${teamDomain}/sendout/${hash}/cv/${cvId}`,
        new PinLoginAdapter().toJson(pinLoginRequest),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new SharedCvAdapter().fromJson(data)),
        tap((data: SharedCv) => console.log(`login sendout for single/multiple cv by pin`, data)),
        catchError(this.handleError<SharedCv>(`login sendout for single/multiple cv by pin`))
      );
  }

  // MEMBERS CV

  public checkMembersCv(teamDomain: string, usernameOrUniqueHash: string, previewToken: string): Observable<GravityTheme> {
    const queryParams = (previewToken) ? `?token=${previewToken}` : '';

    return this.httpClient
      .get(
        `${this.url}/cv/team/${teamDomain}/check/members-cv/${usernameOrUniqueHash}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new GravityThemeAdapter().fromJson(data)),
        tap((data: GravityTheme) => console.log(`check members cv by username or unique hash`, data)),
        catchError(this.handleError<GravityTheme>(`check members cv by username or unique hash ${typeof GravityTheme}`))
      );
  }

  // General

  getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // TODO: Show error messages
      switch (operation) {
        case 'login by pin':
          this.ui.showToast('error',
            this.ts.instant(_t('TOAST.ERROR.GENERAL.TITLE')),
            this.ts.instant(_t('TOAST.ERROR.PIN_PROTECTED.INCORRECT_PIN')));
          break;
      }
      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }
}
