import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';

import {HttpLoaderFactory} from '@app/app.config';
import {AppRoutingModule} from '@app/app-routing.module';
import {AppComponent} from '@app/app.component';
import {ErrorPageModule} from '@modules/error-page/error-page.module';
import {AuthGuard} from '@app/auth/auth.guard';
import {ComponentsModule} from '@components/components.module';
import {ToastrModule} from 'ngx-toastr';
import {GravityToastrComponent} from '@components/gravity-toastr/gravity-toastr.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {NbDatepickerModule, NbLayoutModule, NbSidebarModule, NbThemeModule} from '@nebular/theme';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {FormlyModule} from '@ngx-formly/core';
import {formlyConfig} from '@app/formlyConfig';
import {ErrorInterceptor} from '@api/http-interceptors/error-interceptor';
import {SingleUserModule} from '@layouts/single-user/single-user.module';
import {TeamModule} from '@layouts/team/team.module';
import {environment} from '@env/environment';
import {SharedWebCvService} from '@app/services/shared-web-cv.service';
import {ScenarioType} from '@app/models';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ErrorPageModule,
        ComponentsModule,
        ToastrModule.forRoot({
            toastComponent: GravityToastrComponent,
            timeOut: 0,
            positionClass: 'toast-top-center',
            enableHtml: true,
            closeButton: true,
            progressBar: true,
            maxOpened: 1,
            autoDismiss: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            useDefaultLang: true,
        }),
        ReactiveFormsModule,
        FormlyBootstrapModule,
        FormlyModule.forRoot(formlyConfig),
        AppRoutingModule,
        // NbLayoutModule,
        NbSidebarModule.forRoot(),
        NbThemeModule.forRoot({ name: 'corporate' }),
        NbDatepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({
            format: 'dd.MM.yyyy',
        }),
        SingleUserModule,
        TeamModule,
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: onAppInit,
            multi: true,
            deps: [Injector]
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}

export function onAppInit(injector: Injector): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve : any, reject) => {
      const service = injector.get(SharedWebCvService);

      const forbiddenSubdomains = ['me'];
      const url = new URL(window.location.href);
      const regexPattern = /^([a-zA-Z0-9\-]+)\.(.+)\.(.+)/g;
      const match = regexPattern.exec(url.host);
      const splitPath = url.pathname.split('/').slice(1); // slice(1) removes first empty string. /abc => ['', 'abc']
      const locale = splitPath.shift() || 'de'; // if first part of path (locale) doesn't exist, use default language 'de'
      const remainingPath = splitPath.length > 0 ? '/' + splitPath.join('/') : ''; // add the slash prefix, if there is a path after locale
      let scenario: ScenarioType;

      console.log('host ' + environment.defaultHost);
      if (match != null && match[1] && !forbiddenSubdomains.includes(match[1])) {
        service.checkCustomDomain(url.host).subscribe(response => {
          if (response) {
            if (response.username) {
              scenario = 'userCustomDomain';
              sessionStorage.setItem('scenario', scenario);
              sessionStorage.setItem('username', response.username);
            }
            if (response.teamDomain) {
              scenario = 'teamCustomDomain';
              sessionStorage.setItem('scenario', scenario);
              sessionStorage.setItem('teamDomain', response.teamDomain);
            }
          } else {
            if (match != null && match[1] && !forbiddenSubdomains.includes(match[1])) {
              scenario = 'teamSubdomain';
              sessionStorage.setItem('scenario', scenario);
            }
          }
          resolve();
        });
      } else if (url.host === environment.defaultHost && !remainingPath) {
        scenario = 'default';
        sessionStorage.setItem('scenario', scenario);
        resolve();
      } else {
        scenario = 'userDefault';
        sessionStorage.setItem('scenario', scenario);
        resolve();
      }
    });
  };
}
