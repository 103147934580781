import {GravityDate} from '@models/common/gravity-date';
import {Company} from '@models/profile/company';
import {ProjectSkill, ProjectSkillAdapter, Skill} from './skill';
import {Tag, TagAdapter} from '../web-cv/tag';
import {HelperService} from '@helpers/helper.service';
import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {Localizations, LocalizationsWithData} from '../profile/app-language';
import { environment } from '@env/environment';

export class Project {

  static LIST_NAME = 'content';

  id: number;
  companyId: number;
  userId: number;
  title: Localizations;
  titleLocalizations?: Localizations;
  role: Localizations;
  roleLocalizations?: Localizations;
  description: Localizations;
  localizations: LocalizationsWithData;
  dateFrom: string | Date | GravityDate;
  dateTo: string | Date | GravityDate;
  untilNow: boolean;
  teamSize: number;
  link: string;
  company?: Company;
  created: string;
  updated: string;
  skills?: Skill[] | ProjectSkill[];
  tags?: Tag[];
  isEditMode?: boolean;
  fileName: string;
}

export class ProjectAdapter implements ResourceAdapter {

  fromJson(json: any): Project {
    const obj = new Project();

    obj.id = json.id;
    obj.companyId = json.company ? json.company.id : null;
    obj.title = json.title || json.titleLocalizations;
    obj.description = json.description;
    obj.role = json.role || json.roleLocalizations;
    obj.dateFrom = json.dateFrom;
    obj.dateTo = json.dateTo;
    obj.untilNow = json.untilNow;
    obj.teamSize = json.teamSize;
    obj.link = json.link;
    obj.company = json.company;
    obj.created = json.created;
    obj.updated = json.updated;
    obj.localizations = json.localizations;
    obj.skills = new ProjectSkillAdapter().fromJsonArray(json.skills);
    obj.tags = new TagAdapter().fromJsonArray(json.tags);
    if (!!json.fileName) {
      obj.fileName = environment.s3PublicUrl + '/users/' + json.fileName;
    }

    if (!!json.dateFrom && typeof json.dateFrom === 'string') {
      obj.dateFrom = HelperService.string2Date(json.dateFrom);
    }
    if (!!json.dateTo && typeof json.dateTo === 'string') {
      obj.dateTo = HelperService.string2Date(json.dateTo);
    }

    return obj;
  }

  toJson(resource: Project): any {

    resource.skills = new ProjectSkillAdapter().toJsonArray(resource.skills);

    if (resource.dateFrom) {
      resource.dateFrom = HelperService.toUTCDate(resource.dateFrom);
    }
    if (resource.dateTo) {
      resource.dateTo = HelperService.toUTCDate(resource.dateTo);
    }

    return resource;
  }
}
