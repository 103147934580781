import {Observable} from 'rxjs';
import {Resource} from './classes/resource';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ModelAdapter, ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {QueryOptions} from '@api/classes/query-options';
import {map} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError} from 'rxjs/internal/operators/catchError';
import {of} from 'rxjs/internal/observable/of';
import {appVariables} from '@app/app.config';
import {AuthService} from '@app/auth/auth.service';
import {PageableResource, PaginationMetaAdapter} from '@models/common/pageable-resource';

export abstract class ResourceService<T extends Resource> {

  constructor(
    protected authService: AuthService,
    protected httpClient: HttpClient,
    protected url: string,
    protected endpoint: string,
    protected adapter: ResourceAdapter | ModelAdapter,
    protected listName: string,
  ) {
  }

  public create(item: T): Observable<T> {
    return this.httpClient
      .post<T>(
        `${this.url}/${this.endpoint}`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as T),
        tap((data: T) => console.log(`created item id=${data.id}`)),
        catchError(this.handleError<T>(`create ${typeof item}`))
      );
  }

  public update(item: T): Observable<T> {
    return this.httpClient
      .put<T>(
        `${this.url}/${this.endpoint}`,
        this.adapter.toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => this.adapter.fromJson(data) as T),
        tap(_ => console.log(`updated item id=${item.id}`, _)),
        catchError(this.handleError<T>(`update ${typeof item}`))
      );
  }

  read(id: number): Observable<T> {
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.adapter.fromJson(data) as T),
        tap(_ => console.log(`fetched item id=${id}`, _)),
        catchError(this.handleError<T>(`get item id=${id}`))
      );
  }

  list(queryOptions?: QueryOptions): Observable<T[]> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get items list', []))
      );
  }

  paginatedList(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get paginated items list', []))
      );
  }

  sortedList(queryOptions?: QueryOptions): Observable<PageableResource> {
    const queryParams = (queryOptions) ? `?${queryOptions.toQueryString()}` : '';
    return this.httpClient
      .get(
        `${this.url}/${this.endpoint}/search${queryParams}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map((data: any) => this.convertPageableData(data)),
        tap(_ => console.log('fetched items', _)),
        catchError(this.handleError<any>('get sorted items list', []))
      );
  }

  delete(id: number) {
    return this.httpClient
      .delete(
        `${this.url}/${this.endpoint}/${id}`,
        {headers: this.getHeaders()},
      )
      .pipe(
        tap(_ => console.log(`deleted item id=${id}`)),
        catchError(this.handleError<T>('delete item'))
      );
  }

  refreshToken(res: Response) {
    // TODO: Move to consts
    const accessTokenServer = 'X-Auth-Token';
    const token = res.headers.get(accessTokenServer);
    if (token) {
      localStorage.setItem('accessToken', `${token}`);
      localStorage.setItem(appVariables.accessTokenLocalStorage, `${token}`);
    }
  }

  formUrlParam(url, data) {
    let queryString = '';
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (!queryString) {
          queryString = `?${key}=${data[key]}`;
        } else {
          queryString += `&${key}=${data[key]}`;
        }
      }
    }
    return url + queryString;
  }

  getHeaders(): HttpHeaders {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<A>(operation = 'operation', result?: A) {
    return (error: any): Observable<A> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: Extract error messages from error.data.message
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as A);
    };
  }

  protected convertData(data: any): T[] {
    if (!data) {
      return [];
    }
    if (!!this.listName) {
      return data[this.listName].map(item => this.adapter.fromJson(item));
    } else {
      return data.map(item => this.adapter.fromJson(item));
    }
  }

  protected convertPageableData(data: any): PageableResource {
    const pageable = new PageableResource();
    pageable.content = this.convertData(data);
    pageable.meta = new PaginationMetaAdapter().fromJson(data);
    return pageable;
  }
}
