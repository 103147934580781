import {Pipe, PipeTransform} from '@angular/core';
// import {isBoolean} from 'util';

@Pipe({
  name: 'orderBy',
  pure: false,
})
export class OrderByPipe implements PipeTransform {
  value: string[] = [];

  static orderByComparator(a: any, b: any): number {

    if (a === null || typeof a === 'undefined') {
      a = 0;
    }
    if (b === null || typeof b === 'undefined') {
      b = 0;
    }

    if (typeof(a) === 'boolean'  && typeof(b) === 'boolean') {
      const trueLow = 1; // "trueLow" whether to treat true as lower or higher than false
      const v1 = +a;
      const v2 = +b;
      // tslint:disable-next-line:no-bitwise
      return (v1 ^ v2) ? ((v1 ^ trueLow) ? 1 : -1) : 0;
    }

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      // Isn't a number so lowercase the string to properly compare
      a = a.toString();
      b = b.toString();
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }

    return 0; // equal each other
  }

  transform(input: any, config: any = '+'): any {

    if (!input) {
      return input;
    }

    // make a copy of the input's reference
    this.value = [...input];
    const value = this.value;
    if (!Array.isArray(value)) {
      return value;
    }

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.substr(0, 1) === '-';

      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? value.sort() : value.sort().reverse();
      } else {
        const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
          ? propertyToCheck.substr(1)
          : propertyToCheck;

        return value.sort((a: any, b: any) => {
          let aValue = a[property];
          let bValue = b[property];

          const propertySplit = property.split('.');

          if (typeof aValue === 'undefined' && typeof bValue === 'undefined' && propertySplit.length > 1) {
            aValue = a;
            bValue = b;

            for (const j of propertySplit) {
              aValue = aValue[j];
              bValue = bValue[j];
            }
          }

          return !desc
            ? OrderByPipe.orderByComparator(aValue, bValue)
            : -OrderByPipe.orderByComparator(aValue, bValue);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      return value.sort((a: any, b: any) => {
        for (const i of config) {
          const desc = i.substr(0, 1) === '-';
          const property = i.substr(0, 1) === '+' || i.substr(0, 1) === '-' ? i.substr(1) : i;

          let aValue = a[property];
          let bValue = b[property];

          const propertySplit = property.split('.');

          if (typeof aValue === 'undefined' && typeof bValue === 'undefined' && propertySplit.length > 1) {
            aValue = a;
            bValue = b;

            for (const j of propertySplit) {
              aValue = aValue[j];
              bValue = bValue[j];
            }
          }

          const comparison = !desc
            ? OrderByPipe.orderByComparator(aValue, bValue)
            : -OrderByPipe.orderByComparator(aValue, bValue);

          // Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }

        return 0; // equal each other
      });
    }
  }
}
