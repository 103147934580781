<ng-container *ngIf="webCV?.skillUps?.length">

<div *ngFor="let item of filteredSkillUpList | slice:start:end; let i = index" class="card no-shadow mb-30 cv-qualifications">

  <div class="filtered-project-list">
  <ng-container *ngIf="theme === 'DEFAULT'">
      <div class="card no-shadow mb-30 filtered-card">
          <div class="avail-dates">
              <div class="dates-tag" *ngIf="item?.dateFrom || item?.dateTo">
                  <div class="calender-icon">
                      <img src="assets/images/calender-white-icon.svg" alt="calender-icon">
                  </div>
                  <p>
                      <ng-template [ngIf]="item?.dateFrom && !item?.dateTo && !item?.untilNow">
                          {{ 'SKILL_UP.DATE_START' | translate }}
                      </ng-template>
                      <ng-template [ngIf]="item?.dateFrom != null">
                          {{item?.dateFrom | date:'dd.MM.yyyy'}}
                      </ng-template>
                      <ng-template [ngIf]="!item?.dateFrom && item?.dateTo && !item?.untilNow">
                          {{ 'SKILL_UP.DATE_END' | translate }}
                      </ng-template>
                      <ng-template [ngIf]="!item?.dateTo && item?.untilNow">
                          <ng-template [ngIf]="!item?.dateFrom ">
                             {{ 'SKILL_UP.DATE_END' | translate }}
                          </ng-template>
                          <ng-template [ngIf]="item?.dateFrom"> -</ng-template>
                            {{ 'SKILL_UP.TODAY' | translate }}
                      </ng-template>
                      <ng-template [ngIf]="item?.dateTo">
                          <ng-template [ngIf]="item?.dateFrom"> -</ng-template>
                          <ng-container>
                              {{item?.dateTo |  date:'dd.MM.yyyy'}}
                          </ng-container>
                      </ng-template>
                  </p>
              </div>
              <div class="project-title">{{item?.title[lang] || '' }}</div>
          </div>
          <hr>
          <div class="projects-bio">
              <div class="project-detailed">
                  <h4>{{ 'SKILL_UP.ART' | translate }}</h4>
                  <span>{{item?.skillupType || '' | translate }}</span>
              </div>
              <div *ngIf="item?.link" class="project-detailed">
                  <h4>{{ 'SKILL_UP.LINK' | translate }}</h4>
                  <a *ngIf="item?.link" class="btn-tag btn-accent button-link" href="{{item?.link}}" target="_blank">
                    <img class="button-link__img" [ngStyle]="{'filter': gravityTheme.contrast === 'light' ? 'invert(100%)' : 'none'}" src="assets/icons/link.svg" alt="Link image" />
                    <span class="button-link__text" [ngClass]="{'button-link__text--light': gravityTheme.contrast === 'light'}"
                      *ngIf="item?.skillUpLinkButtonText?.[lang]; else backupText">
                      {{ item?.skillUpLinkButtonText[lang] }}
                    </span>
                  </a>
              </div>
              <div *ngIf="item?.fileName" class="project-detailed">
                  <h4>{{ 'SKILL_UP.CERTIFICATE' | translate }}</h4>
                  <span class="link-text-content" style="cursor: pointer;">
                    <a *ngIf="item?.fileName" href="#"
                    (click)="openFileModal($event, item?.fileName)">{{ 'SKILL_UP.SHOW_CERTIFICATE' | translate }}</a></span>
              </div>
          </div>
          <hr *ngIf="item?.skills?.length > 0">
          <div class="skills-bio" *ngIf="item?.skills?.length > 0">
              <div class="bio-title">
                  <h4>{{ 'SKILL_UP.SKILLS' | translate }}</h4>
              </div>
              <div class="badge-wrapper">
                  <app-gravity-badge *ngFor="let badge of item?.skills | orderBy: ['mainSkill']"
                      [badgeStyleSelected]="'accent ' + gravityTheme.contrast" [badgeStyle]="'dark-grey'"
                      [badge]="badge" [isFeatured]="badge.mainSkill" [isPresentMode]="true" [locale]="lang" [showSkills]="true">
                  </app-gravity-badge>
              </div>
          </div>
          <hr>
          <div *ngIf="item?.description[lang]" class="description-bio">
              <div class="bio-title">
                  <h4>{{ 'SKILL_UP.DESCRIPTION' | translate }}</h4>
              </div>
              <div class="desc-bio-content">
                <div [innerHTML]="item?.description[lang]" class="badge-wrap description-text flex-row align-items-center"></div>
              </div>
          </div>
      </div>
    </ng-container>

  <ng-container *ngIf="theme === 'THEME1'">
    <div class="theme-one-project bt-border">
        <div class="info-text-primary">
            <ng-template [ngIf]="item?.dateFrom && !item?.dateTo && !item?.untilNow">
              {{ 'SKILL_UP.DATE_START' | translate }}
            </ng-template>

            <ng-template [ngIf]="item?.dateFrom != null">
              {{item?.dateFrom | date:'dd.MM.yyyy'}}
            </ng-template>

            <ng-template [ngIf]="!item?.dateFrom && item?.dateTo && !item?.untilNow">
              {{ 'SKILL_UP.DATE_END' | translate }}
            </ng-template>

            <ng-template [ngIf]="!item?.dateTo && item?.untilNow">
              <ng-template [ngIf]="!item?.dateFrom ">
                {{ 'SKILL_UP.DATE_END' | translate }}
              </ng-template>
              <ng-template [ngIf]="item?.dateFrom"> -</ng-template>
              {{ 'SKILL_UP.TODAY' | translate }}
            </ng-template>

            <ng-template [ngIf]="item?.dateTo">
              <ng-template [ngIf]="item?.dateFrom"> -</ng-template>
              <ng-container>
                {{item?.dateTo |  date:'dd.MM.yyyy'}}
              </ng-container>
            </ng-template>
        </div>
        <div class="detail-content-wrapper">
            <div class="project-title">{{item?.title[lang] || '' }}</div>
            <div class="description-wrapper">
                <div [innerHTML]="item?.description[lang]" class="badge-wrap flex-row align-items-center"></div>
            </div>
            <div class="short-details">
                <div class="row mb-3">
                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="info-label">
                            <div class="info-label-text">{{ 'SKILL_UP.ART' | translate }}</div>
                        </div>
                        <div class="info-text"> {{item?.skillupType || '-' | translate }}
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="info-label">
                            <div class="info-label-text">{{ 'SKILL_UP.LINK' | translate }}</div>
                        </div>
                        <div class="info-text">{{item?.link || ''  }}</div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                      <div class="info-label">
                        <i class="gvcv-icon icon-download"></i>
                        <div class="info-label-text">{{ 'SKILL_UP.CERTIFICATE' | translate }}</div>
                      </div>
                      <div  class="info-text">
                        <a *ngIf="item?.fileName" href="#"
                          (click)="openFileModal($event, item?.fileName)">{{ 'SKILL_UP.SHOW_CERTIFICATE' | translate }}</a>
                      </div>


                    </div>
                </div>
            </div>
            <div class="skill-bio-wrapper" *ngIf="item?.skills?.length > 0">
                <div class="info-label">
                    <div class="info-label-text">{{ 'SKILL_UP.SKILLS' | translate }}</div>
                </div>
                <div class="badge-wrap flex-row">
                    <app-gravity-badge *ngFor="let badge of item?.skills | orderBy: ['mainSkill']"
                        [badgeStyleSelected]="'accent ' + gravityTheme.contrast" [badgeStyle]="'dark-grey'"
                        [badge]="badge" [isFeatured]="badge.mainSkill" [isPresentMode]="true" [locale]="lang" [showSkills]="true">
                    </app-gravity-badge>
                </div>
            </div>
        </div>
    </div>
  </ng-container>


  <ng-container *ngIf="theme === 'THEME2'">
    <div class="theme-one-project">

        <div class="detail-content-wrapper">
            <div class="project-title">{{item?.title[lang] || '' }}</div>
            <div class="info-text-primary">
              <ng-template [ngIf]="item?.dateFrom && !item?.dateTo && !item?.untilNow">
                {{ 'SKILL_UP.DATE_START' | translate }}
              </ng-template>

              <ng-template [ngIf]="item?.dateFrom != null">
                {{item?.dateFrom | date:'dd/MM/yyyy'}}
              </ng-template>

              <ng-template [ngIf]="!item?.dateFrom && item?.dateTo && !item?.untilNow">
                {{ 'SKILL_UP.DATE_END' | translate }}
              </ng-template>

              <ng-template [ngIf]="!item?.dateTo && item?.untilNow">
                <ng-template [ngIf]="!item?.dateFrom ">
                  {{ 'SKILL_UP.DATE_END' | translate }}
                </ng-template>
                <ng-template [ngIf]="item?.dateFrom"> -</ng-template>
                {{ 'SKILL_UP.TODAY' | translate }}
              </ng-template>

              <ng-template [ngIf]="item?.dateTo">
                <ng-template [ngIf]="item?.dateFrom"> -</ng-template>
                <ng-container>
                  {{item?.dateTo |  date:'dd/MM/yyyy'}}
                </ng-container>
              </ng-template>
            </div>
            <div class="description-wrapper">
                <div [innerHTML]="item?.description[lang]" class="badge-wrap flex-row align-items-center"></div>
            </div>
            <div class="short-details">
                <div class="row mb-3">
                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="info-label">
                            <div class="info-label-text">{{ 'SKILL_UP.ART' | translate }}</div>
                        </div>
                        <div class="info-text"> {{item?.skillupType || '-' | translate }}
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="info-label">
                            <div class="info-label-text">{{ 'SKILL_UP.LINK' | translate }}</div>
                        </div>
                        <div class="info-text">{{item?.link || '-'  }}</div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                      <div class="info-label">
                        <div class="info-label-text">{{ 'SKILL_UP.CERTIFICATE' | translate }}</div>
                      </div>
                      <div  class="info-text">
                        <a *ngIf="item?.fileName" href="#"
                          (click)="openFileModal($event, item?.fileName)">{{ 'SKILL_UP.SHOW_CERTIFICATE' | translate }}</a>
                      </div>


                    </div>
                </div>
            </div>
            <div class="skill-bio-wrapper skill-badge-theme2" *ngIf="item?.skills?.length > 0">
                <div class="info-label">
                    <div class="info-label-text">{{ 'SKILL_UP.SKILLS' | translate }}</div>
                </div>
                <div class="badge-wrap flex-row">
                    <app-gravity-badge *ngFor="let badge of item?.skills | orderBy: ['mainSkill']"
                        [badgeStyleSelected]="'accent ' + gravityTheme.contrast" [badgeStyle]="'dark-grey'"
                        [badge]="badge" [isFeatured]="badge.mainSkill" [isPresentMode]="true" [locale]="lang" [showSkills]="true">
                    </app-gravity-badge>
                </div>
            </div>
        </div>
    </div>
  </ng-container>

</div>
</div>
</ng-container>

<div *ngIf="(!webCV.skillUps || !webCV.skillUps.length)" class="text-center">
  <h3>{{'SKILL_UP.EMPTY_SKILL_UPS' | translate}}</h3>
</div>

<ng-template #backupText>
  <span class="button-link__text"
    [ngClass]="{'button-link__text--light': gravityTheme.contrast === 'light'}">
    {{ 'SKILL_UP.OPEN_LINK' | translate }}
  </span>
</ng-template>

<ng-template #backupText>
  <span class="button-link__text"
    [ngClass]="{'button-link__text--light': gravityTheme.contrast === 'light'}">
    {{ 'SKILL_UP.OPEN_LINK' | translate }}
  </span>
</ng-template>
