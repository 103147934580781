import { Pipe, PipeTransform } from '@angular/core';
import { CvLanguageValue } from '@app/models';

@Pipe({
  name: 'languageLevel'
})
export class LanguageLevelPipe implements PipeTransform {
  CvLanguageValue = CvLanguageValue;

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case CvLanguageValue.A1:
        return 0.5;
      case CvLanguageValue.A2:
        return 1;
      case CvLanguageValue.B1:
        return 1.5;
      case CvLanguageValue.B2:
        return 2;
      case CvLanguageValue.C1:
        return 2.5;
      case CvLanguageValue.C2:
        return 3;
      case CvLanguageValue.BASIC_KNOWLEDGE:
        return 3.5;
      case CvLanguageValue.GOOD_KNOWLEDGE:
        return 4;
      case CvLanguageValue.FLUENT:
        return 4.5;
      case CvLanguageValue.MOTHER_TONGUE:
        return 5;
    }
  }

}
