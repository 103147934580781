import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class SocialMediaItem {
  network: string;
  link: string;
}

export class SocialMedia {
  social_media: SocialMediaItem[];
}

export class UserSocialMedia {
  linkedin: string;
  twitter: string;
  facebook: string;
  github: string;
  behance: string;
  xing: string;
  stackoverflow: string;
  instagram: string;
  dribble: string;

  constructor(linkedin?: string,
              twitter?: string,
              facebook?: string,
              github?: string,
              behance?: string,
              xing?: string,
              stackoverflow?: string,
              instagram?: string,
              dribble?: string) {
    this.linkedin = linkedin || '';
    this.twitter = twitter || '';
    this.facebook = facebook || '';
    this.github = github || '';
    this.behance = behance || '';
    this.xing = xing || '';
    this.stackoverflow = stackoverflow || '';
    this.instagram = instagram || '';
    this.dribble = dribble || '';
  }
}

export class UserSocialMediaAdapter implements ModelAdapter {

  fromJson(json: any): SocialMedia {
    const obj = new UserSocialMedia();

    obj.linkedin = json.linkedin;
    obj.twitter = json.twitter;
    obj.facebook = json.facebook;
    obj.github = json.github;
    obj.behance = json.behance;
    obj.xing = json.xing;
    obj.stackoverflow = json.stackoverflow;
    obj.instagram = json.instagram;
    obj.dribble = json.dribble;

    return {
      social_media: Object.keys(obj).map((key) => {
        if (obj[key] !== '') {
          return {network: key, link: obj[key]};
        }
      }).filter(item => item !== undefined)
    };
  }

  toJson(data: SocialMedia | any): any {

    const json = new UserSocialMedia();

    data.social_media.map((key) => {
      json[key.network] = key.link;
    });

    return json;
  }
}
