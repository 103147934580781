<section class="new-group-details">
  <div class="group-container">
    <div class="group-content-wrapper">
      <div class="header">
        {{teamGroup?.companyName}} - {{teamGroup?.name}}
      </div>
      <div class="body-content-wrapper">
        <div class="row">
          <div class="col-md-3" style="z-index: 0;">
            <div class="side-bar-wrapper bg-white">
              <div class="company-part-1">
                <div class="company-name">
                  <div *ngIf="teamGroup?.logo" class="company-img-frame">
                    <img src="{{teamGroup?.resizedLogo || teamGroup?.logo}}" alt="{{teamGroup?.companyName}}">
                  </div>
                  <div *ngIf="!teamGroup?.logo" class="company-img-frame">
                    <app-avatar class="cv-logo" *ngIf="!teamGroup?.logo" [avatar]="teamGroup?.logo"
                      [name]="teamGroup?.companyName">
                    </app-avatar>
                  </div>
                  <span>{{teamGroup?.companyName}}</span>
                </div>
                <div class="company-info" [innerHtml]="teamGroup?.bio || '&nbsp;'">
                </div>
                <button type="button" (click)="openContactDialog()" class="theme-btn">
                  {{'TEAMGROUP.CONTACT'| translate}}</button>
              </div>
              <hr>
            </div>
          </div>
          <div class="col-md-9" style="position: relative; z-index: 2;">
            <div class="tabbing-content">
              <mat-tab-group (selectedTabChange)="onTabChanged($event);">
                <mat-tab label="{{'TEAMGROUP.CANDIDATES'| translate}}">
                  <div class="candidate-list-wrapper">
                    <div class="row">
                      <div class="col-xl-4 col-xxl-3 mb-30" *ngFor="let member of initialMembers">
                        <div class="candidate-cv-profile">
                          <div class="card-content-wrapper">
                            <div [ngClass]="{'green-icon':member.availabilityColor == 'bg-success',
                              'red-icon':member.availabilityColor == 'bg-danger',
                              'yellow-icon':member.availabilityColor == 'bg-warning'}">
                              <img [src]="this.imageChage(member.availabilityColor)" alt="">
                              <ng-container *ngIf="member.availabilityDate">
                                <div [ngClass]="{'green-icon-hover':member.availabilityColor == 'bg-success',
                                            'red-icon-hover':member.availabilityColor == 'bg-danger',
                                            'yellow-icon-hover':member.availabilityColor == 'bg-warning'}">
                                  <span>{{'TEAM.MAIN.CANDIDATE.AVAILABLE_FROM'| translate}}</span>
                                  <h4>{{member.availabilityDate | date:'dd.MM.yyyy'}}</h4>
                                </div>
                              </ng-container>
                            </div>
                            <div class="main-card-body">
                              <div class="candidate-pro-img">
                                <app-avatar class="cv-logo" [avatar]="member.avatar"
                                  [name]="member.firstName + '' + member.lastName" [size]="100"></app-avatar>
                              </div>
                              <h5>{{member.firstName}} {{member.lastName}} </h5>
                              <p>{{member.profession || '&nbsp;'}}</p>
                            </div>
                          </div>
                          <div class="main-card-footer">
                            <span class="cursor-pointer"
                              (click)="viewWebCV(member.username, member.memberId, member.webCvId)">{{'TEAM.MAIN.CANDIDATE.VIEW_CV'|
                              translate}}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="text-center" *ngIf="initialMembers?.length != teamGroup?.members.length">
                    <button class="theme-btn" (click)="showMore()">{{'TEAMGROUP.LOAD_MORE'| translate}}</button>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <ng-container *ngIf="skillmatrix">
            <div class="col-md-12 mt-30" *ngIf="members.length == 0">
              <div class="selected-candidate-details">
                <div class="no-candi-select">
                  <div class="candi-icon-img">
                    <img src="assets/images/My-data-hover.svg" alt="">
                  </div>
                  <h4>{{'TEAMGROUP.NO_SKILL'| translate}}</h4>
                  <p>{{'TEAMGROUP.NO_SKILL_MESSAGE'| translate}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-30" *ngIf="members.length > 0">
              <div class="filter-result-list">
                <div class="result-list-wrapper">
                  <app-skill-matrix-area [locale]="teamGroup?.lang" [filteredSkills]="filteredSkills"
                    [filteredMembers]="members" (viewCV)="viewCv($event)" (deleteMember)="deleteMember($event)">
                  </app-skill-matrix-area>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
