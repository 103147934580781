<div [formlyAttributes]="field" class="custom-wrapper">

  <div class="d-inline-flex w-100 position-relative">
    <input #textInput
           (clickOutside)="onClickOutside()"
           (focus)="onFocus()"
           (keydown)="onKeyDown($event)"
           [attr.aria-label]="to.placeholder"
           [attr.disabled]="disabled ? disabled : null"
           [attr.placeholder]="to.placeholder"
           [attr.tabindex]="disabled ? -1 : to.tabindex ? to.tabindex : 0"
           [class.is-invalid]="textInputControl.invalid && textInputControl.dirty"
           [formControl]="textInputControl"
           autocomplete="off"
           class="form-control d-inline-flex"
           placement="left"
           type="text"
    />
  </div>

  <ng-container *ngIf="textInputControl.invalid && textInputControl.touched">
    <div *ngIf="textInputControl.errors?.skillAlreadyAdded" [style.display]="'block'" class="invalid-feedback">
      {{ 'SKILL.SKILL_ALREADY_EXISTS' | translate:  {value: existedSkill} }}
    </div>
  </ng-container>

  <div class="position-relative">
    <div [@showDropdown]="{ value: showDropdown ? 'visible' : 'hidden', params: {dropDownMaxHeight: maxHeight} }"
         class="dropdown-select hide overflow-visible">
      <ng-container *ngFor="let item of filteredItems">
        <div #dropdownItem (click)="onSkillSelect(item)" (keydown)="onItemKeydown($event, item)" class="dropdown-item d-flex cursor-pointer" tabindex="1">
          <span class="ms-3 me-2">{{ item.nameLocalizations[to.locale()] | translate}}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="projectModel" class="pt-3">
    <div class="drag-container">
      <app-gravity-badge
        (deleteItem)="delete(badge)"
        (selectItem)="select(badge)"
        *ngFor="let badge of projectModel[key]"
        [attr.data-id]="badge?.id || badge.nameLocalizations[this.to.locale()]"
        [badge]="badge"
        [isDeletable]="to.isDeletable || false"
        [isFeatured]="badge[to.isFeatured]"
        [isSelectable]="to.isSelectable || false"
        [isStarsEditable]="to.isStarsEditable || false"
        [isSelected]="badge[to.isSelected]"
        [locale]="to.locale()"
      ></app-gravity-badge>
    </div>
  </div>
</div>
