import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {Contact, ContactAdapter} from '../profile/contact';
import {WebCv} from './web-cv';

export enum SendOutStatus {
  REJECTED = 'REJECTED',
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  PENDING = 'PENDING',
}

export class SendOut {

  static LIST_NAME = 'content';

  id: number;
  name: string;
  note: string;
  formLetter: any;
  pdfDownload: boolean;
  wordDownload: boolean;
  isDeleted = false;
  validUntil: Date | string;
  created: Date | string;
  updated: Date | string;
  webCv: WebCv;
  webCvId?: number;
  contacts: Contact[];
  status: SendOutStatus;
  statusValue?: string;
}

export class SendOutAdapter implements ResourceAdapter {
  fromJson(json: any): SendOut {

    const obj = new SendOut();

    obj.id = json.id;
    obj.name = json.name;
    obj.note = json.note;
    obj.formLetter = json.formLetter;
    obj.pdfDownload = json.pdfDownload;
    obj.wordDownload = json.wordDownload;
    obj.isDeleted = json.isDeleted;
    obj.validUntil = json.validUntil;
    obj.created = json.created;
    obj.updated = json.updated;
    obj.webCv = json.webCv;
    obj.contacts = new ContactAdapter().fromJsonArray(json.contacts);
    obj.status = json.status;
    obj.statusValue = SendOutStatus[json.status];

    return obj;
  }

  toJson(resource: SendOut): any {

    resource.webCvId = resource.webCv.id;

    return resource;
  }
}
