import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {Contact, ContactAdapter} from '@models/profile/contact';

export class ContactGroup {

  static LIST_NAME = '';

  id: number;
  userId: number;
  name: string;
  contacts?: Contact[];
}

export class ContactGroupAdapter implements ResourceAdapter {
  fromJson(json: any): ContactGroup {
    const contactGroup = new ContactGroup();

    contactGroup.id = json.id;
    contactGroup.name = json.name;

    if (json.contacts) {
      contactGroup.contacts = new ContactAdapter().fromJsonArray(json.contacts);
    }

    return contactGroup;
  }

  toJson(resource: ContactGroup): any {
    resource.contacts = new ContactAdapter().toJsonArray(resource.contacts);
    return resource;
  }

  fromJsonArray(data: any): ContactGroup[] | any {
    if (!data) {
      return [new ContactGroup()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
