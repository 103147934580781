import {ResourceAdapter} from '@api/interfaces/resourceAdapter';

export enum RateCurrency {
  DOLLAR = 'USD',
  EURO = 'EUR'
}

export enum RateVariant {
  NONE = 'NONE',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export class Rate {

  static LIST_NAME = '';

  id: number;
  userId: number;
  rate: string;
  currency: RateCurrency;
  currencyValue?: string;
  type: RateVariant;
  typeValue?: string;
  info: string;
  created: Date | string;
  updated: Date | string;

  isCollapsed = true;
}

export class RateAdapter implements ResourceAdapter {
  fromJson(json: any): Rate {
    const obj = new Rate();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.rate = json.rate;
    obj.currency = json.currency;
    obj.currencyValue = RateCurrency[json.currency];
    obj.type = json.type;
    obj.typeValue = RateVariant[json.type];
    obj.info = json.info;
    obj.created = json.created;
    obj.updated = json.updated;

    return obj;
  }

  toJson(resource: Rate): any {
    return resource;
  }
}
