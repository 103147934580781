import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {TeamService} from '@app/services/team.service';
import {ThemeService} from '@app/services/theme.service';
import {GravityTheme, PinLoginRequest, ScenarioType, TeamSendout} from '@app/models';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {UiService} from '@app/services/ui.service';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';
import {SelectCVEvent} from '@helpers/events/SelectCVEvent';
import {appVariables} from '@app/app.config';

@Component({
  selector: 'app-team-sendout',
  templateUrl: './team-sendout.component.html',
  styleUrls: ['./team-sendout.component.scss']
})
export class TeamSendoutComponent implements OnInit {

  @Input() team: TeamSendout;
  teamDomain: string;
  gravityTheme: GravityTheme;
  hash: string;
  private scenario: ScenarioType;

  constructor(protected ts: TranslateService,
              private ui: UiService,
              private themeService: ThemeService,
              private teamService: TeamService,
              private viewerService: ViewerAuthService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService) {
    this.scenario = sessionStorage.getItem('scenario') as ScenarioType;
  }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.teamDomain = this.route.snapshot.paramMap.get('teamDomain');

    try {
      this.team = JSON.parse(this.route.snapshot.paramMap.get('team'));
    } catch (e) {
      console.log(e);
    }

    switch (this.scenario) {
      case 'teamCustomDomain':
        this.teamDomain = sessionStorage.getItem('teamDomain');
        break;
      case 'teamSubdomain':
        const url = new URL(window.location.href);
        const regexPattern = /^([a-zA-Z0-9\-]+)\.(.+)\.(.+)/g;
        const match = regexPattern.exec(url.host);
        this.teamDomain = match[1];
        break;
    }

    if (!!this.teamDomain) {
      this.checkSendout();
    } else {
      this.routeNotFoundPage();
    }

  }

  viewCv($event: SelectCVEvent) {
    let url;
    if (this.team && this.team.group) {
      url = this.localizeService.translateRoute(`/s/${this.hash}/cv/${$event.memberId}`);
    } else {
      url = this.localizeService.translateRoute(`/s/${this.hash}/cv/${$event.cvId}`);
    }

    this.router.navigate([url], { state: { companyName: this.team.companyName }});
  }

  checkSendout() {
    this.teamService.checkSendoutPage(this.teamDomain, this.hash).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.ui.isProcessing = false;

        // todo implement valid until for sendout or check if this is handled through error responses

        // e.g. if component is loaded after successful login
        if (!!this.team) {
          return;
        }
        if (!response.pinProtected) {
          this.resolveSendoutPage();
        } else {
          if (this.viewerService.isViewerLoggedInForSendout(this.hash)) {
            this.resolveSendoutPageWithCredentialsFromStorage();
          } else {
            this.routePinProtectionSendoutPage();
          }
        }
      } else {
        this.routeNotFoundPage();
      }
    });
  }

  resolveSendoutPage() {
    this.teamService.getSendoutPage(this.teamDomain, this.hash).subscribe(response => {
      if (response) {
        this.team = response;
      }
    });
  }

  resolveSendoutPageWithCredentialsFromStorage() {
    const pinLoginRequest = new PinLoginRequest();
    pinLoginRequest.email = this.viewerService.getSendoutAccessEmail();
    pinLoginRequest.pin = this.viewerService.getSendoutAccessPin();

    this.teamService.loginSendoutPage(this.teamDomain, this.hash, pinLoginRequest).subscribe(response => {
      if (response) {
        this.team = response;
      }
    });
  }

  routePinProtectionSendoutPage() {
    this.router.navigate(
      [this.localizeService.translateRoute(`/s/login/${this.hash}`)],
      {skipLocationChange: true, queryParamsHandling: 'preserve'}
    )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  routeNotFoundPage() {
    this.router
      .navigate(
        [this.localizeService.translateRoute('/404')],
        {skipLocationChange: true}
      )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

}
