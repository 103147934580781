import {ResourceAdapter} from '@api/interfaces/resourceAdapter';

export class Company {

  static LIST_NAME = '';

  id: number;
  name: string;
  industrialSector: string;
  city: string;
}

export class CompanyAdapter implements ResourceAdapter {
  fromJson(json: any): Company {
    const company = new Company();

    company.id = json.id;
    company.name = json.name;
    company.industrialSector = json.industrialSector;
    company.city = json.city;

    return company;
  }

  toJson(resource: Company): any {
    return resource;
  }

  fromJsonArray(data: any): Company[] | any {
    if (!data) {
      return [new Company()];
    }
    return data.map(item => this.fromJson(item));
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }

}
