<div class="row candidate-area">
  <div *ngFor="let member of members" class="col-lg-3 col-sm-6 mb-4">
    <div class="card no-shadow pt-4 px-3 pb-3">
      <ng-container *ngIf="member.availabilityDate;">
        <div [ngClass]="member.availabilityColor"
             [ngbPopover]="popContent"
             [popoverTitle]="popTitle"
             triggers="mouseenter:mouseleave"
             placement="left"
             class="indicator-mark ms-3"
             popoverClass="popover-{{member.availabilityColor | slice:3}}">
          <i class="gvcv-icon {{member.availabilityIcon}} text-white"></i>
        </div>
      </ng-container>
      <app-avatar
        [avatar]="member.avatar"
        [name]="member.shortName || 'N. N.'"
        [size]="120"
        class="d-flex align-self-center"
        (click)="viewCv(member.username, member.memberId, member.webCvId)">
      </app-avatar>
      <div class="member-name mt-3">{{member.fullName || 'N.N.'}}</div>
      <div class="member-position">{{member.profession || '&nbsp;'}}</div>
      <button (click)="viewCv(member.username, member.memberId, member.webCvId)" class="btn btn-primary align-self-center mt-4">
        {{'TEAM.MAIN.CANDIDATE.VIEW_CV'| translate}}
      </button>
    </div>

    <ng-template #popContent>
      <div class="popover-date">{{member.availabilityDate  | date:'dd.MM.yyyy'}}</div>
    </ng-template>
    <ng-template #popTitle>
      <div class="popover-title">{{'TEAM.MAIN.CANDIDATE.AVAILABLE_FROM'| translate}}</div>
    </ng-template>
  </div>
</div>
