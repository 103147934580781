import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class UserContact {
  street: string;
  city: string;
  country: string;
  zip: string;
  publicEmail: string;
  mobilePhone: string;
  phone: string;
  website: string;
}

export class UserContactAdapter implements ModelAdapter {

  fromJson(json: any): UserContact {
    const obj = new UserContact();

    obj.street = json.street;
    obj.city = json.city;
    obj.country = json.country;
    obj.zip = json.zip;
    obj.publicEmail = json.publicEmail;
    obj.mobilePhone = json.mobilePhone;
    obj.phone = json.phone;
    obj.website = json.website;

    return obj;
  }

  toJson(data: UserContact): any {
    return data;
  }
}
