import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-custom-radio',
  template: `
    <div [ngClass]="{'three-per-row': to.customWidth === 'three-per-row'}">
      <div *ngFor="let option of to.options | formlyOptions:field | async; let i = index;"
           class="form-check custom-control custom-radio"
           [ngClass]="{'form-check-inline': to.formCheck === 'inline'}">
        <input type="radio"
               [id]="id + '_' + i"
               [class.form-check-input]="'custom'"
               [class.custom-control-input]="'custom'"
               [name]="id"
               [class.is-invalid]="showError"
               [attr.value]="option.value"
               [value]="option.value"
               [formControl]="formControl"
               [formlyAttributes]="field">
        <label class="{{to.inputLabelStyle}}"
               [class.form-check-label]="'custom'"
               [class.custom-control-label]="'custom'"
               [for]="id + '_' + i"
               [innerHTML]="option.label">
        </label>
      </div>
    </div>
  `,
  styleUrls: ['./custom-radio.component.scss']
})
export class CustomRadioComponent extends FieldType {

}
