
<!--====================== New Design code start here ======================-->

<div class="theme-two-cv-wrapper">
    <div class="theme-cv-wrapper">
        <div class="language-switcher" *ngIf="langSwitcher">
            <app-lang-switcher
              (langChanged)="langSwitch($event)"
              [selectedLanguage]="lang">
            </app-lang-switcher>
        </div>
        <div class="main-container">
            <div class="profile-info">
                <div class="profile-data-wrapper ">
                    <div class="avatar-profile">
                        <app-avatar [avatar]="webCV.profile?.images?.avatar" [bgColor]="gravityTheme?.accent" [round]="false"
                          [fgColor]="gravityTheme?.contrast" [name]="webCV.profile?.personal?.shortName || 'N. N.'" [size]="160">
                        </app-avatar>
                    </div>
                    <div class="name-details">
                        <h2>{{personal?.fullName}}</h2>
                        <h4>{{webCV.profile?.general?.profession[lang]}}</h4>
                    </div>

                    <!-- <div class="contact-social" *ngIf="!socialLinkNone">
                        <h4>Social Media</h4>
                        <ul class="d-flex flex-wrap">
                            <li *ngFor="let link of social">
                                <a  [href]="link.value" class="cv-social-link icon-{{link.label == 'linkedin' ? 'linked-in' : link.label }}"  target="_blank">
                                </a>
                            </li>
                        </ul>
                    </div> -->

                </div>
                <div class="rate-card-wrapper mb-30">
                    <h3 class="design-border">{{ 'WEB_CV.TAB.RATE_CARDS' | translate }}</h3>
                    <div class="rate-cards-content">
                        <app-section-rate [gravityTheme]="gravityTheme" [webCV]="webCV">
                        </app-section-rate>
                    </div>
                </div>
                <div class="main-skills-wrapper mb-30" *ngIf="mainSkills && mainSkills.length > 0">
                    <h3 class="design-border">{{'WEB_CV.LABEL.MAIN_SKILLS' | translate}}</h3>
                    <div class="main-skill d-flex justify-content-between align-items-center" *ngFor=" let badge of mainSkills | orderBy: ['mainSkillOrder']">
                        <b class="me-2">{{badge.nameLocalizations[lang]}}</b>
                        <bar-rating [rate]="badge.stars" [max]="5" [theme]="'movie'" [readOnly]="true">
                        </bar-rating>
                    </div>
                </div>
                <div class="availability-wrapper mb-30" *ngIf="webCV.profile?.availability || webCV.profile?.general?.employmentMode">
                    <h3 class="design-border">{{'WEB_CV.LABEL.AVAILABILITY' | translate}}</h3>
                    <div class="avail-detail d-flex flex-wrap justify-content-between">
                        <span>{{ 'WEB_CV.SORT_BY_DATE' | translate }}: <span>{{webCV.profile?.availability?.date  | date:'dd.MM.yyyy'}}</span></span>
                        <span>
                          <ng-container *ngIf="webCV.profile?.availability?.type === 'PERCENT' else freeText">
                            <span>{{'WEB_CV.LABEL.PERCENT' | translate: { percent: webCV.profile?.availability?.value } }}</span>
                          </ng-container>
                          <ng-template #freeText>
                            Available: <span>{{webCV.profile?.availability?.value}}</span>
                          </ng-template>
                        </span>
                    </div>
                </div>
                <div class="mode-of-employment address-wrapper mb-30" *ngIf="webCV.profile?.general?.employmentMode">
                    <h3 class="design-border">{{'WEB_CV.LABEL.EMPLOYMENT' | translate}}</h3>
                    <span style="text-transform: capitalize;">{{ 'WEB_CV.EMPLOYED' | translate }}</span>
                </div>
                <div class="address-wrapper mb-30" *ngIf="contactInfo.street || contactInfo.zip || contactInfo.city[lang]">
                    <h3 class="design-border">{{'WEB_CV.LABEL.ADDRESS' | translate}}</h3>
                    <span>{{contactInfo.street}} {{contactInfo.zip}} {{contactInfo.city[lang]}}</span>
                </div>
                <div class="work-location-wrapper mb-30" *ngIf="location.location || location.europe || location.germany || location.radius || location.radius">
                    <h3 class="design-border">{{'WEB_CV.LABEL.LOCATION' | translate}}</h3>
                    <span>
                        <span>
                          {{location.location}}
                        </span><br>
                        <span *ngIf="location.radius">{{'WEB_CV.LOCATION.RADIUS' | translate : {radius: webCV.profile?.location?.radius} }}</span><br>
                        <span *ngIf="location.europe">{{'WEB_CV.LOCATION.EUROPE' | translate}}</span><br>
                        <span *ngIf="location.germany">{{'WEB_CV.LOCATION.GERMANY' | translate}}</span><br>
                        <span *ngIf="location.remote">{{'WEB_CV.LOCATION.REMOTE' | translate}}</span><br>
                    </span>
                </div>
                <div class="social-wrapper mb-30" *ngIf="!socialLinkNone">
                    <h3 class="design-border">{{'WEB_CV.LABEL.SOCIAL_MEDIA' | translate}}</h3>
                    <ul class="d-flex flex-wrap">
                        <li *ngFor="let link of social">
                            <a  [href]="link.value" class="cv-social-link icon-{{link.label == 'linkedin' ? 'linked-in' : link.label }}"  target="_blank">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="profile-box">
                <div class="profile-box-wrapper" *ngIf="showAll">
                    <div class="normal-content white-box ps-50 mb-30">
                        <div class="title-content">
                            <h3 class="design-border">{{ 'WEB_CV.TAB.PROFILE' | translate }}</h3>
                        </div>
                        <div class="normal-content-wrapper">
                            <div class="read-more-content">
                                <div class="tab-content">
                                <app-section-bio [webCV]="webCV"></app-section-bio>
                                    <!-- <div [innerHTML]="webCV?.profile?.bio[lang]" class="cv-bio"></div> -->
                                </div>
                            </div>
                        </div>
                        <div class="others-details">
                            <div class="contact-wrapper common-bio-box">
                                <h5>{{'WEB_CV.LABEL.CONTACT' | translate}}</h5>
                                <div class="content bg-white">
                                    <p><b>Email Address: <a class="mail-content" href="mailto:{{webCV.profile?.contactInfo?.publicEmail}}"> {{contactInfo.publicEmail}}</a></b></p>
                                    <p *ngIf="webCV.profile?.contactInfo?.phone && webCV.profile?.contactInfo?.phone !== null"><b>Phone Number: <span> {{webCV.profile?.contactInfo?.phone}}</span></b></p>
                                </div>
                            </div>
                            <div class="lang-wrapper common-bio-box">
                                <h5>{{'WEB_CV.LABEL.LANGUAGES' | translate}}</h5>
                                <ul class="content bg-white">
                                    <li *ngFor="let lang of languages">
                                        <p><b class="me-2">{{lang.label}}</b></p> <span>
                                            <ng-container [ngSwitch]="lang?.value">
                                                <b  *ngSwitchCase="cvLanguageValueEnum.A1">{{'WEB_CV.LANGUAGES.VALUES.A1' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.A2">{{'WEB_CV.LANGUAGES.VALUES.A2' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.B1">{{'WEB_CV.LANGUAGES.VALUES.B1' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.B2">{{'WEB_CV.LANGUAGES.VALUES.B2' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.C1">{{'WEB_CV.LANGUAGES.VALUES.C1' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.C2">{{'WEB_CV.LANGUAGES.VALUES.C2' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.BASIC_KNOWLEDGE">{{'WEB_CV.LANGUAGES.VALUES.BASIC_KNOWLEDGE' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.GOOD_KNOWLEDGE">{{'WEB_CV.LANGUAGES.VALUES.GOOD_KNOWLEDGE' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.FLUENT">{{'WEB_CV.LANGUAGES.VALUES.FLUENT' | translate}}</b>
                                                <b  *ngSwitchCase="cvLanguageValueEnum.MOTHER_TONGUE">{{'WEB_CV.LANGUAGES.VALUES.MOTHER_TONGUE' | translate}}</b>
                                                <b  *ngSwitchDefault></b>
                                                </ng-container>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="download-file-wrapper common-bio-box">
                                <h5>{{'WEB_CV.LABEL.DOWNLOADS' | translate}}</h5>
                                <div class="downloads position-relative">
                                    <div (click)="downloadCV('pdf')" *ngIf="webCV.controls?.downloadPdf"
                                    class=" d-flex align-items-center  mb-3 cursor-pointer">
                                        <b class="me-2">{{getCleanFileName()}}.pdf</b>
                                        <!-- fill="#6F4DB9" -->
                                        <!-- <img src="assets/images/file-pdf.svg" alt="" class="download-btn ms-2"  style="fill:#6F4DB9;"> -->
                                        <!-- <svg class="ms-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.5207 11.6315L12.6875 14.4654V7.1875C12.6875 6.808 12.3795 6.5 12 6.5C11.6205 6.5 11.3125 6.808 11.3125 7.1875V14.4654L8.47932 11.6322C8.2105 11.3634 7.77532 11.3634 7.50718 11.6322C7.23837 11.901 7.23837 12.3362 7.50718 12.6043L11.3964 16.4935C11.5614 16.6585 11.7869 16.7093 12.0007 16.6715C12.2139 16.7093 12.44 16.6578 12.605 16.4935L16.4942 12.6043C16.763 12.3355 16.763 11.9003 16.4942 11.6322C16.2247 11.3634 15.7895 11.3634 15.5207 11.6315ZM12 1C5.92527 1 1 5.92455 1 12C1 18.0755 5.92527 23 12 23C18.0747 23 23 18.0747 23 12C23 5.92527 18.0755 1 12 1ZM12 21.625C6.68423 21.625 2.375 17.3158 2.375 12C2.375 6.68423 6.68423 2.375 12 2.375C17.3158 2.375 21.625 6.68423 21.625 12C21.625 17.3158 17.3158 21.625 12 21.625Z" fill="#6F4DB9" stroke="#6F4DB9" stroke-width="0.5"/>
                                        </svg> -->
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                            <title>file-pdf</title>
                                            <path fill="#6F4DB9" d="M26.313 18.421c-0.427-0.42-1.372-0.643-2.812-0.662-0.974-0.011-2.147 0.075-3.38 0.248-0.552-0.319-1.122-0.665-1.568-1.083-1.202-1.122-2.205-2.68-2.831-4.394 0.041-0.16 0.075-0.301 0.108-0.444 0 0 0.677-3.846 0.498-5.146-0.025-0.178-0.040-0.23-0.088-0.369l-0.059-0.151c-0.184-0.425-0.545-0.875-1.111-0.85l-0.341-0.011c-0.631 0-1.146 0.323-1.281 0.805-0.411 1.514 0.013 3.778 0.781 6.711l-0.197 0.478c-0.55 1.34-1.238 2.689-1.846 3.88l-0.079 0.155c-0.639 1.251-1.22 2.313-1.745 3.213l-0.543 0.287c-0.040 0.021-0.97 0.513-1.188 0.645-1.852 1.106-3.079 2.361-3.282 3.357-0.065 0.318-0.017 0.725 0.313 0.913l0.525 0.264c0.228 0.114 0.468 0.172 0.714 0.172 1.319 0 2.85-1.643 4.959-5.324 2.435-0.793 5.208-1.452 7.638-1.815 1.852 1.043 4.129 1.767 5.567 1.767 0.255 0 0.475-0.024 0.654-0.072 0.276-0.073 0.508-0.23 0.65-0.444 0.279-0.42 0.335-0.998 0.26-1.59-0.023-0.176-0.163-0.393-0.315-0.541zM6.614 25.439c0.241-0.658 1.192-1.958 2.6-3.111 0.088-0.072 0.306-0.276 0.506-0.466-1.472 2.348-2.458 3.283-3.106 3.577zM14.951 6.24c0.424 0 0.665 1.069 0.685 2.070s-0.214 1.705-0.505 2.225c-0.241-0.77-0.357-1.984-0.357-2.778 0 0-0.018-1.517 0.177-1.517v0zM12.464 19.922c0.295-0.529 0.603-1.086 0.917-1.677 0.765-1.447 1.249-2.58 1.609-3.511 0.716 1.303 1.608 2.41 2.656 3.297 0.131 0.111 0.269 0.222 0.415 0.333-2.132 0.422-3.974 0.935-5.596 1.558v0zM25.903 19.802c-0.13 0.081-0.502 0.128-0.741 0.128-0.772 0-1.727-0.353-3.066-0.927 0.515-0.038 0.986-0.057 1.409-0.057 0.774 0 1.004-0.003 1.761 0.19s0.767 0.585 0.637 0.667v0z"></path>
                                            <path fill="#6F4DB9" d="M28.681 7.159c-0.694-0.947-1.662-2.053-2.724-3.116s-2.169-2.030-3.116-2.724c-1.612-1.182-2.393-1.319-2.841-1.319h-15.5c-1.378 0-2.5 1.121-2.5 2.5v27c0 1.378 1.121 2.5 2.5 2.5h23c1.378 0 2.5-1.122 2.5-2.5v-19.5c0-0.448-0.137-1.23-1.319-2.841v0zM24.543 5.457c0.959 0.959 1.712 1.825 2.268 2.543h-4.811v-4.811c0.718 0.556 1.584 1.309 2.543 2.268v0zM28 29.5c0 0.271-0.229 0.5-0.5 0.5h-23c-0.271 0-0.5-0.229-0.5-0.5v-27c0-0.271 0.229-0.5 0.5-0.5 0 0 15.499-0 15.5 0v7c0 0.552 0.448 1 1 1h7v19.5z"></path>
                                        </svg>
                                    </div>
                                    <div (click)="downloadCV('doc')" *ngIf="webCV.controls?.downloadWord"
                                    class="d-flex align-items-center  mb-2 cursor-pointer">
                                        <b class="me-2">{{getCleanFileName()}}.doc</b>
                                        <!-- <img src="assets/images/file-word.svg" alt="" class="download-btn ms-2"  style="fill:#6F4DB9;"> -->
                                        <!-- <svg class="ms-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.5207 11.6315L12.6875 14.4654V7.1875C12.6875 6.808 12.3795 6.5 12 6.5C11.6205 6.5 11.3125 6.808 11.3125 7.1875V14.4654L8.47932 11.6322C8.2105 11.3634 7.77532 11.3634 7.50718 11.6322C7.23837 11.901 7.23837 12.3362 7.50718 12.6043L11.3964 16.4935C11.5614 16.6585 11.7869 16.7093 12.0007 16.6715C12.2139 16.7093 12.44 16.6578 12.605 16.4935L16.4942 12.6043C16.763 12.3355 16.763 11.9003 16.4942 11.6322C16.2247 11.3634 15.7895 11.3634 15.5207 11.6315ZM12 1C5.92527 1 1 5.92455 1 12C1 18.0755 5.92527 23 12 23C18.0747 23 23 18.0747 23 12C23 5.92527 18.0755 1 12 1ZM12 21.625C6.68423 21.625 2.375 17.3158 2.375 12C2.375 6.68423 6.68423 2.375 12 2.375C17.3158 2.375 21.625 6.68423 21.625 12C21.625 17.3158 17.3158 21.625 12 21.625Z" fill="#6F4DB9" stroke="#6F4DB9" stroke-width="0.5"/>
                                        </svg> -->
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                            <title>file-word</title>
                                            <path fill="#6F4DB9" d="M19.993 14.872h1.382l-1.594 7.068-2.073-9.938h-3.33l-2.41 9.938-1.807-9.938h-3.481l3.534 15.996h3.401l2.384-9.46 2.133 9.46h3.136l4.051-15.996h-5.326v2.87z"></path>
                                            <path fill="#6F4DB9" d="M28.681 7.159c-0.694-0.947-1.662-2.053-2.724-3.116s-2.169-2.030-3.116-2.724c-1.612-1.182-2.393-1.319-2.841-1.319h-15.5c-1.378 0-2.5 1.121-2.5 2.5v27c0 1.378 1.121 2.5 2.5 2.5h23c1.378 0 2.5-1.122 2.5-2.5v-19.5c0-0.448-0.137-1.23-1.319-2.841v0zM24.543 5.457c0.959 0.959 1.712 1.825 2.268 2.543h-4.811v-4.811c0.718 0.556 1.584 1.309 2.543 2.268v0zM28 29.5c0 0.271-0.229 0.5-0.5 0.5h-23c-0.271 0-0.5-0.229-0.5-0.5v-27c0-0.271 0.229-0.5 0.5-0.5 0 0 15.499-0 15.5 0v7c0 0.552 0.448 1 1 1h7v19.5z"></path>
                                        </svg>
                                    </div>
                                    <div *ngIf="downloadSpinner" class="cv-download-spinner">
                                        <div class="spinner-grow text-secondary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-content white-box ps-50 mb-30">
                        <div class="title-content">
                            <h3 class="design-border">{{ 'WEB_CV.TAB.PROJECTS' | translate }}</h3>
                            <button class="theme-btn" (click)="showAll = false; showAllProject = true; scroll()">{{ 'WEB_CV.VIEW_ALL' | translate }}</button>
                        </div>
                        <div class="project-content-wrapper">
                          <app-section-project [gravityTheme]="gravityTheme" [searchVisible]="false" [webCV]="webCV">
                          </app-section-project>
                        </div>
                    </div>

                    <div class="qualifications-content project-content white-box ps-50 mb-30">
                      <div class="title-content">
                          <h3 class="design-border">{{ 'WEB_CV.TAB.QUALIFICATIONS' | translate }}</h3>
                          <button class="theme-btn" (click)="showAll = false; showAllSkillUp = true; scroll()">{{ 'WEB_CV.VIEW_ALL' | translate }}</button>
                      </div>
                      <div class="project-content-wrapper">
                        <app-section-qualifications [gravityTheme]="gravityTheme" [searchVisible]="false" [webCV]="webCV">
                        </app-section-qualifications>
                      </div>
                    </div>
                    <div class="skills-content white-box ps-50 mb-30">
                        <div class="title-content">
                          <h3 class="design-border">{{ 'WEB_CV.TAB.SKILLS' | translate }}</h3>
                          <button class="theme-btn" (click)="showAll = false; showSkillTab = true; scroll()">{{ 'WEB_CV.VIEW_ALL' | translate }}</button>
                        </div>
                        <div class="skill-content-wrapper side-design">
                          <app-section-skills [gravityTheme]="gravityTheme" [webCV]="webCV" [locale]="lang">
                          </app-section-skills>
                        </div>
                    </div>
                    <div class="certification-content white-box ps-50 mb-30">
                        <div class="title-content">
                            <h3 class="design-border">{{ 'WEB_CV.CERTIFICATION' | translate }}</h3>
                        </div>
                        <ul class="certi-list">
                            <ng-container *ngFor="let certificate of webCV.skillUps">
                                <!-- {{certificate | json}} -->
                                <li *ngIf="(!checkFile(certificate.fileName)) && certificate.badgeName"
                                (click)="openFileModal($event, certificate?.fileName)"><img [src]="certificate.badgeName" alt=""></li>
                                <li *ngIf="checkFile(certificate.fileName)"
                                (click)="openFileModal($event, certificate?.fileName)"><img [src]="certificate.fileName" alt=""></li>
                            </ng-container>
                        </ul>
                    </div>

                </div>

                <div class="profile-box-wrapper">

                    <div class="skills-content"  *ngIf="!showAll && showSkillTab">
                        <div class="skill-content-wrapper">
                            <div class="back-btn" (click)="showAll = true; showSkillTab = false">
                                <img src="assets/images/back-arrow.svg" alt="">
                            </div>
                            <div class="tabbing-content">
                                <app-skill-tabs
                                  [gravityTheme]="gravityTheme"
                                  [webCV]="webCV"
                                  [locale]="lang">
                                </app-skill-tabs>
                            </div>
                        </div>
                    </div>
                    <div class="qualifications-content project-content white-box ps-50 mb-30" *ngIf="!showAll && showAllSkillUp">
                        <div class="project-content-wrapper" [ngClass]="showAll === false? 'mt-100' : '' ">
                            <app-section-qualifications [gravityTheme]="gravityTheme" (qualifications)="hideQualifications($event)" [searchVisible]="true" [webCV]="webCV">
                            </app-section-qualifications>
                        </div>
                    </div>

                    <div class="project-content white-box ps-50 mb-30" *ngIf="!showAll && showAllProject">
                        <div class="project-content-wrapper" [ngClass]="showAll === false? 'mt-100' : '' ">
                            <app-section-project [gravityTheme]="gravityTheme" (projects)="hideProjects($event)" [searchVisible]="true"  [webCV]="webCV">
                            </app-section-project>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
    <div class="defaul-footer">
        <app-web-cv-footer *ngIf="!isTeamMember" class="footer web-cv-footer bg-light"></app-web-cv-footer>
      </div>
</div>
<!--====================== New Design code End here ======================-->




<!-- <iframe src="https://certy.px-lab.com/html/demo1/" [ngStyle]="{height:'100%', width:'100%'}">
</iframe> -->

<!---------scratch-->
<!-- <div>

    <svg id="crt-bg-shape-1" class="hidden-sm hidden-xs" height="519" width="758">
        <polygon class="pol" points="0,455,693,352,173,0,92,0,0,71">
        </polygon>
    </svg>
    <svg id="crt-bg-shape-2" class="hidden-sm hidden-xs" height="536" width="633">
        <polygon points="0,0,633,0,633,536"></polygon>
    </svg>

    <div class="sidenav">
        <span class="avatar">
            <app-avatar [avatar]="webCV.profile?.images?.avatar" [bgColor]="gravityTheme?.accent"
                [fgColor]="gravityTheme?.contrast" [name]="webCV.profile?.personal?.shortName || 'N. N.'" [size]="160">
            </app-avatar>

            <h2 class="text-upper" style="text-align: center;">{{personal.fullName}}</h2>

            <div style="display: flex;">
            <a href="#" class="fa fa-facebook"></a>
            <a href="#" class="fa fa-twitter"></a>
            <a href="#" class="fa fa-google"></a>
            <a href="#" class="fa fa-instagram"></a>
            <a href="#" class="fa fa-pinterest"></a>
        </div>

        </span>
    </div>
    <div class="download" *ngIf="webCV.controls?.downloadPdf || webCV.controls?.downloadWord">
        <a class="btn btn-primary btn-lg btn-default" class="btn btn-default btn-lg btn-thin btn-upper">
            <div (click)="downloadCV('pdf')" *ngIf="webCV.controls?.downloadPdf" class=" text-uppercase d-flex align-items-center mb-2 cursor-pointer">
                <mat-icon class="download-icon">cloud_download</mat-icon>
                <b>{{getCleanFileName()}}.pdf</b>
            </div>
        </a>
    </div>

    <div class="main">
        <mat-card>
            <section class="section brd-btm padd-box" id="home">
                <div class="row">
                    <div class="col-sm-12">
                        <h2 class="title-lg text-upper heading-title">About Me</h2>
                        <div class="text-box">
                            <p><b>Helo, I’m Ola Lowe!</b><br> I am talanted florist and decorator. I have graduated from International and cultural university of design and arts. Since first year in high school I relized that nature is most powerfull and
                                beautiful gift in the world. I was captivated by beauty and simplicity of flowers and trees. Since then I have started to create and mastering unique and nice bouquets. My 1st masterpiece was garden of ant Ula Lowe decorated
                                by me
                            </p>
                        </div>
                    </div>
                    <hr>
                </div>

                <div class="row">
                    <div class="col-sm-9">
                        <div class="crt-share-box clearfix">
                            <button id="btn-share" class="btn btn-share btn-upper">
                                <span>
                                    <mat-icon class="mat-con-share">share</mat-icon>
                                </span>Share
                            </button>
                            <div class="addthis_inline_share_toolbox" data-url="https://certy.px-lab.com/html/demo1/" data-title="Certy" style="clear: both;">
                                <div id="atstbx" class="at-resp-share-element at-style-responsive addthis-smartlayers addthis-animated at4-show" aria-labelledby="at-bc59a41a-cd68-432d-baa9-9eda57582990" role="region">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>

                <br>
                <hr>

                <div class=" justify-content-between align-items-center mb-3 ft-size">
                    <section class="section padd-box">
                        <div class="row">
                            <div class="col-sm-6 clear-mrg">
                                <h2 class="title-thin text-muted">Personal Information</h2>
                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper">Full Name</dt>
                                    </div>
                                    <div class="col-6">
                                        <dd>{{personal.fullName}}</dd>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper">D.O.B.</dt>
                                    </div>
                                    <div class="col-6">
                                        <dd>05 Sep 1986</dd>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper" *ngIf="contactInfo.street || contactInfo.zip || contactInfo.city[lang]">
                                            Address
                                        </dt>
                                    </div>
                                    <div class="col-6">
                                        <dd>{{contactInfo.street}} {{contactInfo.zip}} {{contactInfo.city[lang]}}
                                        </dd>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper" *ngIf="location.location || location.europe || location.germany || location.radius || location.radius">
                                            Location</dt>
                                    </div>
                                    <div class="col-6">
                                        <dd>
                                            <mat-icon>location_on</mat-icon>{{location.location}}
                                        </dd>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper" *ngIf="webCV.controls?.contactform && webCV.profile?.contactInfo?.publicEmail">
                                            E-mail</dt>
                                    </div>
                                    <div class="col-6">
                                        <dd><a href="mailto:{{webCV.profile?.contactInfo?.publicEmail}}">{{'WEB_CV.LABEL.HIRE_ME'
                                                |
                                                translate}}</a></dd>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper" *ngIf="contactInfo.phone || contactInfo.mobilePhone">
                                            Phone</dt>

                                    </div>
                                    <div class="col-6">
                                        <dd>{{webCV.profile?.contactInfo?.phone}}</dd>
                                        <dd>{{webCV.profile?.contactInfo?.mobilePhone}}</dd>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <dt class="text-upper">Freelance</dt>
                                    </div>
                                    <div class="col-6">
                                        <dd>Available</dd>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 clear-mrg" *ngIf="languageExist">
                                <h2 class="title-thin text-muted">Languages</h2>
                                <div *ngFor="let lang of languages" class="progress-bullets crt-animated" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10">
                                    <div class="row">
                                        <div class="col-6">
                                            <b class="me-3">{{lang.label}}</b> {{lang.value}}
                                        </div>
                                        <div class="col-6">
                                            <bar-rating class="star-position" [rate]="lang.value | languageLevel" [max]="5" [theme]="'star-rate'" [readOnly]="true">
                                            </bar-rating>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </mat-card>

        <mat-card *ngIf="mainSkills && mainSkills.length > 0" class="ft-size">
            <div class="row g-0 ">

                <div class="col-sm-6 clear-mrg padding-col-right">
                    <h2 class="title-thin text-muted">{{'WEB_CV.LABEL.MAIN_SKILLS' | translate}}</h2>
                    <div class="main-skill d-flex justify-content-between align-items-center" *ngFor=" let badge of mainSkills | orderBy: ['mainSkillOrder']">
                        <b class="me-2">{{badge.name}}</b>
                        <bar-rating [rate]="5" [max]="5" [theme]="'star-rate'" [readOnly]="true">
                        </bar-rating>
                    </div>
                </div>

                <div class="col-sm-6 clear-mrg padding-col-left">
                    <div *ngIf="!contactInfoNone">
                        <h2 class="title-thin text-muted">{{'WEB_CV.LABEL.CONTACT' | translate}}</h2>

                        <div class="contact-personal">
                            <div class="phone d-flex align-items-center padding-contact" *ngIf="contactInfo.phone || contactInfo.mobilePhone">
                                <mat-icon>phone</mat-icon>
                                <div style="margin-left: 30px;" class="d-flex flex-column justify-content-around align-items-center">
                                    <div class="content"> {{webCV.profile?.contactInfo?.phone}} </div>
                                    <div class="content"> {{webCV.profile?.contactInfo?.mobilePhone}} </div>
                                </div>

                            </div>
                            <div class="email d-flex align-items-center padding-contact" *ngIf="contactInfo.publicEmail">
                                <mat-icon>email</mat-icon>
                                <div style="margin-left: 30px;" class="d-flex flex-column justify-content-around align-items-center">
                                    <div class="content">{{contactInfo.publicEmail}}</div>
                                </div>
                            </div>
                            <div class="website d-flex align-items-center padding-contact" *ngIf="contactInfo.website">
                                <mat-icon>link</mat-icon>
                                <div style="margin-left: 30px;" class="d-flex flex-column justify-content-around align-items-center">

                                    <div class="content cursor-pointer" (click)="gotoPersonalSite(contactInfo.website)">
                                        {{contactInfo.website}}
                                    </div>
                                </div>
                            </div>
                            <div class="address d-flex align-items-center padding-contact" *ngIf="contactInfo.street || contactInfo.zip || contactInfo.city[lang]">
                                <mat-icon>public</mat-icon>
                                <div style="margin-left: 30px;" class="d-flex flex-column justify-content-around align-items-center">
                                    <div class="content">{{contactInfo.street}} {{contactInfo.zip}} {{contactInfo.city[lang]}}
                                    </div>
                                </div>
                            </div>
                            <div class="location d-flex align-items-start padding-contact" *ngIf="location.location || location.europe || location.germany || location.radius || location.radius">
                                <mat-icon>location_on</mat-icon>
                                <div style="margin-left: 30px;" class="d-flex flex-column justify-content-around ">
                                    <div class="content">{{location.location}}</div>
                                    <div class="content" *ngIf="location.radius">
                                        {{'WEB_CV.LOCATION.RADIUS' | translate : {radius: webCV.profile?.location?.radius} }}
                                    </div>
                                    <div class="content" *ngIf="location.europe">{{'WEB_CV.LOCATION.EUROPE' | translate}}
                                    </div>
                                    <div class="content" *ngIf="location.germany">{{'WEB_CV.LOCATION.GERMANY' | translate}}
                                    </div>
                                    <div class="content" *ngIf="location.remote">{{'WEB_CV.LOCATION.REMOTE' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>

        <mat-card class="d-flex flex-column mt" *ngFor="let section of webCV?.order?.active; trackBy: trackByOrder">
            <div class="project d-flex flex-column" *ngIf="section.value == 'project'" id="projects">
                <div class="tab-title">
                    <mat-panel-title>
                        <b>
                            {{ 'WEB_CV.TAB.PROJECTS' | translate }}
                        </b>
                    </mat-panel-title>
                </div>
                <div class="tab-content">
                    <app-section-project [gravityTheme]="gravityTheme" [webCV]="webCV">
                    </app-section-project>
                </div>
            </div>
            <div class="skills" *ngIf="section.value == 'skills'" id="skills">
                <div class="tab-title" style="margin: 15px;">
                    <mat-panel-title><b>{{ 'WEB_CV.TAB.SKILLS' | translate }}</b> </mat-panel-title>
                </div>
                <div class="tab-content">
                    <app-section-skills [gravityTheme]="gravityTheme" [webCV]="webCV" [locale]="lang">
                    </app-section-skills>
                </div>
            </div>
            <div class="qualification" *ngIf="section.value == 'qualification'" id="qualifications">
                <div class="tab-title">
                    <mat-panel-title><b>
                            {{ 'WEB_CV.TAB.QUALIFICATIONS' | translate }}</b></mat-panel-title>
                </div>
                <div class="tab-content">
                    <app-section-qualifications [gravityTheme]="gravityTheme" [webCV]="webCV">
                    </app-section-qualifications>
                </div>
            </div>
            <div class="rate" *ngIf="section.value == 'rate' && webCV?.profile?.rates" id="rates">
                <div class="tab-title">
                    <mat-panel-title><b>
                            {{ 'WEB_CV.TAB.RATE_CARDS' | translate }}
                        </b></mat-panel-title>
                </div>
                <div class="tab-content">
                    <app-section-rate [gravityTheme]="gravityTheme" [webCV]="webCV">
                    </app-section-rate>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="sidenav1">
        <div id="crt-nav-inner" style="top: 25px; width: 56px; bottom: auto; position: fixed;">
            <div class="crt-nav-cont">
                <div id="crt-nav-scroll">
                    <nav id="crt-nav" class="crt-nav">
                        <li class="">
                            <a (click)="getLink('home')" matTooltip="Home">
                                <mat-icon>home</mat-icon>
                            </a>
                        </li>
                        <li class="">
                            <a (click)="getLink('projects')" matTooltip="Experience">
                                <span class=" crt-icon-experience">
                                    <mat-icon>card_travel</mat-icon>
                                </span>
                            </a>
                        </li>
                        <li class="">
                            <a (click)="getLink('skills')" matTooltip="Skills">
                                <mat-icon>work</mat-icon>
                            </a>
                        </li>

                        <li class="">
                            <a (click)="getLink('qualifications')" matTooltip="Qualifications">
                                <mat-icon>assessment</mat-icon>
                            </a>
                        </li>
                        <li class="">
                            <a (click)="getLink('rates')" matTooltip="References">
                                <mat-icon>dns</mat-icon>
                            </a>
                        </li>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="defaul-footer">
    <app-web-cv-footer class="footer web-cv-footer w-100 mt-0" *ngIf="!isTeamMember"></app-web-cv-footer>
  </div> -->
