import {Component, Input, OnInit} from '@angular/core';
import {GravityTheme} from '@models/web-cv/gravity-theme';
import {CvGeneratorService} from '@app/services/cv-generator.service';
import {saveAs} from 'file-saver';
import {SharedCv} from '@models/shared-cv/shared-cv';
import {CvLanguageValue} from '@app/models';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';
import { FileModalComponent } from '@app/components/file-modal/file-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedWebCvService } from '@app/services/shared-web-cv.service';
import { ActivatedRoute } from '@angular/router';
import {_t} from "@helpers/string-helpers";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-section-sidebar',
  templateUrl: './section-sidebar.component.html',
  styleUrls: ['./section-sidebar.component.scss']
})
export class SectionSidebarComponent implements OnInit {

  @Input() usernameOrHash: string;
  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() isSendout = false;

  // only used for teams
  @Input() teamWebCvUniqueHash;
  @Input() teamDomain;
  @Input() sendoutHash;
  @Input() groupUrl;
  @Input() locale: string = '';

  social: any[];
  downloadSpinner = false;
  cvLanguageValueEnum = CvLanguageValue;
  url: any;
  languagesTrans: any[] = []

  constructor(private cvGeneratorService: CvGeneratorService,
              private viewerAuthService: ViewerAuthService,
              private webCvService: SharedWebCvService,
              private activatedRoute: ActivatedRoute,
              private ts: TranslateService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    if ((this.webCV.profile.social)) {
      const socialLinks = Object.keys(this.webCV.profile.social).map(key => ({
        label: key,
        value: this.webCV.profile.social[key]
      }));
      this.social = socialLinks.filter(item => !!item.value);
    }
    this.language();
  }

  language() {
    this.activatedRoute.queryParams.subscribe(res => {
      if(res.token){
        if(this.webCV.profile.languages?.length > 0){
          this.webCV.profile?.languages.forEach(language => {
            this.webCvService.language(res.token, language.label).subscribe(res => {
              if(res && res.length){
                if(this.locale && this.locale == 'en'){
                  language.label = res[0].english;
                }else{
                  language.label = res[0].german;
                }
              }
            });
          });
        }
      }
    })
  }

  getCleanFileName() {
    let fullName = '';
    if (this.webCV.profile.personal) {
      fullName = `${this.webCV.profile.personal.firstName || ''} ${this.webCV.profile.personal.lastName || ''}`;
    }
    let fileName = 'cv';

    if (!!fullName.trim()) {
      fileName = [...fullName.trim().split(' '), fileName].join('-');
    }
    return fileName;
  }

  downloadCV(type: 'pdf' | 'doc') {
    const sharedCV = new SharedCv();
    Object.assign(sharedCV, this.webCV);
    delete sharedCV.controls;

    if(sharedCV.profile?.contactInfo?.country && (!sharedCV.profile?.contactInfo?.country?.en || !sharedCV.profile?.contactInfo?.country?.de)) {
      delete sharedCV.profile.contactInfo.country;
    }

    if(sharedCV.profile?.rates?.length > 0) {
        sharedCV.profile.rates.forEach((rate) => {
          if (rate?.currency) {
            rate.currency = this.ts.instant(_t(rate.currency));
          }
          if (rate?.type) {
            rate.type = this.ts.instant(_t(rate.type));
          }
        });
    }

    sharedCV.projects.forEach(project => {
      project.project.skills = project.skills;
    });
    sharedCV.appLanguage = this.locale;

    this.downloadSpinner = true;

    let req;
    if (this.teamWebCvUniqueHash) {
      req = this.cvGeneratorService.downloadTeamCV(this.teamDomain, sharedCV, type);
    } else {
      req = this.cvGeneratorService.downloadCV(this.usernameOrHash, sharedCV, type);
    }

    req.subscribe(res2 => {
      res2.subscribe(response => {
      if (response) {
        saveAs(response, this.getCleanFileName() + "." + type);

        if (this.teamWebCvUniqueHash) {
          this.trackDownloadForTeamMember(type);
        } else {
          this.trackDownloadForSingleUser(type);
        }
      }
      this.downloadSpinner = false;
    });});
  }

  trackDownloadForTeamMember(type: 'pdf' | 'doc') {
    this.cvGeneratorService.trackCvDownloadForTeamMember(this.teamWebCvUniqueHash, this.teamDomain,
      this.groupUrl, this.sendoutHash, type).subscribe(() => {
    });
  }

  trackDownloadForSingleUser(type: 'pdf' | 'doc') {
    if (this.isSendout) {
      this.cvGeneratorService.trackCvSendoutDownloadForSingleUser(this.usernameOrHash, type).subscribe(() => {
      });
    } else {
      this.cvGeneratorService.trackCvDownloadForSingleUser(this.usernameOrHash, type).subscribe(() => {
      });
    }
  }

  checkFile(file){
    if(file && (file.includes('.jpg') || file.includes('.png'))){
      return true
    }
    else{
      return false
    }
  }

  viewContact(): boolean {
    return !!(this.webCV?.profile?.contactInfo && (this.webCV.profile?.contactInfo?.phone || this.webCV.profile?.contactInfo?.publicEmail));
  }

  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    console.log(fileUrl);
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = fileUrl;
  }
}
