import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class LoginRequest {
  login: string;
  password: string;
  rememberMe: boolean;
  neverLoggedIn: boolean;
}

export class LoginResponse {
  id: number;
  username: string;
  token: string;
  neverLoggedIn: boolean;
}

export class LoginAdapter implements ModelAdapter {

  fromJson(json: any): LoginResponse {

    const login = new LoginResponse();
    login.id = json.id;
    login.username = json.username;
    login.token = json.token;
    login.neverLoggedIn = json.neverLoggedIn;
    return login;
  }

  toJson(loginForm: LoginRequest): any {
    return {
      username: loginForm.login,
      password: loginForm.password,
      rememberMe: loginForm.rememberMe
    };
  }
}
