import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AuthService} from '@app/auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  Reference,
  ReferenceAdapter, ReferenceFeedback, ReferenceFeedbackAdapter,
  ReferenceLoginRequest,
  ReferenceLoginRequestAdapter,
  ReferenceLoginResponse,
  ReferenceLoginResponseAdapter
} from '@app/models';
import {catchError, map, tap} from 'rxjs/operators';
import {ResourceService} from '@api/resource.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService extends ResourceService<Reference> {

  constructor(
    authService: AuthService,
    httpClient: HttpClient,
  ) {
    super(
      authService,
      httpClient,
      environment.apiHost,
      'references',
      new ReferenceAdapter(),
      Reference.LIST_NAME
    );
  }

  pinLogin(hash: string, model: ReferenceLoginRequest): Observable<ReferenceLoginResponse> {
    return this.httpClient
      .post<ReferenceLoginRequest>(
        `${this.url}/${this.endpoint}/login/${hash}`,
        new ReferenceLoginRequestAdapter().toJson(model),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        map(data => new ReferenceLoginResponseAdapter().fromJson(data)),
        tap((data: ReferenceLoginResponse) => console.log(`reference login`, data)),
        catchError(this.handleError<ReferenceLoginResponse>(`reference login`))
      );
  }

  sendReference(hash: string, item: ReferenceFeedback): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}/${this.endpoint}/login/${hash}`,
        new ReferenceFeedbackAdapter().toJson(item),
        {headers: this.getHeaders()},
      )
      .pipe(
        map((response: any) => response),
        tap((data: Reference) => console.log(`send feedback for id=${hash}`)),
        catchError(this.handleError<any>(` ${typeof hash}`))
      );
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
}
