export class SignUpUser {
  username: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
}

export class UserNameModel {
  userName: string;

  constructor(userName: string) {
    this.userName = userName;
  }
}

export class UserEmailModel {
  email: string;

  constructor(email: string) {
    this.email = email;
  }

}
