<nb-layout>
  <nb-layout-header class="gravity-header" fixed *ngIf="!isPreview">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div (click)="goToOverview()" class="d-flex align-items-center go-back cursor-pointer">
        <i class="gvcv-icon icon-arrow-left me-2"></i>
        {{'TEAM.MAIN.BACK'| translate}}
      </div>
      <div class="title mx-auto">
        {{companyName || '&nbsp;'}} <span *ngIf="groupName">- {{groupName}}</span>
      </div>
      <div class="language-switcher ps-4" *ngIf="langSwitcher">
        <app-lang-switcher
          (langChanged)="langSwitch($event)"
          [selectedLanguage]="lang">
        </app-lang-switcher>
      </div>
    </div>
  </nb-layout-header>

  <nb-layout-column *ngIf="webCV" [class.p-0]="gravityTheme.theme !== 'DEFAULT'">
    <div class="language-switcher ps-4" *ngIf="isPreview && langSwitcher">
      <app-lang-switcher
        (langChanged)="langSwitch($event)"
        [selectedLanguage]="lang">
      </app-lang-switcher>
    </div>
    <ng-container *ngIf="gravityTheme.theme === 'DEFAULT'">

      <!-- <div class="cv-container cv-section bg-white cv-section-main">
        <app-section-main *ngIf="webCV" [webCV]="webCV" [gravityTheme]="gravityTheme" [isTeamMember]=true>
        </app-section-main>
      </div>

      <div class="cv-container mt-7">
        <app-cv-tabs [gravityTheme]="gravityTheme" [hasSidebar]="hasSidebar" [usernameOrHash]="usernameOrHash"
          [webCV]="webCV" [isSendout]="isSendout" [teamWebCvUniqueHash]="webCV?.teamWebCvUniqueHash"
          [teamDomain]="teamDomain" [sendoutHash]="hash" [groupUrl]="groupUrl" class="mt-5 col-md-8" [locale]="lang">
        </app-cv-tabs>
      </div> -->
      <app-default-template
        [webCV]="webCV"
        [gravityTheme]="gravityTheme"
        [usernameOrHash]="usernameOrHash"
        [isTeamMember]=true
        [isSendout]="isSendout"
        [teamWebCvUniqueHash]="webCV?.teamWebCvUniqueHash"
        [teamDomain]="teamDomain"
        [sendoutHash]="hash"
        [groupUrl]="groupUrl"
        [langSwitcher]="false">
      </app-default-template>
    </ng-container>

    <ng-container *ngIf="gravityTheme.theme === 'THEME1'">
      <app-theme-landing
        [webCV]="webCV"
        [gravityTheme]="gravityTheme"
        [usernameOrHash]="usernameOrHash"
        [isTeamMember]=true
        [isSendout]="isSendout"
        [teamWebCvUniqueHash]="webCV?.teamWebCvUniqueHash"
        [teamDomain]="teamDomain"
        [sendoutHash]="hash"
        [groupUrl]="groupUrl"
        [langSwitcher]="false">
      </app-theme-landing>
    </ng-container>

    <ng-container *ngIf="gravityTheme.theme === 'THEME2'">
      <app-theme-landing2
      [webCV]="webCV"
      [gravityTheme]="gravityTheme"
      [usernameOrHash]="usernameOrHash"
      [isTeamMember]=true
      [isSendout]="isSendout"
      [teamWebCvUniqueHash]="webCV?.teamWebCvUniqueHash"
      [teamDomain]="teamDomain"
      [sendoutHash]="hash"
      [groupUrl]="groupUrl"
      [langSwitcher]="false">


        <!-- <iframe src="https://certy.px-lab.com/html/demo1/"
        [ngStyle]="{height:'100%', width:'100%'}">
       </iframe> -->
      </app-theme-landing2>

    </ng-container>
  </nb-layout-column>
  <nb-layout-footer>
    <div class="defaul-footer">
      <app-web-cv-footer class="footer web-cv-footer"></app-web-cv-footer>
  </div>
    <!-- <app-web-cv-footer class="footer web-cv-footer w-100" [class.pt-8]="gravityTheme.theme === 'DEFAULT'"></app-web-cv-footer>   -->
  </nb-layout-footer>
</nb-layout>

<app-preloader></app-preloader>
