
<!--====================== New Design code start here ======================-->
<div class="theme-cv-wrapper">
    <div class="language-switcher" *ngIf="langSwitcher">
        <app-lang-switcher
          (langChanged)="langSwitch($event)"
          [selectedLanguage]="lang">
        </app-lang-switcher>
    </div>
    <div class="main-container">
        <div class="profile-info">
            <div class="profile-data-wrapper bt-border">
                <div class="avatar-profile">
                    <app-avatar [avatar]="webCV.profile?.images?.avatar" [bgColor]="gravityTheme?.accent"
                      [fgColor]="gravityTheme?.contrast" [name]="webCV.profile?.personal?.shortName || 'N. N.'" [size]="160">
                    </app-avatar>
                </div>
                <div class="contact-social" *ngIf="!socialLinkNone">
                    <h4>{{'WEB_CV.LABEL.SOCIAL_MEDIA' | translate}}</h4>
                    <ul class="d-flex flex-wrap">
                        <li *ngFor="let link of social">
                            <a  [href]="link.value" class="cv-social-link icon-{{link.label == 'linkedin' ? 'linked-in' : link.label }}"  target="_blank">
                                <!-- <i class="gvcv-icon gvcv-icon-lg icon-{{link.label == 'linkedin' ? 'linked-in' : link.label }} me-1"></i> -->
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="main-skills-wrapper bt-border" *ngIf="mainSkills && mainSkills.length > 0">
                <h3>{{'WEB_CV.LABEL.MAIN_SKILLS' | translate}}</h3>
                <div class="main-skill d-flex justify-content-between align-items-center" *ngFor=" let badge of mainSkills | orderBy: ['mainSkillOrder']">
                    <b class="me-2">{{badge.nameLocalizations[lang]}}</b>
                    <bar-rating [rate]="badge.stars" [max]="5" [theme]="'movie'" [readOnly]="true">
                    </bar-rating>
                </div>
            </div>
            <div class="availability-wrapper bt-border" *ngIf="webCV.profile?.availability || webCV.profile?.general?.employmentMode">
                <h3>{{'WEB_CV.LABEL.AVAILABILITY' | translate}}</h3>
                <div class="avail-detail d-flex flex-wrap justify-content-between">
                    <span>{{ 'WEB_CV.SORT_BY_DATE' | translate }}:<span>{{webCV.profile?.availability?.date  | date:'dd.MM.yyyy'}}</span></span>
                    <span>
                      <ng-container *ngIf="webCV.profile?.availability?.type === 'PERCENT' else freeText">
                        <span>{{'WEB_CV.LABEL.PERCENT' | translate: { percent: webCV.profile?.availability?.value } }}</span>
                      </ng-container>
                      <ng-template #freeText>
                        Available: <span>{{webCV.profile?.availability?.value}}</span>
                      </ng-template>
                    </span>
                </div>
            </div>
            <div class="address-wrapper bt-border" *ngIf="contactInfo.street || contactInfo.zip || contactInfo.city[lang]">
                <h3>{{'WEB_CV.LABEL.ADDRESS' | translate}}</h3>
                <span>{{contactInfo.street}} {{contactInfo.zip}} {{contactInfo.city[lang]}}</span>
            </div>
            <div class="work-location-wrapper bt-bottom" *ngIf="location.location || location.europe || location.germany || location.radius || location.radius">
                <h3>{{'WEB_CV.LABEL.LOCATION' | translate}}</h3>
                <span>
                    <span>
                      {{location.location}}
                    </span><br>
                    <span *ngIf="location.radius">{{'WEB_CV.LOCATION.RADIUS' | translate : {radius: webCV.profile?.location?.radius} }}</span><br>
                    <span *ngIf="location.europe">{{'WEB_CV.LOCATION.EUROPE' | translate}}</span><br>
                    <span *ngIf="location.germany">{{'WEB_CV.LOCATION.GERMANY' | translate}}</span><br>
                    <span *ngIf="location.remote">{{'WEB_CV.LOCATION.REMOTE' | translate}}</span><br>
                </span>
            </div>
        </div>

        <div class="profile-box">
            <div class="profile-box-wrapper" *ngIf="showAll">
                <div class="normal-content white-box ps-50 mb-30">
                  <div class="normal-content-wrapper">
                    <div class="name-details">
                        <h2>{{personal?.fullName}}</h2>
                        <h4>{{webCV.profile?.general?.profession[lang]}}</h4>
                    </div>
                    <div class="read-more-content">
                        <div class="tab-content">
                          <app-section-bio [webCV]="webCV"></app-section-bio>
                            <!-- <div [innerHTML]="webCV?.profile?.bio[lang]" class="cv-bio"></div> -->
                        </div>
                    </div>
                  </div>
                  <div class="others-details">
                      <div class="download-file-wrapper common-bio-box">
                          <h5>{{'WEB_CV.LABEL.DOWNLOADS' | translate}}</h5>
                          <div class="downloads position-relative">
                            <div (click)="downloadCV('pdf')" *ngIf="webCV.controls?.downloadPdf"
                              class=" d-flex align-items-center  mb-3 cursor-pointer">
                              <b>{{getCleanFileName()}}.pdf</b>
                              <img src="assets/images/file-pdf.svg" alt="" class="download-btn ms-2"  style="fill:#0878FF;">
                            </div>
                            <div (click)="downloadCV('doc')" *ngIf="webCV.controls?.downloadWord"
                              class="d-flex align-items-center  mb-2 cursor-pointer">
                              <b>{{getCleanFileName()}}.doc</b>
                              <img src="assets/images/file-word.svg" alt="" class="download-btn ms-2" style="fill:#0878FF;">
                            </div>
                            <div *ngIf="downloadSpinner" class="cv-download-spinner">
                              <div class="spinner-grow text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="contact-wrapper common-bio-box">
                          <h5>{{'WEB_CV.LABEL.CONTACT' | translate}}</h5>
                          <div class="content bg-white">
                              <p><b>Email Address: <a class="mail-content" href="mailto:{{webCV.profile?.contactInfo?.publicEmail}}"> {{contactInfo.publicEmail}}</a></b></p>
                              <p *ngIf="webCV.profile?.contactInfo?.phone && webCV.profile?.contactInfo?.phone !== null" ><b>Phone Number: <span> {{webCV.profile?.contactInfo?.phone}}</span></b></p>
                          </div>
                      </div>
                      <div class="lang-wrapper common-bio-box">
                          <h5>{{'WEB_CV.LABEL.LANGUAGES' | translate}}</h5>
                          <ul class="content bg-white">
                              <li *ngFor="let lang of languages">
                                <p><b class="me-2">{{lang.label}}</b></p> <span>
                                  <ng-container [ngSwitch]="lang?.value">
                                    <b  *ngSwitchCase="cvLanguageValueEnum.A1">{{'WEB_CV.LANGUAGES.VALUES.A1' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.A2">{{'WEB_CV.LANGUAGES.VALUES.A2' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.B1">{{'WEB_CV.LANGUAGES.VALUES.B1' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.B2">{{'WEB_CV.LANGUAGES.VALUES.B2' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.C1">{{'WEB_CV.LANGUAGES.VALUES.C1' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.C2">{{'WEB_CV.LANGUAGES.VALUES.C2' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.BASIC_KNOWLEDGE">{{'WEB_CV.LANGUAGES.VALUES.BASIC_KNOWLEDGE' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.GOOD_KNOWLEDGE">{{'WEB_CV.LANGUAGES.VALUES.GOOD_KNOWLEDGE' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.FLUENT">{{'WEB_CV.LANGUAGES.VALUES.FLUENT' | translate}}</b>
                                    <b  *ngSwitchCase="cvLanguageValueEnum.MOTHER_TONGUE">{{'WEB_CV.LANGUAGES.VALUES.MOTHER_TONGUE' | translate}}</b>
                                    <b  *ngSwitchDefault></b>
                                </ng-container>
                                  </span>
                              </li>
                          </ul>
                      </div>
                  </div>
                </div>
                <div class="project-content white-box ps-50 mb-30">
                    <div class="title-content bt-border">
                        <h3>{{ 'WEB_CV.TAB.PROJECTS' | translate }}</h3>
                        <button class="theme-btn" (click)="showAll = false; showAllProject = true; scroll()">{{ 'WEB_CV.VIEW_ALL' | translate }}</button>
                    </div>
                    <div class="project-content-wrapper">
                      <app-section-project [gravityTheme]="gravityTheme" [searchVisible]="false" [webCV]="webCV">
                      </app-section-project>
                    </div>
                </div>

                <div class="qualifications-content project-content white-box ps-50 mb-30">
                  <div class="title-content bt-border">
                      <h3>{{ 'WEB_CV.TAB.QUALIFICATIONS' | translate }}</h3>
                      <button class="theme-btn"(click)="showAll = false; showAllSkillUp = true; scroll()" >{{ 'WEB_CV.VIEW_ALL' | translate }}</button>
                  </div>
                  <div class="project-content-wrapper">
                    <app-section-qualifications [gravityTheme]="gravityTheme" [searchVisible]="false" [webCV]="webCV">
                    </app-section-qualifications>
                  </div>
                </div>
                <div class="skills-content white-box ps-50 mb-30">
                    <div class="title-content bt-border">
                      <h3>{{ 'WEB_CV.TAB.SKILLS' | translate }}</h3>
                      <button class="theme-btn" (click)="showAll = false; showSkillTab = true; scroll()">{{ 'WEB_CV.VIEW_ALL' | translate }}</button>
                    </div>
                    <div class="skill-content-wrapper">
                      <app-section-skills [gravityTheme]="gravityTheme" [webCV]="webCV" [locale]="lang">
                      </app-section-skills>
                    </div>
                </div>
                <div class="certification-content white-box ps-50 mb-30">
                    <div class="title-content bt-border">
                        <h3>{{ 'WEB_CV.CERTIFICATION' | translate }}</h3>
                    </div>
                    <ul class="certi-list">
                      <ng-container *ngFor="let certificate of webCV.skillUps">
                        <!-- {{certificate | json}} -->
                        <li *ngIf="(!checkFile(certificate.fileName)) && certificate.badgeName"
                        (click)="openFileModal($event, certificate?.fileName)"><img [src]="certificate.badgeName" alt=""></li>
                        <li *ngIf="checkFile(certificate.fileName)"
                        (click)="openFileModal($event, certificate?.fileName)"><img [src]="certificate.fileName" alt=""></li>
                      </ng-container>
                      </ul>
                </div>
                <div class="rate-card-content white-box ps-50 mb-30">
                    <div class="title-content bt-border">
                        <h3>{{ 'WEB_CV.TAB.RATE_CARDS' | translate }}</h3>
                    </div>
                    <div class="rate-card-wrapper">
                      <app-section-rate [gravityTheme]="gravityTheme" [webCV]="webCV">
                      </app-section-rate>
                    </div>
                </div>

            </div>

            <div class="profile-box-wrapper" *ngIf="(!showAll && showAllProject) || (!showAll && showAllSkillUp) || (!showAll)">
              <div class="project-content white-box ps-50 mb-30" *ngIf="!showAll && showAllProject">
                <div class="project-content-wrapper" [ngClass]="showAll === false? 'mt-100' : '' ">
                  <app-section-project [gravityTheme]="gravityTheme" (projects)="hideProjects($event)" [searchVisible]="true"  [webCV]="webCV">
                  </app-section-project>
                </div>
              </div>

              <div class="qualifications-content project-content white-box ps-50 mb-30" *ngIf="!showAll && showAllSkillUp">
                <div class="project-content-wrapper" [ngClass]="showAll === false? 'mt-100' : '' ">
                  <app-section-qualifications [gravityTheme]="gravityTheme" (qualifications)="hideQualifications($event)" [searchVisible]="true" [webCV]="webCV">
                  </app-section-qualifications>
                </div>
              </div>

              <div class="skills-content"  *ngIf="!showAll && showSkillTab">
                <div class="skill-content-wrapper">
                    <div class="back-btn" (click)="showAll = true; showSkillTab = false">
                        <img src="assets/images/back-arrow.svg" alt="">
                    </div>
                    <div class="tabbing-content">
                        <app-skill-tabs
                          [gravityTheme]="gravityTheme"
                          [webCV]="webCV"
                          [locale]="lang">
                        </app-skill-tabs>
                    </div>
                </div>
              </div>

          </div>


        </div>
    </div>
</div>
<!--====================== New Design code End here ======================-->


<!-- <div class="relative-container">
  <div class="language-switcher" *ngIf="langSwitcher">
    <app-lang-switcher
      (langChanged)="langSwitch($event)"
      [selectedLanguage]="lang">
    </app-lang-switcher>
  </div>

  <div class="main-container">
    <div class="profile-info pb-3">
      <div class="avatar-name my-4">
        <div class="avatar">
          <app-avatar [avatar]="webCV.profile?.images?.avatar" [bgColor]="gravityTheme?.accent" [round]="false"
            [fgColor]="gravityTheme?.contrast" [name]="webCV.profile?.personal?.shortName || 'N. N.'" [size]="190">
          </app-avatar>
        </div>
        <div class="name-academic">
          <div class="name">{{personal.fullName}}</div>
          <div class="academic" *ngIf="personal.title[lang]">{{personal.title[lang]}}</div>
        </div>
      </div>
      <div class="hire-me d-flex flex-column align-items-center">
        <div class="contact-btn" *ngIf="webCV.controls?.contactform && webCV.profile?.contactInfo?.publicEmail">
          <a class="btn btn-accent {{gravityTheme?.contrast}}" href="mailto:{{webCV.profile?.contactInfo?.publicEmail}}">
            <span class="btn-icon icon-start">
              <i class="gvcv-icon icon-send"></i>
              <span>{{'WEB_CV.LABEL.HIRE_ME' | translate}}</span>
            </span>
          </a>
        </div>
        <div class="cv-main-col d-flex flex-column align-items-end my-3"
          *ngIf="webCV.profile?.availability || webCV.profile?.general?.employmentMode">
          <div *ngIf="webCV.profile?.availability" class="flex-column align-items-end">
            <div class="cv-label accent-h4 text-uppercase d-flex align-items-center justify-content-end">
              <i class="gvcv-icon icon-date me-1"></i>
              {{'WEB_CV.LABEL.AVAILABILITY' | translate}}
            </div>
            <div class="cv-value cv-availability d-flex flex-column align-items-end">
              <ng-container>
                {{webCV.profile?.availability?.date  | date:'dd.MM.yyyy'}}
              </ng-container>

              <div class="d-flex justify-content-end availability"
                *ngIf="webCV.profile?.availability?.value && webCV.profile?.availability?.value !== '0'">
                <ng-container *ngIf="webCV.profile?.availability?.type === 'PERCENT' else freeText">
                  {{'WEB_CV.LABEL.PERCENT' | translate: { percent: webCV.profile?.availability?.value } }}
                </ng-container>
                <ng-template #freeText>
                  {{webCV.profile?.availability?.value}}
                </ng-template>
              </div>
            </div>
          </div>
          <div *ngIf="webCV.profile?.general?.employmentMode" class="flex-column align-items-end justify-content-end">
            <div class="cv-label accent-h4 text-uppercase d-flex align-items-center justify-content-end">
              <i class="gvcv-icon icon-edit me-1"></i>
              {{'WEB_CV.LABEL.EMPLOYMENT' | translate}}
            </div>
            <div class="cv-value cv-employment d-flex justify-content-end">
              {{webCV.profile?.general?.employmentModeText | translate}}
            </div>
          </div>
        </div>
      </div>
      <mat-accordion multi>
        <ng-container *ngIf="!contactInfoNone">
          <mat-expansion-panel [expanded]="infoExpanded">
            <mat-expansion-panel-header>
              <mat-panel-title> <b> {{'WEB_CV.LABEL.CONTACT' | translate}} </b> </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="contact-personal">
              <div class="phone d-flex align-items-center" *ngIf="contactInfo.phone || contactInfo.mobilePhone">
                <mat-icon>phone</mat-icon>
                <div class="d-flex flex-column justify-content-around align-items-center">
                  <div class="content"> {{webCV.profile?.contactInfo?.phone}} </div>
                  <div class="content"> {{webCV.profile?.contactInfo?.mobilePhone}} </div>
                </div>
              </div>
              <div class="email d-flex align-items-center" *ngIf="contactInfo.publicEmail">
                <mat-icon>email</mat-icon>
                <div class="content">{{contactInfo.publicEmail}}</div>
              </div>
              <div class="website d-flex align-items-center" *ngIf="contactInfo.website">
                <mat-icon>link</mat-icon>
                <div class="content cursor-pointer" (click)="gotoPersonalSite(contactInfo.website)">
                  {{contactInfo.website}}
                </div>
              </div>

              <div class="address d-flex align-items-center"
                *ngIf="contactInfo.street || contactInfo.zip || contactInfo.city[lang]">
                <mat-icon>public</mat-icon>
                <div class="content">{{contactInfo.street}} {{contactInfo.zip}} {{contactInfo.city[lang]}}</div>
              </div>
              <div class="location d-flex align-items-start"
                *ngIf="location.location || location.europe || location.germany || location.radius || location.radius">
                <mat-icon>location_on</mat-icon>
                <div>
                  <div class="content">{{location.location}}</div>
                  <div class="content" *ngIf="location.radius">
                    {{'WEB_CV.LOCATION.RADIUS' | translate : {radius: webCV.profile?.location?.radius} }}
                  </div>
                  <div class="content" *ngIf="location.europe">{{'WEB_CV.LOCATION.EUROPE' | translate}}</div>
                  <div class="content" *ngIf="location.germany">{{'WEB_CV.LOCATION.GERMANY' | translate}}</div>
                  <div class="content" *ngIf="location.remote">{{'WEB_CV.LOCATION.REMOTE' | translate}}</div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="!socialLinkNone">
          <mat-expansion-panel [expanded]="infoExpanded">
            <mat-expansion-panel-header>
              <mat-panel-title> <b> {{'WEB_CV.LABEL.CONTACT' | translate}} </b> </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="contact-social d-flex flex-wrap">
              <a *ngFor="let link of social" [href]="link.value" class="cv-social-link" target="_blank">
                <i class="gvcv-icon gvcv-icon-lg icon-{{link.label == 'linkedin' ? 'linked-in' : link.label }} me-1"></i>
              </a>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="languageExist">
          <mat-expansion-panel [expanded]="infoExpanded">
            <mat-expansion-panel-header>
              <mat-panel-title> <b> {{'WEB_CV.LABEL.LANGUAGES' | translate}} </b> </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let lang of languages">
              <div class="language">
                <b class="me-3">{{lang.label}}</b> {{lang.value}}
                <bar-rating [rate]="lang.value | languageLevel" [max]="5" [theme]="'star-rate'" [readOnly]="true">
                </bar-rating>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="mainSkills && mainSkills.length > 0">
          <mat-expansion-panel [expanded]="infoExpanded">
            <mat-expansion-panel-header>
              <mat-panel-title> <b> {{'WEB_CV.LABEL.MAIN_SKILLS' | translate}} </b> </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="main-skill d-flex justify-content-between align-items-center"
              *ngFor=" let badge of mainSkills | orderBy: ['mainSkillOrder']">
              <b class="me-2">{{badge.name}}</b>
              <bar-rating [rate]="5" [max]="5" [theme]="'star-rate'" [readOnly]="true">
              </bar-rating>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="webCV.controls?.downloadPdf || webCV.controls?.downloadWord">
          <mat-expansion-panel [expanded]="infoExpanded">
            <mat-expansion-panel-header>
              <mat-panel-title> <b> {{'WEB_CV.LABEL.DOWNLOADS' | translate}} </b> </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="downloads position-relative">
              <div (click)="downloadCV('pdf')" *ngIf="webCV.controls?.downloadPdf"
                class="text-uppercase d-flex align-items-center mb-2 cursor-pointer">
                <mat-icon>cloud_download</mat-icon>
                <b>{{getCleanFileName()}}.pdf</b>
              </div>
              <div (click)="downloadCV('docx')" *ngIf="webCV.controls?.downloadWord"
                class="text-uppercase d-flex align-items-center mb-2 cursor-pointer">
                <mat-icon>cloud_download</mat-icon>
                <b>{{getCleanFileName()}}.doc</b>
              </div>
              <div *ngIf="downloadSpinner" class="cv-download-spinner">
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
    <div class="profile-box d-flex flex-column">
      <div class="name-general-degree mb-4">
        <div class="name">{{personal?.fullName}}</div>
        <div class="general text-uppercase">{{webCV.profile?.general?.profession[lang]}}</div>
        <ng-container *ngFor="let degree of webCV.profile?.graduations">
          <div class="degree">{{degree.instituteName}}</div>
        </ng-container>
      </div>
      <ng-container *ngFor="let section of webCV?.order?.active; trackBy: trackByOrder">
        <div class="profile" *ngIf="section.value == 'profile'" id="profile">
          <div class="tab-title">
            <mat-icon class="me-3">person_outline</mat-icon> <span>{{ 'WEB_CV.TAB.PROFILE' | translate }}</span>
          </div>
          <div class="tab-content">
            <div [innerHTML]="webCV?.profile?.bio[lang]" class="cv-bio"></div>
          </div>
        </div>
        <div class="project" *ngIf="section.value == 'project'" id="projects">
          <div class="tab-title">
            <mat-icon class="me-3">traffic</mat-icon> <span>{{ 'WEB_CV.TAB.PROJECTS' | translate }}</span>
          </div>
          <div class="tab-content">
            <app-section-project [gravityTheme]="gravityTheme" [webCV]="webCV">
            </app-section-project>
          </div>
        </div>
        <div class="skills" *ngIf="section.value == 'skills'" id="skills">
          <div class="tab-title">
            <mat-icon class="me-3">card_giftcard</mat-icon> <span>{{ 'WEB_CV.TAB.SKILLS' | translate }}</span>
          </div>
          <div class="tab-content">
            <app-section-skills [gravityTheme]="gravityTheme" [webCV]="webCV" [locale]="lang">
            </app-section-skills>
          </div>
        </div>
        <div class="qualification" *ngIf="section.value == 'qualification'" id="qualifications">
          <div class="tab-title">
            <mat-icon class="me-3">security</mat-icon> <span>{{ 'WEB_CV.TAB.QUALIFICATIONS' | translate }}</span>
          </div>
          <div class="tab-content">
            <app-section-qualifications [gravityTheme]="gravityTheme" [webCV]="webCV">
            </app-section-qualifications>
          </div>
        </div>
        <div class="rate" *ngIf="section.value == 'rate' && webCV?.profile?.rates" id="rates">
          <div class="tab-title">
            <mat-icon class="me-3">rate_review</mat-icon> <span>{{ 'WEB_CV.TAB.RATE_CARDS' | translate }}</span>
          </div>
          <div class="tab-content">
            <app-section-rate [gravityTheme]="gravityTheme" [webCV]="webCV">
            </app-section-rate>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div> -->
<!-- <div class="defaul-footer">
  <app-web-cv-footer class="footer web-cv-footer w-100 mt-0" *ngIf="!isTeamMember"></app-web-cv-footer>
</div> -->
<div class="defaul-footer">
  <app-web-cv-footer *ngIf="!isTeamMember" class="footer web-cv-footer"></app-web-cv-footer>
</div>
