<section *ngIf="model" class="cv-main-section">
  <div class="cv-logo-wrap d-flex align-items-center flex-grow-1">
    <div *ngIf="model?.website; else logoImage">
      <a href="{{model?.website}}" target="_blank">
        <ng-container *ngTemplateOutlet="logoImage"></ng-container>
      </a>
    </div>
    <ng-template #logoImage>
      <img
        *ngIf="model?.logo"
        alt="Logo {{model?.companyName}}"
        class="img-fluid cv-logo"
        src="{{model?.resizedLogo || model?.logo}}"
      >
    </ng-template>
    <app-avatar
      class="cv-logo"
      *ngIf="!model?.logo"
      [avatar]="model?.resizedLogo || model?.logo"
      [name]="model?.companyName"
      [bgColor]="gravityTheme?.accent"
      [size]="160">
    </app-avatar>
  </div>
  <div class="d-flex flex-column ps-lg-4 ps-0 w-100">
    <div class="cv-text-item d-flex justify-content-between">
      <div class="cv-text-item-left float-md-left">
        <h1 class="cv-user-name">
          {{model?.companyName || '&nbsp;'}}
        </h1>
        <div class="accent-h3 text-uppercase">{{model.shortDescription || '&nbsp;'}}</div>
      </div>
      <div class="float-md-right mt-3 mt-md-0">
        <a class="btn btn-primary" *ngIf="model?.email" href="mailto:{{model?.email}}">
          <span class="btn-icon icon-start text-white">
            <i class="gvcv-icon icon-send"></i>
            <span>{{'WEB_CV.LABEL.BUTTON_CONTACT' | translate}}</span>
          </span>
        </a>
      </div>
    </div>
    <div class="cv-text-item mt-4">
      <div [innerHTML]="model?.bio" class="cv-bio text-break"></div>
      <dl class="cv-team-contacts mt-md-8 mt-4 mb-0">
        <div class="cv-team-contact-item" *ngIf="model?.phone">
          <dd class="d-flex me-3">{{'WEB_CV.LABEL.PHONE' | translate}}</dd>
          <dt class="d-flex me-4">{{model.phone}}</dt>
        </div>
        <div class="cv-team-contact-item" *ngIf="model?.email">
          <dd class="d-flex me-3">{{'WEB_CV.LABEL.EMAIL' | translate}}</dd>
          <dt class="d-flex me-4">{{model.email}}</dt>
        </div>
        <div class="cv-team-contact-item" *ngIf="model?.website">
          <dd class="d-flex me-3">{{'WEB_CV.LABEL.WEB' | translate}}</dd>
          <dt class="d-flex me-4"><a href="{{model?.website}}" target="_blank">{{model.website}}</a></dt>
        </div>
      </dl>
    </div>
  </div>
</section>
