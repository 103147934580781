import {FormlyFieldConfig} from '@ngx-formly/core';

import {RepeatableComponent} from '@components/formly/fields/repeatable/repeatable.component';
import {CustomSelectComponent} from '@components/formly/fields/custom-select/custom-select.component';
import {DropdownSelectComponent} from '@components/formly/fields/dropdown-select/dropdown-select.component';
import {NebularSelectComponent} from '@components/formly/fields/nebular-select/nebular-select.component';
import {ButtonFieldComponent} from '@components/formly/buttons/button-field/button-field.component';
import {FieldWrapperComponent} from '@components/formly/wrappers/field-wrapper/field-wrapper.component';
import {AddonWrapperComponent} from '@components/formly/wrappers/addon-wrapper/addon-wrapper.component';
import {FieldHorizontalComponent} from '@components/formly/wrappers/field-horizontal/field-horizontal.component';
import {CustomRadioComponent} from '@components/formly/fields/custom-radio/custom-radio.component';
import {EditorComponent} from '@components/formly/editor/editor.component';
import {CustomCheckboxComponent} from '@components/formly/fields/custom-checkbox/custom-checkbox.component';
import {BadgeWrapperComponent} from '@components/formly/wrappers/badge-wrapper/badge-wrapper.component';
import {InputDragAutocompleteComponent} from '@components/formly/fields/input-drag-autocomplete/input-drag-autocomplete.component';
import { InputAutocompleteComponent } from '@components/formly/fields/input-autocomplete/input-autocomplete.component';

export function serverErrorMessage(error: any, field?: FormlyFieldConfig) {
  return error;
}

export const formlyConfig = {
  types: [
    {name: 'repeatable', component: RepeatableComponent},
    {name: 'custom-checkbox', component: CustomCheckboxComponent, wrappers: ['form-field']},
    {name: 'custom-select', component: CustomSelectComponent, wrappers: ['form-field']},
    {name: 'dropdown-select', component: DropdownSelectComponent, wrappers: ['form-field']},
    {name: 'nebular-select', component: NebularSelectComponent, wrappers: ['form-field']},
    {name: 'button-field', component: ButtonFieldComponent, wrappers: ['form-field']},
    {name: 'custom-radio', component: CustomRadioComponent, wrappers: ['form-field']},
    {name: 'editor', component: EditorComponent, wrappers: ['form-field']},
    {name: 'input-drag-autocomplete', component: InputDragAutocompleteComponent, wrappers: ['form-field']},
    {name: 'input-autocomplete', component: InputAutocompleteComponent, wrappers: ['form-field']},
  ],
  wrappers: [
    {name: 'form-field', component: FieldWrapperComponent},
    {name: 'addons', component: AddonWrapperComponent},
    {name: 'field-horizontal', component: FieldHorizontalComponent},
    {name: 'badge-wrapper', component: BadgeWrapperComponent}
  ],
  validationMessages: [
    {name: 'server-error', message: serverErrorMessage},
  ],
};
