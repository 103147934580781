import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@layouts/single-user/main/main.component';
import {LoginComponent} from '@layouts/single-user/login/login.component';
import {ReferenceFormComponent} from '@layouts/single-user/reference/reference-form/reference-form.component';
import {ReferenceLoginComponent} from '@layouts/single-user/reference/reference-login/reference-login.component';
import {ReferenceThanksComponent} from '@layouts/single-user/reference/reference-thanks/reference-thanks.component';
import {ErrorPageComponent} from '@modules/error-page/default/error-page.component';


export const SINGLE_USER_ROUTES: Routes = [
  {
    path: '',
    component: MainComponent,
  },
  {
    path: ':usernameOrHash',
    component: MainComponent,
  },
  {
    path: 'login/:usernameOrHash',
    component: LoginComponent,
  },
  {
    path: 's/:hash',
    component: MainComponent,
  },
  {
    path: 's/login/:hash',
    component: LoginComponent,
  },
  {
    path: 'reference/:hash',
    component: ReferenceFormComponent,
  },
  {
    path: 'reference/login/:hash',
    component: ReferenceLoginComponent,
  },
  {
    path: 'reference/confirmed/:hash',
    component: ReferenceThanksComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(SINGLE_USER_ROUTES)],
  exports: [RouterModule]
})
export class SingleUserRoutingModule {
}
