import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileModalComponent } from '@app/components/file-modal/file-modal.component';
import { CvContactInfo, CvLanguage, CvLanguageValue, CvLocation, CvOrderItem, CvPersonal, CvSocial, GravityTheme, SharedCv, Skill } from '@app/models';
import { CvGeneratorService } from '@app/services/cv-generator.service';
import { SharedWebCvService } from '@app/services/shared-web-cv.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {saveAs} from 'file-saver';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-theme-landing',
  templateUrl: './theme-landing.component.html',
  styleUrls: ['./theme-landing.component.scss']
})
export class ThemeLandingComponent implements OnInit, OnDestroy {

  alive = true;
  infoExpanded: boolean;
  isSmallScreen: any;
  lang: string;
  showAll = true;
  showAllProject = false;
  showAllSkillUp = false;
  showSkillTab = false;
  cvLanguageValueEnum = CvLanguageValue;


  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() usernameOrHash: string;
  @Input() isSendout: false;
  @Input() langSwitcher: boolean;

  personal: CvPersonal;

  contactInfo: CvContactInfo;
  location: CvLocation;
  socialLink: CvSocial;
  social: any[];
  languages: CvLanguage[];
  mainSkills: Skill[];

  downloadSpinner = false;
  // only used for teams
  @Input() teamWebCvUniqueHash;
  @Input() teamDomain;
  @Input() sendoutHash;
  @Input() groupUrl;
  @Input() isTeamMember = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cvGeneratorService: CvGeneratorService,
    private modalService: NgbModal,
    private webCvService: SharedWebCvService,
    private activatedRoute: ActivatedRoute,

  ) {
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    const layoutChanges = breakpointObserver.observe([
      '(max-width: 767px)'
    ]);

    layoutChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(result => {
        this.isSmallScreen = result.matches;
        console.log(this.isSmallScreen);
        this.infoExpanded = this.isSmallScreen ? false : true;
      });
  }

  ngOnInit() {
    this.language();
    if (!!this.webCV.profile) {
      this.personal = this.webCV.profile.personal;

      this.contactInfo = this.webCV.profile.contactInfo;
      this.location = this.webCV.profile.location;
      this.socialLink = this.webCV.profile.social;
      if (this.socialLink) {
        const socialLinks = Object.keys(this.socialLink).map(key => ({
          label: key,
          value: this.socialLink[key]
        }));
        this.social = socialLinks.filter(item => !!item.value);
      }
      this.languages = this.webCV.profile.languages;

      const { categorized, uncategorized } = this.webCV.profile.skills;
      const categorizedSkills = [].concat(...[...categorized.map(({ skills }) => skills)]);
      const allSkills = [...categorizedSkills, ...uncategorized];
      this.mainSkills = allSkills.filter(item => item.mainSkill);
    }
  }
  language(){
    this.activatedRoute.queryParams.subscribe(res => {
      if(res.token){
        if(this.webCV.profile.languages.length > 0){
          this.webCV.profile.languages.forEach(language => {
            this.webCvService.language(res.token, language.label).subscribe(res => {
              if(res){
                if(this.lang && this.lang == 'en'){
                  language.label = res[0].english;
                }else{
                  language.label = res[0].german;
                }
              }
            })
          })
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  public get contactInfoNone() {
    return this.contactInfo ? Object.keys(this.contactInfo).length === 0 : true;
  }
  public get socialLinkNone() {
    return this.socialLink ? Object.keys(this.socialLink).length === 0 : true;
  }
  public get languageExist() {
    return this.languages ? this.languages.length > 0 : false;
  }

  gotoPersonalSite(url: string) {
    window.open(url, '_blank');
  }

  getCleanFileName() {
    let fullName = '';
    if (this.webCV.profile.personal) {
      fullName = `${this.webCV.profile.personal.firstName || ''} ${this.webCV.profile.personal.lastName || ''}`;
    }
    let fileName = 'cv';

    if (!!fullName.trim()) {
      fileName = [...fullName.trim().split(' '), fileName].join('-');
    }
    return fileName;
  }

  downloadCV(type: 'pdf' | 'doc') {
    const sharedCV = new SharedCv();
    Object.assign(sharedCV, this.webCV);
    delete sharedCV.controls;
    this.downloadSpinner = true;
    sharedCV.appLanguage = this.lang;

    let res;
    if (this.teamWebCvUniqueHash) {
      res = this.cvGeneratorService.downloadTeamCV(this.teamDomain ,sharedCV, type);
    } else {
      res = this.cvGeneratorService.downloadCV(this.usernameOrHash ,sharedCV, type);
    }

    res.subscribe(res2 => {
      res2.subscribe(response => {
      if (response) {
        saveAs(response, this.getCleanFileName() + "." + type);

        if (this.teamWebCvUniqueHash) {
          this.trackDownloadForTeamMember(type);
        } else {
          this.trackDownloadForSingleUser(type);
        }
      }
      this.downloadSpinner = false;
    });});
  }

  trackDownloadForTeamMember(type: 'pdf' | 'doc') {
    this.cvGeneratorService.trackCvDownloadForTeamMember(this.teamWebCvUniqueHash, this.teamDomain,
      this.groupUrl, this.sendoutHash, type).subscribe(() => {
    });
  }

  trackDownloadForSingleUser(type: 'pdf' | 'doc') {
    if (this.isSendout) {
      this.cvGeneratorService.trackCvSendoutDownloadForSingleUser(this.usernameOrHash, type).subscribe(() => {
      });
    } else {
      this.cvGeneratorService.trackCvDownloadForSingleUser(this.usernameOrHash, type).subscribe(() => {
      });
    }
  }

  trackByOrder(index: number, item: CvOrderItem): string {
    return item.value;
  }

  langSwitch(lang: string) {
    const url = new URL(window.location.href);
    window.location.href = '/' + lang + url.pathname.substr(3) + url.search;
  }
  hideProjects(event){
    this.showAll = true
    this.showAllProject = event
  }

  hideQualifications(event){
    this.showAll = true
    this.showAllSkillUp = event
  }
  scroll(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
     });
  }
  checkFile(file){
    if(file && (file.includes('.jpg') || file.includes('.png'))){
      return true
    }
    else{
      return false
    }
  }
  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    console.log(fileUrl);
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = fileUrl;
  }
}
