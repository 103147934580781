import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {TeamMember, TeamMemberAdapter} from './team-member';
import {Skill, SkillAdapter} from '../skillfeed/skill';

export class TeamGroup {

  name: string;
  urlName: string;
  resizedLogo: string;
  logo: string;
  domain: string;
  companyName: string;
  shortDescription: string;
  email: string;
  bio: string;
  website: string;
  phone: string;
  members: TeamMember[];
  skills: Skill[];
  lang: string;
}

export class TeamGroupAdapter implements ModelAdapter {

  fromJson(json: any): TeamGroup {
    const obj = new TeamGroup();

    Object.assign(obj, json);

    if (json.members) {
      obj.members = new TeamMemberAdapter().fromJsonArray(json.members);
    }

    if (json.skills) {
      obj.skills = new SkillAdapter().fromJsonArray(json.skills);
    }

    if (json.logo) {
      obj.logo = json.logo;
      obj.resizedLogo = json.resizedLogo;
    }

    if(json.lang) {
      obj.lang = json.lang;
    }

    return obj;
  }

  toJson(data: TeamGroup): any {

    return data;
  }
}
