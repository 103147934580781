<div class="lang-container position-relative">
  <div (click)="showLanguageDropdown = !showLanguageDropdown"
       (clickOutside)="showLanguageDropdown = false"
       class="d-flex flex-row align-items-center language pt-1 justify-content-end">

    <img alt="" class="flag-icon" [src]="'../../../assets/images/flag-' + selectedLanguage + '.svg'">
    <i [ngClass]="showLanguageDropdown ? 'icon-chevron-up' : 'icon-chevron-down'" class="gvcv-icon"></i>
  </div>
  <div [@showLanguageDropdown]="showLanguageDropdown ? 'showSidebar' : 'hideSidebar'"
       class="dropdown-language hide">
    <div aria-labelledby="languageDropdown"
         class="d-flex flex-column align-items-baseline">
      <button (click)="changeLanguage('en')"
              [disabled]="selectedLanguage === 'en'"
              class="btn-language mb-3">
        <img alt="" src="../../../assets/images/flag-en.svg">
      </button>
      <button (click)="changeLanguage('de')"
              [disabled]="selectedLanguage === 'de'"
              class="btn-language">
        <img alt="" src="../../../assets/images/flag-de.svg">
      </button>
    </div>
  </div>
</div>
