import { ModelAdapter } from '@api/interfaces/resourceAdapter';
import { Skill, SkillAdapter } from '../skillfeed/skill';
import { Localizations } from '@app/models';

export class CvSkillCategory {
  nameLocalizations: Localizations;
  skills: Skill[];
}

export class CvSkillCategoryAdapter implements ModelAdapter {

  fromJson(json: any): CvSkillCategory {
    const obj = new CvSkillCategory();

    obj.nameLocalizations = json.nameLocalizations;
    if (json.skills) {
      obj.skills = new SkillAdapter().fromJsonArray(json.skills);
    }

    return obj;
  }

  toJson(resource: CvSkillCategory): any {
    return resource;
  }

  fromJsonArray(data: any): CvSkillCategory[] | any {
    if (!data) {
      return [new CvSkillCategory()];
    }
    return data.map(item => this.fromJson(item));
  }
}
