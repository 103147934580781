import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CustomDomain {
  username: string;
  teamDomain: string;
}
export class CustomDomainAdapter implements ModelAdapter {

  fromJson(json: any): CustomDomain {
    const obj = new CustomDomain();

    obj.username = json.username;
    obj.teamDomain = json.teamDomain;

    return obj;
  }

  toJson(data: CustomDomain): any {
    return data;
  }
}
