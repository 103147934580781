<div class="chart-area d-flex justify-content-around"
     [class.default-chart-area]="gravityTheme.theme === 'DEFAULT'"
     [class.theme1-chart-area]="gravityTheme.theme === 'THEME1'">
  <div id="top-hours" class="chart" *ngIf="topDays">
    <div class="skillstats-info indicator-mark bg-warning"
         [ngbPopover]="'SKILLS.SKILL_STATS_INFO' | translate"
         popoverClass="popover-success"
         triggers="mouseenter:mouseleave"
         (click)="gotoSkillStatsDescription()">
      <i class="gvcv-icon icon-info text-white"></i>
    </div>
    <apx-chart
      [series]="chartHoursOptions.series"
      [chart]="chartHoursOptions.chart"
      [labels]="chartHoursOptions.labels"
      [fill]="chartHoursOptions.fill"
      [stroke]="chartHoursOptions.stroke"
      [legend]="chartHoursOptions.legend"
      [title]="chartHoursOptions.title"
      [responsive]="chartHoursOptions.responsive"
    ></apx-chart>
  </div>
  <div id="top-score" class="chart" *ngIf="topScores">
    <apx-chart
      [series]="chartScoreOptions.series"
      [chart]="chartScoreOptions.chart"
      [dataLabels]="chartScoreOptions.dataLabels"
      [plotOptions]="chartScoreOptions.plotOptions"
      [title]="chartScoreOptions.title"
      [legend]="chartScoreOptions.legend"
      [responsive]="chartScoreOptions.responsive"
    ></apx-chart>
  </div>
</div>

<div *ngFor="let item of webCV?.profile?.skills?.categorized; let i = index" class="card p-3 mb-30" [class.no-shadow]="gravityTheme.theme === 'DEFAULT'">
  <div class=" d-flex justify-content-between">
    <div class="category-title">{{item.nameLocalizations[locale] || '' }}</div>
  </div>
  <!-- <hr> -->
  <div *ngIf="item?.skills?.length > 0">
    <div class="badge-wrap flex-row">
      <app-gravity-badge
        *ngFor="let badge of item?.skills | orderBy: ['order']"
        [badgeStyleSelected]="'accent ' + gravityTheme.contrast"
        [badgeStyle]="'dark-grey'"
        [badge]="badge"
        [isSelected]="badge.mainSkill"
        [locale]="locale"
        [showSkills]="true">
      </app-gravity-badge>
    </div>
  </div>
</div>

<div *ngIf="webCV?.profile?.skills?.uncategorized?.length > 0" class="card p-3 mb-30" [class.no-shadow]="gravityTheme.theme === 'DEFAULT'">
  <div class=" d-flex justify-content-between">
    <div class="category-title">{{ 'SKILLS.UNCATEGORIZED' | translate }}</div>
  </div>
  <!-- <hr> -->
  <div *ngIf="webCV?.profile?.skills?.uncategorized?.length > 0">
    <div class="badge-wrap flex-row">
      <app-gravity-badge
        *ngFor="let badge of webCV?.profile?.skills?.uncategorized | orderBy: ['order']"
        [badgeStyleSelected]="'accent ' + gravityTheme.contrast"
        [badgeStyle]="'dark-grey'"
        [badge]="badge"
        [isSelected]="badge.mainSkill"
        [locale]="locale"
        [showSkills]="true">
      </app-gravity-badge>
    </div>
  </div>
</div>

<div *ngIf="!webCV?.profile?.skills?.categorized[0]?.skills?.length && !webCV?.profile?.skills?.uncategorized?.length" class="text-center">
  <h3>{{'SKILLS.EMPTY_SKILLS' | translate}}</h3>
</div>


