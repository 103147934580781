import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import { Localizations } from '../profile/app-language';

export class CvContactInfo {
  phone: string;
  mobilePhone: string;
  bookingLink: string;
  bookingLinkButtonText: Localizations;
  fax: string;
  zip: string;
  country: Localizations;
  city: Localizations;
  publicEmail: string;
  website: string;
  street: string;
}

export class CvContactInfoAdapter implements ModelAdapter {
  fromJson(json: any): CvContactInfo {
    const obj = new CvContactInfo();

    obj.phone = json.phone;
    obj.mobilePhone = json.mobilePhone;
    obj.bookingLink = json.bookingLink;
    obj.bookingLinkButtonText = json.bookingLinkButtonText;
    obj.fax = json.fax;
    obj.zip = json.zip;
    obj.country = json.country;
    obj.city = json.city;
    obj.publicEmail = json.publicEmail;
    obj.website = json.website;
    obj.street = json.street;

    return obj;
  }

  toJson(resource: CvContactInfo): any {
    return resource;
  }
}
