import { BreakpointObserver } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {CvSkillCategory, GravityTheme, SharedCv, Skill} from '@app/models';
import {_t} from '@helpers/string-helpers';

import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexLegend,
  ApexResponsive,
  ChartComponent,
  ApexDataLabels,
  ApexStroke,
  ApexFill,
  ApexTitleSubtitle,
  ApexAxisChartSeries
} from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
export interface ChartOptions {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries | any[];
  chart: ApexChart;
  labels: string[];
  colors: string[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive | ApexResponsive[];
  title: ApexTitleSubtitle;
}

@Component({
  selector: 'app-section-filter',
  templateUrl: './section-filter.component.html',
  styleUrls: ['./section-filter.component.scss']
})
export class SectionFilterComponent implements OnChanges {
  filterCat = new CvSkillCategory();

  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() locale: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private ts: TranslateService
  ) {
    
    this.filterCat.nameLocalizations = {"en": "Filtered", "de": "Gefiltert"};
    this.filterCat.skills = [];
  }

  ngOnInit() {
    var existing = this.getFiltered();
    if(existing){
      this.filterCat = existing;
    }else{
      this.webCV.profile.skills.categorized.push(this.filterCat);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.webCV?.currentValue) {
      this.cdRef.detectChanges();
    }
  }

  deleteBadge(delBageEvent) {
   this.filterCat.skills = this.filterCat.skills.filter(s => s.nameLocalizations[this.locale] != delBageEvent.badge.nameLocalizations[this.locale]);
  }

  filter(skills: Skill[]): Skill[] {
    if(!this.hasFiltered()) return skills;
    return skills.filter(skill => !this.filterCat.skills.map(s => s.nameLocalizations[this.locale]).includes(skill.nameLocalizations[this.locale]))
  }

  get filteredSkills() {
    return this.filter(this.webCV.profile.skills.allSkills());
  }

  hasFiltered(): boolean {
    return this.filterCat.skills.length > 0;
  }

  getFiltered(): CvSkillCategory {
    return this.webCV.profile.skills.categorized.find(c => c.nameLocalizations.en == "Filtered");
  }

  addFilterSkill(skill: Skill) {
    this.getFiltered().skills.push(skill);
  }
}
