import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {CvAvailability, CvAvailabilityAdapter} from './cv-availability';
import {CvSocial, CvSocialAdapter} from './cv-social';
import {CvImages, CvImagesAdapter} from './cv-images';
import {CvLanguage, CvLanguageAdapter} from './cv-language';
import {CvRate, CvRateAdapter} from './cv-rate';
import {CvContactInfo, CvContactInfoAdapter} from './cv-contact-info';
import {CvPersonal, CvPersonalAdapter} from './cv-personal';
import {CvSkillCategorized, CvSkillCategorizedAdapter} from './cv-skill-categorized';
import {CvLocation, CvLocationAdapter} from './cv-location';
import {CvGraduation, CvGraduationAdapter} from './cv-graduation';
import {CvGeneral, CvGeneralAdapter} from '@models/shared-cv/cv-general';
import { Localizations } from '../profile/app-language';

export class CvProfile {
  images: CvImages;
  general: CvGeneral;
  personal: CvPersonal;
  contactInfo: CvContactInfo;
  social: CvSocial;
  availability: CvAvailability;
  bio: Localizations;
  rates: CvRate[];
  languages: CvLanguage[];
  skills: CvSkillCategorized;
  location: CvLocation;
  graduations: CvGraduation[];
  teamBio: {};
  teamName: string;
}

export class CvProfileAdapter implements ModelAdapter {
  fromJson(json: any): CvProfile {
    const lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    const obj = new CvProfile();

    if (json.images) {
      obj.images = new CvImagesAdapter().fromJson(json.images);
    }
    if (json.general) {
      obj.general = new CvGeneralAdapter().fromJson(json.general);
    }
    if (json.personal) {
      obj.personal = new CvPersonalAdapter().fromJson(json.personal);
    }
    if (json.teamName) {
      obj.teamName = json.teamName;
    }
    if (json.teamBio) {
      obj.teamBio = {[`${lang}`]: json.teamBio};
    }
    if (json.contactInfo) {
      obj.contactInfo = new CvContactInfoAdapter().fromJson(json.contactInfo);
    }
    if (json.social) {
      obj.social = new CvSocialAdapter().fromJson(json.social);
    }
    if (json.availability) {
      obj.availability = new CvAvailabilityAdapter().fromJson(json.availability);
    }
    if (json.bio) {
      obj.bio = json.bio;
    }
    if (json.rates) {
      obj.rates = new CvRateAdapter().fromJsonArray(json.rates);
    }
    if (json.languages) {
      obj.languages = new CvLanguageAdapter().fromJsonArray(json.languages);
    }
    if (json.skills) {
      obj.skills = new CvSkillCategorizedAdapter().fromJson(json.skills);
    }
    if (json.location) {
      obj.location = new CvLocationAdapter().fromJson(json.location);
    }
    if (json.graduations) {
      obj.graduations = new CvGraduationAdapter().fromJsonArray(json.graduations);
    }
    return obj;
  }

  toJson(resource: CvProfile): any {
    return resource;
  }
}
