import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UiService} from '@app/services/ui.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {SharedWebCvService} from '@app/services/shared-web-cv.service';
import {ThemeService} from '@app/services/theme.service';
import {GravityTheme} from '@models/web-cv/gravity-theme';
import {SharedCv, SharedCvAdapter} from '@models/shared-cv/shared-cv';
import {ScenarioType} from '@app/models';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  gravityTheme: GravityTheme;
  usernameOrHash: string;
  previewToken: string = null;
  isSendout = false;
  validUntil: Date; // only for sendout

  @Input() webCV: SharedCv;
  private scenario: ScenarioType;

  languageSwitcher: boolean;

  constructor(private ui: UiService,
              private service: SharedWebCvService,
              private themeService: ThemeService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService,
              private activatedRoute: ActivatedRoute,
  ) {
    this.ui.isProcessing = true;
    this.scenario = sessionStorage.getItem('scenario') as ScenarioType;
  }

  ngOnInit(): void {

    if (this.router.url.includes('/s/')) {
      this.isSendout = true;
      this.usernameOrHash = this.route.snapshot.paramMap.get('hash');
    } else {
      switch (this.scenario) {
        case 'userCustomDomain':
          const hash = this.route.snapshot.paramMap.get('usernameOrHash');
          this.usernameOrHash = !!hash ? hash : sessionStorage.getItem('username');
          break;
        case 'userDefault':
          this.usernameOrHash = this.route.snapshot.paramMap.get('usernameOrHash');
          break;
      }
    }

    this.previewToken = this.route.snapshot.queryParamMap.get('token');
    // TODO: Show alert for Preview Mode

    try {
      this.webCV = JSON.parse(this.route.snapshot.paramMap.get('webCV'));
      if(this.webCV == null){
        throw new Error('webCV route param is null')
      }
    } catch (e) {
      console.log(e);
    }

    if (!!this.usernameOrHash && this.isSendout) {
      this.resolveSendOut();
    } else if (!!this.usernameOrHash) {
      this.resolveWebCv();
    } else {
      this.goToNotFound();
    }
  }

  resolveSendOut() {
    this.service.checkSendout(this.usernameOrHash).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.validUntil = response.validUntil;
        this.ui.isProcessing = false;

        if (!!this.webCV) {
          return;
        }

        if (response.pinProtected) {
          this.goToSendoutLogin();
        } else {
          this.getSendOut();
        }

      } else {
        this.goToNotFound();
      }
    });
  }

  resolveWebCv() {
    this.service.checkCV(this.usernameOrHash, this.previewToken).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.ui.isProcessing = false;

        if (!!this.webCV) {
          return;
        }

        if (this.previewToken) {
          this.getPublicCvAsPreview();
        } else {
          if (response.pinProtected) {
            this.goToLogin(response.lang);
          } else {
            this.getPublicCv();
          }
        }
      } else {
        this.goToNotFound();
      }
    });
  }

  getPublicCvAsPreview() {
    this.service.getPublicCV(this.usernameOrHash, this.previewToken).subscribe(responseCV => {
      if (responseCV) {
        if (this.languageSwitchCheck(responseCV)) {
          this.webCV = responseCV;
        }
      }
    });
  }

  getPublicCv() {
    this.service.getPublicCV(this.usernameOrHash).subscribe(responseCV => {
      if (responseCV) {
        if (this.languageSwitchCheck(responseCV)) {
          this.webCV = responseCV;
        }
      }
    });
  }

  getSendOut() {
    this.service.getSendout(this.usernameOrHash).subscribe(responseCV => {
      if (responseCV) {
        if (this.languageSwitchCheck(responseCV)) {
          this.webCV = responseCV;
        }
      }
    });
  }

  goToLogin(lang) {
    this.localizeService.changeLanguage(lang);
    this.router.navigate(
      [this.localizeService.translateRoute('/login'), this.usernameOrHash,
        {gravityTheme: JSON.stringify(this.gravityTheme)}],
      {skipLocationChange: true, queryParamsHandling: 'preserve'},
    )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  goToSendoutLogin() {
    this.router.navigate(
      [this.localizeService.translateRoute('/s/login'), this.usernameOrHash,
        {gravityTheme: JSON.stringify(this.gravityTheme)}],
      {skipLocationChange: true, queryParamsHandling: 'preserve'}
    )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  goToNotFound() {
    this.router
      .navigate(
        [this.localizeService.translateRoute('/404')],
        {skipLocationChange: true}
      )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  languageSwitchCheck(webCv: SharedCv) {
    if (webCv.translations.length > 1) {
      this.languageSwitcher = true;
      return true;
    } else {
      if (!webCv.translations.includes(this.localizeService.parser.currentLang)) {
        const lang = webCv.translations[0];
        const url = new URL(window.location.href);
        window.location.href = '/' + lang + url.pathname.substr(3) + url.search;
        return false;
      }
      this.languageSwitcher = false;
      return true;
    }
  }

}
