<div *ngIf="filteredMembers.length > 0; else noMatrix" class="scrolling-table">
  <table mat-table [dataSource]="skillMatrix" >
    <ng-container matColumnDef="matrix" sticky>
      <th mat-header-cell *matHeaderCellDef style="min-width: 150px;max-width: 150px !important;width: 150px !important;"> </th>
      <td mat-cell *matCellDef="let element" style="min-width: 150px;max-width: 150px !important;width: 150px !important;"> {{element.skill.nameLocalizations[element.locale]}} </td>
    </ng-container>

    <ng-container matColumnDef="spacer">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>

    <ng-container *ngFor="let member of filteredMembers" matColumnDef="{{member.memberId}}">
      <th mat-header-cell *matHeaderCellDef class="memberCol pb-2" style="color: #000;min-width: 150px;max-width: 150px !important;width: 150px !important;">
        <app-avatar
          [avatar]="member.avatar"
          [name]="member.shortName || 'N. N.'"
          [size]="40"
          class="m-2"
          (click)="onViewCV(member.username, member.memberId, member.webCvId)">
        </app-avatar>
        <div class="selected-option-name mt-2">
          {{member.fullName}}
        </div>
        <img src="assets/images/blue-cross.svg" (click)="delete(member.memberId)" class="close-icon" alt="">
      </th>
      <td mat-cell *matCellDef="let element" class="memberCol" style="width: 120px;">
        <div *ngIf="element.hasStars(member); else nostars">
          <span class="stars" *ngFor="let star of element.getStars(member)">
            <img src="assets/images/star-icon.svg" width="18" height="18" class="gvcv-icon"/>
          </span>
        </div>
        <ng-template #nostars>
          <div *ngIf="element.hasSkill(member)">
            <img src="assets/images/green-mark.svg" width="20" height="20" class="gvcv-icon"/>
          </div>
        </ng-template>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<ng-template #noMatrix>
  <span>{{'TEAM.MAIN.SKILL_MATRIX_EMPTY'| translate}}</span>
</ng-template>
