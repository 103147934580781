import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {environment} from '@env/environment';
import {appVariables} from '@app/app.config';
import {TeamMember, TeamMemberAdapter} from './team-member';
import {TeamGroup, TeamGroupAdapter} from './team-group';

export class TeamSendout {

  logo: string;
  domain: string;
  companyName: string;
  shortDescription: string;
  email: string;
  bio: string;
  website: string;
  phone: string;
  members: TeamMember[];
  group: TeamGroup;

}

export class TeamSendoutAdapter implements ModelAdapter {

  fromJson(json: any): TeamSendout {
    const obj = new TeamSendout();

    Object.assign(obj, json);

    if (json.teamGroup) {
      obj.group = new TeamGroupAdapter().fromJson(json.teamGroup); // Sendout of a team group
    } else if (json.webCvs) {
      obj.members = new TeamMemberAdapter().fromJsonArray(json.webCvs); // Sendout for SINGLE or MULTIPLE Cvs
    }

    if (json.logo) {
      obj.logo = json.logo;
    }

    return obj;
  }

  toJson(data: TeamSendout): any {

    return data;
  }
}
