import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class CvGraduation {
  educationLevel: string;
  instituteName: string;
  major: string;
}

export class CvGraduationAdapter implements ModelAdapter {
  fromJson(json: any): CvGraduation {

    const obj = new CvGraduation();

    obj.educationLevel = json.educationLevel;
    obj.instituteName = json.instituteName;
    obj.major = json.major;

    return obj;
  }

  toJson(resource: CvGraduation): any {
    return resource;
  }

  fromJsonArray(data: any): CvGraduation[] {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }
}
