import {environment} from '@env/environment';

export class ReferenceFeedback {

  pin: number;
  feedback: string;
  contactAllowed: boolean;
  position: string;
  agbVersion: string;
  dataProtectionVersion: string;
}

export class ReferenceFeedbackAdapter {
  toJson(resource: ReferenceFeedback): any {

    resource.agbVersion = environment.agbVersion;
    resource.dataProtectionVersion = environment.dataProtectionVersion;

    return resource;
  }

}
