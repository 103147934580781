import {Project, ProjectAdapter} from '@models/skillfeed/project';

export class ReferenceLoginRequest {
  pin: number;
}

export class ReferenceLoginRequestAdapter {
  fromJson(json: any): ReferenceLoginRequest {
    return json;
  }

  toJson(resource: ReferenceLoginRequest): any {
    if (resource.pin) {
      resource.pin = Number(resource.pin);
    }
    return resource;
  }
}

export class ReferenceLoginResponse {
  userFullName: string;
  contactFullName: string;
  formLetter: string;
  project: Project;
}

export class ReferenceLoginResponseAdapter {
  fromJson(json: any): ReferenceLoginResponse {
    const obj = new ReferenceLoginResponse();
    obj.userFullName = json.userFullName;
    obj.contactFullName = json.contactFullName;
    obj.formLetter = json.formLetter;
    if (json.project) {
      obj.project = new ProjectAdapter().fromJson(json.project);
    }
    return obj;
  }

  toJson(resource: ReferenceLoginResponse): any {
    return resource;
  }
}
