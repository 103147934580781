import {Component, Input, Output, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { UiService } from '@app/services/ui.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {_t} from '@helpers/string-helpers';
import {AbstractForm} from '@helpers/abstract.form';
import {TeamMember, Skill} from '@app/models';

@Component({
  selector: 'app-skill-search-area',
  templateUrl: './skill-search-area.component.html',
  styleUrls: ['./skill-search-area.component.scss']
})
export class SkillSearchAreaComponent extends AbstractForm{

  @Input() locale: string;
  @Input() allSkills: Skill[];
  @Output() skillsAdded: EventEmitter<Skill[]> = new EventEmitter<Skill[]>();
  model = {};

  fields: FormlyFieldConfig[];

  constructor(protected ts: TranslateService,
              protected ui: UiService) {
    super(ts, ui);
  }

  ngOnInit() {
    this.model['skills'] = [];
    this.fields = [
      {
        wrappers: ['badge-wrapper'],
        // templateOptions: {
        //   title: this.tr(_t('SKILLS.ADD_SKILLS_COMPONENT.SKILL_SEARCH_TITLE')),
        //   subtitle: this.tr(_t('SKILLS.ADD_SKILLS_COMPONENT.SKILL_SEARCH_SUBTITLE')),
        // },
        fieldGroup: [
          {
            key: 'skills',
            wrappers: ['form-field'],
            type: 'input-drag-autocomplete',
            templateOptions: {
              onSelect:() =>  this.submit(),
              locale: () => this.locale,
              isSelectable: false,
              isStarsEditable: true,
              allSkills: () => this.allSkills,
              isDeletable: true,
              isSelected: 'isMainProjectSkill',
              placeholder: this.tr(_t('SKILLS.ADD_SKILLS_COMPONENT.SKILL_PLACEHOLDER')),
            },
          },
        ],
      }
    ];
  }

  submit() {
    this.skillsAdded.emit(this.model['skills']);
  }
}
