import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {_t} from '@helpers/string-helpers';

export let orderItemEnum = {
  profile: {value: 'profile', label: _t('VISIBILITY.LIST_PFOFILE'), icon: 'profile'},
  skills: {value: 'skills', label: _t('VISIBILITY.LIST_SKILLS'), icon: 'skillz'},
  project: {value: 'project', label: _t('VISIBILITY.LIST_PROJECT'), icon: 'projects'},
  qualification: {value: 'qualification', label: _t('VISIBILITY.LIST_QUALIFICATION'), icon: 'qualifications'},
  rate: {value: 'rate', label: _t('VISIBILITY.LIST_CARD'), icon: 'rate-card'},
};

export interface CvOrderItem {
  value: string;
  label: string;
  icon: string;
}

export class CvOrder {
  active: CvOrderItem[] = [];
  inactive: CvOrderItem[] = [];

  constructor() {
    this.active = CvOrder.getDefaultActiveArray();
    this.inactive = CvOrder.getDefaultInactiveArray();
  }

  static getDefaultOrderFields(): CvOrder {
    const order: CvOrder = new CvOrder();

    order.active = this.getDefaultActiveArray();
    order.inactive = this.getDefaultInactiveArray();

    return order;
  }

  static getDefaultActiveArray(): CvOrderItem[] {
    const activeArray: CvOrderItem[] = [];

    const item1 = orderItemEnum.profile as CvOrderItem;
    const item2 = orderItemEnum.skills as CvOrderItem;
    const item3 = orderItemEnum.project as CvOrderItem;
    const item4 = orderItemEnum.qualification as CvOrderItem;
    const item5 = orderItemEnum.rate as CvOrderItem;

    activeArray.push(item1);
    activeArray.push(item2);
    activeArray.push(item3);
    activeArray.push(item4);
    activeArray.push(item5);

    return activeArray;
  }

  static getDefaultInactiveArray(): CvOrderItem[] {
    return [];
  }
}

export class OrderAdapter implements ModelAdapter {

  fromJson(json: any): CvOrder {

    if (json == null) {
      return new CvOrder();
    }

    for (const item of json.active) {
      item.label = orderItemEnum[item.value].label as CvOrderItem;
      item.icon = orderItemEnum[item.value].icon as CvOrderItem;
    }

    for (const item of json.inactive) {
      item.label = orderItemEnum[item.value].label as CvOrderItem;
      item.icon = orderItemEnum[item.value].icon as CvOrderItem;
    }

    return json;
  }

  toJson(data: CvOrder): any {

    for (const item of data.active) {
      item.label = undefined;
      item.icon = undefined;
    }

    for (const item of data.inactive) {
      item.label = undefined;
      item.icon = undefined;
    }

    return data;
  }
}
