import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {CvSkillCategory, CvSkillCategoryAdapter} from './cv-skill-category';
import {Skill, SkillAdapter} from '../skillfeed/skill';

export class CvSkillCategorized {
  categorized: CvSkillCategory[];
  uncategorized: Skill[];
  allSkills(): Skill[] {
    let allCat: Skill[] = [];
    this.categorized.forEach(cat =>{
      allCat = allCat.concat(cat.skills);
    });
    return this.uncategorized.concat(allCat);
  }
}

export class CvSkillCategorizedAdapter implements ModelAdapter {
  fromJson(json: any): CvSkillCategorized {
    const obj = new CvSkillCategorized();

    if (json.categorized) {
      obj.categorized = new CvSkillCategoryAdapter().fromJsonArray(json.categorized);
    }
    if (json.uncategorized) {
      obj.uncategorized = new SkillAdapter().fromJsonArray(json.uncategorized);
    }

    return obj;
  }

  toJson(resource: CvSkillCategorized): any {
    return resource;
  }
}
