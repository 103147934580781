
<div class="card p-3 mb-30 no-shadow skills-filtered-wapper">
    <app-form-skills-new
        [locale]="locale"
        [allSkills]="filteredSkills"
        (skillAdded)="addFilterSkill($event)">
    </app-form-skills-new>
</div>

<div class="card p-3 mb-30 filtered-value" [class.no-shadow]="gravityTheme.theme === 'DEFAULT'">
  <div class=" d-flex justify-content-between">
    <div class="category-title">{{'WEB_CV.LABEL.HIGHLIGHT_SKILL' | translate}}</div>
  </div>
  <hr>
  <div *ngIf="getFiltered()?.skills?.length > 0">
    <div class="badge-wrap flex-row">
      <app-gravity-badge
        *ngFor="let badge of getFiltered().skills | orderBy: ['order']"
        [badgeStyleSelected]="'accent ' + gravityTheme.contrast"
        [badgeStyle]="'dark-grey'"
        [badge]="badge"
        [isSelected]="badge.mainSkill"
        [isDeletable]="true"
        (deleteItem)="deleteBadge($event)"
        [locale]="locale">
      </app-gravity-badge>
    </div>
  </div>
</div>