import {ResourceAdapter} from '@api/interfaces/resourceAdapter';
import {Contact, ContactAdapter} from '../profile/contact';
import {Project, ProjectAdapter} from '../skillfeed/project';

export enum ReferenceStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING'
}

export class Reference {

  static LIST_NAME = '';

  id: number;
  userId: number;
  projectId: number;
  contactId: number;
  uniqueHash: string;
  pin: number;
  confirmed: string | Date;
  dateSent: string | Date;
  feedback: string;
  contactAllowed: boolean;
  emailStatus: boolean;
  visibleInCv: boolean;
  status: ReferenceStatus;
  created: string | Date;
  updated: string | Date;
  project: Project;
  contact: Contact;
  formLetter: string;
}

export class ReferenceAdapter implements ResourceAdapter {
  fromJson(json: any): Reference {

    const obj = new Reference();

    obj.id = json.id;
    obj.projectId = json.projectId;
    obj.contactId = json.contactId;
    obj.uniqueHash = json.uniqueHash;
    obj.pin = json.pin;
    obj.confirmed = json.confirmed;
    obj.dateSent = json.dateSent;
    obj.feedback = json.feedback;
    obj.contactAllowed = json.contactAllowed;
    obj.emailStatus = json.emailStatus;
    obj.visibleInCv = json.visibleInCv;
    obj.status = json.status;
    obj.created = json.created;
    obj.updated = json.updated;
    obj.project = json.project ? new ProjectAdapter().fromJson(json.project) : null;
    obj.contact = json.contact ? new ContactAdapter().fromJson(json.contact) : null;

    return obj;
  }

  toJson(resource: Project): any {
    return resource;
  }

  fromJsonArray(data: any): Reference[] | any {
    if (!data) {
      return [new Reference()];
    }
    return data.map(item => this.fromJson(item));
  }
}
