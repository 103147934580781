import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {SalutationEnum} from '@models/profile/salutation';
import {HelperService} from '@helpers/helper.service';
import {GravityDate} from '@app/models';

export class UserPersonal {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  profession?: string;
  dateOfBirth?: string | Date | GravityDate;
  cityOfBirth?: string;
  salutation?: SalutationEnum;
  academicTitle?: string;
}

export class UserPersonalAdapter implements ModelAdapter {

  fromJson(json: any): UserPersonal {
    const obj = new UserPersonal();

    obj.firstName = json.firstName;
    obj.lastName = json.lastName;
    obj.profession = json.profession;
    obj.cityOfBirth = json.cityOfBirth;
    obj.salutation = json.salutation;
    obj.academicTitle = json.academicTitle;
    obj.dateOfBirth = json.dateOfBirth;

    if (typeof json.dateOfBirth === 'string') {
      obj.dateOfBirth = HelperService.string2Date(json.dateOfBirth);
    }

    obj.fullName = `${json.firstName || ''} ${json.lastName || ''}`.trim();

    return obj;
  }

  toJson(data: UserPersonal): any {

    if (data.dateOfBirth && typeof data.dateOfBirth !== 'string') {
      data.dateOfBirth = HelperService.toUTCDate(data.dateOfBirth);
    }

    return data;
  }
}
