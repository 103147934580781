

<div class="comman-tabbing cv-tabs" *ngIf="webCV?.order?.active?.length > 0">
  <div class="tabbing-content">
    <mat-tab-group (tabChange)="tabChange($event)">
      {{webCV?.order?.active?.length}}

      <ng-container  *ngFor="let section of webCV?.order?.active; trackBy: trackByOrder">
      <mat-tab label="{{ 'WEB_CV.TAB.PROFILE' | translate }}" *ngIf="section.value ==  'profile' && isTabActive('profile')">
          <div class="profile-wrapper">
              <div class="profile-info-1" *ngIf="hasSidebar">
                  <app-section-sidebar
                      [gravityTheme]="gravityTheme"
                      [usernameOrHash]="usernameOrHash"
                      [webCV]="webCV"

                      [isSendout]="isSendout"
                      [teamWebCvUniqueHash]="teamWebCvUniqueHash"
                      [teamDomain]="teamDomain"
                      [sendoutHash]="sendoutHash"
                      [groupUrl]="groupUrl"
                      [locale]="locale">
                  </app-section-sidebar>
              </div>
              <div class="profile-info-2 mb-30" *ngIf="webCV?.profile?.bio?.de || webCV?.profile?.bio?.en">
                  <app-section-bio
                      [gravityTheme]="gravityTheme"
                      [webCV]="webCV"
                      *ngIf="webCV?.order?.active?.length">
                  </app-section-bio>
              </div>

          </div>
        <div class="profile-wrapper" *ngIf="webCV?.importantProjects?.length">
          <div class="profile-info-2">
            <div class="bio-card desc-bio bg-white">
              <div class=" bg-white">
                <h4>{{'WEB_CV.LABEL.MOST_IMPORTANT_PROJECTS' | translate}}</h4>
                <button class="btn btn-outline-secondary me-2 mb-2" (click)="openModal(projectDetails, project)" *ngFor="let project of webCV.importantProjects">{{project.project.title[lang]}}</button>
              </div>
            </div>
            <ng-template #projectDetails let-activeModal>
              <div class="modal-header">
                <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
                  <span aria-hidden="true"><i class="gvcv-icon icon-close"></i></span>
                </button>
              </div>
              <div class="modal-body comman-tabbing">
                <app-section-project class="projects-wrapper"
                                     [importantProject]="[selectedProject]"
                                     [gravityTheme]="gravityTheme"
                                     [webCV]="webCV">
                </app-section-project>
              </div>

              <div class="modal-footer flex-wrap justify-content-center">
                <button (click)="activeModal.dismiss('Cancel button click')"
                        class="btn btn-link btn-link-dark"
                        ngbAutofocus
                        type="button">
                  {{ 'MODAL.QUIT' | translate }}
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'WEB_CV.TAB.SKILLS' | translate }}" *ngIf="section.value ==  'skills' && isTabActive('skills')">
          <div class="skills-wrapper">
              <app-skill-tabs
                  [gravityTheme]="gravityTheme"
                  [webCV]="webCV"
                  [locale]="locale">
              </app-skill-tabs>
          </div>
      </mat-tab>
      <mat-tab label="{{ 'WEB_CV.TAB.PROJECTS' | translate }}" *ngIf="section.value ==  'project' && isTabActive('project')">
          <div class="projects-wrapper">
              <app-section-project
                  [gravityTheme]="gravityTheme"
                  [webCV]="webCV">
              </app-section-project>
          </div>
      </mat-tab>
      <mat-tab label="{{ 'WEB_CV.TAB.QUALIFICATIONS' | translate }}" *ngIf="section.value ==  'qualification' && isTabActive('qualification')">
          <div class="qualifications-wrapper projects-wrapper">
              <app-section-qualifications
                  [gravityTheme]="gravityTheme"
                  [webCV]="webCV">
              </app-section-qualifications>
          </div>
      </mat-tab>
      <mat-tab label="{{ 'WEB_CV.TAB.RATE_CARDS' | translate }}" *ngIf="section.value ==  'rate' && isTabActive('rate')">
          <div class="rate-card-wrapper">
              <app-section-rate
                  [gravityTheme]="gravityTheme"
                  [webCV]="webCV">
              </app-section-rate>
          </div>
      </mat-tab>
      </ng-container>

    </mat-tab-group>
  </div>
</div>


<!-- <div *ngIf="webCV?.order?.active?.length > 0" class="cv-tabs">
  <ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)">
    <ng-container *ngFor="let section of webCV?.order?.active; trackBy: trackByOrder">
      <ngb-tab *ngIf="section.value == 'profile'" id="profile">
        <ng-template ngbTabTitle>
          <div class="text-uppercase d-inline-flex">
            <i class="gvcv-icon icon-profile me-2"></i>
            {{ 'WEB_CV.TAB.PROFILE' | translate }}
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <div [@fadeInOut] class="row g-0">
            <div class="{{hasSidebar ? 'col-xl-8 col-lg-8 col-xs-12' : 'col-12'}}">
              <div class="cv-section bg-white mt-4">
                <app-section-bio
                  [gravityTheme]="gravityTheme"
                  [webCV]="webCV">
                </app-section-bio>
              </div>
            </div>
            <div *ngIf="hasSidebar" class="col-xl-4 col-lg-4 col-xs-12 ps-lg-3">
              <app-section-sidebar
                [gravityTheme]="gravityTheme"
                [usernameOrHash]="usernameOrHash"
                [webCV]="webCV"
                [isSendout]="isSendout"
                [teamWebCvUniqueHash]="teamWebCvUniqueHash"
                [teamDomain]="teamDomain"
                [sendoutHash]="sendoutHash"
                [groupUrl]="groupUrl">
              </app-section-sidebar>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="section.value == 'project'" id="projects">
        <ng-template ngbTabTitle>
          <div class="text-uppercase d-inline-flex">
            <i class="gvcv-icon icon-projects me-2"></i>
            {{ 'WEB_CV.TAB.PROJECTS' | translate }}
            <app-glow-dot *ngIf="glowShow" class="ms-2"></app-glow-dot>
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <div [@fadeInOut] class="cv-section p-0 mt-4">
            <app-section-project
              [gravityTheme]="gravityTheme"
              [webCV]="webCV">
            </app-section-project>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="section.value == 'skills'" id="skills">
        <ng-template ngbTabTitle>
          <div class="text-uppercase d-inline-flex">
            <i class="gvcv-icon icon-skillz me-2"></i>
            {{ 'WEB_CV.TAB.SKILLS' | translate }}
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <div [@fadeInOut] class="cv-section p-0 mt-4">
            <app-skill-tabs
              [gravityTheme]="gravityTheme"
              [webCV]="webCV"
              [locale]="locale">
            </app-skill-tabs>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="section.value == 'qualification'" id="qualifications">
        <ng-template ngbTabTitle>
          <div class="text-uppercase d-inline-flex">
            <i class="gvcv-icon icon-qualifications me-2"></i>
            {{ 'WEB_CV.TAB.QUALIFICATIONS' | translate }}
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <div [@fadeInOut] class="cv-section p-0 mt-4">
            <app-section-qualifications
              [gravityTheme]="gravityTheme"
              [webCV]="webCV">
            </app-section-qualifications>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="section.value == 'rate' && webCV?.profile?.rates" id="rates">
        <ng-template ngbTabTitle>
          <div class="text-uppercase d-inline-flex">
            <i class="gvcv-icon icon-rate-card me-2"></i>
            {{ 'WEB_CV.TAB.RATE_CARDS' | translate }}
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <div [@fadeInOut] class="cv-section p-0 mt-4">
            <app-section-rate
              [gravityTheme]="gravityTheme"
              [webCV]="webCV">
            </app-section-rate>
          </div>
        </ng-template>
      </ngb-tab>
    </ng-container>
  </ngb-tabset>
</div> -->
