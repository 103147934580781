import {Injectable} from '@angular/core';
import {appVariables} from '@app/app.config';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {JwtHelperService} from '@helpers/jwt-helper-service';
import {JwtObject} from '@models/auth/jwt-object';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private localizeService: LocalizeRouterService,
              private jwtService: JwtHelperService,
              private httpClient: HttpClient,
              private router: Router) {
  }

  static login(token: string) {
    localStorage.setItem(appVariables.accessTokenLocalStorage, token);
  }

  static isUserLoggedIn(): boolean {
    const token = localStorage.getItem(appVariables.accessTokenLocalStorage);
    return !!(token && token.length > 0);
  }

  getToken = (): string => localStorage.getItem(appVariables.accessTokenLocalStorage);

  goToNotFoundPage(message?: string) {
    localStorage.clear();
    sessionStorage.clear();
    this.router
      .navigate(
        [this.localizeService.translateRoute('/404')],
        {skipLocationChange: true}
      );
  }

  goToExpiredPage(message?: string) {
    localStorage.clear();
    sessionStorage.clear();
    this.router
      .navigate(
        [this.localizeService.translateRoute('/expired')],
        {skipLocationChange: true}
      );
  }

  getDecodedToken(): JwtObject {
    const token = this.getToken();
    return this.jwtService.decodeToken(token);
  }

  getUserIdFromToken(): number {
    const token = this.getToken();
    const decoded = this.jwtService.decodeToken(token);
    return decoded.userid;
  }

  getUsernameFromToken(): string {
    const token = this.getToken();
    const decoded = this.jwtService.decodeToken(token);
    return decoded.sub;
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    return this.jwtService.isTokenExpired(token);
  }

  getTokenExpirationDate() {
    const token = this.getToken();
    this.jwtService.getTokenExpirationDate(token);
  }

  protected handleError(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      console.log(`${operation} failed: ${error.message}`);
      return of(result);
    };
  }
}
