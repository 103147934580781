import {Component, OnInit} from '@angular/core';
import {Company, Project, ReferenceFeedback} from '@app/models';

@Component({
  selector: 'app-reference-thanks',
  templateUrl: './reference-thanks.component.html',
  styleUrls: ['./reference-thanks.component.scss']
})
export class ReferenceThanksComponent implements OnInit {

  model: ReferenceFeedback;

  constructor() {
  }

  ngOnInit() {
  }

}
