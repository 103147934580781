<section class="reference-form-page">
  <nb-layout>
    <nb-layout-column>

      <nb-layout-header class="gravity-header" fixed>
        <app-gravity-logo class="mx-auto"></app-gravity-logo>
      </nb-layout-header>

      <section class="reference-container">

        <div *ngIf="referenceRequest" class="form-section">
          <h3 class="h1-title">
            {{'REFERENCE.GREETING'| translate:{value: referenceRequest?.contactFullName} }}
          </h3>
          <h5 class="copy-big-subtitle">
            {{'REFERENCE.SENDER_TEXT'| translate: {value: referenceRequest?.userFullName} }}
          </h5>

          <div class="card mt-4 mb-5 mx-n3">
            <div class="card-body pt-3 px-4 pb-4">
              <div class="d-flex">
                <!-- fixme add avatar if exists after MVP -->
                <app-avatar
                  [name]="referenceRequest?.userFullName"
                ></app-avatar>
                <div class="letter-title ps-3">
                  {{'REFERENCE.LETTER_TITLE' | translate: {value: referenceRequest?.userFullName} }}
                </div>
              </div>
              <div [innerHTML]="referenceRequest?.formLetter" class="letter-content ps-7"></div>
              <div class="letter-content ps-7" *ngIf="!referenceRequest?.formLetter">{{'REFERENCE.EMPTY_FORMLETTER' | translate }}</div>
            </div>

            <div class="card-footer bg-primary pt-3 px-4 pb-4">
              <div class="info-label">
                <app-project-dates [project]="referenceRequest?.project"></app-project-dates>
              </div>
              <div class="project-title text-white mb-3">
                <ng-conrainer *ngIf="referenceRequest?.project?.title">
                  {{referenceRequest?.project?.title[lang] || '' }}
                </ng-conrainer>
              </div>
              <div class="info-label">
                <i class="gvcv-icon icon-client"></i>
                <div class="info-label-text">{{ 'PROJECT.CLIENT' | translate }}</div>
              </div>
              <div class="info-text ps-3 mb-3"> {{referenceRequest?.project?.company?.name || ''  }}</div>
              <div class="info-label">
                <i class="gvcv-icon icon-personal-data"></i>
                <div class="info-label-text">{{ 'PROJECT.ROLE' | translate }}</div>
              </div>
              <div class="info-text ps-3">
                <ng-container *ngIf="referenceRequest?.project?.role">
                  {{referenceRequest?.project?.role[lang] || ''  }}
                </ng-container>

              </div>
            </div>
          </div>
          <div class="card mt-4 mb-5 mx-n3">
            <div class="card-body pt-3 px-4 pb-4">
          <h3 class="h1-title">
            {{'REFERENCE.FEEDBACK_TITLE'| translate}}
          </h3>
          <h5 class="copy-big-subtitle mb-4">
            {{'REFERENCE.FEEDBACK_SUBTITLE'| translate}}
          </h5>

          <form (submit)="submit()" [formGroup]="form" *ngIf="model">
            <formly-form
              [fields]="fields"
              [form]="form"
              [model]="model"
              [options]="options">
            </formly-form>

            <div class="text-end mt-6">
              <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                {{'REFERENCE.SEND'| translate}}
              </button>
            </div>
          </form>
</div></div>
        </div>
      </section>
    </nb-layout-column>

    <nb-layout-footer>
      <app-web-cv-footer class="footer"></app-web-cv-footer>
    </nb-layout-footer>

  </nb-layout>
</section>
