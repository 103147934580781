import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderByPipe} from '@helpers/pipes/order-by.pipe';
import {FormlySelectOptionsPipe} from './formly-select-options.pipe';
import { LanguageLevelPipe } from './language-level.pipe';
import { FilterArrayPipe } from './filter-array.pipe';

@NgModule({
  declarations: [
    OrderByPipe,
    FormlySelectOptionsPipe,
    LanguageLevelPipe,
    FilterArrayPipe
  ],
  exports: [
    OrderByPipe,
    FormlySelectOptionsPipe,
    LanguageLevelPipe,
    FilterArrayPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
