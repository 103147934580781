import {ResourceAdapter} from '@api/interfaces/resourceAdapter';

export enum LogoPosition {
  TOP = 'TOP',
  SIDEBAR = 'SIDEBAR'
}

export class CvSettings {

  static LIST_NAME = '';

  id: number;
  userId?: number;
  themeColor: string;
  themeLayout: string;
  logo: string;
  logoPosition: LogoPosition;
  linkLogo: boolean; // TODO: create form for this
  created?: string | Date;
  updated?: string | Date;
}

export class CvSettingsAdapter implements ResourceAdapter {

  fromJson(json: any): CvSettings {

    const obj: CvSettings = new CvSettings();

    obj.id = json.id;
    obj.userId = json.userId;
    obj.themeColor = json.themeColor;
    obj.themeLayout = json.themeLayout;
    obj.logo = json.logo;
    obj.logoPosition = json.logoPosition;
    obj.linkLogo = json.linkLogo;
    obj.created = json.created;
    obj.updated = json.updated;

    return obj;
  }

  toJson(resource: CvSettings): any {
    return resource;
  }
}



