import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { Skill } from '@app/models/skillfeed/skill';
import { sk, th } from 'date-fns/locale';


@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  animations: [
    trigger('showDropdown', [
      state('visible', style({
        maxHeight: '{{dropDownMaxHeight}}',
        opacity: 1,
        visibility: 'visible'
      }), { params: { dropDownMaxHeight: '9rem' } }),
      state('hidden', style({ maxHeight: 0, opacity: 0, visibility: 'hidden' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
    ]),
  ]
})
export class InputAutocompleteComponent extends FieldType implements OnChanges, OnDestroy {

  @ViewChild('popover', { static: true }) popover;
  @ViewChild('textInput') public textInput;
  @ViewChildren('dropdownItem') public dropdownItem: QueryList<ElementRef>;

  existedSkill: string;
  showDropdown = false;
  textInputControl: UntypedFormControl;

  inputValue: string;
  private onDestroy$ = new Subject<void>();

  constructor() {
    super();
  }

  // Input section
  get disabled() {
    return this.to.disabled || false;
  }

  // Dropdown section
  get identifyBy() {
    return this.to.identifyBy || 'id';
  }

  // Dropdown section
  get displayBy() {
    return this.to.displayBy || 'name';
  }

  // Dropdown section
  get maxHeight(): string {
    return this.to.maxHeight || '12rem';
  }

  get skillNames(){
    let skills = this.to.allSkills();
    if(skills){
      return skills.map(s => s.nameLocalizations[this.to.locale()]);
    }
    return null;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && !changes.disabled.firstChange) {
      if (changes.disabled.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  onTextChange($event){
    this.to.setSkill(this.skillFromName(this.inputValue));
  }

  // Input section
  onFocus() {
    this.showDropdown = true;
  }

  onKeyDown($event) {
    this.showDropdown = true;
    if ($event.key === 'Enter') {
      let value = this.inputValue;
      let skill = this.skillFromName(value);
      if(skill){
        this.to.setSkill(skill);
        this.showDropdown = false;
      } else {
        this.setValueDelayed(value);
      }
    }
  }

  onItemKeydown($event: KeyboardEvent, item: string) {
    if ($event.key === 'Enter' && this.showDropdown) {
      this.onSkillSelect(item);
      this.showDropdown = false;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent($event: KeyboardEvent) {
    if (!this.dropdownItem) {
      return;
    }

    let focused = this.dropdownItem.first;
    let index = -1;

    const getIndex = (idx, direction) => {
      let elementIdx;
      if (idx === 0) {
        elementIdx = idx + direction;
        if (elementIdx === -1) {
          elementIdx = 0;
        }
      } else {
        elementIdx = idx + direction;
        if (elementIdx >= this.dropdownItem.length) {
          elementIdx = (this.dropdownItem.length === 1) ? 0 : this.dropdownItem.length - 1;
        }
        if (elementIdx <= 0) {
          elementIdx = 0;
        }
      }

      return (item, i: number) => i === elementIdx;
    };
    if (this.showDropdown && this.to.allSkills().length > 0) {
      this.dropdownItem.map((el, i) => {
        if (el.nativeElement.classList.contains('is-focused')) {
          focused = el;
          index = i;
        }
        return el;
      });

      switch ($event.key) {
        case 'ArrowDown':
          focused.nativeElement.classList.remove('is-focused');
          focused.nativeElement.blur();
          const next = this.dropdownItem.find(getIndex(index, 1));
          next.nativeElement.classList.add('is-focused');
          next.nativeElement.focus();
          $event.preventDefault();
          break;
        case 'ArrowUp':
          focused.nativeElement.classList.remove('is-focused');
          focused.nativeElement.blur();
          const prev = this.dropdownItem.find(getIndex(index, -1));
          prev.nativeElement.classList.add('is-focused');
          prev.nativeElement.focus();
          $event.preventDefault();
          break;
      }

    }
  }

  // Input section
  onClickOutside() {
    this.showDropdown = false;
  }

  // Dropdown component
  onSkillSelect(value: string) {
    this.setValueDelayed(value);
    this.to.setSkill(this.skillFromName(value));
  }

  skillFromName(name: string): Skill {
    return this.to.allSkills().find(s => s.nameLocalizations[this.to.locale()] === name);
  }

  setValueDelayed(value) {
    let self = this;
    setTimeout(function() {
      self.inputValue = value;
    }, 20);
  }
}
