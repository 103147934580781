import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {CvProject, CVProjectAdapter} from './cv-project';
import {CvProfile, CvProfileAdapter} from './cv-profile';
import {CvControls, CvControlsAdapter} from './cv-controls';
import {CvSkillUp, CvSkillUpAdapter} from './cv-skill-up';
import {CvOrder, OrderAdapter} from './cv-order';
import { SkillStatistics, SkillStatisticsAdapter, SkillStatisticsArray } from './cv-skillstats';

export class SharedCv {
  appLanguage: string;
  profile: CvProfile;
  importantProjects: CvProject[];
  projects: CvProject[];
  skillUps: CvSkillUp[];
  controls: CvControls;
  topDays: SkillStatisticsArray;
  topScores: SkillStatisticsArray;
  order: CvOrder;
  teamWebCvUniqueHash: string;
  translations: string[];
}

export class SharedCvAdapter implements ModelAdapter {

  removeEmpty(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null || obj[key].length === 0) {
        delete obj[key];
      }
    });
    return obj;
  }

  fromJson(json: any, userId?: number): any {
    const sharedCv = new SharedCv();
    if (json.appLanguage) {
      sharedCv.appLanguage = json.appLanguage;
    }
    if (json.profile) {
      if(json.teamProfile) {
        json.profile.teamName = json.teamProfile.teamName;
        json.profile.teamBio = json.teamProfile.teamBio;
      }
      sharedCv.profile = new CvProfileAdapter().fromJson(json.profile);
    }
    if (json.projects) {
      sharedCv.projects = new CVProjectAdapter().fromJsonArray(json.projects);
    }
    if (json.importantProjects) {
      sharedCv.importantProjects = new CVProjectAdapter().fromJsonArray(json.importantProjects);
    }
    if (json.skillUps) {
      sharedCv.skillUps = new CvSkillUpAdapter().fromJsonArray(json.skillUps);
    }
    if (json.controls) {
      sharedCv.controls = new CvControlsAdapter().fromJson(json.controls);
    }
    if (json.order) {
      sharedCv.order = new OrderAdapter().fromJson(json.order);
    }

    if (json.topDays) {
      sharedCv.topDays = new SkillStatisticsAdapter().fromJsonArray(json.topDays);
    }
    if (json.topScores) {
      sharedCv.topScores = new SkillStatisticsAdapter().fromJsonArray(json.topScores);
    }

    if (json.translations) {
      sharedCv.translations = json.translations;
      // this only for new user
      if(!json.translations.length) {
        sharedCv.translations = ['en', 'de'];
      }
    }
    if (json.teamWebCvUniqueHash) {
      sharedCv.teamWebCvUniqueHash = json.teamWebCvUniqueHash;
    }

    return this.removeEmpty(sharedCv);
  }

  fromJsonArray(data: any): SharedCv[] | any {
    if (!data) {
      return [];
    }
    return data.map(item => this.fromJson(item));
  }

  toJson(resource: any): any {
    return resource;
  }
}
