
<!--===================== New design code start here =========================-->
<div class="row" *ngIf="webCV.profile?.rates?.length > 0; else noRates">
  <!-- <div class="col-md-6 col-lg-4 col-xxl-3 mb-30" *ngFor="let option of webCV.profile.rates">
      <div class="card no-shadow">
          <h2>{{option?.amount}},</h2>
          <p>{{option?.currency | translate}} - {{option?.type | translate}}</p>
      </div>
  </div> -->

   <!-- If Selected theme = 2 then this card show -->
  <div class="col-xl-12 col-xxl-6 mb-30" *ngFor="let option of webCV.profile.rates">
    <div class="card no-shadow">
        <h2>{{option?.amount}},-</h2>
        <p>{{option?.currency | translate}} / {{option?.type | translate}}</p>
    </div>
  </div>

  <!-- <div class="col-xl-3 mb-30" >
      <div class="card no-shadow">
          <h2>700,</h2>
          <p>EUR - Monthly</p>
      </div>
  </div>
  <div class="col-xl-3 mb-30" >
      <div class="card no-shadow">
          <h2>1500,</h2>
          <p>EUR - Monthly</p>
      </div>
  </div>
  <div class="col-xl-3 mb-30" >
      <div class="card no-shadow">
          <h2>2500,</h2>
          <p>EUR - Monthly</p>
      </div>
  </div> -->
</div>
<ng-template #noRates>
<div #noRates class="text-center">
  <h3>{{'RATE_CARDS.EMPTY_RATES' | translate}}</h3>
</div>
</ng-template>
<!--===================== New design code End here =========================-->


<!-- <div *ngIf="webCV.profile?.rates?.length > 0; else noRates" class="cards-wrapper d-flex flex-wrap">
  <div *ngFor="let option of webCV.profile.rates" class="rate-card-item me-3 mb-3">
    <div class="inner-wrapper px-4 py-3">
      <p class="info mb-2">{{option?.where || '&nbsp;'}}</p>
      <span class="rate-card-amount accent-basic mb-1">
        {{ option?.amount }},-
        <span class="rate-card-type" [innerHTML]="option?.currencyText">
          {{ option?.currencyText }}
        </span>
      </span>
    </div>
  </div>
</div> -->

<!-- <ng-template #noRates>
  <div class="cards-wrapper d-flex flex-wrap pb-4">
    {{'RATE_CARDS.EMPTY_RATES' | translate}}
  </div>
</ng-template> -->
