
import {ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {GravityTheme, PinLoginRequest, ScenarioType, Skill, TeamMember} from '@app/models';
import {UiService} from '@app/services/ui.service';
import {ThemeService} from '@app/services/theme.service';
import {TeamService} from '@app/services/team.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {TeamGroup} from '@models/team/team-group';
import {SelectCVEvent} from '@helpers/events/SelectCVEvent';
import {ViewerAuthService} from '@app/auth/viewer-auth.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '@app/components/modal-dialog/modal-config';
import { ModalDialogComponent } from '@app/components/modal-dialog/modal-dialog.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { List } from 'immutable';

@Component({
  selector: 'app-team-group-details',
  templateUrl: './team-group-details.component.html',
  styleUrls: ['./team-group-details.component.scss'],
})
export class TeamGroupDetailsComponent implements OnInit {

  @Input() teamGroup: TeamGroup;
  teamDomain: string;
  groupUrl: string;
  previewToken: string = null;
  gravityTheme: GravityTheme;
  private scenario: ScenarioType;
  filter: Skill[] = [];
  members : any[] = [];
  filterMember: any;
  selected: any = null;
  skills: any;
  selectedAvaibilityDate: any;
  resetFilteredArrayOnDate: any;
  skillmatrix = false
  initialMembers: any;
  options= false;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  // filteredMembers: List<TeamMember> = List.of();
  // filteredSkills: List<Skill> = List.of();

  constructor(protected ts: TranslateService,
              private ui: UiService,
              private themeService: ThemeService,
              private viewerService: ViewerAuthService,
              private teamService: TeamService,
              private route: ActivatedRoute,
              private router: Router,
              private localizeService: LocalizeRouterService,
              public datepipe: DatePipe,
              private modalService: NgbModal,
              @Inject(DOCUMENT) private document: Document) {
    this.scenario = sessionStorage.getItem('scenario') as ScenarioType;
  }

  ngOnInit() {

    this.teamDomain = this.route.snapshot.paramMap.get('teamDomain');
    this.groupUrl = this.route.snapshot.paramMap.get('groupUrl');
    this.previewToken = this.route.snapshot.queryParamMap.get('token');

    try {
      this.teamGroup = JSON.parse(this.route.snapshot.paramMap.get('teamGroup'));
      this.setFilteredValues();
    } catch (e) {
      console.log(e);
    }

    switch (this.scenario) {
      case 'teamCustomDomain':
        this.teamDomain = sessionStorage.getItem('teamDomain');
        break;
      case 'teamSubdomain':
        const url = new URL(window.location.href);
        const regexPattern = /^([a-zA-Z0-9\-]+)\.(.+)\.(.+)/g;
        const match = regexPattern.exec(url.host);
        this.teamDomain = match[1];
        break;
    }

    if (!!this.teamDomain && !!this.groupUrl) {
      this.checkRouting();
    } else {
      this.routeNotFoundPage();
    }
  }


  viewCv($event: SelectCVEvent) {
    const url = this.localizeService.translateRoute(`/group/${this.groupUrl}/${$event.username}`);

    this.router.navigate([url], { state: { companyName: this.teamGroup.companyName, groupName: this.teamGroup.name }});
  }

  viewWebCV(username, memberId, webCvId){
    const url = this.localizeService.translateRoute(`/group/${this.groupUrl}/${username}`);
    this.router.navigate([url], { state: { companyName: this.teamGroup.companyName, groupName: this.teamGroup.name }});
  }

  checkRouting() {
    this.teamService.checkPublicGroupPage(this.teamDomain, this.groupUrl, this.previewToken).subscribe(response => {
      if (!!response) {
        this.gravityTheme = response;
        this.themeService.setCssVariables(this.gravityTheme);
        this.ui.isProcessing = false;

        // e.g. if component is loaded after successful login
        if (!!this.teamGroup) {
          return;
        }

        if (!response.pinProtected || !!this.previewToken) {
          this.resolveGroupPage();
        } else {
          if (this.viewerService.isViewerLoggedInForTeamGroups(this.groupUrl)) {
            this.resolveGroupPageWithCredentialsFromStorage();
          } else {
            this.routePinProtectionPage();
          }
        }
      } else {
        this.routeNotFoundPage();
      }
    });
  }

  resolveGroupPageWithCredentialsFromStorage() {
    const pinLoginRequest = new PinLoginRequest();
    pinLoginRequest.email = this.viewerService.getTeamGroupAccessEmail();
    pinLoginRequest.pin = this.viewerService.getTeamGroupAccessPin();

    this.teamService.loginPublicGroupPage(this.teamDomain, this.groupUrl, pinLoginRequest).subscribe(response => {
      if (response) {
        this.teamGroup = response;
        this.initalizeCandidate()
        this.filterMember = this.teamGroup.members;
        // this.candidates = this.teamGroup.members;
        this.setFilteredValues();
      }
    });
  }

  resolveGroupPage() {
    this.teamService.getPublicGroupPage(this.teamDomain, this.groupUrl, this.previewToken).subscribe(response => {
      if (response) {
        this.teamGroup = response;
        this.initalizeCandidate()
        this.filterMember = this.teamGroup.members;
        // this.candidates = this.teamGroup.members;
        this.setFilteredValues();
      }
    });
  }

    imageChage(avalibilitycolor:any){
      switch(avalibilitycolor){
        case 'bg-success':return "assets/images/green-right-icon.png"; break;
        case 'bg-warning':return "assets/images/yellow-warning-icon.png"; break;
        case 'bg-danger':return "assets/images/red-info-icon.png"; break;
      }
    }

  redirectToContact(email:any){
      var emailstring = "mailto:"+email;
      window.location.href = emailstring;
  }

  setFilteredValues():void{
    // this.filteredMembers = this.calcFilteredMembers();
    // this.filteredSkills = this.calcFilteredSkills();
  }

  routePinProtectionPage() {
    this.router.navigate(
      [this.localizeService.translateRoute(`/group/${this.groupUrl}/login`)],
      {skipLocationChange: true, queryParamsHandling: 'preserve'}
    )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  routeNotFoundPage() {
    this.router
      .navigate(
        [this.localizeService.translateRoute('/404')],
        {skipLocationChange: true}
      )
      .finally(() => {
        this.ui.isProcessing = false;
      });
  }

  //remove user specific fields from each skill, filter duplicates
  strip(skills: Skill[]): Skill[] {
    if(!skills) return undefined;
    let strippedSkills: Skill[] = [];
    skills.forEach(skill => {
      if(!strippedSkills.map( e => e.nameLocalizations[this.teamGroup.lang]).includes(skill.nameLocalizations[this.teamGroup.lang])){
        let strippedSkill = new Skill();
        strippedSkill.nameLocalizations = skill.nameLocalizations;
        strippedSkills.push(strippedSkill);
      }
    });
    return strippedSkills;
  }

  get filteredMembers(): TeamMember[] {
    return this.teamGroup.members.filter(member => {
      let fullfillsFilters = true;
      this.filter.forEach(skill => {
        let memberSkill = member.skills.find(s => s.nameLocalizations[this.teamGroup.lang] == skill.nameLocalizations[this.teamGroup.lang]);
        if(!memberSkill || memberSkill.stars < skill.stars){
          fullfillsFilters = false;
        }
      });
      return fullfillsFilters;
    }).sort((a,b) => this.sumMemberSkills(b) - this.sumMemberSkills(a)
    ).slice(0,6);
  }


  get filteredSkills(): Skill[] {
    let skills: Skill[] = [];
    this.filteredMembers.forEach(member => {
      //if a skill is filtered it should appear on top of skills that are not filtered
      member.skills.sort((a,b) => b.stars+(this.inFilter(b)?5:0)-a.stars-(this.inFilter(a)?5:0)
      ).forEach(skill => {
        if(!skills.map( e => e.nameLocalizations[this.teamGroup.lang]).includes(skill.nameLocalizations[this.teamGroup.lang])){
          let strippedSkill = new Skill();
          strippedSkill.nameLocalizations = skill.nameLocalizations;
          skills.push(strippedSkill);
        }
      });
    });
    return skills;
  }


  addSkills(skills) {
    this.filter = JSON.parse(JSON.stringify(skills));
    this.skills = skills
    this.members = []
     let memberArray : any[]= [];
    if(this.skills.length > 0){
      this.skills.forEach((x,index) => {
        let skillMatchedMemberArray : any
        skillMatchedMemberArray = this.teamGroup.members.filter(um=>um.skills.find(us=>us.nameLocalizations.en == x.nameLocalizations.en));
        skillMatchedMemberArray.forEach(b => {
          var filterArray = memberArray.filter(a=>a.memberId == b.memberId)
          if(filterArray && filterArray.length == 0){
            memberArray.push(b)
          }
        })
      })
      if(this.selectedAvaibilityDate){
        memberArray = memberArray.filter(x => this.changeDateFormate(x.availabilityDate) ==  this.selectedAvaibilityDate)
      }
      this.filteredSkills.length == 0 ? this.filterMember = [] : this.filterMember = memberArray
      this.resetFilteredArrayOnDate = this.filterMember
    }
    else{
      this.filterMember = this.teamGroup.members
      this.resetFilteredArrayOnDate = this.filterMember
    }
    this.selected = null;
    // this.filteredMembers = this.calcFilteredMembers();
    // this.filteredSkills = this.calcFilteredSkills();
  }




  inFilter(skill:Skill): boolean {
    return this.filter.map(s =>s.nameLocalizations[this.teamGroup.lang]).includes(skill.nameLocalizations[this.teamGroup.lang]);
  }

  sumMemberSkills(member: TeamMember): number {
    return member.skills.filter(s => this.inFilter(s) || this.filter.length == 0).map(s => s.stars).reduce((a,b)=>a+b, 0);
  }

  onChange(event: any){
    // this.selected = event.target.value;
    // event.preventDefault();
    if(this.selected != null){
      let memberArray = this.filterMember.find(x => x.memberId == this.selected)
      this.members.push(memberArray)
      if(this.members.length > 0){
        this.filterMember = this.filterMember.filter(x => x.memberId != this.selected)
        this.filterMember = [...this.filterMember]
      }
      if(this.filterMember.length == 0){
        this.options = false
        this.ngSelectComponent.blur();
      }
      else{
        this.options = true
      }
    }
    this.selected = null;
    this.ngSelectComponent.handleClearClick();
    // this.ngSelectComponent.blur();
    // this.ngSelectComponent.open()
    // this.options = true
    // this.selected = "0";
  }
  selectAll(){
    this.filterMember.forEach(x => {
      this.members.push(x)
    })
    this.filterMember = []
    this.selected = null;
    this.filterMember = [...this.filterMember]
  }
  deleteAll(event: any){
    event.preventDefault();
    this.members.forEach(x => {
      this.filterMember.push(x)
    })
    this.members = [];
    this.selected = null;
    this.filterMember = [...this.filterMember]
  }
  deleteMember(event){
    this.members = this.members.filter(x => x.memberId != event.memberId)
    let findMember = this.teamGroup.members.find(x => x.memberId == event.memberId)
    this.filterMember.push(findMember)
    this.filterMember = [...this.filterMember]
    this.selected = null;
  }
  onChangeAvaibility(event){
    if(this.skills && this.skills.length > 0){
      this.filterMember = this.resetFilteredArrayOnDate.filter(x => this.changeDateFormate(x.availabilityDate) <=  this.changeDateFormate(event))
    }
    else{
      this.filterMember = this.teamGroup.members.filter(x => this.changeDateFormate(x.availabilityDate) <=  this.changeDateFormate(event))
    }
    this.filterMember = [...this.filterMember]
    this.selected = null;
  }
  changeDateFormate(date){
    let dateNew = this.datepipe.transform(date, 'yyyy-MM-dd');
    return dateNew
  }
  resetDatePicker(){
    this.selectedAvaibilityDate = null
    if(this.skills &&this.skills.length > 0){
      this.addSkills(this.skills)
    }
    else{
      this.filterMember = this.teamGroup.members
    }
  }
  initalizeCandidate(){
    if(this.teamGroup?.members.length > 8){
      this.initialMembers = this.teamGroup?.members.slice(0,8)
    }
    else{
      this.initialMembers = this.teamGroup?.members
    }
  }

  showMore(){
    let newLength = this.initialMembers?.length + 4;
    if (newLength > this.teamGroup?.members.length) {
        newLength = this.teamGroup?.members.length
    }
    this.initialMembers = this.teamGroup?.members.slice(0, newLength);
  }
  onTabChanged(event){
    const bodyTag = document.body;
    if(event.index == 0){
      this.skillmatrix = false
      bodyTag.classList.remove('fix-sidebar');
    }
    if(event.index == 1){
      this.skillmatrix = true
      bodyTag.classList.add('fix-sidebar');

    }
  }
  openContactDialog() {
    const config = new ModalConfig();

    config.title = 'Contact information';
    config.type = 'custom';
    // config.message = '';

    config.phoneNumber = this.teamGroup.phone;
    config.email = this.teamGroup.email;
    config.link = this.teamGroup.website;
    // config.value = title;

    const modalRef = this.modalService.open(ModalDialogComponent, {size: 'sm'});
    modalRef.componentInstance.config = config;
  }

  calcFilteredMembers(): List<TeamMember> {
    console.log("Members: " + this.teamGroup.members);
    return List.of(...this.teamGroup.members.filter(member => {
      let fullfillsFilters = true;
      this.filter.forEach(skill => {
        let memberSkill = member.skills.find(s => s.nameLocalizations[this.teamGroup.lang] == skill.nameLocalizations[this.teamGroup.lang]);
        if(!memberSkill || memberSkill.stars < skill.stars){
          fullfillsFilters = false;
        }
      });
      return fullfillsFilters;
    }).sort((a,b) => this.sumMemberSkills(b) - this.sumMemberSkills(a)
    ).slice(0,6));
  }

  calcFilteredSkills(): List<Skill> {
    let skills: Skill[] = [];
    this.filteredMembers.forEach(member => {
      //if a skill is filtered it should appear on top of skills that are not filtered
      member.skills.sort((a,b) => b.stars+(this.inFilter(b)?5:0)-a.stars-(this.inFilter(a)?5:0)
      ).forEach(skill => {
        if(!skills.map( e => e.nameLocalizations[this.teamGroup.lang]).includes(skill.nameLocalizations[this.teamGroup.lang])){
          let strippedSkill = new Skill();
          strippedSkill.nameLocalizations = skill.nameLocalizations;
          skills.push(strippedSkill);
        }
      });
    });
    return List.of(...skills);
  }



}

