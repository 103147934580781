import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
  animations: [
    trigger('showLanguageDropdown', [
      state('showSidebar', style({transform: 'translate3d(0, 65px, 0)', opacity: 1, visibility: 'visible'})),
      state('hideSidebar', style({transform: 'translate3d(0, 30px, 0)', opacity: 0, visibility: 'hidden'})),
      transition('* => *', [animate('300ms ease-in')])
    ]),
  ]
})
export class LangSwitcherComponent implements OnInit {

  @Input() selectedLanguage: string;
  @Output() langChanged: EventEmitter<string> = new EventEmitter<string>();

  showLanguageDropdown = false;


  constructor() { }

  ngOnInit() {
  }

  changeLanguage(lang: string) {
    this.langChanged.emit(lang);
  }

}
