import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {_t} from '@helpers/string-helpers';
import { Localizations } from '../profile/app-language'

export enum EmploymentMode {
  FREELANCE = 'FREELANCE',
  EMPLOYED = 'EMPLOYED',
  INTERNSHIP = 'INTERNSHIP',
  EXECUTIVE_DIRECTOR = 'EXECUTIVE_DIRECTOR'
}

export class CvGeneral {
  profession: Localizations;
  employmentMode: EmploymentMode;
  employmentModeText: string;
}

export class CvGeneralAdapter implements ModelAdapter {
  fromJson(json: any): CvGeneral {
    const obj = new CvGeneral();

    obj.profession = json.profession;
    obj.employmentMode = json.employmentMode;

    switch (json.employmentMode) {
      case EmploymentMode.FREELANCE:
        obj.employmentModeText = _t('EMPLOYMENT.FREELANCE');
        break;
      case EmploymentMode.EMPLOYED:
        obj.employmentModeText = _t('EMPLOYMENT.EMPLOYED');
        break;
      case EmploymentMode.INTERNSHIP:
        obj.employmentModeText = _t('EMPLOYMENT.INTERNSHIP');
        break;
      case EmploymentMode.EXECUTIVE_DIRECTOR:
        obj.employmentModeText = _t('EMPLOYMENT.EXECUTIVE_DIRECTOR');
        break;
    }

    return obj;
  }

  toJson(resource: CvGeneral): any {
    return resource;
  }
}
