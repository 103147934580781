import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {GrantedAuthority} from '@models/profile/granted-authority';
import {AppLanguageEnum} from '@models/profile/app-language';

export class User {

  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  applicationLanguage?: AppLanguageEnum;
  authorities?: Array<GrantedAuthority>;
  confirmedAt?: Date;
  created?: Date;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  id?: number;
  registrationIp?: string;
  roles?: Array<string>;
  unconfirmedEmail?: string;
  updated?: Date;
  username?: string;

}

export class UserAdapter implements ModelAdapter {

  fromJson(json: any): User {

    const user = new User();

    user.accountNonExpired = json.accountNonExpired;
    user.accountNonLocked = json.accountNonLocked;
    user.applicationLanguage = json.applicationLanguage;
    user.authorities = json.authorities;
    user.confirmedAt = json.confirmedAt;
    user.created = json.created;
    user.credentialsNonExpired = json.credentialsNonExpired;
    user.email = json.email;
    user.enabled = json.enabled;
    user.id = json.userid;
    user.registrationIp = json.registrationIp;
    user.roles = json.roles;
    user.unconfirmedEmail = json.unconfirmedEmail;
    user.updated = json.updated;
    user.username = json.username;

    return user;
  }

  toJson(user: User): any {
    return {
      accountNonExpired: user.accountNonExpired,
      accountNonLocked: user.accountNonLocked,
      applicationLanguage: user.applicationLanguage,
      authorities: user.authorities,
      confirmedAt: user.confirmedAt,
      created: user.created,
      credentialsNonExpired: user.credentialsNonExpired,
      email: user.email,
      enabled: user.enabled,
      id: user.id,
      registrationIp: user.registrationIp,
      roles: user.roles,
      unconfirmedEmail: user.unconfirmedEmail,
      updated: user.updated,
      username: user.username,
    };
  }
}
