import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbActiveModal, NgbCollapseModule, NgbDatepickerModule, NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {AvatarModule} from 'ngx-avatars';
import {PreloaderComponent} from './preloader/preloader.component';
import {GravityToastrComponent} from './gravity-toastr/gravity-toastr.component';
import {AvatarComponent} from './avatar/avatar.component';
import {TranslateModule} from '@ngx-translate/core';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {FormlyModule} from '@ngx-formly/core';
import {RepeatableComponent} from './formly/fields/repeatable/repeatable.component';
import {CustomSelectComponent} from './formly/fields/custom-select/custom-select.component';
import {DropdownSelectComponent} from './formly/fields/dropdown-select/dropdown-select.component';
import {BadgeComponent} from './badge/badge.component';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';
import {GravityLogoComponent} from './gravity-logo/gravity-logo.component';
import {NbDatepickerModule, NbLayoutModule, NbSelectModule} from '@nebular/theme';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {NebularSelectComponent} from './formly/fields/nebular-select/nebular-select.component';
import {ButtonFieldComponent} from './formly/buttons/button-field/button-field.component';
import {AddonWrapperComponent} from './formly/wrappers/addon-wrapper/addon-wrapper.component';
import {FieldWrapperComponent} from './formly/wrappers/field-wrapper/field-wrapper.component';
import {FieldHorizontalComponent} from './formly/wrappers/field-horizontal/field-horizontal.component';
import {CustomRadioComponent} from './formly/fields/custom-radio/custom-radio.component';
import {PipesModule} from '@helpers/pipes/pipes.module';
import {WebCvFooterComponent} from './web-cv-footer/web-cv-footer.component';
import {PictureComponent} from './picture/picture.component';
import {QuillModule} from 'ngx-quill';
import {EditorComponent} from './formly/editor/editor.component';
import {ProjectDatesComponent} from './project-dates/project-dates.component';
import {CustomCheckboxComponent} from './formly/fields/custom-checkbox/custom-checkbox.component';
import {FileModalComponent} from '@components/file-modal/file-modal.component';
import { GlowDotComponent } from './glow-dot/glow-dot.component';
import { LangSwitcherComponent } from './lang-switcher/lang-switcher.component';
import { ClickOutsideModule } from 'ng-click-outside';
import {BadgeWrapperComponent} from './formly/wrappers/badge-wrapper/badge-wrapper.component';
import {InputDragAutocompleteComponent} from './formly/fields/input-drag-autocomplete/input-drag-autocomplete.component';
import { InputAutocompleteComponent } from './formly/fields/input-autocomplete/input-autocomplete.component';
import { FormSkillsNewComponent } from '@app/components/form-skills-new/form-skills-new.component';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
    declarations: [
        PreloaderComponent,
        GravityToastrComponent,
        AvatarComponent,
        BadgeComponent,
        BadgeWrapperComponent,
        InputDragAutocompleteComponent,
        InputAutocompleteComponent,
        FormSkillsNewComponent,
        RepeatableComponent,
        CustomSelectComponent,
        DropdownSelectComponent,
        ModalDialogComponent,
        FileModalComponent,
        GravityLogoComponent,
        NebularSelectComponent,
        ButtonFieldComponent,
        AddonWrapperComponent,
        FieldWrapperComponent,
        FieldHorizontalComponent,
        CustomRadioComponent,
        WebCvFooterComponent,
        PictureComponent,
        EditorComponent,
        ProjectDatesComponent,
        CustomCheckboxComponent,
        GlowDotComponent,
        LangSwitcherComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AvatarModule,
        NgbCollapseModule,
        TranslateModule,
        LocalizeRouterModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        FormlyModule,
        NbSelectModule,
        NgbModule,
        NbDatepickerModule,
        NbDateFnsDateModule,
        PipesModule,
        NbLayoutModule,
        ClickOutsideModule,
        QuillModule.forRoot(),
        ClipboardModule,
        NgbTooltipModule

    ],
    exports: [
        PreloaderComponent,
        AvatarComponent,
        BadgeComponent,
        BadgeWrapperComponent,
        ModalDialogComponent,
        FileModalComponent,
        GravityLogoComponent,
        WebCvFooterComponent,
        PictureComponent,
        EditorComponent,
        ProjectDatesComponent,
        CustomCheckboxComponent,
        GlowDotComponent,
        FormSkillsNewComponent,
        LangSwitcherComponent
    ],
    providers: [
        NgbActiveModal
    ]
})
export class ComponentsModule {
}
