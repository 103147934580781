<ng-container *ngIf="!importantProject?.length">
<div
  class="search-field-wrapper card no-shadow p-3 mb-30"
  *ngIf="theme === 'DEFAULT'"
>
  <form [formGroup]="form">
    <formly-form
      (modelChange)="submit()"
      [fields]="fieldsSearch"
      [form]="form"
      [model]="model"
      [options]="options"
    >
    </formly-form>
  </form>
</div>
<div class="theme-one-search">
  <div
    class="search-field-wrapper card no-shadow mb-30"
    *ngIf="
      (theme === 'THEME1' && searchVisible) ||
      (theme === 'THEME2' && searchVisible)
    "
  >
    <div class="back-btn" (click)="closeProject()">
      <img src="assets/images/back-arrow.svg" alt="" />
    </div>
    <form [formGroup]="form">
      <formly-form
        (modelChange)="submit()"
        [fields]="fieldsSearch"
        [form]="form"
        [model]="model"
        [options]="options"
      >
      </formly-form>
    </form>
  </div>
</div>
</ng-container>

<div [class.mb-3]="theme === 'DEFAULT'">
  <ng-container *ngIf="theme === 'DEFAULT'">
    <div class="filtered-project-list">
      <div
        *ngFor="let item of filteredProjectList; let i = index"
        class="card no-shadow mb-30 filtered-card"
      >
        <div class="avail-dates">
          <div class="dates-tag" *ngIf="item?.project?.dateFrom || item?.project?.dateTo">
            <div class="calender-icon">
              <img
                src="assets/images/calender-white-icon.svg"
                alt="calender-icon"
              />
            </div>
            <p>
              <app-project-dates [project]="item?.project"></app-project-dates>
            </p>
          </div>
          <div class="project-title">
            {{ item?.project?.title[lang] || "" }}
          </div>
        </div>
        <hr *ngIf="item?.company || item?.project?.role[lang] || item?.project?.teamSize"/>
        <div class="projects-bio" *ngIf="item?.company || item?.project?.role[lang] || item?.project?.teamSize">
          <div *ngIf="item?.company?.name || item?.company?.industrialSector" class="project-detailed">
            <h4>{{ "PROJECT.CUSTOMER" | translate }}</h4>
            <span>{{item?.company?.name || item?.company?.industrialSector | translate}}</span>
          </div>
          <div *ngIf="item?.project?.role[lang]" class="project-detailed">
            <h4>{{ "PROJECT.ROLE" | translate }}</h4>
            <span>{{ item?.project?.role[lang] }}</span>
          </div>
          <div *ngIf="item?.project?.teamSize" class="project-detailed">
            <h4>{{ "PROJECT.TEAM" | translate }}</h4>
            <span>{{ item?.project?.teamSize }}</span>
          </div>
          <div *ngIf="item?.project?.fileName" class="project-detailed">
            <h4>{{ "PROJECT.ATTACHMENT" | translate }}</h4>
            <a class="btn-tag btn-accent button-link" href="#" (click)="openFileModal($event, item?.project.fileName)">
              <img class="button-link__img" [ngStyle]="{'filter': gravityTheme.contrast === 'light' ? 'invert(100%)' : 'none'}"
                src="assets/icons/link.svg" alt="Link image" />
              <span class="button-link__text"
                    [ngClass]="{'button-link__text--light': gravityTheme.contrast === 'light'}"
                    *ngIf="item?.project?.localizations[lang]?.projectLinkButtonText; else backupText">
                {{ item?.project?.localizations[lang]?.projectLinkButtonText }}
              </span>
            </a>
          </div>
        </div>
        <hr *ngIf="item?.skills?.length > 0" />
        <div class="skills-bio" *ngIf="item?.skills?.length > 0">
          <div class="bio-title">
            <h4>{{ "PROJECT.SKILLS" | translate }}</h4>
          </div>
          <div class="badge-wrapper">
            <app-gravity-badge
              *ngFor="let badge of item?.skills | orderBy : ['skillOrder']"
              [badgeStyleSelected]="
                'accent ' + gravityTheme.contrast
              "
              [badgeStyle]="'dark-grey'"
              [badge]="badge.skill"
              [isPresentMode]="true"
              [isFeatured]="badge.skill.mainSkill"
              [locale]="lang"
              [showSkills]="true"
            >
            </app-gravity-badge>
          </div>
        </div>
        <hr *ngIf="item?.project?.description[lang]" />
        <div class="description-bio" *ngIf="item?.project?.description[lang]">
          <div class="bio-title">
            <h4>{{ "PROJECT.DESCRIPTION" | translate }}</h4>
          </div>
          <div class="desc-bio-content">
            <div
              [innerHTML]="item?.project?.description[lang]"
              class="flex-row align-items-center description-text ql-editor"
            ></div>
          </div>
        </div>
        <section
          *ngIf="item?.references?.length > 0"
          class="row g-0 reference-feedback-section bg-accent"
        >
          <div class="col-xl-10 offset-xl-2">
            <div class="d-flex flex-wrap flex-md-nowrap">
              <div class="quote-area pe-3 pb-3">
                <img
                  alt="project quote"
                  class="quote-icon"
                  src="../../../../../assets/images/quote.svg"
                />
              </div>
              <div class="quote-text-area">
                <div
                  *ngFor="let reference of item.references"
                  class="reference-item"
                >
                  <div
                    [innerHTML]="reference.feedback"
                    class="reference-quote bg-accent {{
                        gravityTheme.contrast
                      }}"
                  ></div>
                  <div *ngIf="reference.contactAllowed">
                    <div
                      class="reference-contact bg-accent {{
                          gravityTheme.contrast
                        }}"
                    >
                      {{ reference.contact?.fullName }}
                    </div>
                    <div
                      class="reference-company bg-accent {{
                          gravityTheme.contrast
                        }}"
                    >
                      {{
                      reference.contact?.position &&
                      reference.contact?.company?.name
                        ? reference.contact?.position + " - "
                        : ""
                      }}{{ reference.contact?.company?.name }}
                    </div>
                  </div>
                  <div *ngIf="!reference.contactAllowed">
                    <div
                      class="reference-contact bg-accent {{
                          gravityTheme.contrast
                        }}"
                    >
                      {{ "PROJECT.ANONYMOUS_REFERENCE" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="theme === 'THEME1'">
    <div
      *ngFor="
        let item of filteredProjectList | slice : startLength : endLength;
        let i = index
      "
      [class.mb-3]="theme === 'DEFAULT'"
    >
      <div class="theme-one-project bt-border">
        <div class="info-text-primary" *ngIf="theme === 'THEME1'">
          <app-project-dates [project]="item?.project"></app-project-dates>
        </div>
        <div class="detail-content-wrapper">
          <div class="project-title">
            {{ item?.project?.title[lang] || "" }}
          </div>
          <div
            class="description-wrapper"
            *ngIf="item?.project?.description[lang]"
          >
            <div
              [innerHTML]="item?.project?.description[lang]"
              class="flex-row align-items-center description-text"
            ></div>
          </div>
          <div class="short-details">
            <div
              class="row mb-3"
              *ngIf="
                item?.company ||
                item?.project?.role[lang] ||
                item?.project?.teamSize
              "
            >
              <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.CUSTOMER" | translate }}
                  </div>
                </div>
                <div class="info-text">
                  {{
                    item?.company?.name ||
                      item?.company?.industrialSector ||
                      "-" | translate
                  }}
                </div>
              </div>

              <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.ROLE" | translate }}
                  </div>
                </div>
                <div class="info-text">
                  {{ item?.project?.role[lang] || "-" }}
                </div>
              </div>

              <div class="col-xl-2 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.TEAM" | translate }}
                  </div>
                </div>
                <div class="info-text">
                  {{ item?.project?.teamSize || "-" }}
                </div>
              </div>

              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.ATTACHMENT" | translate }}
                  </div>
                </div>
                <div *ngIf="!isImage(item?.project.fileName)" class="info-text">
                  <a
                    *ngIf="item?.project.fileName"
                    href="#"
                    (click)="openFileModal($event, item?.project.fileName)"
                    >{{ "PROJECT.SHOW_ATTACHMENT" | translate }}</a
                  >
                </div>
                <div
                  class="info-text"
                  (click)="openFileModal($event, item?.project.fileName)"
                  style="cursor: pointer; word-break: break-word"
                >
                  {{ item?.project.fileName }}
                </div>
              </div>
            </div>
          </div>
          <div class="skill-bio-wrapper" *ngIf="item?.skills?.length > 0">
            <div class="info-label">
              <div class="info-label-text">
                {{ "PROJECT.SKILLS" | translate }}
              </div>
            </div>
            <div class="badge-wrap flex-row">
              <app-gravity-badge
                *ngFor="let badge of item?.skills | orderBy : ['skillOrder']"
                [badgeStyleSelected]="
                  'accent ' + gravityTheme.contrast
                "
                [badgeStyle]="'dark-grey'"
                [badge]="badge.skill"
                [isPresentMode]="true"
                [isFeatured]="badge.skill.mainSkill"
                [locale]="lang"
                [showSkills]="true"
              >
              </app-gravity-badge>
            </div>
          </div>
          <section
            *ngIf="item?.references?.length > 0"
            class="row g-0 reference-feedback-section bg-accent"
          >
            <div class="col-xl-10 offset-xl-2">
              <div class="d-flex flex-wrap flex-md-nowrap">
                <div class="quote-area pe-3 pb-3">
                  <img
                    alt="project quote"
                    class="quote-icon"
                    src="../../../../../assets/images/quote.svg"
                  />
                </div>
                <div class="quote-text-area">
                  <div
                    *ngFor="let reference of item.references"
                    class="reference-item"
                  >
                    <div
                      [innerHTML]="reference.feedback"
                      class="reference-quote bg-accent {{
                        gravityTheme.contrast
                      }}"
                    ></div>
                    <div *ngIf="reference.contactAllowed">
                      <div
                        class="reference-contact bg-accent {{
                          gravityTheme.contrast
                        }}"
                      >
                        {{ reference.contact?.fullName }}
                      </div>
                      <div
                        class="reference-company bg-accent {{
                          gravityTheme.contrast
                        }}"
                      >
                        {{
                          reference.contact?.position &&
                          reference.contact?.company?.name
                            ? reference.contact?.position + " - "
                            : ""
                        }}{{ reference.contact?.company?.name }}
                      </div>
                    </div>
                    <div *ngIf="!reference.contactAllowed">
                      <div
                        class="reference-contact bg-accent {{
                          gravityTheme.contrast
                        }}"
                      >
                        {{ "PROJECT.ANONYMOUS_REFERENCE" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="theme === 'THEME2'">
    <div
      *ngFor="
        let item of filteredProjectList | slice : startLength : endLength;
        let i = index
      "
      class="cv-qualifications"
      [class.mb-3]="theme === 'DEFAULT'"
    >
      <div class="theme-one-project">
        <div class="detail-content-wrapper">
          <div class="project-title">
            {{ item?.project?.title[lang] || "" }}
          </div>
          <div class="info-text-primary" *ngIf="theme === 'THEME2'">
            <app-project-dates [project]="item?.project"></app-project-dates>
          </div>
          <div
            class="description-wrapper"
            *ngIf="item?.project?.description[lang]"
          >
            <div
              [innerHTML]="item?.project?.description[lang]"
              class="flex-row align-items-center description-text"
            ></div>
          </div>
          <div class="short-details">
            <div
              class="row mb-3"
              *ngIf="
                item?.company ||
                item?.project?.role[lang] ||
                item?.project?.teamSize
              "
            >
              <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.CUSTOMER" | translate }}
                  </div>
                </div>
                <div class="info-text">
                  {{
                    item?.company?.name ||
                      item?.company?.industrialSector ||
                      "-" | translate
                  }}
                </div>
              </div>

              <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.ROLE" | translate }}
                  </div>
                </div>
                <div class="info-text">
                  {{ item?.project?.role[lang] || "-" }}
                </div>
              </div>

              <div class="col-xl-2 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.TEAM" | translate }}
                  </div>
                </div>
                <div class="info-text">
                  {{ item?.project?.teamSize || "-" }}
                </div>
              </div>

              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <div class="info-label">
                  <div class="info-label-text">
                    {{ "PROJECT.ATTACHMENT" | translate }}
                  </div>
                </div>
                <div *ngIf="!isImage(item?.project.fileName)" class="info-text">
                  <a
                    *ngIf="item?.project.fileName"
                    href="#"
                    (click)="openFileModal($event, item?.project.fileName)"
                    >{{ "PROJECT.SHOW_ATTACHMENT" | translate }}</a
                  >
                </div>
                <div
                  class="info-text"
                  (click)="openFileModal($event, item?.project.fileName)"
                  style="cursor: pointer; word-break: break-word"
                >
                  {{ item?.project.fileName }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="skill-bio-wrapper skill-badge-theme2"
            *ngIf="item?.skills?.length > 0"
          >
            <div class="info-label">
              <div class="info-label-text">
                {{ "PROJECT.SKILLS" | translate }}
              </div>
            </div>
            <div class="badge-wrap flex-row">
              <app-gravity-badge
                *ngFor="let badge of item?.skills | orderBy : ['skillOrder']"
                [badgeStyleSelected]="
                  'accent ' + gravityTheme.contrast
                "
                [badgeStyle]="'dark-grey'"
                [badge]="badge.skill"
                [isPresentMode]="true"
                [isFeatured]="badge.skill.mainSkill"
                [locale]="lang"
                [showSkills]="true"
              >
              </app-gravity-badge>
            </div>
          </div>
          <section
            *ngIf="item?.references?.length > 0"
            class="row g-0 reference-feedback-section bg-accent"
          >
            <div class="col-xl-10 offset-xl-2">
              <div class="d-flex flex-wrap flex-md-nowrap">
                <div class="quote-area pe-3 pb-3">
                  <img
                    alt="project quote"
                    class="quote-icon"
                    src="../../../../../assets/images/quote.svg"
                  />
                </div>
                <div class="quote-text-area">
                  <div
                    *ngFor="let reference of item.references"
                    class="reference-item"
                  >
                    <div
                      [innerHTML]="reference.feedback"
                      class="reference-quote bg-accent {{
                        gravityTheme.contrast
                      }}"
                    ></div>
                    <div *ngIf="reference.contactAllowed">
                      <div
                        class="reference-contact bg-accent {{
                          gravityTheme.contrast
                        }}"
                      >
                        {{ reference.contact?.fullName }}
                      </div>
                      <div
                        class="reference-company bg-accent {{
                          gravityTheme.contrast
                        }}"
                      >
                        {{
                          reference.contact?.position &&
                          reference.contact?.company?.name
                            ? reference.contact?.position + " - "
                            : ""
                        }}{{ reference.contact?.company?.name }}
                      </div>
                    </div>
                    <div *ngIf="!reference.contactAllowed">
                      <div
                        class="reference-contact bg-accent {{
                          gravityTheme.contrast
                        }}"
                      >
                        {{ "PROJECT.ANONYMOUS_REFERENCE" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  *ngIf="!filteredProjectList || filteredProjectList.length == 0"
  class="text-center">
  <h3>{{ "PROJECT.EMPTY_PROJECTS" | translate }}</h3>
</div>

<ng-template #backupText>
  <span class="button-link__text"
    [ngClass]="{'button-link__text--light': gravityTheme.contrast === 'light'}">
    {{ 'SKILL_UP.OPEN_LINK' | translate }}
  </span>
</ng-template>

<ng-template #backupText>
  <span class="button-link__text"
    [ngClass]="{'button-link__text--light': gravityTheme.contrast === 'light'}">
    {{ 'SKILL_UP.OPEN_LINK' | translate }}
  </span>
</ng-template>
