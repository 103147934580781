<section class="pin-protected-page">
  <nb-layout>
    <nb-layout-column>

      <div class="blue-circle"></div>
      <div class="grey-circle"></div>
      <div class="red-circle"></div>

      <app-gravity-logo [width]="15" [wrapClass]="'mx-auto mt-8 position-relative'"></app-gravity-logo>

      <div class="pin-reference mx-auto mt-7 mb-9 position-relative">

        <div class="form-section">
          <h3 class="h1-title"> {{'GENERAL.WELCOME'| translate}} </h3>
          <h5 class="copy-big-subtitle"> {{'REFERENCE.SUBTITLE'| translate}} </h5>

          <form (submit)="submit()" [formGroup]="form">
            <formly-form
              [fields]="fields"
              [form]="form"
              [model]="model"
              [options]="options">
            </formly-form>
            <p class="copy-small-info mb-4"> {{'REFERENCE.HELPER_TEXT'| translate}} </p>

            <div class="text-right my-2">
              <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                {{'REFERENCE.LOGIN_BTN'| translate}}
              </button>
            </div>
          </form>
        </div>
      </div>

    </nb-layout-column>

    <nb-layout-footer>
      <app-web-cv-footer class="footer" linkClass="text-white"></app-web-cv-footer>
    </nb-layout-footer>

  </nb-layout>
</section>
