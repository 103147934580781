<form [formGroup]="form">
  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options">
  </formly-form>
</form>
<!-- <button class="btn btn-primary mb-8" type="submit" [disabled]="!form.valid" (click)="submit()">
  {{'FORM.BUTTON_FILTER'| translate}}
</button> -->