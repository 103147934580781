import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CvSkillUp, GravityTheme, SharedCv, Skill} from '@app/models';
import {AbstractForm} from '@helpers/abstract.form';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '@app/services/ui.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileModalComponent} from '@components/file-modal/file-modal.component';
@Component({
  selector: 'app-section-qualifications',
  templateUrl: './section-qualifications.component.html',
  styleUrls: ['./section-qualifications.component.scss']
})
export class SectionQualificationsComponent extends AbstractForm implements OnInit, OnDestroy {

  @Input() gravityTheme: GravityTheme;
  @Input() webCV: SharedCv;
  @Input() searchVisible: boolean;

  @Output() qualifications = new EventEmitter<any>();
  theme: string;
  start = 0
  end = 2

  skillUpList: CvSkillUp[];
  filteredSkillUpList: CvSkillUp[];
  onDestroy$ = new Subject<void>();
  lang: string;

  model: any = {};

  constructor(protected ts: TranslateService,
              protected ui: UiService,
              private modalService: NgbModal) {
    super(ts, ui);
    this.lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
  }

  ngOnInit(): void {
    this.theme = this.gravityTheme.theme;
    this.skillUpList = this.webCV.skillUps;
    this.filteredSkillUpList = this.webCV.skillUps;
    if(this.theme == 'THEME1' || this.theme == 'THEME2'){
      if(this.searchVisible){
        this.end = this.filteredSkillUpList.length + 1
      }
      else{
        this.end = 2
      }
    }
    else{
      this.end = this.filteredSkillUpList.length + 1
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openFileModal(event, fileUrl: string): void {
    event.preventDefault();
    console.log(fileUrl);
    const modalRef = this.modalService.open(FileModalComponent, {size: 'xl'});
    modalRef.componentInstance.fileUrl = fileUrl;
  }

  isImage(fileUrl: string): boolean {
    return typeof fileUrl === 'string' && fileUrl.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  submit() {
    const valueSearch = this.model.search.trim().toLowerCase();

    if (valueSearch) {
      this.filteredSkillUpList = this.skillUpList.filter(skillUp => {
        const includesTitleEn = skillUp.title.en ? skillUp.title.en.toLowerCase().includes(valueSearch) : false;
        const includesTitleDe = skillUp.title.de ? skillUp.title.de.toLowerCase().includes(valueSearch) : false;
        const inclu1desDescriptionEn = skillUp.description.en ? skillUp.description.en.toLowerCase().includes(valueSearch) : false;
        const inclu1desDescriptionDe = skillUp.description.de ? skillUp.description.de.toLowerCase().includes(valueSearch) : false;
        const includesSkills = (skillUp.skills)
          ? skillUp.skills.some((val: Skill) => val.nameLocalizations[this.lang].trim().toLowerCase().includes(valueSearch))
          : false;

        if (includesTitleEn || inclu1desDescriptionEn || includesTitleDe || inclu1desDescriptionDe || includesSkills) {
          return skillUp;
        }
      });
    } else {
      this.filteredSkillUpList = this.skillUpList;
    }
  }
}
