<ng-template [ngIf]="scenario">
  <ng-container  [ngSwitch]="scenario">
    <ng-container *ngSwitchCase="'teamCustomDomain'">
      <app-team></app-team>
    </ng-container>

    <ng-container *ngSwitchCase="'teamSubdomain'">
      <app-team></app-team>
    </ng-container>

    <ng-container *ngSwitchCase="'userCustomDomain'">
      <app-single-user></app-single-user>
    </ng-container>

    <ng-container *ngSwitchCase="'userDefault'">
      <app-single-user></app-single-user>
    </ng-container>

    <ng-container *ngSwitchCase="'default'">
      <router-outlet></router-outlet>
    </ng-container>
  </ng-container>
</ng-template>
