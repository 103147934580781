import {Component} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FieldArrayType} from '@ngx-formly/core';
import {Observable} from 'rxjs';
import {HelperService} from '@helpers/helper.service';

@Component({
  selector: 'app-repeat-language',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
      <formly-field class="col" [field]="field"></formly-field>
      <div class="repeatable-delete-item"
           [ngClass]="hasLabel ? 'has-label' : ''"
           [attr.aria-label]="to.removeText">
        <i class="gvcv-icon icon-close focusable"
           tabindex="0"
           (click)="remove(i)"
           (keypress)="remove(i)"
           [title]="to.removeText"
           role="button"></i>
      </div>
    </div>
    <div>
      <div class="repeatable-add-item focusable"
           tabindex="0"
           (click)="add()"
           (keypress)="add()"
           [title]="to.addText"
           *ngIf="!hasLimit()">
        <i class="gvcv-icon icon-add-circle me-2"></i>
        {{ to.addText }}
      </div>
    </div>
  `,
  styleUrls: ['repeatable.component.scss'],
})
export class RepeatableComponent extends FieldArrayType {
  constructor(){
    super()
  }
  // TODO: add explicit constructor


  get hasLabel(): boolean {
    if (typeof this.to.hasLabel === 'boolean') {
      return this.to.hasLabel;
    }
    return true;
  }

  add(i?: number, initialModel?: any) {
    if (!this.hasLimit()) {
      i = HelperService.isNullOrUndefined(i) ? this.field.fieldGroup.length : i;
      this.model.splice(i, 0, initialModel ? this.clone(initialModel) : undefined);

      (this.options as any)._buildForm(true);
    }
  }

  hasLimit() {
    if (!!this.to.repeatLimit && Number.isInteger(this.to.repeatLimit)) {
      return this.field.fieldGroup.length >= this.to.repeatLimit;
    }
    return false;
  }

  clone(value: any): any {
    if (!HelperService.isObject(value) || value instanceof RegExp ||
      value instanceof Observable || value.changingThisBreaksApplicationSecurity) {
      return value;
    }

    if (value instanceof AbstractControl) {
      return null;
    }

    if (value instanceof Date) {
      return new Date(value.getTime());
    }

    if (Array.isArray(value)) {
      return value.slice(0).map(v => this.clone(v));
    }

    value = Object.assign({}, value);
    Object.keys(value).forEach(k => value[k] = this.clone(value[k]));

    return value;
  }
}
