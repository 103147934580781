import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultTemplateComponent} from './default-template.component';
import {SectionMainComponent} from './section-main/section-main.component';
import {SectionSidebarComponent} from './section-sidebar/section-sidebar.component';
import {SectionBioComponent} from './section-bio/section-bio.component';
import {SectionRateComponent} from './section-rate/section-rate.component';
import {SectionProjectComponent} from './section-project/section-project.component';
import {SectionSkillsComponent} from './section-skills/section-skills.component';
import {SectionQualificationsComponent} from './section-qualifications/section-qualifications.component';
import {ComponentsModule} from '@components/components.module';
import {NbButtonModule, NbCardModule, NbLayoutModule} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {NgbModule, NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {PipesModule} from '@helpers/pipes/pipes.module';
import {CvTabsComponent} from './cv-tabs/cv-tabs.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SkillTabsComponent } from './skill-tabs/skill-tabs.component';
import { SectionFilterComponent } from './section-filter/section-filter.component';
import {MatTabsModule} from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    DefaultTemplateComponent,
    SectionMainComponent,
    SectionSidebarComponent,
    SectionBioComponent,
    SectionRateComponent,
    SectionProjectComponent,
    SectionSkillsComponent,
    SectionFilterComponent,
    SectionQualificationsComponent,
    CvTabsComponent,
    SkillTabsComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NbLayoutModule,
    NbCardModule,
    NbButtonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    NgbNavModule,
    PipesModule,
    NgbModule,
    NgbTooltipModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    NgApexchartsModule,
    MatTabsModule
  ],
  exports: [
    DefaultTemplateComponent,
    SectionMainComponent,
    SectionSidebarComponent,
    SectionBioComponent,
    SectionRateComponent,
    SectionProjectComponent,
    SectionSkillsComponent,
    SectionQualificationsComponent,
    CvTabsComponent,
    SkillTabsComponent
  ]
})
export class DefaultModule {
}
