import {ModelAdapter} from '@api/interfaces/resourceAdapter';
import {HelperService} from '@helpers/helper.service';

export enum AvailabilityType {
  TEXT = 'TEXT',
  PERCENT = 'PERCENT'
}

export class Availability {
  date: string | Date;
  value: string;
  type: AvailabilityType;
  typeValue?: string;
  isDateOnly?: boolean;
  valueText?: string;
  valuePercent?: string;
}

export class AvailabilityAdapter implements ModelAdapter {
  fromJson(json: any): Availability {
    const obj = new Availability();

    obj.date = json.date;
    obj.value = json.value;
    obj.type = json.type;
    obj.typeValue = AvailabilityType[json.type];
    obj.isDateOnly = true;

    if (typeof json.date === 'string') {
      obj.date = HelperService.string2Date(json.date);
    }

    if (!json.type) {
      obj.type = AvailabilityType.TEXT;
    }

    switch (json.type) {
      case AvailabilityType.TEXT:
        obj.valueText = json.value;
        break;
      case AvailabilityType.PERCENT:
        obj.valuePercent = json.value;
        break;
    }

    return obj;
  }

  toJson(data: Availability): any {

    if (data.date && typeof data.date !== 'string') {
      data.date = HelperService.toUTCDate(data.date);
    }

    let value;
    switch (data.type) {
      case AvailabilityType.TEXT:
        value = data.valueText;
        break;
      case AvailabilityType.PERCENT:
        value = data.valuePercent;
        break;
    }

    return {
      date: data.date,
      type: data.type,
      value,
    };
  }
}
