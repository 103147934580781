import {SkillTiny} from '@models/skillfeed/skill';

export class SkillCategory {

  static LIST_NAME = 'skill-categories';

  id: number;
  user_id: number;
  name: string;
  isEditing?: boolean;
  skills?: SkillTiny[];
}
