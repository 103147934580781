<div *ngIf="displayName; else noName"
     [ngClass]="{'custom': customClass}"
     class="d-inline-flex">

  <span (click)="onSelectItem()" *ngIf="isPresentMode; else regularMode"
        [ngClass]="{featured: isFeatured}"
        class="btn-tag btn-{{isFeatured ? badgeStyleSelected : badgeStyle}}">
      <i *ngIf="isFeatured" class="icon-featured gvcv-icon icon-crown"></i>
      <span [innerHtml]="displayName"></span>
  </span>

  <ng-template #regularMode>
    <ng-container *ngIf="isSkill">
      <span class="stars" *ngFor="let star of starsArray; index as idx">
        <img src="assets/icons/star.svg" width="6" height="6" [ngStyle]="{'transform': 'translate('+idx*8+'px)'}" class="gvcv-icon star"/>
      </span>
    </ng-container>
    <span (click)="onSelectItem()"
          [ngClass]="{deletable: isDeletable,
                      selectable: isSelectable,
                      featured: isFeatured,
                      'is-selected': isSelected}"
          class="btn-tag btn-{{isSelected ? badgeStyleSelected : badgeStyle}}">
        <i *ngIf="isFeatured" class="icon-featured gvcv-icon icon-crown"></i>
        <span [innerHtml]="displayName"></span>
    </span>
  </ng-template>

  <a (click)="onMenu()" *ngIf="isDeletable || isSelectable" class="{{isMenuMode ? 'btn-tag-middle' : 'btn-tag-delete'}} {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="gvcv-icon" viewBox="0 0 16 16">
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
    </svg>
  </a>

  <a (click)="removeStar()" *ngIf="isMenuMode && isStarsEditable" style="padding-right: 2px" class="btn-tag-middle {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <img src="assets/icons/starGrey.svg" width="20" height="20" class="gvcv-icon"/>
  </a>

  <a (click)="addStar()" *ngIf="isMenuMode && isStarsEditable" style="padding-left: 2px" class="btn-tag-middle {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <img src="assets/icons/star.svg" width="20" height="20" class="gvcv-icon"/>
  </a>

  <a (click)="onDeleteItem()" *ngIf="isMenuMode && isDeletable" class="btn-tag-delete {{isSelected ? 'is-selected' : ''}} style-{{isSelected ? badgeStyleSelected : badgeStyle}}">
    <img src="assets/icons/close.svg" width="24" height="24" class="gvcv-icon"/>
  </a>

</div>

<ng-template #noName>
  <div [ngClass]="{'custom': customClass}" class="d-inline-flex">
  <span class="btn-tag empty"><span [innerHtml]="''"></span></span>
  </div>
</ng-template>
