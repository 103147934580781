import {ModelAdapter} from '@api/interfaces/resourceAdapter';

export class UserLanguage {
  created: Date;
  id: number;
  label: string;
  updated: Date;
  userId: number;
  value: string;
}

export class UserLanguageArray {
  languages: UserLanguage[];
}

export class UserLanguageAdapter implements ModelAdapter {

  fromJson(json: any): UserLanguage {
    const obj = new UserLanguage();

    obj.created = json.created;
    obj.id = json.id;
    obj.label = json.label;
    obj.updated = json.updated;
    obj.userId = json.userId;
    obj.value = json.value;

    return obj;
  }

  toJson(data: UserLanguage): any {
    return {
      id: data.id || null,
      userId: data.userId || null,
      label: data.label || '',
      value: data.value || '',
    };
  }

  fromJsonArray(data: any): UserLanguageArray | any {
    if (!data) {
      return [{languages: new UserLanguage()}];
    }
    return {languages: data.map(item => this.fromJson(item))};
  }

  toJsonArray(data: any): any {
    if (!data) {
      return [];
    }

    return data.map(item => this.toJson(item));
  }
}
