<div *ngIf="gravityTheme && webCV">
  <div [ngSwitch]="gravityTheme.theme">

    <ng-container *ngSwitchCase="'THEME1'">
      <app-theme-landing
        [gravityTheme]="gravityTheme"
        [webCV]="webCV"
        [usernameOrHash]="usernameOrHash"
        [langSwitcher]="languageSwitcher"
        [isSendout]="isSendout">
      </app-theme-landing>
    </ng-container>

    <ng-container *ngSwitchCase="'THEME2'">
      <!-- <iframe src="https://certy.px-lab.com/html/demo1/" [ngStyle]="{height:'-webkit-fill-available', width:'100%'}">
      </iframe> 
    [gravityTheme]="gravityTheme"
      [webCV]="webCV"
      [usernameOrHash]="usernameOrHash"
      [langSwitcher]="languageSwitcher"
      [isSendout]="isSendout"
    -->
    <app-theme-landing2
    [gravityTheme]="gravityTheme"
    [webCV]="webCV"
    [usernameOrHash]="usernameOrHash"
    [langSwitcher]="languageSwitcher"
    [isSendout]="isSendout">
  </app-theme-landing2>
   
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-default-template
        [gravityTheme]="gravityTheme"
        [webCV]="webCV"
        [usernameOrHash]="usernameOrHash"
        [langSwitcher]="languageSwitcher"
        [isSendout]="isSendout">
      </app-default-template>
    </ng-container>

  </div>
</div>

<app-preloader></app-preloader>
